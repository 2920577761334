import React, { 
  useEffect,
 } from "react";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

interface AuthenticatedAppProps {
  children: React.ReactNode;
}

export function AuthenticatedApp({ children }: AuthenticatedAppProps) {
  const [ , { getProfileSettings }] = useProfileSettingsCache();

  useEffect(() => {
    async function fetchSettings() {
      await getProfileSettings();
    }
    fetchSettings();
  }, [getProfileSettings]);


  return <>{children}</>;
}

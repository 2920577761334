import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

type State = {
  disbled: boolean;
  visible: boolean;
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  setDisabled:
    (disabled: boolean) =>
    ({ setState }: StoreApi) => {
      setState({
        disbled: disabled,
      });
    },
  setVisble:
    (visible: boolean) =>
    ({ setState }: StoreApi) => {
      setState({
        visible: visible,
      });
    },    
};

const Store = createStore<State, Actions>({
  initialState: {
    disbled: false,
    visible: true,
  },
  actions,
  name: "uploadDropZone"
});

const getUploadDropZoneStates = (state: State) => ({
  disabled: state.disbled,
  visible: state.visible
});

const useUploadDropZoneStates = createHook(Store, {
  selector: getUploadDropZoneStates,
});

const useUploadDropZoneActions = createHook(Store, {
  selector: null,
});

export { useUploadDropZoneActions, useUploadDropZoneStates };

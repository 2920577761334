import React, { 
  useCallback 
} from "react";
import { 
  styled, 
  Box 
} from "@mui/material";
import { 
  borderRadius,
  colors, 
  fontFamily, 
  fontSize, 
  fontWeight, 
  margin
} from "theme/defaultStyle";
import FileUploadIcon from "theme/icons/FileUploadIcon";
import { 
  ErrorCode, 
  FileRejection, 
  FileWithPath, 
  useDropzone 
} from "react-dropzone";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useUploadDropZoneStates } from "utils/context/UploadDropZoneContext";
import { hasValue } from "utils/shared";

const Container = styled(Box)({
  fontFamily: fontFamily.primary,
  "& .drop-zone": {
    marginTop: margin.xlarge,
    height: "201px",
    border: "1px dashed #384EB74D", 
    borderRadius: borderRadius.small,
    background: "#92C4DB1A", 
    minWidth: "100%",
    display: "flex",
  },
  "& .drop-zone-text": {
    margin: "auto",
    textAlign: "center",
    height: "129px",
    width: "344px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  "& .drop-zone-text.hidden": {
    visibility: "hidden",
  },
  "& .drop-zone-text .file-upload-icon": {
    margin: "auto",
  },
  "& .drop-zone-text .drag-and-drop": {
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.medium,
  },
  "& .drop-zone-text .choose-files": {
    fontWeight: fontWeight.normal1,
    fontSize: fontSize.medium,
    color: colors.blue15,
    textDecoration: "underline",
  },
  "& .drop-zone-text .supported-formats": {
    fontWeight: fontWeight.normal1,
    fontSize: fontSize.small,
  },
});

type Props = {
  // disabled?: boolean;
  handleOnUpload: (acceptedFiles:FileWithPath[], fileRejections: FileRejection[]) => void;
}

const UploadDropZone = ({handleOnUpload}:Props) => {
  const { generalConfig } = useConfigContext();  
  const [{disabled, visible}] = useUploadDropZoneStates();

  const allowedFileSize = generalConfig?.verifyPolicyUploadConfiguration?.allowedFileSize ? generalConfig?.verifyPolicyUploadConfiguration?.allowedFileSize : 50;
  const multiple:boolean = hasValue(generalConfig?.verifyPolicyUploadConfiguration?.multiple) ? Boolean(generalConfig?.verifyPolicyUploadConfiguration?.multiple) : true;
  const maxFileSize = (allowedFileSize * 1024 * 1024); 
  function maxFileSizeValidator(file:FileWithPath) {
    if (file.size > maxFileSize) {
      return {
        code: ErrorCode.FileTooLarge,
        message: "File size is too large",
      }
    }
  
    return null;
  }
  
  const getAcceptMimeTypes = () => {
    const accept = new Map();
    if (generalConfig?.verifyPolicyUploadConfiguration?.acceptMimeTypes && 
      generalConfig?.verifyPolicyUploadConfiguration?.acceptMimeTypes.length > 0
    ) {
      generalConfig.verifyPolicyUploadConfiguration.acceptMimeTypes.map((acceptMimeType) => {
        const extensions = acceptMimeType.extensions.map((extension) => extension.extension);
        accept.set(acceptMimeType.mimeType,extensions);
      })
    }
    else {
      accept.set('text/csv',['.csv']);
    }

    return accept;
  }

  const getSupportedExtensions = () => {

    let supportedExtensions = "";
    if (generalConfig?.verifyPolicyUploadConfiguration?.acceptMimeTypes && 
      generalConfig?.verifyPolicyUploadConfiguration?.acceptMimeTypes.length > 0
    ) {
      
      generalConfig.verifyPolicyUploadConfiguration.acceptMimeTypes.map((acceptMimeType) => {
        const extensions = acceptMimeType.extensions.map((extension) => extension.extension);
        if (supportedExtensions.length > 0) {
          supportedExtensions += ", ";
        }
        supportedExtensions += extensions.join(", ");
      })
    }
    else {
      supportedExtensions = ".csv";
    }
    return supportedExtensions;
  }

  const onDrop = useCallback(async (acceptedFiles:FileWithPath[], fileRejections: FileRejection[]) => {
    await handleOnUpload(acceptedFiles, fileRejections);
  }, [])  

  const { getRootProps, getInputProps  } = useDropzone({
    // accept: {"text/csv": ['.csv']},
    accept: Object.fromEntries(getAcceptMimeTypes()),
    validator: maxFileSizeValidator,
    onDrop,
    disabled: disabled,
    multiple: multiple,
  });   
    
  return (
    <>
      <Container>
          <div className="drop-zone" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={`drop-zone-text ${visible ? "":"hidden"} `}>
              <div className="file-upload-icon">
                <FileUploadIcon />
              </div>
              <div className="drag-and-drop">
                Drag and drop register data or&nbsp;
                <span className="choose-files">choose file</span>
              </div>
              <div className="supported-formats">
                Supported description: {getSupportedExtensions()} with a maximum size of {allowedFileSize} MB
              </div>
            </div>
          </div>        
      </Container>
    </>
  )

}

export default UploadDropZone;
import CloseIcon from "@mui/icons-material/Close";
import {
    IconButton,
} from "@mui/material";
import StewartInput from "controls/global/stewart-input";
import { InventoryAssignSearchCriteria } from "entities/UIModel/InventoryAssignSearchCriteria";
import React, {
    useRef,
    useState,
} from "react";

// NOTE: The InputSearch could likely be modified to a single shared control
//          as opposed to only the SearchCriteria props varying on each version.
interface Props {
    name: keyof InventoryAssignSearchCriteria;
    disabled?: boolean;
    onChange: (name: keyof InventoryAssignSearchCriteria, value: string | null) => void;
    defaultValue ?: string;
}
const InputSearch = ({ name, disabled, onChange, defaultValue }: Props) => {
    const [value, setValue] = useState<string>(defaultValue ? defaultValue :"");
    const searchInputRef = useRef<HTMLInputElement>(null);

    const clearInput = () => {
        setValue("");
        if (searchInputRef.current) {
            searchInputRef.current.value = "";
            const event = new Event("input", { bubbles: true });
            Object.defineProperty(event, "target", {
                value: searchInputRef.current,
                enumerable: true,
            });
            searchInputRef.current.dispatchEvent(event);
            const changeEvent = {
                target: searchInputRef.current,
            } as React.ChangeEvent<HTMLInputElement>;
            handleInputChange(changeEvent);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name as keyof InventoryAssignSearchCriteria;
        const currentValue = event.target.value; //.trim() ? event.target.value.trim() : null;
        if (value !== event.target.value.trim()) {
            onChange(name, currentValue);
        }
        setValue(currentValue || "");
    };

    return (
            <StewartInput
                {...{ name }}
                id={name}
                onChange={handleInputChange}
                disabled={disabled}
                label="Search"
                inputRef={searchInputRef}
                InputProps={{
                    endAdornment: !!value && (
                        <IconButton onClick={clearInput} disabled={disabled}>
                            <CloseIcon />
                        </IconButton>
                    ),
                }}
                InputLabelProps={{
                    shrink: !!value
                }}
                value={value}
            />
    );
};

export default InputSearch;

import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

const SerialNumberAssignIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string } = (
  props: SvgIconProps,
) => {
  const { width = "72", height = "72", color = "#9E233A", ...otherProps } = props;

  return (
    <SvgIcon
      {...otherProps}
      viewBox="0 0 72 72"
      style={{ width, height }}
    >
      <g id="Document outline">
        <path
          id="Vector"
          d="M18.375 3C16.6842 3 15.0627 3.67165 13.8672 4.86719C12.6716 6.06274 12 7.68425 12 9.375V60.375C12 62.0658 12.6716 63.6873 13.8672 64.8828C15.0627 66.0784 16.6842 66.75 18.375 66.75H56.625C58.3158 66.75 59.9373 66.0784 61.1328 64.8828C62.3284 63.6873 63 62.0658 63 60.375V22.9534C63 22.423 62.7893 21.9143 62.4142 21.5392L44.4608 3.58579C44.0857 3.21071 43.577 3 43.0466 3H18.375ZM17.6667 9.66667C17.6667 9.11438 18.1144 8.66667 18.6667 8.66667H40.6875V25.3125H57.3333V61.0833H18.6667C18.1144 61.0833 17.6667 60.6356 17.6667 60.0833V9.66667ZM24.75 35.5833V41.25H50.25V35.5833H24.75ZM24.75 46.9167V52.5833H41.25V46.9167H24.75Z"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
};

SerialNumberAssignIcon.muiName = "SvgIcon";

export default SerialNumberAssignIcon;

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
} from 'react';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  gaps,
  margin,
} from "theme/defaultStyle";

export type Choice<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  groupName: string;
  choices: Choice<T>[];
  prompt?: string;
  selected: string;
  setSelected: Dispatch<SetStateAction<T>>;
};

const Title = styled('div')({
  color: colors.grey08,
  display: 'flex',
  fontFamily: fontFamily.primary,
  fontSize: fontSize.xlarge0,
  fontWeight: fontWeight.normal1,
  maxWidth: '375px',
  gap: '20px',
  '& span': {
    textAlign: 'center',
  },
});

const Container = styled('div')({
  display: 'block',
  gap: gaps.large1,
  '& label.MuiFormControlLabel-root': {
    marginRight: margin.zero,
  },
});

function FileSelectionOptions<T>({ groupName, choices, selected, setSelected, prompt }: Props<T>) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value as unknown as T);
  };

  return (
    <>
      <Title>{prompt && <span>{prompt}</span>}</Title>
      <RadioGroup name={groupName} value={selected} onChange={handleChange}>
        <Container>
          {choices.map((c) => (
            <FormControlLabel
              key={c.value as unknown as string}
              value={c.value}
              control={<Radio />}
              label={c.label}
            />
          ))}
        </Container>
      </RadioGroup>
    </>
  );
}

export default FileSelectionOptions;

import React from "react";
import { 
  SvgIcon, 
  SvgIconProps 
} from "@mui/material";

const AddNoteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.4286 4.92857C18.7821 4.92857 19.0714 5.21786 19.0714 5.57143V14.5714H15.8571C15.146 14.5714 14.5714 15.146 14.5714 15.8571V19.0714H5.57143C5.21786 19.0714 4.92857 18.7821 4.92857 18.4286V5.57143C4.92857 5.21786 5.21786 4.92857 5.57143 4.92857H18.4286ZM5.57143 21H14.5714H14.7924C15.4754 21 16.1304 20.7308 16.6125 20.2487L20.2487 16.6125C20.7308 16.1304 21 15.4754 21 14.7924V14.5714V5.57143C21 4.15313 19.8469 3 18.4286 3H5.57143C4.15313 3 3 4.15313 3 5.57143V18.4286C3 19.8469 4.15313 21 5.57143 21ZM8.14286 16.1786C8.14286 15.9228 8.04126 15.6776 7.86042 15.4967C7.67959 15.3159 7.43432 15.2143 7.17857 15.2143C6.92283 15.2143 6.67756 15.3159 6.49672 15.4967C6.31588 15.6776 6.21429 15.9228 6.21429 16.1786C6.21429 16.4343 6.31588 16.6796 6.49672 16.8604C6.67756 17.0413 6.92283 17.1429 7.17857 17.1429C7.43432 17.1429 7.67959 17.0413 7.86042 16.8604C8.04126 16.6796 8.14286 16.4343 8.14286 16.1786ZM7.17857 6.85714C6.92283 6.85714 6.67756 6.95874 6.49672 7.13958C6.31588 7.32041 6.21429 7.56568 6.21429 7.82143C6.21429 8.07717 6.31588 8.32244 6.49672 8.50328C6.67756 8.68412 6.92283 8.78571 7.17857 8.78571C7.43432 8.78571 7.67959 8.68412 7.86042 8.50328C8.04126 8.32244 8.14286 8.07717 8.14286 7.82143C8.14286 7.56568 8.04126 7.32041 7.86042 7.13958C7.67959 6.95874 7.43432 6.85714 7.17857 6.85714ZM8.14286 12C8.14286 11.7443 8.04126 11.499 7.86042 11.3181C7.67959 11.1373 7.43432 11.0357 7.17857 11.0357C6.92283 11.0357 6.67756 11.1373 6.49672 11.3181C6.31588 11.499 6.21429 11.7443 6.21429 12C6.21429 12.2557 6.31588 12.501 6.49672 12.6819C6.67756 12.8627 6.92283 12.9643 7.17857 12.9643C7.43432 12.9643 7.67959 12.8627 7.86042 12.6819C8.04126 12.501 8.14286 12.2557 8.14286 12Z" />
    </SvgIcon>
  );
};

export default AddNoteIcon;

import React, { 
  useCallback,
  useEffect,
  useRef,
  useState 
} from "react";
import { 
  Box, 
  Grid, 
  styled 
} from "@mui/material";
import { 
  FormProvider, 
  useForm, 
  // useWatch
} from "react-hook-form";
import { gaps } from "theme/defaultStyle";
import FormPrefixGrid from "./FormPrefixGrid";
import {
  checkIfInventoryModifyFormIsDirty,
  getNextFormPrefixItem,
  hasAnyFormPrefixItemData,
  isCompanyLocationType,
  isValidLocation,
  validateOrigCompanyLocation,
  validateSerialNumberOverlapping,
  validateSerialNumberRange,
} from "./InventoryModifyNewHelper";
import TextInputField from "controls/global/text-input-field";
import AgencyLocationField from "../../components/AgencyLocationField";
import { CompanyLocation } from "entities/UIModel/CompanyLocation";
import ActionButton from "controls/global/action-button";
import { yupResolver } from "@hookform/resolvers/yup";
import modifyValidationSchema from "./ModifyValidationSchema";
import { 
  ErrorMessage, 
  InventoryModifyType, 
  SerialNumberRangeType, 
} from "pages/modify/new/enum";
import { hasValue } from "utils/shared";
import useInventoryModify from "./useInventoryModify";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import useModifyStore from "utils/context/InventoryModifyContext";
import { 
  InventoryModifyDetail, 
  InventoryModifySaveRequest 
} from "entities/ApiModel";
import SerialNumberStatusField from "./SerialNumberStatusField";
import ConfirmationDialogV2 from "controls/global/dialogs/confirmation-dialog/ConfirmationDialogV2";
import { InventoryModifyForm } from "entities/UIModel/InventoryModifyForm";
import InventoryModifyTypeField from "./InventoryModifyTypeField";
import HeaderRefs from "pages/utils/HeaderRefs";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "utils/context/NavigationContext";
import {
  ActionResultStatus,
  FlashMessageType,
  NavigateToType,
  PERMISSIONS,
  TabTriggerType,
  UIConstants,
} from "utils/data/enum";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import {
  InventoryModifyFormPrefixItem,
  NavigateToInfo,
} from "entities/UIModel";
import TabRefs from "controls/global/tab-navigator/TabRefs";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import FlashMessage from "controls/global/flash-message/FlashMessage";
import { useFlashMessageActions } from "utils/context/FlashMessageContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import useFlashMessage from "utils/custom-hooks/useFlashMessage";
import { isValidNotes } from "pages/utils/pageHelper";

const ContainerBox = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: gaps.small2,
});

const ModificationTypeBox = styled(Box)({});

const FormPrefixBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.small2,
});

const AgencyBox = styled(Box)({});

const NoteBox = styled(Box)({});

const SubmitBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const TRANSFER_ORIG_LOCATION_LABEL = "Current Agency/Legacy ID (Location)";
const EDIT_ORIG_LOCATION_LABEL = "Agency/Legacy ID (Location)"

const MAIN_SAVE_MESSAGE = "Save changes?";
const ADDITIONAL_SAVE_MESSAGE = 'Click "Save" to save your changes. Click “Continue” to navigate away without saving.';
const MAIN_EXIT_MESSAGE = "Exit without submitting?";
const ADDITIONAL_EXIT_MESSAGE = "Leaving will result in data loss.";

const TRANSFER_SUCCESS_MESSAGE = "Transfer successfully completed!";
const EDIT_SUCCESS_MESSAGE = "Edit successfully completed!";

const MODIFY_ERROR_MESSAGE_HEADER = "Modifcation Failed!";
const MODIFY_ERROR_MESSAGE_DETAIL = "Please try again. ";

const initialValues: InventoryModifyForm = {
  inventoryModifyTypeCode: InventoryModifyType.Transfer,
  formPrefixItems: [getNextFormPrefixItem([])],

  isValidOrigLocation: false,
  origCompanyID: "",
  origCompanyName: "",
  origCompanyLegacyID: "",
  origLocationUniqueID: "",
  origLocationLegacyID: "",
  origLocationDisplayName: "",
  origLocationFullDisplayName: "",
  origLocationStateCode: "",
  origLocationStateAbbr: "",

  selectedNewLocationFullDisplayName: "",
  newCompanyID: "",
  newCompanyName: "",
  newCompanyLegacyID: "",
  newLocationUniqueID: "",
  newLocationLegacyID: "",
  newLocationDisplayName: "",
  newLocationFullDisplayName: "",
  newLocationStateCode: "",
  newLocationStateAbbr: "",

  isNewLocationValidationRequired: false,
  newLocationErrorMessage: "",

  oldSerialNumberStatusTypeCode: "",
  newSerialNumberStatusTypeCode: "",
  isNewSNStatusTypeCodeValidationRequired: false,

  notes: "",
  isNotesValidationRequired: false,
};

interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
  tabSaveChangesRef: any;
}

const SAVE_MODIFY_IN_PROGRESS_MESSAGE = "Please wait while saving your changes";

const InventoryModifyNew = ({ saveFileRef, saveFileChangeRef, tabSaveChangesRef }: Props) => {
  const getResolver = useCallback(
    async (data: any, context: any, options: any) => {
      const ctx = { ...context }; // can include more validation data here if needed
      return yupResolver(modifyValidationSchema)(data, ctx, options);
    },
    []
  );

  const methods = useForm({
    resolver: getResolver, 
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: initialValues,
  });
  const {
    setValue,
    getValues,
    clearErrors,
    trigger,
    reset,
    watch,
  
  } = methods;
  const { setFPIErrorMessagesByErrorCode } = useInventoryModify();
  const [selectedInventoryModifyTypeCode, setSelectedInventoryModifyTypeCode] =
    useState<string>(InventoryModifyType.Transfer);
  const [
    selectedOrigLocationFullDisplayName,
    setSelectedOrigLocationFullDisplayName,
  ] = useState<string | undefined>(undefined);
  const [origLocationFullDisplayNameLabel, setOrigLocationFullDisplayNameLabel] = useState(TRANSFER_ORIG_LOCATION_LABEL);
  const [addItemDisabled, setAddItemDisabled] = useState<boolean>(true);  
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const [, { saveModify }] = useModifyStore();
  const [{ nextTab }, { setTabTriggerType }] = useNavigation();
  const { navigateAfterSaveChangesDialog } = useNavRedirect();
  
  const [newLocationFullDisplayName, newSerialNumberStatusTypeCode, notes] = watch([
      "newLocationFullDisplayName" as const,
      "newSerialNumberStatusTypeCode" as const,
      "notes" as const,
   ]);
  const firstFormPrefix = getValues("formPrefixItems.0.formPrefix");
  const firstSerialNumberBegin =  getValues("formPrefixItems.0.serialNumberBegin");
  const firstSerialNumberEnd = getValues("formPrefixItems.0.serialNumberEnd");


  const nextInventoryModifyTypeCode = useRef(""); 
  const isSaving = useRef(false);

  // Dialog's states and confirmation dialog
  const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);
  const [openExitDialog, setOpenExitDialog] = useState<boolean>(false);
  const [openTabExitDialog, setOpenTabExitDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const [, { navigateToNextUrl }] = useNavigation();  

  // FlashMessage 
  const [, { showFlashMessage, closeFlashMessage }] = useFlashMessageActions();  
  useFlashMessage();

  // Error dialog states
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [correlationId, setCorrelationId] = useState("");
  const [{profileSettings}] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];
  const hasInventoryTransferModifyPermission = permissionCodes.includes(PERMISSIONS.INV_TRANSFER_MODIFY);
  const hasInventoryEditModifyPermission = permissionCodes.includes(PERMISSIONS.INV_EDIT_MODIFY);

  function getOrigLocationLabel(inventoryModifyTypeCode: string) {
    return inventoryModifyTypeCode === InventoryModifyType.Transfer
      ? TRANSFER_ORIG_LOCATION_LABEL
      : EDIT_ORIG_LOCATION_LABEL;
  }

  const validateNewLocation = () => {
    const newLocationUniqueID = getValues("newLocationUniqueID");
    const newLocationFullDisplayName = getValues("newLocationFullDisplayName");
    const selectedNewLocationFullDisplayName = getValues(
      "selectedNewLocationFullDisplayName"
    );
    if (
      !newLocationUniqueID ||
      !selectedNewLocationFullDisplayName ||
      newLocationFullDisplayName?.toLowerCase() !==
        selectedNewLocationFullDisplayName?.toLowerCase()
    ) {
      setValue("newLocationErrorMessage", "Invalid Receiving Agency");
    }
    else {
      setValue("newLocationErrorMessage", "");
    }    
  }

  const handleOnClickModificationType = (event: any) => {
    nextInventoryModifyTypeCode.current = event?.target?.value;    
    const formValues = getValues();
    const isDirty = checkIfInventoryModifyFormIsDirty(
      initialValues,
      formValues
    );

    if (isDirty) {
      setOpenSaveDialog(true);
    } else {
      if (event?.target?.value !== selectedInventoryModifyTypeCode) {
        clearErrors();
        reset(initialValues);
        setAddItemDisabled(true);
        setSelectedInventoryModifyTypeCode(event?.target?.value);
        setValue("inventoryModifyTypeCode", event?.target?.value);
        setOrigLocationFullDisplayNameLabel(getOrigLocationLabel(event?.target?.value));
      }

    }
  };

  const clearNewAgencyLocation = () => {
    setValue("selectedNewLocationFullDisplayName", "");
    setValue("newCompanyID", "");
    setValue("newCompanyName", "");
    setValue("newCompanyLegacyID", "");
    setValue("newLocationUniqueID", "");
    setValue("newLocationLegacyID", "");
    setValue("newLocationDisplayName", "");
    setValue("newLocationFullDisplayName", "");
    setValue("newLocationStateCode", "");
    setValue("newLocationStateAbbr", "");
  };

  const handleNewLocationChange = (newLocation: CompanyLocation) => {
    //if (!newLocation || typeof newLocation === "string") return;
    if (!newLocation || typeof newLocation === "string") {
      clearNewAgencyLocation();
      setValue(
        "newLocationFullDisplayName",
        newLocation
      );
      return;
    }

    if (newLocation && isCompanyLocationType(newLocation)) {
      setValue(
        "selectedNewLocationFullDisplayName",
        newLocation.locationFullDisplayName
      );
      setValue("newCompanyID", newLocation.companyID);
      setValue("newCompanyName", newLocation.companyName);
      setValue("newCompanyLegacyID", newLocation.companyLegacyID);
      setValue("newLocationUniqueID", newLocation.locationUniqueID);
      setValue("newLocationLegacyID", newLocation.locationLegacyID);
      setValue("newLocationDisplayName", newLocation.locationDisplayName);
      setValue(
        "newLocationFullDisplayName",
        newLocation.locationFullDisplayName
      );
      setValue("newLocationStateCode", newLocation.locationStateCode);
      setValue("newLocationStateAbbr", newLocation.locationStateAbbr);
    }
  };

  const handleNewLocationBlur = (event: any) => {
    // const newInputValue = event?.target?.value;
    // const newLocationUniqueID = getValues("newLocationUniqueID");
    // const selectedNewLocationFullDisplayName = getValues(
    //   "selectedNewLocationFullDisplayName"
    // );

    // if (
    //   !newLocationUniqueID ||
    //   !newInputValue ||
    //   !selectedNewLocationFullDisplayName ||
    //   newInputValue?.toLowerCase() !==
    //     selectedNewLocationFullDisplayName?.toLowerCase()
    // ) {
    //   clearNewAgencyLocation();
    // }
    validateNewLocation();    
  };

  const clearFPIerrorMessages = (index: number) => {
    setValue(`formPrefixItems.${index}.serialNumberBeginErrorMessage`, "");
    setValue(`formPrefixItems.${index}.serialNumberEndErrorMessage`, "");
  };

  const setModifyTransferValidationInfo = async () => {
    const origLocationUniqueID = getValues("origLocationUniqueID");
    const formValues: InventoryModifyForm = getValues();

    let index = 0;
    if (formValues?.formPrefixItems && formValues.formPrefixItems.length > 0) {
      for (const fpi of formValues.formPrefixItems) {
        // Clear error messages
        clearFPIerrorMessages(index);

        if (
          index === 0 ||
          hasAnyFormPrefixItemData(fpi.formPrefix, fpi.serialNumberBegin, fpi.serialNumberEnd)
        ) {

          // Set initial value
          let isValidSerialNumberBegin = true;
          let isValidSerialNumberRange = true;
          // let isValidLocationForSerialNumberRange = true; // only for index = 0 (1st row)
          // let isValidBothLocationAndSerialNumbers = true; // for index > 0

          setValue(`formPrefixItems.${index}.isValidationRequired`, true);

          // validate overlapping for Serial Number Begin
          

          if (index > 0 && hasValue(fpi.serialNumberBegin)) {
            const formPrefixItems: InventoryModifyFormPrefixItem[] = getValues("formPrefixItems");
            if (validateSerialNumberOverlapping(formPrefixItems, index, fpi.serialNumberBegin!)) {
              setValue(
                `formPrefixItems.${index}.serialNumberBeginErrorMessage`,
                ""
              );
            } else {
              isValidSerialNumberBegin = false;
              setValue(
                `formPrefixItems.${index}.serialNumberBeginErrorMessage`,
                ErrorMessage.InvalidOverlapped
              );

            }
          }

          // validate serial number begin with selected original location
          if (
            isValidSerialNumberBegin &&
            index > 0 &&
            hasValue(fpi.formPrefix) &&
            origLocationUniqueID &&
            hasValue(fpi.serialNumberBegin)
          ) {
            const errorCode = await validateOrigCompanyLocation(
              fpi.formPrefix!,
              fpi.serialNumberBegin!,
              undefined,
              origLocationUniqueID
            );
    
            if (errorCode) {
              if (isValidLocation(errorCode)) {
                setValue(`formPrefixItems.${index}.serialNumberBeginErrorMessage`, "");
              } else {
                isValidSerialNumberBegin = false;
                setFPIErrorMessagesByErrorCode(
                  index,
                  errorCode,
                  SerialNumberRangeType.Begin,
                  setValue,
                );
              }
            }            
          }


          // Validate serial number range,  and show error message and exit when it's invalid
          if (
            isValidSerialNumberBegin &&
            hasValue(fpi.serialNumberBegin) &&
            hasValue(fpi.serialNumberEnd)
          ) {

            // 1. validate serialNumberRange (already ready to be called from Helper)
            const isValid = validateSerialNumberRange(
              fpi.serialNumberBegin,
              fpi.serialNumberEnd
            );

            if (isValid) {
              setValue(`formPrefixItems.${index}.serialNumberEndErrorMessage`, "");
            }
            else {
              isValidSerialNumberRange = false;
              setValue(
                `formPrefixItems.${index}.serialNumberBeginErrorMessage`,
                ErrorMessage.InvalidEndingRange
              );
            }

            // validate orig location with both serial number begin and end
            if (
              isValidSerialNumberBegin &&
              isValidSerialNumberRange &&
              hasValue(fpi.formPrefix) &&
              hasValue(fpi.serialNumberBegin) &&
              hasValue(fpi.serialNumberEnd)
            ) {
              if (index === 0) {
                const errorCode = await validateOrigCompanyLocation(
                  fpi.formPrefix!,
                  fpi.serialNumberBegin!,
                  fpi.serialNumberEnd,
                  undefined
                );
        
                if (errorCode) {
                  if (isValidLocation(errorCode)) {
                    setValue(`formPrefixItems.${index}.serialNumberBeginErrorMessage`, "");
                    setValue(`formPrefixItems.${index}.serialNumberEndErrorMessage`, "");
                  } else {
                    // isValidLocationForSerialNumberRange = false;
                    setFPIErrorMessagesByErrorCode(
                      index,
                      errorCode,
                      SerialNumberRangeType.Both,
                      setValue,
                    );
                  }
                }     
              }
              else { // index > 0
                const errorCode = await validateOrigCompanyLocation(
                  fpi.formPrefix!,
                  fpi.serialNumberBegin!,
                  fpi.serialNumberEnd,
                  origLocationUniqueID
                );
        
                if (errorCode) {
                  if (isValidLocation(errorCode)) {
                    setValue(`formPrefixItems.${index}.serialNumberBeginErrorMessage`, "");
                    setValue(`formPrefixItems.${index}.serialNumberEndErrorMessage`, "");
                  } else {
                    setFPIErrorMessagesByErrorCode(
                      index,
                      errorCode,
                      SerialNumberRangeType.Both,
                      setValue,
                    );
                  }
                }     
              }
            }            
          }
        } 
        else {
          setValue(`formPrefixItems.${index}.isValidationRequired`, false);
          setValue(`formPrefixItems.${index}.serialNumberBeginErrorMessage`,"");
          setValue(`formPrefixItems.${index}.serialNumberEndErrorMessage`,"");
    
        }

        index++;
      }
    }

    if (formValues.inventoryModifyTypeCode === InventoryModifyType.Transfer) {
      setValue("isNewLocationValidationRequired", true);
      setValue("isNotesValidationRequired", true);

      setValue("isNewSNStatusTypeCodeValidationRequired", false);

      validateNewLocation();
      // const newLocationUniqueID = getValues("newLocationUniqueID");
      // const newLocationFullDisplayName = getValues("newLocationFullDisplayName");
      // const selectedNewLocationFullDisplayName = getValues(
      //   "selectedNewLocationFullDisplayName"
      // );
      // if (
      //   !newLocationUniqueID ||
      //   !selectedNewLocationFullDisplayName ||
      //   newLocationFullDisplayName?.toLowerCase() !==
      //     selectedNewLocationFullDisplayName?.toLowerCase()
      // ) {
      //   setValue("newLocationErrorMessage", "Invalid Receiving Agency");
      // }
      // else {
      //   setValue("newLocationErrorMessage", "");
      // }

    }
    else {
      setValue("isNewSNStatusTypeCodeValidationRequired", true);

      setValue("isNewLocationValidationRequired", false);
      setValue("isNotesValidationRequired", false);
    }

  };

  const onYesSaveDialogHandler = async () => {
    setOpenSaveDialog(false);
    const result = await handleSubmit();
    if (result === ActionResultStatus.Success) {
      setSelectedInventoryModifyTypeCode(nextInventoryModifyTypeCode.current);
      setValue("inventoryModifyTypeCode", nextInventoryModifyTypeCode.current);
      setOrigLocationFullDisplayNameLabel(getOrigLocationLabel(nextInventoryModifyTypeCode.current));
    }
  };

  const onNoSaveDialogHandler = () => {
    reset(initialValues);
    setOpenSaveDialog(false);
    setSelectedInventoryModifyTypeCode(nextInventoryModifyTypeCode.current);
    setValue("inventoryModifyTypeCode", nextInventoryModifyTypeCode.current);
    setOrigLocationFullDisplayNameLabel(getOrigLocationLabel(nextInventoryModifyTypeCode.current));
  };

  const onCancelSaveDialogHandler = () => {
    setOpenSaveDialog(false);
  };

  const onYesExitDialogHandler = () => {
    setOpenExitDialog(false);
    navigateToNextUrl(navigate);    
  };

  const onNoExitDialogHandler = () => {
    setOpenExitDialog(false);
    // navigateToNextUrl(navigate);
  };

  const onCancelExitDialogHandler = () => {
    setOpenExitDialog(false);
  };

  const onYesTabExitDialogHandler = () => {
    setOpenTabExitDialog(false);

    const navgiateToInfo: NavigateToInfo = {
      navigateToType: NavigateToType.Tab,
      tabName: nextTab,
    }

    setTabTriggerType(TabTriggerType.System);
    setTimeout(() => {
      navigateAfterSaveChangesDialog(navgiateToInfo);
    }, 100);

  }

  const onNoTabExitDialogHandler = () => {
    setOpenTabExitDialog(false);
  };

  const onCancelTabExitDialogHandler = () => {
    setOpenTabExitDialog(false);
  };  


    
  function handleFlashMessageClose(
    event: React.SyntheticEvent | Event,
    reason?: string
  ) {
    if (reason === "clickaway") {
      return;
    }

    closeFlashMessage();
  }

  const save = async(): Promise<ActionResultStatus> => {
    const formData: InventoryModifyForm = getValues();    
    const inventoryModifyDetails = formData?.formPrefixItems?.map((fpi) => {
      const inventoryModifyDetail: InventoryModifyDetail = {
        FormPrefix: fpi.formPrefix!,
        FormType: fpi.formType,
        ProductType: fpi.productType,
        ProductTypeAbbr: fpi.productTypeAbbr,
        BeginningSerialNumber: fpi.serialNumberBegin!,
        EndingSerialNumber: fpi.serialNumberEnd!,
        OrigCompanyID: formData.origCompanyID,
        OrigCompanyLegacyID: formData.origCompanyLegacyID,
        OrigCompanyName: formData.origCompanyName,
        OrigLocationDisplayName: formData.origLocationDisplayName,
        OrigLocationLegacyID: formData.origLocationLegacyID,
        OrigLocationStateAbbr: formData.origLocationStateAbbr,
        OrigLocationStateCode: formData.origLocationStateCode,
        OrigLocationUniqueID: formData.origLocationUniqueID,

        NewCompanyID: formData.inventoryModifyTypeCode === InventoryModifyType.Transfer
        ? formData.newCompanyID
        : undefined,
        NewCompanyLegacyID: formData.inventoryModifyTypeCode === InventoryModifyType.Transfer
        ? formData.newCompanyLegacyID
        : undefined,

        NewCompanyName: formData.inventoryModifyTypeCode === InventoryModifyType.Transfer
        ? formData.newCompanyName
        : undefined,

        NewLocationDisplayName:  formData.inventoryModifyTypeCode === InventoryModifyType.Transfer
        ? formData.newLocationDisplayName
        : undefined,

        NewLocationLegacyID: formData.inventoryModifyTypeCode === InventoryModifyType.Transfer
        ? formData.newLocationLegacyID
        : undefined,

        NewLocationStateAbbr: formData.inventoryModifyTypeCode === InventoryModifyType.Transfer
        ? formData.newLocationStateAbbr
        : undefined,

        NewLocationStateCode: formData.inventoryModifyTypeCode === InventoryModifyType.Transfer
        ? formData.newLocationStateCode
        : undefined,

        NewLocationUniqueID: formData.inventoryModifyTypeCode === InventoryModifyType.Transfer
        ? formData.newLocationUniqueID
        : undefined,

      };

      return inventoryModifyDetail;
    })
    ;
    const saveRequest: InventoryModifySaveRequest = {
      InventoryModifyTypeCode: formData.inventoryModifyTypeCode,
      InventoryModifyDetails: inventoryModifyDetails || [],
      OrigLocationUniqueID: formData.origLocationUniqueID,
      NewLocationUniqueID:
        formData.inventoryModifyTypeCode === InventoryModifyType.Transfer
          ? formData.newLocationUniqueID
          : formData.origLocationUniqueID, // temporary - undefined,
      OldSerialNumberStatusTypeCode: formData.oldSerialNumberStatusTypeCode,
      NewSerialNumberStatusTypeCode:
        formData.inventoryModifyTypeCode === InventoryModifyType.Edit
          ? formData.newSerialNumberStatusTypeCode
          : undefined,
      Notes: formData.notes,
    };

    const result = await saveModify(saveRequest);
    // console.log("saveRequest:", saveRequest, "result:", result);    

    if (result.status === ActionResultStatus.Success) {
      showFlashMessage(
        FlashMessageType.Success,
        formData.inventoryModifyTypeCode === InventoryModifyType.Transfer
          ? TRANSFER_SUCCESS_MESSAGE
          : EDIT_SUCCESS_MESSAGE,
        ""
      );
      reset(initialValues);
      setValue("inventoryModifyTypeCode", selectedInventoryModifyTypeCode);
      return ActionResultStatus.Success;
    }
    else {
      showFlashMessage(
        FlashMessageType.Error,
        MODIFY_ERROR_MESSAGE_HEADER,
        MODIFY_ERROR_MESSAGE_DETAIL
      );
      return ActionResultStatus.Failed;
    }
  }

  const handleSubmit = async (event?: React.MouseEvent<HTMLElement> | undefined):Promise<ActionResultStatus> => {
    if (!isSaving.current) {
      isSaving.current = true;
      clearErrors();

      try {
        await setModifyTransferValidationInfo();
        const isFormValid = await trigger();

        if (!isFormValid) {
          return ActionResultStatus.Failed;
        }

        openAutomaticProgressDialog(SAVE_MODIFY_IN_PROGRESS_MESSAGE);

        const result = await save();

        closeAutomaticProgressDialog();
        return result;

      } finally {
        isSaving.current = false;
      }
    }
    else {
      return ActionResultStatus.Failed;
    }
  };

  const handleSaveChanges = async() => {
    const formValues = getValues();
    const isDirty = checkIfInventoryModifyFormIsDirty(
      initialValues,
      formValues
    );    
    if (isDirty) {
      setOpenExitDialog(true);
    }
    else {
      navigateToNextUrl(navigate);
    }
  }

  const handleSaveInventoryModify = async() => {
    // Nothing here, but it requires.
  }

  const handleTabSaveChanges = () => {
    const formValues = getValues();
    const isDirty = checkIfInventoryModifyFormIsDirty(
      initialValues,
      formValues
    );    
    if (isDirty) {
      setOpenTabExitDialog(true);
    }
    else {
      const navgiateToInfo: NavigateToInfo = {
        navigateToType: NavigateToType.Tab,
        tabName: nextTab,
      }
      setTabTriggerType(TabTriggerType.System);
      setTimeout(() => {
        navigateAfterSaveChangesDialog(navgiateToInfo);
      }, 100);
      
    }

  }

  const handleOnYesErrorDialog = () => {
    setOpenErrorDialog(false);
    setErrorMessage("");
    setCorrelationId("");
  };

  // const handleDebug = () => {
  //   console.log(getValues());
  //   // showFlashMessage(FlashMessageType.Success, "Test", "this is a test");
  // }

  useEffect(() => {
    if (hasInventoryTransferModifyPermission) {
      setValue("inventoryModifyTypeCode", InventoryModifyType.Transfer);
      setSelectedInventoryModifyTypeCode(InventoryModifyType.Transfer);
      setOrigLocationFullDisplayNameLabel(getOrigLocationLabel(InventoryModifyType.Transfer));
    } else {
      setValue("inventoryModifyTypeCode", InventoryModifyType.Edit);
      setSelectedInventoryModifyTypeCode(InventoryModifyType.Edit);
      setOrigLocationFullDisplayNameLabel(getOrigLocationLabel(InventoryModifyType.Edit));
    }
  }, [hasInventoryTransferModifyPermission, hasInventoryEditModifyPermission]);


  useEffect(() => {
    if (selectedInventoryModifyTypeCode === InventoryModifyType.Transfer) {
      if (
        hasValue(firstFormPrefix) &&
        hasValue(firstSerialNumberBegin) &&
        hasValue(firstSerialNumberEnd) &&
        hasValue(newLocationFullDisplayName) &&
        hasValue(notes) && isValidNotes(notes) // notes?.length <=500
      ) {
        setSubmitDisabled(false);
      } else {
        setSubmitDisabled(true);
      }
  
    }
    else {
      if (
        hasValue(firstFormPrefix) &&
        hasValue(firstSerialNumberBegin) &&
        hasValue(firstSerialNumberEnd) &&
        hasValue(newSerialNumberStatusTypeCode) && notes?.length <=500
      ) {
        setSubmitDisabled(false);
      } else {
        setSubmitDisabled(true);
      }
  
    }
  }, [
    selectedInventoryModifyTypeCode,
    firstFormPrefix,
    firstSerialNumberBegin,
    firstSerialNumberEnd,
    newLocationFullDisplayName,
    newSerialNumberStatusTypeCode,
    notes,
  ]);

  useEffect(() => {
    const triggerValidation = async () => {
      await trigger("notes");
    };

    triggerValidation();
  }, [notes]);
  
  return (
    <>
      <FormProvider {...methods}>
        <ContainerBox>
          <ModificationTypeBox>
            <InventoryModifyTypeField
              name="inventoryModifyTypeCode"
              onClick={handleOnClickModificationType}
              hasTransferModifyPermission={hasInventoryTransferModifyPermission}
              hasEditModifyPermission={hasInventoryEditModifyPermission}
            />
            {/* <InventoryModifyTypeUCField 
              name="inventoryModifyTypeCode"
              value={selectedInventoryModifyTypeCode}
              onClick={handleOnClickModificationType}
            /> */}
          </ModificationTypeBox>
          <FormPrefixBox>
            <FormPrefixGrid
              name="formPrefixItems"
              inventoryModifyTypeCode={selectedInventoryModifyTypeCode}
              setAddItemDisabled={setAddItemDisabled}
              addItemDisabled={addItemDisabled}
              setSelectedOrigLocationFullDisplayName={
                setSelectedOrigLocationFullDisplayName
              }
              showFlashMessage={showFlashMessage}
            />
          </FormPrefixBox>
          <AgencyBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextInputField 
                  label={origLocationFullDisplayNameLabel} //"Current Agency/Legacy ID (Location)"
                  name="origLocationFullDisplayName"
                  defaultValue={selectedOrigLocationFullDisplayName}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {selectedInventoryModifyTypeCode === InventoryModifyType.Transfer ? (
                  <AgencyLocationField
                    label="Receiving Agency/Legacy ID (Location)"
                    name="newLocationFullDisplayName"
                    onChange={handleNewLocationChange}
                    onBlur={handleNewLocationBlur}
                    onClear={clearNewAgencyLocation}
                  />
                ) : (
                  <SerialNumberStatusField
                    label="Status"
                    name="newSerialNumberStatusTypeCode"
                  />
                )}
              </Grid>
            </Grid>
          </AgencyBox>
          <NoteBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInputField label="Notes" name="notes" />
              </Grid>
            </Grid>
          </NoteBox>
          <SubmitBox>
            <ActionButton
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              disabled={submitDisabled}
            >
              Submit
            </ActionButton>
          </SubmitBox>
          {/* <SubmitBox>
            <ActionButton
              variant="outlined"
              color="primary"
              onClick={handleDebug}
              disabled={false}
            >
              Debug
            </ActionButton>
          </SubmitBox> */}
        </ContainerBox>
      </FormProvider>
      <HeaderRefs
        saveFileRef={saveFileRef}
        saveFileChangeRef={saveFileChangeRef}
        handleSaveChanges={handleSaveChanges}
        handleSaveFile={handleSaveInventoryModify}
      />
      <TabRefs
        tabSaveChangesRef={tabSaveChangesRef}
        handleTabSaveChanges={handleTabSaveChanges}
      />
      <ConfirmationDialogV2
        isOpen={openSaveDialog}
        mainMessage={MAIN_SAVE_MESSAGE}
        additionalMessage={ADDITIONAL_SAVE_MESSAGE}
        singleActionButtonText="Save"
        onYes={onYesSaveDialogHandler}
        onNo={onNoSaveDialogHandler}
        buttonNoText={"Continue"}
        onCancel={onCancelSaveDialogHandler}
      />
      <ConfirmationDialogV2
        isOpen={openExitDialog}
        mainMessage={MAIN_EXIT_MESSAGE}
        additionalMessage={ADDITIONAL_EXIT_MESSAGE}
        singleActionButtonText="Exit"
        onYes={onYesExitDialogHandler}
        onNo={onNoExitDialogHandler}
        buttonNoText={"Cancel"}
        onCancel={onCancelExitDialogHandler}
      />
      {/* Dialog for tab */}
      <ConfirmationDialogV2
        isOpen={openTabExitDialog}
        mainMessage={MAIN_EXIT_MESSAGE}
        additionalMessage={ADDITIONAL_EXIT_MESSAGE}
        singleActionButtonText="Exit"
        onYes={onYesTabExitDialogHandler}
        onNo={onNoTabExitDialogHandler}
        buttonNoText={"Cancel"}
        onCancel={onCancelTabExitDialogHandler}
      />
      <FlashMessage
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // autoHideDuration={null}
        autoHideDuration={Number(
          UIConstants.FLASH_MESSAGE_AUTO_HIDDEN_DURATION
        )}
        onClose={handleFlashMessageClose}
        // action={flashMessageAction}
      />
      <ErrorDialog
        isOpen={openErrorDialog}
        confirmationMessage={errorMessage}
        correlationId={correlationId}
        onYes={handleOnYesErrorDialog}
      />
    </>
  );
};

export default InventoryModifyNew;

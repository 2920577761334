import React from 'react';
import styled from 'styled-components';

interface ToastProps {
  title: string;
  message: string;
  onClose: () => void; // Event handler for closing the toast
}

const ToastContainer = styled.div`
  display: flex;
  width: 428px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  z-index: 999; /* Set the z-index value as per your requirement */
  max-width: 540px;
`;

const ToastLeft = styled.div`
  display: flex;
  width: 90px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px 0px 0px 8px;
  background: var(--Info-Gradient-Error, linear-gradient(180deg, #E00000 0%, #A40000 100%));
`;

const CloseIcon = styled.svg`
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
`;

const ToastRight = styled.div`
  display: flex;
  width: 428px;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 8px 8px 0px;
  background: var(--token_neutral-f, #FFF);
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
  position: relative; /* Position relative for absolute positioning of the close icon */
`;

const CloseIconRight = styled.svg`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  margin-left :16px
`;

const OopsHeading = styled.h3`
  color: var(--Text-Main, #212121);
  /* Heading/ H3 : Semi Bold */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ToastDatePicker: React.FC<ToastProps> = ({ title, message, onClose }) => {
  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the click event from propagating to the parent elements
    onClose(); // Call the onClose function provided by the parent component
  };

  return (
    <ToastContainer>
      <ToastLeft>
        <CloseIcon viewBox="0 0 34 34">
        <path d="M17.0007 2.83301C9.16648 2.83301 2.83398 9.16551 2.83398 16.9997C2.83398 24.8338 9.16648 31.1663 17.0007 31.1663C24.8348 31.1663 31.1673 24.8338 31.1673 16.9997C31.1673 9.16551 24.8348 2.83301 17.0007 2.83301ZM24.084 22.0855L22.0865 24.083L17.0007 18.9972L11.9148 24.083L9.91732 22.0855L15.0032 16.9997L9.91732 11.9138L11.9148 9.91634L17.0007 15.0022L22.0865 9.91634L24.084 11.9138L18.9981 16.9997L24.084 22.0855Z" fill="white"/>
        </CloseIcon>
      </ToastLeft>
      <ToastRight onClick={onClose}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <OopsHeading>Oops</OopsHeading>
          <div>{message}</div>
        </div>
        <CloseIconRight viewBox="0 0 24 24" onClick={handleClose}>
          <path d="M18.3007 5.70973C17.9107 5.31973 17.2807 5.31973 16.8907 5.70973L12.0007 10.5897L7.1107 5.69973C6.7207 5.30973 6.0907 5.30973 5.7007 5.69973C5.3107 6.08973 5.3107 6.71973 5.7007 7.10973L10.5907 11.9997L5.7007 16.8897C5.3107 17.2797 5.3107 17.9097 5.7007 18.2997C6.0907 18.6897 6.7207 18.6897 7.1107 18.2997L12.0007 13.4097L16.8907 18.2997C17.2807 18.6897 17.9107 18.6897 18.3007 18.2997C18.6907 17.9097 18.6907 17.2797 18.3007 16.8897L13.4107 11.9997L18.3007 7.10973C18.6807 6.72973 18.6807 6.08973 18.3007 5.70973Z" fill="#666666"/>
        </CloseIconRight>
      </ToastRight>
    </ToastContainer>
  );
}

export default ToastDatePicker;

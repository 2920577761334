import CheckIcon from "@mui/icons-material/Check";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  IconButton,
  styled,
} from "@mui/material";
import ActionButton from "controls/global/action-button";
import React, {
  useEffect,
  useState,
} from "react";
import {
  colors,
  fontSize,
  padding,
} from "theme/defaultStyle";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle,
} from "../stewart-dialog/StewartDialog";

const StyledSupportId = styled("div")({
  fontSize: "0.6em",
  paddingLeft: padding.xlarge1,
  textAlign: "left",
  whiteSpace: "nowrap",
});

const StyledIconButton = styled(IconButton)({
  color: colors.blue15,
  fontSize: fontSize.small1,
});

type Props = {
  confirmationMessage: string | React.ReactNode;
  correlationId?: string;
  isOpen: boolean;
  onYes?: any;
  onNo?: any;
};

const ErrorDialog = ({ confirmationMessage, correlationId, isOpen, onYes }: Props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  if (!confirmationMessage) {
    return null;
  }

  return (
    <StewartDialog open={open} width={450}>
      <StewartDialogTitle type={"ERROR"}>
        <ReportProblemIcon style={{ fontSize: 40, color: colors.white }} />
      </StewartDialogTitle>
      <StewartDialogContent>
        {confirmationMessage}
      </StewartDialogContent>
      <StewartDialogActions>
        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          style={{ width: "80px" }}
          onClick={onYes}
          startIcon={<CheckIcon />}
        >
          OK
        </ActionButton>
      </StewartDialogActions>
      {correlationId && (
        <StyledSupportId>
          <span>Suport ID: {correlationId}</span>
          {/* <IconButton title="Send email to support" className={`fa fa-envelope ${classes.emailToIcon}`} onClick={(e) => { window.location.href = `mailto:CustomerCare@Stewart.com?subject=${correlationId}`; e.preventDefault(); }} /> */}
          <StyledIconButton
            title="Copy to clipboard"
            className={"fa fa-clipboard"}
            onClick={() => navigator.clipboard.writeText(correlationId)}
          />
        </StyledSupportId>
      )}
    </StewartDialog>
  );
};

export default ErrorDialog;

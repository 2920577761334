import React from "react";

function useHover() {
  const [htmlElement, setHtmlElement] = React.useState({
    open: false,
    text: "",
  });

  const elRef = React.useRef<any>();

  const evalHover = (shouldOpen: boolean) => {
    if (!elRef.current) return;
    if (!shouldOpen) {
      setHtmlElement({ open: false, text: "" });
      return;
    }

    const node = elRef.current;
    const compare = node.scrollWidth > node.clientWidth;
    setHtmlElement({
      open: compare,
      text: node.getAttribute("value") || node.innerText || "",
    });
  };

  return { evalHover, htmlElement, elRef };
}

export default useHover;

import { Table } from "@mui/material";
import { PolicyActivity } from "entities/UIModel";
import React, { 
  useState 
} from "react";
import { Order } from "utils/sorting";
import PolicyHistoryHead from "./PolicyHistoryHead";
import PolicyHistoryBody from "./PolicyHistoryBody";

interface Props {
  activities?: PolicyActivity[];
}

const PolicyHistory = ({ activities = [] }: Props) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof PolicyActivity>(
    "activityCreatedDate"
  );

  const handleRequestSort = (property: keyof PolicyActivity) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Table className="history-table">
      <PolicyHistoryHead
        {...{ order, orderBy, onRequestSort: handleRequestSort }}
      />
      <PolicyHistoryBody {...{ order, orderBy, activities }} />
    </Table>
  );
};

export default React.memo(PolicyHistory);

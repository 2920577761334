const isHomePage = (pathname: string) =>
  pathname === "/" || pathname === "/Home";

const isProfileSettingsPage = (pathname: string) => {
  const path = pathname.split("/");
  return (
    path.includes("profile-settings")
  );
};

const isInventoryAssignNew = (pathname: string, saveFileChangeRef: any) => {
  const path = pathname.split("/");
  return (
    saveFileChangeRef?.current && path.includes("inventoryAssign")
  );
};

const isInventoryModifyNew = (pathname: string, saveFileChangeRef: any) => {
  const path = pathname.split("/");
  return (
    saveFileChangeRef?.current && path.includes("inventoryModify")
  );
};

export { 
  isHomePage, 
  isProfileSettingsPage,
  isInventoryAssignNew,
  isInventoryModifyNew,
};

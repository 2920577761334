import { Table } from "@mui/material";
import { PolicyNotes } from "entities/UIModel";
import React, { 
  useState 
} from "react";
import { Order } from "utils/sorting";
import NotesSectionBody from "./NotesSectionBody";
import NotesSectionHead from "./NotesSectionHead";

interface Props {
  notes?: PolicyNotes[];
}

const NotesSection = ({ notes = [] }: Props) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof PolicyNotes>("notesCreatedDate");

  const handleRequestSort = (property: keyof PolicyNotes) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Table className="notes-table">
      <NotesSectionHead
        {...{ order, orderBy, onRequestSort: handleRequestSort }}
      />
      <NotesSectionBody {...{ order, orderBy, notes }} />
    </Table>
  );
};

export default React.memo(NotesSection);

import React, { 
  useState
} from "react";
import { styled } from "@mui/styles";
// import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import useTitle from "utils/custom-hooks/useTitle";
import theme from "theme/default";
import { 
  borderRadius, 
  fontSize, 
  fontWeight, 
  gradients,
  padding,
} from "theme/defaultStyle";
import {
  Box,
  Typography,
} from "@mui/material";
import GradientIcon from "theme/icons/GradientIcon";
import TabNavigator from "controls/global/tab-navigator";

import { TabNavigatorItem } from "entities/UIModel";
import { Modify, PERMISSIONS } from "utils/data/enum";
import ModifyGridHome from "./modifyGrid/InventoryModify";
import InventoryModifyNew from "./new/InventoryModifyNew";
import SerialNumberModifyIcon from "theme/icons/SerialNumberModifyIcon";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

const Container = styled("div")({
  backgroundColor: theme.palette.background.default,
  borderBottomLeftRadius: borderRadius.xlarge,
  borderBottomRightRadius: borderRadius.xlarge,
  position: "relative",
  "& .header": {
    display: "flex",
    alignItems: "center",
  },
  "& .headerContainer": {
    display: "flex",
    alignItems: "center",
    height: "28px",
    margin: "40px 0px 20px 0px",
    justifyContent: "space-between",
  },
  "& .titleQuickReferenceCard": {
    marginLeft: "10px",
    background: gradients.redGradient03, 
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: fontWeight.bold2,
    fontSize: fontSize.xlarge2,
    paddingLeft: "10px",
  },
});

interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
  tabSaveChangesRef: any;
}

const InventoryModifyHome = ({ saveFileRef, saveFileChangeRef, tabSaveChangesRef }: Props) => {
  useTitle("Stewart Connect - Modify");
  const [tabsWithErrors ] = useState<string[]>([]);  
  const [{ profileSettings },] = useProfileSettingsCache();
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);

  const getTabNames = () => {
    let tabNames: TabNavigatorItem[] = [];
    if (
      permissionCodes?.includes(PERMISSIONS.INV_TRANSFER_MODIFY) ||
      permissionCodes?.includes(PERMISSIONS.INV_EDIT_MODIFY)
    ) {
      tabNames.push({
        title: Modify.New,
        disabled: false,
      });
    }

    if (permissionCodes?.includes(PERMISSIONS.INV_MODIFY_VIEW)) {
      tabNames.push({
        title: Modify.Modified,
        disabled: false,
      });
    }    

    return tabNames;

  }

  const tabNames = getTabNames();
  const navigatePolicyTabs = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !evt.shiftKey) {
      const element = evt.target as HTMLElement;
      if (element.tabIndex < 0) {
        const nextTab = tabNames.findIndex(tab => tab.title === element.id) + 1;
        if (nextTab < tabNames.length) {
          evt.preventDefault();
          document?.getElementById(tabNames[nextTab].title)?.focus();
        }
        if (nextTab === tabNames.length) {
          let element = document?.getElementById("New");
          if (!element) {
            element = document?.getElementById("Modified");
          }

          if (element) {
            evt.preventDefault();
            element?.focus();
          }
        }
      }
    }
  }  

  const renderComponents = () => {
    let tabComponents = [];
    if (
      permissionCodes?.includes(PERMISSIONS.INV_TRANSFER_MODIFY) ||
      permissionCodes?.includes(PERMISSIONS.INV_EDIT_MODIFY)
    ) {
      tabComponents.push(
        <InventoryModifyNew
          key={"new-data"}
          saveFileRef={saveFileRef}
          saveFileChangeRef={saveFileChangeRef}
          tabSaveChangesRef={tabSaveChangesRef}
        />
      );
    }

    if (permissionCodes?.includes(PERMISSIONS.INV_MODIFY_VIEW)) {
      tabComponents.push(<ModifyGridHome key={"modified-data"} />);
    }    
    
    return tabComponents;
  };

  return (
    <Container>
      <Box className="headerContainer">
        <Box className="header">    
          <GradientIcon Icon={SerialNumberModifyIcon} />
          <Typography className="titleQuickReferenceCard" variant="h6">
            Modify Inventory
          </Typography>
        </Box>
        {/* <ScrollToTopArrow /> */}
      </Box>
      <TabNavigator
          tabsWithErrors={tabsWithErrors}
          tabs={tabNames}
          padding={padding.medium2}
          onKeyDown={(evt: KeyboardEvent) => {
            navigatePolicyTabs(evt);
          }}
          checkSaveChanges={true}
          tabSaveChangesRef={tabSaveChangesRef}
          >
          {renderComponents()}
        </TabNavigator>      
    </Container>
  );
};

export default InventoryModifyHome;

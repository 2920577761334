import React from 'react';
import styled from 'styled-components';

interface ToastProps {
  onClose:() => void; // Event handler for closing the toast
  message?: string | undefined;
  title?: string | undefined;
  type?: string | undefined;
}

const ToastContainer = styled.div`
  align-items: center;
  display: flex;
  font-family: Arial, sans-serif;
  justify-content: center;
  max-width: 540px;
  padding: 24px 24px 0 24px;
  width: 500px;
  z-index: 999; /* Set the z-index value as per your requirement */
  .cancelBgColor {
    background: var(--Info-Gradient-Error, linear-gradient(180deg, #E00000 0%, #A40000 100%));
  }
  .successBgColor {
    background: var(--Policy-Information-Gradient-Policy-Information-Gradient, linear-gradient(180deg, #01875A 0%, #015737 100%));
  }
  .warningBgColor {
    background: var(--Policy-Information-Gradient-Policy-Information-Gradient, linear-gradient(180deg, #FFC401 0%, #FF9601 100%));
  }
`;

const ToastLeft = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 14px;
  width: 90px;
`;

const CloseIcon = styled.svg`
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
  flex-shrink: 0;
  height: 34px;
  width: 34px;
`;

const ToastRight = styled.div`
  align-items: center;
  background: var(--token_neutral-f, #FFF);
  border-radius: 0px 8px 8px 0px;
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
  display: flex;
  justify-content: space-between;
  padding: 24px;
  position: relative; /* Position relative for absolute positioning of the close icon */
  width: 428px;
`;

const ToastRightSubContainer = styled.div`
  display: flex;
  flex-direction: column; 
  padding-right: 40px;
`;

const CloseIconRight = styled.svg`
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
  flex-shrink: 0;
  height: 24px;
  margin-left :16px;
  position: absolute;
  right: 16px;
  top: 20%;
  transform: translateY(-50%);
  width: 24px;
`;

const Title = styled.h3`
  color: var(--Text-Main, #212121);
  /* Heading/ H3 : Semi Bold */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Message = styled.p`
  color: var(--Policy-Text-Main, #212121);
  /* Body/B2 Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Toast: React.FC<ToastProps> = ({ title, message, onClose, type }) => {
  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the click event from propagating to the parent elements
    onClose(); // Call the onClose function provided by the parent component
  };

  const renderToastIcon = () => {
    switch (type) {
      case 'close':
        return <CloseIcon viewBox="0 0 34 34">
        <path d="M17.0007 2.83301C9.16648 2.83301 2.83398 9.16551 2.83398 16.9997C2.83398 24.8338 9.16648 31.1663 17.0007 31.1663C24.8348 31.1663 31.1673 24.8338 31.1673 16.9997C31.1673 9.16551 24.8348 2.83301 17.0007 2.83301ZM24.084 22.0855L22.0865 24.083L17.0007 18.9972L11.9148 24.083L9.91732 22.0855L15.0032 16.9997L9.91732 11.9138L11.9148 9.91634L17.0007 15.0022L22.0865 9.91634L24.084 11.9138L18.9981 16.9997L24.084 22.0855Z" fill="white"/>
        </CloseIcon>;
      case 'success':
        return <CloseIcon xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
        <path d="M16.9987 2.83325C9.1787 2.83325 2.83203 9.17992 2.83203 16.9999C2.83203 24.8199 9.1787 31.1666 16.9987 31.1666C24.8187 31.1666 31.1654 24.8199 31.1654 16.9999C31.1654 9.17992 24.8187 2.83325 16.9987 2.83325ZM14.1654 24.0833L7.08203 16.9999L9.07953 15.0024L14.1654 20.0741L24.9179 9.32159L26.9154 11.3333L14.1654 24.0833Z" fill="white"/>
      </CloseIcon>;
      case 'warning':
        return  <CloseIcon xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
        <path d="M16.9987 3.33325C9.1787 3.33325 2.83203 9.67992 2.83203 17.4999C2.83203 25.3199 9.1787 31.6666 16.9987 31.6666C24.8187 31.6666 31.1654 25.3199 31.1654 17.4999C31.1654 9.67992 24.8187 3.33325 16.9987 3.33325ZM18.4154 24.5833H15.582V21.7499H18.4154V24.5833ZM18.4154 18.9166H15.582V10.4166H18.4154V18.9166Z" fill="white"/>
      </CloseIcon>;
      default:
        return '';
    }

  }
  return (
    <ToastContainer>
      <ToastLeft className={`${type}BgColor`}>
        {renderToastIcon()}
      </ToastLeft>
      <ToastRight onClick={onClose}>
        <ToastRightSubContainer>
          <Title>{title}</Title>
          <Message>{message}</Message>
        </ToastRightSubContainer>
        <CloseIconRight viewBox="0 0 24 24" onClick={handleClose}>
          <path d="M18.3007 5.70973C17.9107 5.31973 17.2807 5.31973 16.8907 5.70973L12.0007 10.5897L7.1107 5.69973C6.7207 5.30973 6.0907 5.30973 5.7007 5.69973C5.3107 6.08973 5.3107 6.71973 5.7007 7.10973L10.5907 11.9997L5.7007 16.8897C5.3107 17.2797 5.3107 17.9097 5.7007 18.2997C6.0907 18.6897 6.7207 18.6897 7.1107 18.2997L12.0007 13.4097L16.8907 18.2997C17.2807 18.6897 17.9107 18.6897 18.3007 18.2997C18.6907 17.9097 18.6907 17.2797 18.3007 16.8897L13.4107 11.9997L18.3007 7.10973C18.6807 6.72973 18.6807 6.08973 18.3007 5.70973Z" fill="#666666"/>
        </CloseIconRight>
      </ToastRight>
    </ToastContainer>
  );
}

export default Toast;

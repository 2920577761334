import React from "react";
import { 
  styled, 
  Box, 
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead
} from "@mui/material";
import { 
  borderRadius, 
  colors, 
  fontFamily, 
  fontSize, 
  fontWeight, 
  padding,
} from "theme/defaultStyle";
import { InventoryVerifyErrorDetail } from "entities/UIModel";
import TooltipCell from "controls/global/stewart-table/TooltipCell";

const StyledTableContainer = styled(Box)({
  width: "100%",
  // maxHeight: "330px",
  maxHeight: "calc(75vh)",
  overflowX: "auto",
  "& table": {
    maxWidth: "100%",
    minWidth: "100%",
    backgroundColor: colors.white,
    tableLayout: "fixed",
    "& td": {
      border: `1px solid ${colors.grey09}`,
      borderStyle: "solid solid solid solid",
      padding: "8px",
      textWrap: "noWrap",
    },      
    "& td.file-name": {
      width: "auto",
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal1,
    },
    "& td.error-message": {
      width: "auto",
      color: colors.red01,
      fontFamily: fontFamily.secondary,
      fontSize: fontSize.large,
      fontWeight: fontWeight.normal1,
    },
    "& td.delete-file": {
      width: "60px",
      textAlign: "right",
    },
  },
  "& tbody": {
    "& tr:hover td": {
      background: colors.grey10,
    }
  },  
  "& .MuiSelect-select.MuiSelect-outlined, input.MuiInputBase-input.MuiOutlinedInput-input": {
    paddingTop: padding.xsmall,
    paddingBottom: padding.xsmall,
  },
  "& tr:first-child": {
    "& td:first-child": {
      borderTopLeftRadius: borderRadius.medium,
    },
    "& td:last-child": {
      borderTopRightRadius: borderRadius.medium,
    }
  },  

  "& td div": {
    // fontSize: fontSize.large,
    // fontWeight: fontWeight.normal1,
    lineHeight: "150%",
    overflow: "hidden",
    // paddingTop: padding.xsmall,
    textOverflow: "ellipsis",
  },
  // "& thead th": {
  //   fontWeight: fontWeight.bold1,
  // },
  // "& th.error-heading": {
  //   width: "40%",
  // },

  
});

const StyledTableHead = styled(TableHead)( {
  display: "table-header-group",
  userSelect: "none",
  position: "sticky",
  top: "0px",
  backgroundColor: colors.white,

});

const StyledTableHeadRow = styled(TableRow)({

})

const StyledTableHeadCell = styled(TableCell)({
  fontSize: fontSize.medium,
  fontWeight: fontWeight.bold1,
  color: colors.black01,  
  "&.policy-number-heading":{
    width: "auto",
  },
  "&.reporting-agency-heading": {
    width: "23%",
  },
  "&.file-name-number-heading": {
    width: "23%",
  },
  "&.error-heading": {
    width: "40%",
  },

})

const StyledTableRow = styled(TableRow)({
});

const StyledTableCell = styled(TableCell)({
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  padding: padding.zero,
  "&:first-child": {
    paddingLeft: padding.small2,
  },
  "& .MuiTextField-root": {
    maxWidth: "180px",
    minWidth: "120px",
    textOverflow: "ellipsis",
  },
  "& .MuiInputBase-root": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .delete-icon": {
    color: colors.blue15,
  },
  "& .delete-icon-disabled": {
    color: colors.blue11,
  },
});



type Props = {
  inventoryVerifyErrorDetailList: InventoryVerifyErrorDetail[];
  className?: string;
};

const InventoryStatusGrid = ({
  inventoryVerifyErrorDetailList,
  className,
}: Props) => {

  return (
    <StyledTableContainer className={className}>
      <Table className="inventory-verify-error-table">
        <StyledTableHead>
          <StyledTableHeadRow>
            <StyledTableHeadCell className="policy-number-heading">
              POLICY NUMBER            
            </StyledTableHeadCell>
            <StyledTableHeadCell className="reporting-agency-heading">
              REPORTING AGENCY/ID
            </StyledTableHeadCell>
            <StyledTableHeadCell className="file-name-number-heading">
              FILE NAME/NUMBER        
            </StyledTableHeadCell>
            <StyledTableHeadCell className="error-heading">
              ERROR        
            </StyledTableHeadCell>
          </StyledTableHeadRow>    
        </StyledTableHead>
        <TableBody>
          {inventoryVerifyErrorDetailList?.map(
            (inventoryVerifyErrorDetail: InventoryVerifyErrorDetail, index: number) => {
              return (
                <React.Fragment key={`{uploadRequestId}`}>
                  <StyledTableRow key={`{inventoryVerifyErrorDetail.documentIndex}`}>
                    <StyledTableCell className="policy-number">
                      <TooltipCell
                        title={`${inventoryVerifyErrorDetail.policyNumber}`}
                      />
                    </StyledTableCell>
                    <StyledTableCell className="reporting-agency-id">
                      <TooltipCell
                        title={`${inventoryVerifyErrorDetail.reportingAgencyID}`}
                      />
                    </StyledTableCell>
                    <StyledTableCell className="client-file-id">
                      <TooltipCell
                        title={`${inventoryVerifyErrorDetail.clientFileID}`}
                      />
                    </StyledTableCell>                                        
                    <StyledTableCell className="error-message">
                      <TooltipCell
                        title={`${inventoryVerifyErrorDetail.inventoryVerifyErrorMessage}`}
                      />
                    </StyledTableCell>

                  </StyledTableRow>
                </React.Fragment>
              );
            }
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default InventoryStatusGrid;
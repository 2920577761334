import * as yup from "yup";

const requiredErrorMessage: string = "Required";

const ProfileValidationSchema = yup.object().shape({
  displayName: yup.string().required(requiredErrorMessage),
  stewartAccessUsername: yup.string().required(requiredErrorMessage),
  userTimeZone: yup.string().required(requiredErrorMessage),
});

export default ProfileValidationSchema;

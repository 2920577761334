import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Box,
  Container,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, {
  useEffect,
} from "react";
import {
  borderRadius,
  boxShadows,
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
// import { MetaTab } from "utils/context/StewartAccessContext";
import theme from "theme/default";
import TabPanel from "./TabPanel";
import { TabNavigatorItem } from "entities/UIModel";
import { useNavigation } from "utils/context/NavigationContext";
import { TabTriggerType } from "utils/data/enum";

interface Props {
  children?: Array<React.ReactNode>;
  tabs: Array<TabNavigatorItem>;
  // metaTabs?: Array<MetaTab>;
  padding?: string;
  tabsWithErrors?: Array<string>;
  onKeyDown?: (event: KeyboardEvent) => void;
  checkSaveChanges?:boolean;
  tabSaveChangesRef?:any;
  onTabChangeTrigger?:(tabValue: number) => boolean;
  onTabChangeTriggerAction?: () => void;
}

const NavigationContainer = styled(Container)({
  background: "transparent",
  padding: padding.zero,
  [theme.breakpoints.up("sm")]: {
    padding: padding.zero,
  },
  "& .MuiTabs-root": {
    height: "49px",
    marginTop: margin.zero,
    minHeight: "49px",
    "& .MuiTabs-flexContainer": {
      marginTop: margin.zero,
    },
  },
  "& .tabPanel": {
    backgroundColor: colors.white,
    borderRadius: `0 ${borderRadius.xlarge} ${borderRadius.xlarge} ${borderRadius.xlarge}`,
    boxShadow: boxShadows.boxShadow05,
    margin: margin.zero,
    "& > .MuiBox-root": {
      padding: "24px",
    },
  },
  "& .tab": {
    "&.MuiButtonBase-root.MuiTab-root": {
      backgroundColor: colors.grey10,
    },
  },
});

const StyledTabs = styled(Tabs)({
  borderRadius: `${borderRadius.large} ${borderRadius.large} 0 0`,
  boxShadow: boxShadows.boxShadow04,
  height: 48,
  marginBottom: margin.zero,
  marginLeft: margin.zero,
  marginRight: margin.zero,
  marginTop: margin.xsmall1,
  width: "fit-content",
  "& .MuiTabs-scroller": {
    background: colors.grey10,
    "& .MuiTabs-flexContainer": {
      "& .MuiButtonBase-root.MuiTab-root": {
        flexDirection: "row",
        // minWidth: "160px",
        "& p": {
          color: colors.blue05,
          fontSize: fontSize.base,
          fontWeight: fontWeight.bold1,
        },
        opacity: 1,
      },
      "& button.MuiTab-labelIcon": {
        minHeight: 45
      },
      "& .MuiButtonBase-root.MuiTab-root:first-child": {
        borderRadius: `${borderRadius.large} 0 0 0`,
      },
      "& .MuiButtonBase-root.MuiTab-root:last-child": {
        borderRadius: `0 ${borderRadius.large} 0  0`,
      },
      "& .MuiButtonBase-root.MuiTab-root:only-child": {
        borderRadius: `${borderRadius.large} ${borderRadius.large} 0 0`,
      },
      "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
        backgroundColor: colors.white,
        textDecoration: "none",
        "& p": {
          color: colors.blue15,
        },
      },
      "& .MuiSvgIcon-root": {
        color: colors.red01,
        marginRight: margin.xsmall,
      },
    },
  },
});

const TabNavigator = ({
  children,
  tabs,
  padding = margin.xlarge1,
  tabsWithErrors = [],
  onKeyDown,
  checkSaveChanges = false,
  tabSaveChangesRef,
  onTabChangeTrigger,
  onTabChangeTriggerAction,
}: Props) => {
  const [value, setValue] = React.useState(0);
  const [{tabTriggerType}, { setNextTab, setTabTriggerType }] = useNavigation();

  // const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
  //   setValue(newValue);
  // };

  const handleChange = (evt: React.ChangeEvent<{}>, newValue: number) => {
    // console.log("handleChange - tabTriggerTpye:", tabTriggerType);
    setNextTab(tabs[newValue].title);
    if (checkSaveChanges && tabSaveChangesRef.current && tabTriggerType === TabTriggerType.User) {
      evt.preventDefault();
      setTimeout(() => {
        if (tabSaveChangesRef.current) {
          tabSaveChangesRef.current.click();
        }
      }, 100);
      
    }
    else if (onTabChangeTrigger) {
      if (onTabChangeTrigger(newValue)) {
        onTabChangeTriggerAction && onTabChangeTriggerAction();
        evt.preventDefault();
      }
      else {
        setValue(newValue);    
      }
    } 
    else {
      setValue(newValue);
    }

    if (tabTriggerType === TabTriggerType.System) {
      setTimeout(() => {
        setTabTriggerType(TabTriggerType.User);
      }, 100);
    }    
    
  };  

  useEffect(() => {
    const setToZero = () => setValue(0);
    document.addEventListener("documentReset", setToZero);
    return () => {
      document.removeEventListener("documentReset", setToZero);
    };
  });

  useEffect(() => {
    if (children && children.length > 0 && value > children.length - 1) {
      setValue(0);
    }
  }, [value, children]);

  // console.log("RERENDER - tabTriggerTpye:", tabTriggerType);  
  
  const tabValue = (tabs.length - 1) < value ? 0 : value;
  const iconStyles = { fontSize: 20, color: colors.red01 };

  return (
    <NavigationContainer>
      <StyledTabs
        {...{ value: tabValue }}
        onChange={handleChange}
      >
        {tabs.map((tab, index) => {
          const hasErrors = tabsWithErrors.indexOf(tab.title) >= 0;

          return (
            <Tab
              className={"tab"}
              id={tab.title}
              key={`${index}-${tab.title}`}
              icon={
                hasErrors ? <ReportProblemIcon style={iconStyles} /> : undefined
              }
              onKeyDown={(evt: any) => {
                onKeyDown?.(evt);
              }}
              label={<Typography>{tab.title}</Typography>}
              disabled={tab.disabled}
            />
          );
        })}
      </StyledTabs>
      {children ? (
        children.map((child, index) => (
          <Box key={index}>
            <TabPanel
              padding={padding}
              {...{ index, value }}
              classes={"tabPanel"}
            >
              {child}
            </TabPanel>
          </Box>
        ))
      ) : (
        <></>
      )}
    </NavigationContainer>
  );
};

export default TabNavigator;

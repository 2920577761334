import React from "react";
import { 
    SvgIcon, 
    SvgIconProps 
} from "@mui/material";

const PencilIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M3 17.2515V21.0015H6.75L17.81 9.94152L14.06 6.19152L3 17.2515ZM20.71 7.04152C21.1 6.65152 21.1 6.02152 20.71 5.63152L18.37 3.29152C17.98 2.90152 17.35 2.90152 16.96 3.29152L15.13 5.12152L18.88 8.87152L20.71 7.04152Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default PencilIcon;
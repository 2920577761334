import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { LayoutBodyContext } from "layout/LayoutBodyContext";
import {
  colors,
  margin,
padding,
} from "theme/defaultStyle";
import PageSection from "controls/global/page-section";
import { InventoryAssignSearchCriteria } from "entities/UIModel/InventoryAssignSearchCriteria";
import { Order } from "utils/sorting";
import { useUser } from "utils/context/UserContext";
import {
  orderBy as _orderBy,
  debounce
} from "lodash";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { DateTypeCode } from "utils/data/enum";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import { IColumn } from "entities/ApiModel/IColumn";
import { InventoryAssignExportCriteria } from "entities/ApiModel/InventoryAssignExportCriteria";
import { getColumnSearchField } from "utils/columnsGrid";
import InventoryAssignGrid from "./InventoryAssignGrid";
import InventoryAssignHeader from "./InventoryAssignHeader";
import { InventoryAssignUI } from "entities/UIModel/InventoryAssignUI";
import { SelectionConfig } from "controls/global/stewart-table/StewartTable";
import useAssignStore from "utils/context/InventoryAssignContext";
import FileExportDialog from "controls/global/file-export-dialog/FileExportDialog";
import IosShareIcon from '@mui/icons-material/IosShare';

const StyledBoxContainer = styled(Box)({
  position: "relative",
  "& .MuiOutlinedInput-input": {
    marginBottom: margin.xxsmall2,
    marginLeft: margin.small2,
    marginTop: margin.small,
    padding: `${padding.small} ${padding.small2}`,
  },
  "&.image-archive > div > div:nth-child(2):not(.MuiInputAdornment-root)": {
    boxShadow: "none",
    paddingTop: "10px",
  },
  "& div.MuiTableContainer-root": {
    marginTop: `-${margin.medium}`,
  },
  "& div.grid-edit-mode": {
    "& > div.MuiBox-root": {
      color: colors.grey11,
      "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
        color: colors.grey11,
      },
      "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis).Mui-selected": {
        backgroundColor: colors.grey10,
      }
    }
  }
});


const InventoryAssign = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const InitialMessage = "Loading results...";
  const NoRecordsMessage = "No results Found";
  const TimeoutMessage = "Sorry, this search took too long. Please modify your search criteria and try again.";
  const [page, setPage] = useState(1);
  const [data, setData] = useState<InventoryAssignUI[]>();
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [
    { selectedAssignItems, isAdvanceSearch, error: requestError, progressPercent: loadingProgressPercent, refreshData },
    {
      getSearchDocuments,
      getInitialColumnsDefintion,
      getColumnsDefinition,
      setColumnDefinition,
      updateSelectedAssignItems,
      exportFiles,
      updateSelectedAssignItem,
      resetSelectedAssignItem,
      setAdvanceSearch,
      cancelRequests,
    },
  ] = useAssignStore();
  const [readyToSearch, setReadyToSearch] = useState<boolean>(false);
  const [isAdvSearchOpen, setIsAdvSearchOpen] = useState<boolean>(false);
  const { generalConfig } = useConfigContext();

  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof InventoryAssignUI | undefined>("assignedDate");
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [filters, setFilters] = useState<InventoryAssignSearchCriteria | undefined>(undefined);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [partialFilterUpdate, setPartialFilterUpdate] = useState<boolean>(false);
  const [defaultOpenedDate, setDefaultOpenedDate] = useState<Date>(new Date());
  const [userInputDebounceTimeMs, setUserInputDebounceTimeMs] = useState<number>(750);
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);

  const [, { getRowsPerPageSetting, setRowsPerPageSetting }] = useUser();
  const [noRecordsMessage, setNoRecordsMessage] = useState<string[]>([InitialMessage]);
  const [columns, setColumns] = useState<IColumn[]>(getInitialColumnsDefintion(false));
  const [hiddenColumns, setHiddenColumns] = useState<(keyof InventoryAssignUI)[]>
  (getInitialColumnsDefintion(true));
  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
  const [exportedColumns, setExportedColumns] = useState<string[]>([]);
  const bodyRef = useContext(LayoutBodyContext);
  const getDatabaseSortColumnName = (name: string) => {
    const mappedColumnNames: { [key: string]: string } = {
      documentDisplayFileName: "DocumentDisplayFileName",
      assignedUserName: "AssignedBy",
      assignDetailStatusTypeName: "Status",
      companyName: "AgencyName",
      locationDisplayName: "LocationDisplayName",
      formPrefix: "FormPrefix",
      inventoryAssignID: "TicketNumber",
      companyId: "CompanyId",
      policyTypeDesc: "PolicyTypeDesc",
      documentUploadedByUserName: "DocumentUploadedByUserName",
      documentUploadedDate: "DocumentUploadedDate",
      rowsPerPage: "RowsPerPage",
      currentRow: "CurrentRow",
      currentPage: "CurrentPage",
      totalPages: "TotalPages",
      totalRows: "TotalRows",
      locationStateAbbr: "LocationStateAbbr",
      locationLegacyID: "LocationLegacyID",
      companyLegacyID: "CompanyLegacyID",
      documentSize: "DocumentSize",
      documentFileTypeCode: "DocumentFileTypeCode",
      clientFileID: "ClientFileID",
    };
    return mappedColumnNames[name] ?? name;
  };

  const getFileSearchCriteria = () => {
    let criteria: InventoryAssignSearchCriteria;

    criteria = {
      ...filters,
      listCount: rowsPerPage,
      requestedPage: page,
      dateTypeCode: isAdvSearchOpen ? DateTypeCode.Assigned : undefined,
    };
    if (sortColumn) {
      criteria = {
        ...criteria,
        sortColumn: sortColumn,
      };
    }
    return criteria;
  };
  const loadSettings = async () => {
    const debounceTime = generalConfig.userInputDebounceTimeMs;
    const filterDays = generalConfig.filesSearchDefaultLookupDays;
    // const timeoutMs = generalConfig.filesSearchTimeoutMs;

    if (debounceTime) {
      setUserInputDebounceTimeMs(debounceTime);
    }
    if (filterDays) {
      setDefaultOpenedDate(new Date(new Date().getTime() - filterDays * 24 * 60 * 60 * 1000));
    }
   
  };

  const getDefaultFilters = (advanced: boolean): InventoryAssignSearchCriteria => {
    const sortColumn = isAdvSearchOpen ? undefined : "-assignedDate";
    const dateType = advanced ? DateTypeCode.Assigned : undefined;

    const defaultFilters: InventoryAssignSearchCriteria = {
      listCount: rowsPerPage,
      dateTypeCode: dateType,
      requestedPage: 1,
      sortColumn: sortColumn,
    };
    return defaultFilters;
  };

  const performSearch = async () => {
    let criteria = getFileSearchCriteria();
    criteria.isInitialLoad = false;
    if (initialLoad) {
      criteria.isInitialLoad = true;
      setInitialLoad(false);
    }  
    let files: InventoryAssignUI[] | undefined = [];
    try {
      files = await getSearchDocuments({ ...criteria });
      if (files !== undefined) {
        setData(files);
        if (!files?.length) {
          setNoRecordsMessage(getNoRecordsMessage());
          setPage(1);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRowsPerPageChange = async (count: number) => {
    await cancelRequests();
    setRowsPerPageSetting(count);
    setRowsPerPage(count);
    setPartialFilterUpdate(false);
    setPage(1);
  };

  
  const handleToggleAllRows = async () => {
    if (data) {
      updateSelectedAssignItems(data);
    }
  };

  const handleToggleRow = (document: InventoryAssignUI) => {
    updateSelectedAssignItem(document);
  };

  const selectionConfig: SelectionConfig<InventoryAssignUI> = {
    enabled: true,
    selectedRows: selectedAssignItems?.map((d) => d.uniqueIdentifier) ?? [],
    onToggleAllRows: handleToggleAllRows,
    onToggleRow: handleToggleRow,
    isRowSelected: (document: InventoryAssignUI) =>
    selectedAssignItems?.some((d) => d.uniqueIdentifier === document.uniqueIdentifier),
  };
  
  const getNoRecordsMessage = () => {
    if (!filters) {
      return [NoRecordsMessage];
    }
    let firstMessage = "No Matches Found";
    const secondMessage = "Please adjust your search criteria and search again.";
    const keys: (keyof InventoryAssignSearchCriteria)[] = [
      "formPrefix",
      "beginningSerialNumber",
      "endingSerialNumber",
      "locationDisplayName",
      "locationStateAbbr",
      "assignedUserName",
      "assignDetailStatusTypeName",
      "locationLegacyID",
      "inventoryAssignTypeName",
      "inventoryModifyTypeName",
      "policyTypeDesc",
      "companyName",
      "companyLegacyID",
      "inventoryAssignID",
    ];
    let providedFields = keys.map((key) => filters[key]).filter((value) => !!value);
    if (providedFields.length) {
      firstMessage = `No matches found for "${providedFields.join(", ")}"`;
    }
    return [firstMessage, secondMessage];
  };

  const handleAdvancedFilterChange = (name: keyof InventoryAssignSearchCriteria, value: any, shouldSearch: boolean) => {
    if (isAdvSearchOpen) {
      setPartialFilterUpdate(!shouldSearch);
      handleFilterChange(name, value);
    } else {
      handleFilterChange(name, null);
    }
  };

  const handleAdvanceSearchPanelOpen = async (open: boolean) => {
    await cancelRequests();
    setAdvanceSearch(open)
    setIsAdvSearchOpen(open);
    setReadyToSearch(true);
    setPage(1);
  };

  const handlePageChange = async (page: number) => {
    await cancelRequests();
    setPage(page);
  };

  useEffect(() => {
    if (readyToSearch) {
      const search = async () => {
        await performSearch();
        setReadyToSearch(false);
      };

      search();
    }
  }, [readyToSearch]);

  const canSearch = (): boolean => {
    return !partialFilterUpdate && filters !== undefined && Object.values(filters).length > 0 ;
  };

  const debounceSetReadyToSearch = useCallback(
    debounce(() => setReadyToSearch(true), userInputDebounceTimeMs),
    [userInputDebounceTimeMs],
  );

  useEffect(() => {
    if (canSearch()) {
      if (partialFilterUpdate) {
        setPartialFilterUpdate(false);
      }

      debounceSetReadyToSearch();
    }
  }, [
    filters,
    page,
    sortColumn,
    filtersApplied,
    settingsLoaded,
    columns,
    rowsPerPage,
    partialFilterUpdate,
    isAdvanceSearch,
    refreshData,
  ]);

  useEffect(() => {
    const defaultFilters = getDefaultFilters(false);
     setFilters(defaultFilters);
  }, [defaultOpenedDate]);

  
  const getSearchField = (field: keyof InventoryAssignUI) =>
    getColumnSearchField<InventoryAssignUI, InventoryAssignSearchCriteria>(columns, field);

  const handleRequestSort = async (columnName: any, disableToggling: boolean = false) => {
    await cancelRequests();
    let sortBy = getDatabaseSortColumnName(columnName);
    if (!disableToggling && sortColumn === sortBy) {
      sortBy = `-${sortBy}`;
    }

    setOrder(sortBy.startsWith("-") ? "desc" : "asc");
    setOrderBy(columnName.replace("-", "") as keyof InventoryAssignUI);
    setSortColumn(sortBy);
  };

  const handleFilterChange = async (name: keyof InventoryAssignSearchCriteria, value: any) => {
    await cancelRequests();
    const formattedValue = typeof value === "string" ? value.replaceAll(",", "|") : value;
    const updatedFilters = (filters: InventoryAssignSearchCriteria | undefined) => {
      let dateType;
      if (name === "dateTypeCode") {
        dateType = value;
      } else if (name === "assignedDate") {
        handleRequestSort("-assignedDate", true);
      } else {
        dateType = filters?.dateTypeCode;
      }
      
      let adjustedFilters: InventoryAssignSearchCriteria = {
        ...filters,
        [name]: formattedValue,
        dateTypeCode: dateType,
      };
      adjustedFilters = removeInvalidProperties(adjustedFilters).object;
      return adjustedFilters;
    };

    setFiltersApplied(true);
    setFilters(updatedFilters);
  };
  useEffect(() => {
    bodyRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [rowsPerPage]);
  useEffect(() => {
    const getCount = async () => {
      const count = await getRowsPerPageSetting();
      setRowsPerPage(count);
    };

    getCount();
  }, [getRowsPerPageSetting]);

  useEffect(() => {
    const init = async () => {
      await loadSettings();
      resetSelectedAssignItem();
      setSettingsLoaded(true);
    };

    init();
  }, []);

  useEffect(() => {
    const getColumns = async () => {
      const { gridColumns, gridHiddenColumns } = await getColumnsDefinition();
      setColumns(gridColumns);
      setHiddenColumns(gridHiddenColumns);
    };
    getColumns();
  }, [getColumnsDefinition]);

  useEffect(() => {
    let sortColumnChanged = false;
    if (sortColumn) {
      const sortColumnName = sortColumn.replace("-", "");
      hiddenColumns.forEach((c) => {
        const mappedName = getDatabaseSortColumnName(c);
        if (mappedName === sortColumnName) {
          setSortColumn(undefined);
          sortColumnChanged = true;
          return;
        }
      });
    }

    const updatedFilters = removeInvalidProperties({ ...filters });
    if (sortColumnChanged || updatedFilters.deleteCount || data === undefined) {
      setFilters((filters: InventoryAssignSearchCriteria | undefined) => updatedFilters.object);
    }
  }, [hiddenColumns]);

  useEffect(() => {
    handleColumnsModified(columns);
  }, [hiddenColumns]);

  useEffect(() => {
    const exportedCols = _orderBy(columns.filter(c => !hiddenColumns.includes(c.field as keyof InventoryAssignUI)), "position")
      .map(c => c.field);

    setExportedColumns(exportedCols);
  }, [columns ,hiddenColumns]);

  useEffect(() => {
    setInitialLoad(true);
  },[])

  const handleColumnResize = (column: keyof InventoryAssignUI, newWidth: number) => {
    if (column && newWidth > 0) {
      setColumnDefinition([column], "width", newWidth);
    }
  };
  const handleUpdateHiddenColumns = (columns: (keyof InventoryAssignUI)[]) => {
    setHiddenColumns(columns);
  };

  const handleExport = async (allFiles: boolean) => {
    let searchCriteria: InventoryAssignSearchCriteria = {
      ...getFileSearchCriteria(),
    };
    searchCriteria.listCount = 0;
  
    const exportCriteria: InventoryAssignExportCriteria = {
      InventoryAssignDetailIDLists: allFiles
        ? []
        : selectedAssignItems
            .map((items: InventoryAssignUI) => items.inventoryAssignDetailID)
            .filter((id): id is number => id !== undefined),
      ExportColumns: exportedColumns,
      AssignDetailSearchCriteria: (selectedAssignItems.length > 0 && !allFiles) ? {} : searchCriteria
    };
  
    await exportFiles(exportCriteria);
    setOpenExportDialog(false);
  };
  

  const handleColumnsModified = (columns: IColumn[]) => {
    const exportedCols = columns.filter(c => !hiddenColumns.includes(c.field as keyof InventoryAssignUI))
      .map(c => c.field);
    setExportedColumns(exportedCols);
  };

  const visibleColumns = columns
    .filter((c) => c.hideable)
    .map((c) => ({
      field: c.field as keyof InventoryAssignUI,
      name: c.name,
      checked: !hiddenColumns.includes(c.field as keyof InventoryAssignUI),
    }));
    
  const removeInvalidProperties = (object: { [key: string]: any; }) => {
    let deleteCount = 0;
    Object.keys(object).forEach((key) => {

      // Remove null properties and fields that are hidden,
      // with the exception of dateRangeStart which can always be searched even if hidden.
      if (object[key] === null ||
        object[key] === undefined ||
        (!isAdvSearchOpen
          && key !== "dateRangeStart"
          && hiddenColumns.find(c => c === key || getSearchField(c) === key)
        )
      ) {
        delete object[key];
        deleteCount++;

      }
    });

    return { object, deleteCount };
  };

  useEffect(()=>{
  delete filters?.dateRangeStart
  delete filters?.dateRangeEnd
  } ,[isAdvanceSearch]);
 
    useEffect(() => {
      if (requestError === "TIMEOUT") {
        setNoRecordsMessage([TimeoutMessage]);
        setData([]);
      }
    }, [requestError]);

  return (
    <>
      <StyledBoxContainer className="image-archive">
        <PageSection title="">
          <>
            <Typography className="subheading">Below are the historical listings of all assignments. Search in the columns below or click the Advanced button to filter the results.</Typography>
            <InventoryAssignHeader
              columns={visibleColumns}
              hiddenColumns={hiddenColumns}
              updateHiddenColumns={handleUpdateHiddenColumns}
              handleAdvancedFilterChange={handleAdvancedFilterChange}
              handleAdvanceSearchPanelOpen={handleAdvanceSearchPanelOpen}
              onExport={handleExport}
              setOpenExportDialog={setOpenExportDialog}
            />
            <InventoryAssignGrid
              performSearch={performSearch}
              colsConfig={columns}
              rows={data ?? []}
              rowsPerPage={rowsPerPage}
              noRowsMessage={noRecordsMessage}
              page={page}
              hiddenColumns={hiddenColumns}
              onFiltersChange={handleFilterChange}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onColumnResize={handleColumnResize}
              selectionConfig={selectionConfig}
              loadingProgressPercent={loadingProgressPercent}
            />
            <FileExportDialog
              open={openExportDialog}
              onCancel={() => setOpenExportDialog(false)}
              onExport={handleExport}
              showSelectionPrompt={selectedAssignItems?.some((s) => s)}
              selectionPrompt={"Include only the selected policies or all policies?"}
              icon={IosShareIcon}
            />
            <ScrollToTopArrow refreshSize={data} />
          </>
        </PageSection>
      </StyledBoxContainer>
    </>
  );
};

export default InventoryAssign;

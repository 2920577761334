import StewartTableFooterPaginator from "controls/global/stewart-table/StewartTableFooterPaginator";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { yupResolver } from "@hookform/resolvers/yup";
import {
   useForm, 
   FormProvider ,
  } from "react-hook-form";
import * as yup from "yup";
import React, { useMemo } from "react";
import { 
  borderRadius, 
  borderSize, 
  colors, 
  fontSize, 
  fontWeight, 
  gaps, 
  margin, 
  padding, 
  zIndex,
 } from "theme/defaultStyle";
import StewartTable, { 
  SelectionConfig, 
  StewartTableColumn,
 } from "controls/global/stewart-table/StewartTable";
import { 
  Box, 
  styled,
 } from "@mui/material";
import DatePickerSearch from "controls/global/datepicker-search";
import { InventoryModifyUI } from "entities/UIModel/InventoryModifyUI";
import { Order } from "utils/sorting";

import { IColumn } from "entities/ApiModel/IColumn";
import { getColumnConfig } from "utils/columnsGrid";
import InputSearch from "./InputSearch";
import { InventoryModifySearchCriteria } from "entities/UIModel/InventoryModifySearchCriteria";
import useStewartTableStore from "utils/context/StewartTableContext";
import { formatDateString } from "utils/shared";
import { orderBy as _orderBy } from "lodash";
// import { getNavigationInfo } from "utils/services/utilsService";

type ImageArchiveGridProps = {
  page: number;
  rows: InventoryModifyUI[];
  onFiltersChange: (name: keyof InventoryModifySearchCriteria, value: any) => void;
  orderBy?: keyof InventoryModifyUI;
  order: Order;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (count: number) => void;
  rowsPerPage: number;
  noRowsMessage: string[];
  onRequestSort?: (property: any, disableToggling?: boolean) => void;
  performSearch?: () => Promise<void>;
  onColumnResize: (column: any, newWidth: number) => void;
  colsConfig: IColumn[];
  hiddenColumns?: (keyof InventoryModifyUI)[];
  selectionConfig?: SelectionConfig<InventoryModifyUI>;
  loadingProgressPercent ?: number;
};

const StyledTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& table.MuiTable-root": {
    "& th.MuiTableCell-root.MuiTableCell-head": {
      color: colors.grey08,
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal2,
      "& div.MuiBox-root": {
        color: colors.grey08,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal2,
      },
      "& div.MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-adornedEnd": {
        border: 0,
        borderBottom: `1px solid ${colors.grey15}`,
        borderRadius: 0,
        "&:focus-within": {
          borderBottom: `2px solid ${colors.blue15}`,
        },
        "& fieldset": {
          border: 0,
        },
      },
    },
    "& thead": {
      "& tr th div.MuiFormControl-root": {
        width: "100%",
        marginLeft: margin.small1_5,
      },
      "& tr th label.MuiFormLabel-root:not(.MuiInputLabel-shrink)": {
        transform: "translate(14px, 11px) scale(1)",
      },
      "& .MuiTableRow-root.header-actions th": {
        paddingRight: padding.large2,
        zIndex: zIndex.positiveMax,
      },
      "& tr th input": {
        padding: "6px 3px",
      },
    },
    "& tbody": {
      "& tr:not(.checked-row):hover td": {
        background: colors.grey10,
      },
      "& tr td.emptyCell": {
        paddingLeft: "20px",
        boxShadow: "none",
      },
      "& td:not(.emptyCell)": {
        maxWidth: "75px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      "& tr:last-child": {
        "& td:not(.emptyCell)": {
          borderBottom: `1px solid ${colors.grey09}`,
        },
        "& td:not(.emptyCell):first-child": {
          borderBottomLeftRadius: borderSize.zero,
        },
        "& td:not(.emptyCell):last-child": {
          borderBottomRightRadius: borderSize.zero,
        },
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-body": {
      "& div": {
        color: colors.grey08,
        fontWeight: fontWeight.normal1,
      },
      "& button.MuiButtonBase-root": {
        margin: `${margin.xxsmall1} ${margin.small1}`,
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-footer": {
      borderBottom: `1px solid ${colors.grey09}`,
      borderBottomLeftRadius: borderRadius.small,
      borderBottomRightRadius: borderRadius.small,
      borderLeft: `1px solid ${colors.grey09}`,
      borderRight: `1px solid ${colors.grey09}`,
      padding: padding.xsmall2,
      paddingRight: padding.small2,
    },
  },
  "& > .MuiTableContainer-root": {
    maxHeight: "100px",
    minHeight: "auto",
  },
});

const imageArchiveGridSchema = yup.object().shape({
  documentDisplayFileName: yup.string().trim().nullable().required("Required Field"),
});

const InventoryModifyGrid: React.FC<ImageArchiveGridProps> = ({
  page,
  rows,
  onFiltersChange,
  orderBy,
  order,
  onRowsPerPageChange,
  onPageChange,
  rowsPerPage,
  noRowsMessage,
  onRequestSort,
  performSearch,
  onColumnResize,
  colsConfig,
  hiddenColumns,
  selectionConfig,
  loadingProgressPercent,
}) => {
  const methods = useForm({
    resolver: yupResolver(imageArchiveGridSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [{ maxDate, minDate }] = useStewartTableStore();

  const columns = useMemo(
    (): StewartTableColumn<InventoryModifyUI>[] =>
      _orderBy(
        [
          {
            field: "formPrefix",
            valueGetter: ({ formPrefix }: InventoryModifyUI) => (
              <TooltipCell title={formPrefix || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch name="formPrefix" onChange={onFiltersChange} />
            ),
            left: 0,
            ...getColumnConfig<InventoryModifyUI>(colsConfig, "formPrefix"),
          },
          {
            field: "inventoryModifyID",
            actionComponent: () => (
              <InputSearch
                name="inventoryModifyID"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ inventoryModifyID }: InventoryModifyUI) => (
              <TooltipCell title={inventoryModifyID || ""} />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "inventoryModifyID"
            ),
          },
          {
            field: "newCompanyName",
            actionComponent: () => (
              <InputSearch name="companyName" onChange={onFiltersChange} />
            ),
            valueGetter: ({ newCompanyName }: InventoryModifyUI) => (
              <TooltipCell title={newCompanyName || ""} />
            ),
            ...getColumnConfig<InventoryModifyUI>(colsConfig, "newCompanyName"),
          },
          {
            field: "beginningSerialNumber",
            valueGetter: ({ beginningSerialNumber }: InventoryModifyUI) => (
              <TooltipCell title={beginningSerialNumber || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch
                name="beginningSerialNumber"
                onChange={onFiltersChange}
              />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "beginningSerialNumber"
            ),
          },
          {
            field: "endingSerialNumber",
            valueGetter: ({ endingSerialNumber }: InventoryModifyUI) => (
              <TooltipCell title={endingSerialNumber || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch
                name="endingSerialNumber"
                onChange={onFiltersChange}
              />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "endingSerialNumber"
            ),
          },
          {
            field: "inventoryModifiedDate",
            valueGetter: ({ inventoryModifiedDate }: InventoryModifyUI) => (
              <TooltipCell
                title={formatDateString(inventoryModifiedDate)}
              ></TooltipCell>
            ),
            actionComponent: () => (
              <DatePickerSearch
                handleDateChange={(date: Date | null) => {
                  onFiltersChange("inventoryModifiedDate", date);
                }}
                minDate={minDate}
                maxDate={maxDate}
              />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "inventoryModifiedDate"
            ),
          },
          {
            field: "newLocationDisplayName",
            actionComponent: () => (
              <InputSearch
                name="locationDisplayName"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ newLocationDisplayName }: InventoryModifyUI) => (
              <TooltipCell title={newLocationDisplayName || ""} />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "newLocationDisplayName"
            ),
          },
          {
            field: "newLocationStateAbbr",
            actionComponent: () => (
              <InputSearch
                name="locationStateAbbr"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ newLocationStateAbbr }: InventoryModifyUI) => (
              <TooltipCell title={newLocationStateAbbr || ""} />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "newLocationStateAbbr"
            ),
          },
          {
            field: "newLocationLegacyID",
            valueGetter: ({ newLocationLegacyID }: InventoryModifyUI) => (
              <TooltipCell title={newLocationLegacyID || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch name="locationLegacyID" onChange={onFiltersChange} />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "newLocationLegacyID"
            ),
          },
          {
            field: "inventoryModifiedUserName",
            actionComponent: () => (
              <InputSearch
                name="inventoryModifiedUserName"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ inventoryModifiedUserName }: InventoryModifyUI) => (
              <TooltipCell title={inventoryModifiedUserName || ""} />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "inventoryModifiedUserName"
            ),
          },
          {
            field: "inventoryModifyTypeName",
            actionComponent: () => (
              <InputSearch
                name="inventoryModifyTypeName"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ inventoryModifyTypeName }: InventoryModifyUI) => (
              <TooltipCell title={inventoryModifyTypeName || ""} />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "inventoryModifyTypeName"
            ),
          },

          {
            field: "policyTypeDesc",
            actionComponent: () => (
              <InputSearch name="policyTypeDesc" onChange={onFiltersChange} />
            ),
            valueGetter: ({ policyTypeDesc }: InventoryModifyUI) => (
              <TooltipCell title={policyTypeDesc || ""} />
            ),
            ...getColumnConfig<InventoryModifyUI>(colsConfig, "policyTypeDesc"),
          },
          {
            field: "newCompanyLegacyID",
            valueGetter: ({ newCompanyLegacyID }: InventoryModifyUI) => (
              <TooltipCell title={newCompanyLegacyID || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch name="companyLegacyID" onChange={onFiltersChange} />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "newCompanyLegacyID"
            ),
          },
          {
            field: "serialNumberStatusTypeName",
            actionComponent: () => (
              <InputSearch
                name="serialNumberStatusTypeName"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({
              serialNumberStatusTypeName,
            }: InventoryModifyUI) => (
              <TooltipCell title={serialNumberStatusTypeName || ""} />
            ),
            ...getColumnConfig<InventoryModifyUI>(
              colsConfig,
              "serialNumberStatusTypeName"
            ),
          },
        ],
        "position"
      ),
    [colsConfig, minDate, maxDate, onFiltersChange]
  );
  
  return (
    <>
      <FormProvider {...methods}>
        <StyledTableContainer width={1}>
          <StewartTable
            cols={columns}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            showActionRow={true}
            noRowsMessage={noRowsMessage}
            orderBy={orderBy}
            order={order}
            useDbPagingSorting={true}
            onRequestSort={onRequestSort}
            onColumnResize={onColumnResize}
            hiddenColumns={hiddenColumns}
            selectionConfig={selectionConfig}
            loadingProgressPercent={loadingProgressPercent}
          />
          <StewartTableFooterPaginator
            totalRows={rows[0]?.totalRows || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            totalPages={rows[0]?.totalPages}
          />
        </StyledTableContainer>
      </FormProvider>
    </>
  );
};

export default InventoryModifyGrid;

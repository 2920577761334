import {
  EventMessage,
  EventType,
  InteractionType,
  RedirectRequest,
  ServerError,
} from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  useMsal,
} from "@azure/msal-react";
import {
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import AutomaticProgressDialog from "controls/global/dialogs/progress-dialog/AutomaticProgressDialog";
import Router from "layout";
import React, {
  Suspense,
  useEffect,
} from "react";
import { RouterProvider } from "react-router-dom";
import ConfigContextProvider from "utils/context/ConfigContextProvider";
import { AuthenticatedApp } from "./AuthenticatedApp";
import theme from "./theme/default";

interface AppProps {
  passwordResetRequest: RedirectRequest;
  loginRequest: RedirectRequest;
}

const B2C_FORGOT_PASSWORD_CODE = "AADB2C90118";
const B2C_FLOW_CANCELLED_CODE = "AADB2C90091";

const App = ({ passwordResetRequest, loginRequest }: AppProps) => {
  const { instance } = useMsal();

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: EventMessage) => {
      if (message.eventType === EventType.LOGIN_FAILURE) {
        if (message.error?.message.includes(B2C_FORGOT_PASSWORD_CODE)) {
          instance.loginRedirect(passwordResetRequest);
        }

        if (message.error?.message.includes(B2C_FLOW_CANCELLED_CODE)) {
          instance.loginRedirect(loginRequest);
        }
      }
      else if (message.eventType === EventType.LOGIN_SUCCESS) {
        const messagePayload: any = message?.payload;
        Router.navigate({ pathname: messagePayload.state });
      }
    });

    return () => {
      if (callbackId) instance.removeEventCallback(callbackId);
    };
  }, [instance, loginRequest, passwordResetRequest]);

  const ErrorComponent = (error: any) => {
    let showError = true;

    if (error?.error instanceof ServerError) {
      const msg = (error.error as ServerError).errorMessage;
      if (msg && (msg.includes(B2C_FORGOT_PASSWORD_CODE) || msg.includes(B2C_FLOW_CANCELLED_CODE))) {
        showError = false;
      }
    }

    if (showError) {
      return <div>Please try again or contact the Agency Support Center for assistance.</div>;
    }
    else {
      return <></>;
    }
  };

  // FY: This no longer appears to be a problem, but leaving to test while deployed to dev-01 / qa-01.
  //This line is needed to prevent endless login attempts that started with React 18
  // if (inProgress !== InteractionStatus.None) return <></>;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ConfigContextProvider>
          <Suspense fallback={null}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={loginRequest}
              errorComponent={ErrorComponent}
            >
              <AuthenticatedApp>
                <RouterProvider router={Router} />
                <AutomaticProgressDialog />
              </AuthenticatedApp>
            </MsalAuthenticationTemplate>
          </Suspense>
        </ConfigContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

import {
  Checkbox,
  CheckboxProps,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&.Mui-disabled": {
    color: theme.palette.action.disabled,
    opacity: 0.5,
  },
}));

const StewartCheckbox = (props: CheckboxProps) => {

  return (
    <CustomCheckbox{...props} />
  );
};

export default StewartCheckbox;

import { Column } from "entities/ApiModel/IColumn";
import { DownloadFileType } from "utils/data/enum";
import { IColumn } from "entities/ApiModel/IColumn";
import { InventoryAssign } from "entities/ApiModel/InventoryAssign";
import { InventoryAssignExportCriteria } from "entities/ApiModel/InventoryAssignExportCriteria";
import { InventoryAssignSearchCriteria } from "entities/UIModel/InventoryAssignSearchCriteria";
import { InventoryAssignUI } from "entities/UIModel/InventoryAssignUI";
import { LocalStorageKeys } from "utils/data/enum";
import { StoreActionApi } from "react-sweet-state";

import { axiosSecuredInstance, cancelToken } from "configurations/axiosConfig";
import { createHook } from "react-sweet-state";
import { createStore } from "react-sweet-state";
import { dateTimeAsFilename } from "utils/shared";
import { downloadByBase64 } from "utils/shared";
import { getBase64DataType } from "utils/shared";
import { startNotifying, stopNotifying } from "utils/services/ContextProgressNotifierService";
import { CancelTokenSource } from "axios";
type State = {
  progressPercent ?: number,
  isLoading: boolean;
  refreshData: boolean;
  requestTimeoutMs: number;
  axiosCancelToken?: CancelTokenSource;
  documents: InventoryAssignUI[];
  minDate: Date | null;
  maxDate: Date | null;
  showToast: boolean;
  toastType: string | undefined;
  toastMessage: string | undefined;
  isEditMode: boolean;
  isAdvanceSearch: boolean;
  fileDocuments: InventoryAssignUI[];
  isFilePolicyUploadEditMode: boolean;
  isFilePolicyUploadShowToast: boolean;
  error?: any;
  dateType: string;
  dateTypeFieldValue: string;
  selectedAssignItems: InventoryAssignUI[];
};

const getSearchType = (criteria?: InventoryAssignSearchCriteria) => {
  if (criteria === undefined)
    return "";

  const getDayBetween = (startDate: Date, endDate: Date): number => {
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(endDate.valueOf() - startDate.valueOf()) / msInDay
    );
  };

  const propertiesToIgnore = [
    "dateRangeStart",
    "dateTypeCode",
    "fileStatusTypeCode",
    "listCount",
    "requestedPage",
    "showInActive",
    "sortColumn",
    "userFilter",
  ];

  let extendedDateSearch = true;
  const filtersApplied = Object.entries(criteria)
    .filter(([name, value]) => value !== null && !propertiesToIgnore.includes(name))
    .length > 0;

  if (!filtersApplied && criteria.dateRangeStart) {
    const daysForQuickerSearch = 90;
    extendedDateSearch = getDayBetween(new Date(), criteria.dateRangeStart) > daysForQuickerSearch;
  }

  return (filtersApplied || extendedDateSearch) ? "Assign-long" : "Assign-short";
};

const startProgressNotififer =
  (criteria: InventoryAssignSearchCriteria) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      startNotifying(getSearchType(criteria), handleUpdateProgress);
    };

const stopProgressNotififer =
  (criteria?: InventoryAssignSearchCriteria) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      stopNotifying(getSearchType(criteria), handleUpdateProgress);
    };

type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;
const setFilesRecordList = (filesSearchResponse: InventoryAssign[]) => (): InventoryAssignUI[] => {
  if (!filesSearchResponse || filesSearchResponse.length === 0) {
    return [];
  }

  return filesSearchResponse.map((file: InventoryAssign) => {
    return {
      uniqueIdentifier: file.InventoryAssignDetailID || 0,
      inventoryAssignDetailID: file.InventoryAssignDetailID || 0,
      inventoryAssignID: file.InventoryAssignID || 0,
      formPrefix: file.FormPrefix || "",
      beginningSerialNumber: file.BeginningSerialNumber || "",
      endingSerialNumber: file.EndingSerialNumber || "",
      locationDisplayName: file.LocationDisplayName || "",
      locationStateAbbr: file.LocationStateAbbr || "",
      locationLegacyID: file.LocationLegacyID || "",
      assignedUserName: file.AssignedUserName || "",
      assignDetailStatusTypeName: file.AssignDetailStatusTypeName || "",
      assignedDate: file.AssignedDate || "",
      companyName: file.CompanyName || "",
      companyLegacyID: file.CompanyLegacyID || "",
      inventoryModifyTypeName: file.ProductType,
      policyTypeDesc: file.PolicyTypeDesc || "",
      active: file.Active,
      isInventory: 0,
      rowsPerPage: file.RowsPerPage || 0,
      currentRow: file.CurrentRow || 0,
      currentPage: file.CurrentPage || 0,
      totalPages: file.TotalPages || 0,
      totalRows: file.TotalRows || 0,
      policyNumber: file.PolicyNumber || "",
    };
  });
};

const setColumns =
  (columns: any[]) =>
    () => {
      const gridColumns: IColumn[] = columns.filter((c) => Column.isValid(c));
      const gridHiddenColumns = gridColumns
        .filter((c) => c.hidden)
        .map((m) => m.field as keyof InventoryAssignUI);
      localStorage.setItem(LocalStorageKeys.COLUMNS_INVENTORY_ASSIGN, JSON.stringify(gridColumns));
      localStorage.setItem(LocalStorageKeys.COLUMNS_INVENTORY_ASSIGN_HIDDEN, JSON.stringify(gridHiddenColumns));
      return { gridColumns, gridHiddenColumns };
    };
const actions = {
  getSearchDocuments:
    (
      payload: InventoryAssignSearchCriteria // ImageArchiveUI) =>
    ) =>
    async ({ dispatch, setState ,getState }: StoreApi) => {
      try {
        dispatch(startProgressNotififer(payload));
        setState({ isLoading: true });
        const token = getState().axiosCancelToken;
        if (token) {
          token.cancel("AssignContext was cancelled due to new request");
        }

        const newCancelToken = cancelToken.source();
        setState({ error: undefined, axiosCancelToken: newCancelToken });
        const { data } = await axiosSecuredInstance.post(
          "/InventoryAssign/search",
          payload,
          {
            cancelToken: newCancelToken.token,
            timeout: getState().requestTimeoutMs,
            timeoutErrorMessage: "TIMEOUT",
          }
        );
        dispatch(stopProgressNotififer(payload));
         return dispatch(setFilesRecordList(data));
      } catch (error: any) {
        setState({ error: error.message });
        dispatch(stopProgressNotififer());      }
    },
    cancelRequests:
  () =>
    async ({ dispatch, getState }: StoreApi) => {
      dispatch(stopProgressNotififer());
      const token = getState().axiosCancelToken;
      if (token) {
        token.cancel(" Lookup was requested to be cancelled");
      }
    },
  exportFiles: (criteria: InventoryAssignExportCriteria) => async () => {
    const getExportFilename = (): string => {
      let filename = `Inventory Assign ${dateTimeAsFilename()}.${
        DownloadFileType.Xlsx
      }`;
      return filename;
    };
    try {
      const { data } = await axiosSecuredInstance.post<string>(
        "/InventoryAssign/Export",
        criteria
      );
      const filename = getExportFilename();
      downloadByBase64(
        `data:${getBase64DataType(DownloadFileType.Xlsx)};base64,${data}`,
        filename
      );
      return {};
    } catch (error: any) {
      console.error(error);
    }
  },
  updateSelectedAssignItem:
  (item: InventoryAssignUI) =>
  ({ setState, getState }: StoreApi) => {
    const currentAssignItems = getState().selectedAssignItems || [];
    const updatedAssignItems = currentAssignItems?.filter(
      (d) => d.inventoryAssignDetailID !== item.inventoryAssignDetailID
    );

    if (updatedAssignItems.length === currentAssignItems?.length) {
      updatedAssignItems.push(item);
    }

    setState({
      selectedAssignItems: updatedAssignItems,
    });
  },
updateSelectedAssignItems:
  (items: InventoryAssignUI[]) =>
  ({ setState, getState }: StoreApi) => {
    const currentAssignItems = getState().selectedAssignItems || [];
    const newAssignItems = items.filter(
      (item) =>
        !currentAssignItems?.find(
          (currentAssignItem) =>
            currentAssignItem.inventoryAssignDetailID === item.inventoryAssignDetailID
        )
    );

    let updatedAssignItems: InventoryAssignUI[] = [];
    if (newAssignItems.length > 0) {
      updatedAssignItems = currentAssignItems?.concat(newAssignItems);
    }

    setState({
      selectedAssignItems: updatedAssignItems,
    });
  },
resetSelectedAssignItem:
  () =>
  ({ setState }: StoreApi) => {
    setState({
      selectedAssignItems: [],
    });
  },
  setDateType:
  (dateTypeFieldValue: string) =>
  ({ setState }: StoreApi) => {
    setState({ dateTypeFieldValue });
  },
  setMinDate:
    (minDate: Date | null) =>
    ({ setState }: StoreApi) => {
      setState({ minDate });
    },
  setMaxDate:
    (maxDate: Date | null) =>
    ({ setState }: StoreApi) => {
      setState({ maxDate });
    },
  setShowToast:
    (showToast: boolean, toastType?: string,
      toastMessage?: string) =>
    ({ setState }: StoreApi) => {
      setState({ showToast });
      toastType && setState({ toastType });
      toastMessage && setState({ toastMessage });
    },
  setEditMode:
    (isEditMode: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isEditMode });
    },
  setFileShowToast:
    (isFilePolicyUploadShowToast: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isFilePolicyUploadShowToast });
    },
  setFileEditMode:
    (isFilePolicyUploadEditMode: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isFilePolicyUploadEditMode });
    },
  setAdvanceSearch:
    (isAdvanceSearch: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isAdvanceSearch });
    },
  getInitialColumnsDefintion: (hidden: boolean) => () => {
    const key = hidden
      ? LocalStorageKeys.COLUMNS_INVENTORY_ASSIGN_HIDDEN
      : LocalStorageKeys.COLUMNS_INVENTORY_ASSIGN;
    const existingColumns = localStorage.getItem(key) || "[]";
    return JSON.parse(existingColumns);
  },
  getColumnsDefinition:
    () =>
    async ({ dispatch }: StoreApi) => {
      const { data } = await axiosSecuredInstance.get(
        "/UISettings/grids/columnSettings/InventoryAssign"
      );
      return dispatch(setColumns(data));
    },
  setColumnDefinition:
    (
      fields: (keyof InventoryAssignUI)[],
      propName: keyof Omit<IColumn, "field" | "name">,
      value: string | number | boolean | undefined
    ) =>
    async ({ dispatch }: StoreApi) => {
      const data: IColumn[] = fields.map((field) => ({
        field,
        name: "", //NOT REQUIRED,
        [propName]: value,
      }));

      try {
        const response = await axiosSecuredInstance.post(
          "/UISettings/grids/columnSettings/InventoryAssign",
          data
        );
        if (response.data) {
          dispatch(setColumns(response.data));
        }
      } catch (error) {
        console.error(error);
      }
    },
    setDateTypeCode:
    (dateType: string) =>
    ({ setState }: StoreApi) => {
      setState({ dateType });
    },
};

const initialState: State = {
  progressPercent: -1,
  isLoading: false,
  documents: [],
  minDate: null,
  maxDate: null,
  showToast: false,
  toastType: '',
  toastMessage: '',
  isEditMode: false,
  isAdvanceSearch: false,
  fileDocuments: [],
  isFilePolicyUploadEditMode: false,
  isFilePolicyUploadShowToast: false,
  error: null,
  dateType: 'ASSIGNED_DATE',
  dateTypeFieldValue: '',
  selectedAssignItems :[],
  requestTimeoutMs: 45000,
  refreshData: false,
};

const documentStore = createStore<State, Actions>({
  initialState,
  actions,
  name: "documentStore",
});

const getFileDocuments = (state: State) => {
  return state.fileDocuments;
}

const getFilePolicyUploadStates = (state: State) => ({
  isFilePolicyUploadEditMode: state.isFilePolicyUploadEditMode,
  isFilePolicyUploadShowToast: state.isFilePolicyUploadShowToast,
})

const useAssignStore = createHook(documentStore);

const useFileDocuments = createHook(documentStore, {
  selector: getFileDocuments,
})

const useFilePolicyUploadStates = createHook(documentStore, {
  selector: getFilePolicyUploadStates,
})

export default useAssignStore;

export {
  useFileDocuments,
  useFilePolicyUploadStates,
}

import StewartTableFooterPaginator from "controls/global/stewart-table/StewartTableFooterPaginator";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { yupResolver } from "@hookform/resolvers/yup";
import { 
  useForm, 
  FormProvider 
} from "react-hook-form";
import * as yup from "yup";
import React, { 
  useMemo 
} from "react";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";
import StewartTable, {
  SelectionConfig,
  StewartTableColumn,
} from "controls/global/stewart-table/StewartTable";
import { 
  Box, 
  IconButton, 
  styled 
} from "@mui/material";
import DatePickerSearch from "controls/global/datepicker-search";
import { InventoryLookupUI } from "entities/UIModel/InventoryLookupUI";
import { Order } from "utils/sorting";
import { PageType, PERMISSIONS } from "utils/data/enum";
import { IColumn } from "entities/ApiModel/IColumn";
import { getColumnConfig } from "utils/columnsGrid";
import InputSearch from "./InputSearch";
import useStewartTableStore from "utils/context/StewartTableContext";
import { InventoryLookupSearchCriteria } from "entities/UIModel/InventoryLookupSearchCriteria ";
import CustomTooltip from "controls/global/custom-tooltip/CustomTooltip";
import EyeIcon from "theme/icons/EyeIcon";
import { formatDateString } from "utils/shared";
import { useNavigate } from "react-router-dom";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { orderBy as _orderBy } from "lodash";

type ImageArchiveGridProps = {
  page: number;
  rows: InventoryLookupUI[];
  onFiltersChange: (
    name: keyof InventoryLookupSearchCriteria,
    value: any
  ) => void;
  orderBy?: keyof InventoryLookupUI;
  order: Order;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (count: number) => void;
  rowsPerPage: number;
  noRowsMessage: string[];
  onRequestSort?: (property: any, disableToggling?: boolean) => void;
  performSearch?: () => Promise<void>;
  onColumnResize: (column: any, newWidth: number) => void;
  colsConfig: IColumn[];
  hiddenColumns?: (keyof InventoryLookupUI)[];
  selectionConfig?: SelectionConfig<InventoryLookupUI>;
  loadingProgressPercent?: number;
};

const StyledTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& table.MuiTable-root": {
    "& th.MuiTableCell-root.MuiTableCell-head": {
      color: colors.grey08,
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal2,
      "& div.MuiBox-root": {
        color: colors.grey08,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal2,
      },
      "& div.MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-adornedEnd":
        {
          border: 0,
          borderBottom: `1px solid ${colors.grey15}`,
          borderRadius: 0,
          "&:focus-within": {
            borderBottom: `2px solid ${colors.blue15}`,
          },
          "& fieldset": {
            border: 0,
          },
        },
    },
    "& thead": {
      "& tr th div.MuiFormControl-root": {
        width: "100%",
        marginLeft: margin.small1_5,
      },
      "& tr th label.MuiFormLabel-root:not(.MuiInputLabel-shrink)": {
        transform: "translate(14px, 11px) scale(1)",
      },
      "& .MuiTableRow-root.header-actions th": {
        paddingRight: padding.large2,
        zIndex: zIndex.positiveMax,
      },
      "& tr th input": {
        padding: "6px 3px",
      },
    },
    "& tbody": {
      "& tr:not(.checked-row):hover td": {
        background: colors.grey10,
      },
      "& tr td.emptyCell": {
        paddingLeft: "20px",
        boxShadow: "none",
      },
      "& td:not(.emptyCell)": {
        maxWidth: "75px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      "& tr:last-child": {
        "& td:not(.emptyCell)": {
          borderBottom: `1px solid ${colors.grey09}`,
        },
        "& td:not(.emptyCell):first-child": {
          borderBottomLeftRadius: borderSize.zero,
        },
        "& td:not(.emptyCell):last-child": {
          borderBottomRightRadius: borderSize.zero,
        },
      },
      // "& td:nth-last-child(1)": {
      //   boxShadow: "5px 0 2px -2px rgba(87, 87, 87, 0.10) inset",
      //   paddingLeft: padding.zero,
      //   "& div": {
      //     marginLeft: margin.small2,
      //     "& button": {
      //       width: "23px",
      //     },
      //   },
      // },
    },
    "& td.MuiTableCell-root.MuiTableCell-body": {
      "& div": {
        color: colors.grey08,
        fontWeight: fontWeight.normal1,
      },
      "& button.MuiButtonBase-root": {
        margin: `${margin.xxsmall1} ${margin.small1}`,
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-footer": {
      borderBottom: `1px solid ${colors.grey09}`,
      borderBottomLeftRadius: borderRadius.small,
      borderBottomRightRadius: borderRadius.small,
      borderLeft: `1px solid ${colors.grey09}`,
      borderRight: `1px solid ${colors.grey09}`,
      padding: padding.xsmall2,
      paddingRight: padding.small2,
    },
  },
  "& > .MuiTableContainer-root": {
    maxHeight: "100px",
    minHeight: "auto",
  },
});
const imageArchiveGridSchema = yup.object().shape({
  documentDisplayFileName: yup
    .string()
    .trim()
    .nullable()
    .required("Required Field"),
});

const InventoryLookupGrid: React.FC<ImageArchiveGridProps> = ({
  page,
  rows,
  onFiltersChange,
  orderBy,
  order,
  onRowsPerPageChange,
  onPageChange,
  rowsPerPage,
  noRowsMessage,
  onRequestSort,
  performSearch,
  onColumnResize,
  colsConfig,
  hiddenColumns,
  selectionConfig,
  loadingProgressPercent,
}) => {
  const methods = useForm({
    resolver: yupResolver(imageArchiveGridSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [{ maxDate, minDate }] = useStewartTableStore();
  const [{ profileSettings }] = useProfileSettingsCache();

  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  const hasLookupViewPermission = permissionCodes.includes(PERMISSIONS.INV_LOOKUP_VIEW);

  const navigate = useNavigate();

  const handleNavigateToPolicyDetails = (serialNumberDetailID: number) => {
    navigate(`/policy-details`, {
      state: { id: serialNumberDetailID },
    });
  };


  const columns = useMemo(
    (): StewartTableColumn<InventoryLookupUI>[] =>
      _orderBy(
        [
          {
            field: "clientFileId",
            actionComponent: () => (
              <InputSearch
                name="clientFileId"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ clientFileId }: InventoryLookupUI) => <TooltipCell title={clientFileId || ""} />,
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "clientFileId"),
          },
          {
            field: "formPrefix",
            valueGetter: ({ formPrefix }: InventoryLookupUI) => <TooltipCell title={formPrefix || ""}></TooltipCell>,
            actionComponent: () => (
              <InputSearch
                name="formPrefix"
                onChange={onFiltersChange}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "formPrefix"),
          },
          {
            field: "serialNumber",
            valueGetter: ({ serialNumber }: InventoryLookupUI) => (
              <TooltipCell title={serialNumber || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch
                name="serialNumber"
                onChange={onFiltersChange}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "serialNumber"),
          },
          {
            field: "assignedDate",
            valueGetter: ({ assignedDate }: InventoryLookupUI) => (
              <TooltipCell title={formatDateString(assignedDate)}></TooltipCell>
            ),
            actionComponent: () => (
              <DatePickerSearch
                handleDateChange={(date: Date | null) => {
                  onFiltersChange("assignedDate", date);
                }}
                page={PageType.LookupPage}
                minDate={minDate}
                maxDate={maxDate}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "assignedDate"),
          },
          {
            field: "locationStateAbbr",
            actionComponent: () => (
              <InputSearch
                name="locationStateAbbr"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ locationStateAbbr }: InventoryLookupUI) => <TooltipCell title={locationStateAbbr || ""} />,
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "locationStateAbbr"),
          },
          {
            field: "serialNumberStatusTypeName",
            actionComponent: () => (
              <InputSearch
                name="serialNumberStatusTypeName"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ serialNumberStatusTypeName }: InventoryLookupUI) => (
              <TooltipCell title={serialNumberStatusTypeName || ""} />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "serialNumberStatusTypeName"),
          },
          {
            field: "locationLegacyID",
            valueGetter: ({ locationLegacyID }: InventoryLookupUI) => (
              <TooltipCell title={locationLegacyID || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch
                name="locationLegacyID"
                onChange={onFiltersChange}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "locationLegacyID"),
          },
          {
            field: "locationDisplayName",
            actionComponent: () => (
              <InputSearch
                name="locationDisplayName"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ locationDisplayName }: InventoryLookupUI) => (
              <TooltipCell title={locationDisplayName || ""} />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "locationDisplayName"),
          },
          {
            field: "companyName",
            actionComponent: () => (
              <InputSearch
                name="companyName"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ companyName }: InventoryLookupUI) => <TooltipCell title={companyName || ""} />,
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "companyName"),
          },
          {
            field: "policyNumber",
            valueGetter: ({ policyNumber }: InventoryLookupUI) => (
              <TooltipCell title={policyNumber || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch
                name="policyNumber"
                onChange={onFiltersChange}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "policyNumber"),
          },
          {
            field: "policyTypeDesc",
            valueGetter: ({ policyTypeDesc }: InventoryLookupUI) => (
              <TooltipCell title={policyTypeDesc || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch
                name="policyTypeDesc"
                onChange={onFiltersChange}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "policyTypeDesc"),
          },
          {
            field: "companyLegacyID",
            actionComponent: () => (
              <InputSearch
                name="companyLegacyID"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ companyLegacyID }: InventoryLookupUI) => <TooltipCell title={companyLegacyID || ""} />,
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "companyLegacyID"),
          },
          {
            field: "voidedDate",
            valueGetter: ({ voidedDate }: InventoryLookupUI) => (
              <TooltipCell title={formatDateString(voidedDate)}></TooltipCell>
            ),
            actionComponent: () => (
              <DatePickerSearch
                handleDateChange={(date: Date | null) => {
                  onFiltersChange("voidedDate", date);
                }}
                page={PageType.LookupPage}
                minDate={minDate}
                maxDate={maxDate}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "voidedDate"),
          },
          {
            field: "verifiedDate",
            valueGetter: ({ verifiedDate }: InventoryLookupUI) => (
              <TooltipCell title={formatDateString(verifiedDate)}></TooltipCell>
            ),
            actionComponent: () => (
              <DatePickerSearch
                handleDateChange={(date: Date | null) => {
                  onFiltersChange("verifiedDate", date);
                }}
                page={PageType.LookupPage}
                minDate={minDate}
                maxDate={maxDate}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "verifiedDate"),
          },
          {
            field: "issueDate",
            valueGetter: ({ issueDate }: InventoryLookupUI) => (
              <TooltipCell title={formatDateString(issueDate)}></TooltipCell>
            ),
            actionComponent: () => (
              <DatePickerSearch
                handleDateChange={(date: Date | null) => {
                  onFiltersChange("issueDate", date);
                }}
                page={PageType.LookupPage}
                minDate={minDate}
                maxDate={maxDate}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "issueDate"),
          },
          {
            field: "lastModifiedDate",
            valueGetter: ({ lastModifiedDate }: InventoryLookupUI) => (
              <TooltipCell title={formatDateString(lastModifiedDate)}></TooltipCell>
            ),
            actionComponent: () => (
              <DatePickerSearch
                handleDateChange={(date: Date | null) => {
                  onFiltersChange("lastModifiedDate", date);
                }}
                page={PageType.LookupPage}
                minDate={minDate}
                maxDate={maxDate}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "lastModifiedDate"),
          },
          {
            field: "effectiveDate",
            valueGetter: ({ effectiveDate }: InventoryLookupUI) => (
              <TooltipCell title={formatDateString(effectiveDate)}></TooltipCell>
            ),
            actionComponent: () => (
              <DatePickerSearch
                handleDateChange={(date: Date | null) => {
                  onFiltersChange("effectiveDate", date);
                }}
                page={PageType.LookupPage}
                minDate={minDate}
                maxDate={maxDate}
              />
            ),
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "effectiveDate"),
          },
          {
            field: "inventoryAssignID",
            actionComponent: () => (
              <InputSearch
                name="inventoryAssignID"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ inventoryAssignID }: InventoryLookupUI) => <TooltipCell title={inventoryAssignID || ""} />,
            ...getColumnConfig<InventoryLookupUI>(colsConfig, "inventoryAssignID"),
          },
          {
            field: "actions",
            actionComponent: () => <div style={{ height: "40px", width: "130px" }} />,
            name: "",
            width: 130,
            right: -0.5,
            sticky: true,
            draggable: true,
            resizable: true,
            position: colsConfig.length + 1,
            valueGetter: ({ serialNumberDetailID }: InventoryLookupUI) => (
              <>
                {hasLookupViewPermission && (
                  <>
                    <Box sx={{ marginLeft: "10px" }}>
                      <CustomTooltip
                        title="View"
                        placement="top"
                        aria-label="View"
                        arrow
                      >
                        <IconButton
                          aria-label="View"
                          sx={{
                            color: colors.blue01,
                            "&.Mui-disabled": {
                              color: colors.blue11,
                            },
                          }}
                          onClick={handleNavigateToPolicyDetails.bind(null, serialNumberDetailID)}
                        >
                          <EyeIcon />
                        </IconButton>
                      </CustomTooltip>
                    </Box>
                  </>
                )}
              </>
            ),
          },
        ],
        "position",
      ),
    [colsConfig, minDate, maxDate, onFiltersChange],
  );

  return (
    <>
      <FormProvider {...methods}>
        <StyledTableContainer width={1}>
          <StewartTable
            cols={columns}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            showActionRow={true}
            noRowsMessage={noRowsMessage}
            orderBy={orderBy}
            order={order}
            useDbPagingSorting={true}
            onRequestSort={onRequestSort}
            onColumnResize={onColumnResize}
            hiddenColumns={hiddenColumns}
            selectionConfig={selectionConfig}
            loadingProgressPercent={loadingProgressPercent}
          />
          <StewartTableFooterPaginator
            totalRows={rows[0]?.totalRows || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            totalPages={rows[0]?.totalPages}
          />
        </StyledTableContainer>
      </FormProvider>
    </>
  );
};

export default InventoryLookupGrid;

import { CancelTokenSource } from "axios";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { orderBy } from "lodash";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { 
  DateType, 
  FormPrefix 
} from "entities/UIModel";
import { CompanyLocation } from "entities/UIModel/CompanyLocation";
import { 
  createHook, 
  createStore, 
  StoreActionApi 
} from "react-sweet-state";
import {
  PageType,
} from "utils/data/enum";

export interface KeyValuePair {
  key: string;
  value: string;
}

interface State {
  error: string | null;
  isLoading: boolean;
  isSearchingCompanyLocation: boolean;
  isStatesLoading: boolean;
  formPrefixes: FormPrefix[];
  companyLocations: CompanyLocation[];
  serialNumberStatusTypes: SelectFieldOption[];
  axiosCancelToken?: CancelTokenSource;
}

type StoreApi = StoreActionApi<State>;

const setError =
  (error: string | null) =>
  ({ setState }: StoreApi) => {
    setState({ error });
  };

const setDefaultState =
  (params: Record<string, any>) =>
  ({ setState }: StoreApi) => {
    setState({ ...defaultState, ...params });
  };

const setFormPrefixes =
  (data: any) =>
  ({ setState }: StoreApi) => {
    let formPrefixes: FormPrefix[] = [];
    if (data && data?.length > 0) {
      formPrefixes = data.map((fp: any) => {
        const formPrefix: FormPrefix = {
          formPrefixCode: fp.FormPrefixCode,
          formPrefixDescription: fp.FormPrefixDescription,
          formType: fp.FormType || "",
          productType: fp.ProductType || "",
          productTypeAbbr: fp.ProductTypeAbbr || "",
          isInventory: fp.IsInventory,
          isActive: fp.IsActive,
        };

        return formPrefix;
      });
    }

    setState({
      formPrefixes: formPrefixes,
    });
  };

const setCompanyLocations =
  (data: any) =>
  ({ setState }: StoreApi) => {
    let companyLocations: CompanyLocation[] = [];
    if (data && data?.length > 0) {
      companyLocations = data.map((l: any) => {
        const location: CompanyLocation = {
          companyID: l.CompanyID,
          companyName: l.CompanyName,
          companyLegacyID: l.CompanyLegacyID,
          locationUniqueID: l.LocationUniqueID,
          locationLegacyID: l.LocationLegacyID,
          locationDisplayName: l.LocationDisplayName,
          locationFullDisplayName: l.LocationFullDisplayName,
          locationStateCode: l.LocationStateCode,
          locationStateAbbr: l.locationStateAbbr,
        };

        return location;
      });
    }

    setState({
      companyLocations: companyLocations,
    });
  };  

const mapCompanyLocationsSearchResult =
  (data: any, locationUniqueID?: string) =>
  ({ setState }: StoreApi) => {
    let companyLocations: CompanyLocation[] = [];
    if (data && data?.length > 0) {
      
      if(locationUniqueID) {
        data = data.filter((item:any) => item?.LocationUniqueID!==locationUniqueID);
      }

      companyLocations = data.map((l: any) => {
        const location: CompanyLocation = {
          companyID: l.CompanyID,
          companyName: l.CompanyName,
          companyLegacyID: l.CompanyLegacyID,
          locationUniqueID: l.LocationUniqueID,
          locationLegacyID: l.LocationLegacyID,
          locationDisplayName: l.LocationDisplayName,
          locationFullDisplayName: l.LocationFullDisplayName,
          locationStateCode: l.LocationStateCode,
          locationStateAbbr: l.locationStateAbbr,
          isActive: l.IsActive,
        };

        return location;
      });
    }

    setState({ isSearchingCompanyLocation: false });

    return companyLocations; 
  };


const setDateTypes =
  (data: any, page: PageType) =>
  ({ setState }: StoreApi) => {
    if (!data || data.length === 0) {
      return [];
    }

    const dateTypes: DateType[] = data.map((x: any) => {
      let selected: boolean = false;
      return {
        code: x.dateTypeCode,
        name: x.dateTypeName,
        selected: selected || data.length === 1,
      };
    });
    return dateTypes;
  };

const setSerialNumberStatusTypes =  
(data: any) =>
  ({ setState }: StoreApi) => {
    let serialNumberStatusTypes: SelectFieldOption[] = [];
    if (data && data.length > 0) {
      serialNumberStatusTypes = data
        .filter(
          (s: any) =>
            s.SubType?.toUpperCase() === "INVENTORY" &&
            s.SerialNumberStatusTypeCode.toUpperCase() !== "VERIFIED"
        )
        .map((s: any) => {
          const serialNumberStatus: SelectFieldOption = {
            text: s.SerialNumberStatusTypeName,
            value: s.SerialNumberStatusTypeCode,
            sortOrder: s.SortOrder,
            selected: data.length === 1,
          };
          return serialNumberStatus;
        });

      serialNumberStatusTypes =  orderBy(serialNumberStatusTypes, "sortOrder", "asc");

    }

    setState({serialNumberStatusTypes: serialNumberStatusTypes});
  } 

const defaultState: State = {
  error: null,
  isLoading: false,
  isSearchingCompanyLocation: false,
  isStatesLoading: false,
  formPrefixes: [],
  companyLocations: [],
  serialNumberStatusTypes:[],
};

const Store = createStore<State, any>({
  initialState: defaultState,
  actions: {
    getFormPrefixList:
      () =>
      async ({ dispatch, getState, setState }: StoreApi) => {
        try {
          setState({ isLoading: true });
          const { data } = await axiosSecuredInstance.get<any>(
            `/lookup/GetFormPrefixList`
          );

          dispatch(setFormPrefixes(data));
          setState({ isLoading: false });
        } catch (error: any) {
          setState({ isLoading: false, error: error });
          console.error("ERROR: LookupContext: getFormPrefixList.", error);
        }
      },
    getSerialNumberStatusTypes:
      () => 
        async ({ dispatch, getState, setState }: StoreApi) => {
          try {
            setState({ isLoading: true });
            const { data } = await axiosSecuredInstance.get<any>(
              `/lookup/GetSerialNumberStatusTypes`
            );
            dispatch(setSerialNumberStatusTypes(data));
            setState({ isLoading: false });
          } catch (error: any) {
            setState({ isLoading: false, error: error });
            console.error("ERROR: LookupContext: getSerialNumberStatusList.", error);
          }
        },        
    getCompanyLocations:
      () =>
      async ({ dispatch, getState, setState }: StoreApi) => {
        try {
          setState({ isLoading: true });
          const { data } = await axiosSecuredInstance.get<any>(
            `/lookup/GetCompanyLocations`
          );

          dispatch(setCompanyLocations(data));
          setState({ isLoading: false });
        } catch (error: any) {
          setState({ isLoading: false, error: error });
          console.error("ERROR: LookupContext: getCompanyLocations.", error);
        }
      },
    searchCompanyLocations:
      (searchText: string,  locationUniqueID: string) =>
      async ({ dispatch, setState }: StoreApi) => {
        try {
          setState({ isSearchingCompanyLocation: true });
          const { data } = await axiosSecuredInstance.get<any>(
            `/lookup/SearchCompanyLocations?searchText=${searchText}`
          );

          return dispatch(mapCompanyLocationsSearchResult(data, locationUniqueID));
          
        } catch (error: any) {
          setState({ isSearchingCompanyLocation: false, error: error });
          console.error("ERROR: LookupContext: getCompanyLocations.", error);
          return [];
        }
      },      
    getDateTypes:
      (page: PageType) =>
      async ({ dispatch }: StoreApi) => {
        try {
          const { data } = await axiosSecuredInstance.get<DateType[]>(
            "/lookup/getList/datetype"
          );
          let filteredData = data;
          return dispatch(setDateTypes(filteredData, page));
        } catch (error: any) {
          dispatch(setError(error));
        }
      },
    resetLookups:
      (params: Record<string, any>) =>
      ({ dispatch }: StoreApi) => {
        dispatch(setDefaultState(params));
      },
  },
  name: "lookup",
});

const getFormPrefixes = (state: State) => {
  return state.formPrefixes;
};

const getSerialNumberStatusTypes = (state: State) => {
  return state.serialNumberStatusTypes;
}
const getCompanyLocations = (state: State) => {
  return state.companyLocations;
};

const getIsSearchingCompanyLocation = (state: State) => ({
  isSearchingCompanyLocation: state.isSearchingCompanyLocation,
});

const hook = createHook(Store);
const useLookup = () => {
  return hook();
};

const useLookupActions = createHook(Store, { selector: null });

const useLookupFormPrefixes = createHook(Store, {
  selector: getFormPrefixes,
});

const useLookupSerialNumberStatusTypes = createHook(Store, {
  selector: getSerialNumberStatusTypes,
})

const useLookupCompanyLocations = createHook(Store, {
  selector: getCompanyLocations,
});

const useLookupIsSearchinggCompanyLocation = createHook(Store, {
  selector: getIsSearchingCompanyLocation,
});

export {
  useLookup,
  useLookupActions,
  useLookupFormPrefixes,
  useLookupSerialNumberStatusTypes,
  useLookupCompanyLocations,
  useLookupIsSearchinggCompanyLocation,
};

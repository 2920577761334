import {
  CircularProgress,
  styled,
} from "@mui/material";
import React from "react";
import { colors } from "theme/defaultStyle";

const Container = styled("div")({
  "-ms-flex-align": "center",
  "-ms-flex-direction": "column",
  "-ms-flex-pack": "center",
  "-webkit-box-align": "center",
  "-webkit-box-direction": "normal",
  "-webkit-box-orient": "vertical",
  "-webkit-box-pack": "center",
  alignIitems: "center",
  background: colors.white,
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  justifyContent: "center",
  left: "0px",
  opacity: "0.8",
  position: "fixed",
  top: "0px",
  width: "100%",
  zIndex: "9999",
});

const Loading = () => (
  <Container>
    <CircularProgress color="primary" />
  </Container>
);

export default Loading;

import LightTooltip from "controls/global/light-tooltip";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";

interface Props {
  tooltip: string;
  text: string | React.ReactNode;
  style?: object;
  showAlways?: boolean;
}

const OverflowTooltip = ({
  tooltip,
  text,
  style,
  showAlways = false,
}: Props) => {
  const [hoverStatus, setHover] = useState(false);
  const textElementRef = useRef<HTMLInputElement | null>(null);

  const compareSize = () => {
    let compare =
      !!(
        textElementRef &&
        textElementRef.current &&
        textElementRef?.current?.offsetWidth <
        textElementRef?.current?.scrollWidth
      ) || showAlways;

    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => window.removeEventListener("resize", compareSize);
  }, [tooltip]);

  return (
    <LightTooltip
      title={tooltip}
      //interactive
      disableHoverListener={!hoverStatus}
      arrow
      placement={showAlways ? "right-start" : "bottom"}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...style,
        }}
      >
        {text}
      </div>
    </LightTooltip>
  );
};

export default OverflowTooltip;

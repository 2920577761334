import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import { TabTriggerType } from "utils/data/enum";
import { resetSessionStorage } from "utils/shared";

interface State {
  nextUrl: string;
  nextTab: string;
  tabTriggerType: TabTriggerType;
}

type StoreApi = StoreActionApi<State>;

const Store = createStore<State, any>({
  initialState: {
    nextUrl: "",
    nextTab: "",
    tabTriggerType: TabTriggerType.User,
  },
  actions: {
    setNextUrl:
      (nextUrl: string) =>
      ({ setState }: StoreApi) => {
        setState({ nextUrl });
      },
    setNextTab:
      (nextTab: string) =>
      ({ setState }: StoreApi) => {
        setState({ nextTab });
      },
    setTabTriggerType:
      (tabTriggerType: TabTriggerType) =>
      ({ setState }: StoreApi) => {
        setState({ tabTriggerType });
      },
    navigateToNextUrl:
      (navigateFunction: (url: string) => void) =>
      ({ getState }: StoreApi) => {
        const url = getState().nextUrl;

        // if the requested url starts with "https" then user wants to go to an absolute path
        // so we take the browser to the new absolute url
        if (url.startsWith('https://') || url.startsWith('http://')) {
          window.open(url, '_blank');
        }
        else
        {
          if (url === "reload") {
            resetSessionStorage();
            window.location.reload();
          } else if (url) {
            navigateFunction(url);
          }
      }
      },
  },
  name: "navigation",
});

const hook = createHook(Store);
export const useNavigation = () => { return hook(); };

import { getInvalidNotesMessage, isValidNotes } from "pages/utils/pageHelper";
import { convertToNumber } from "utils/shared";
import * as yup from "yup";

const requiredMessage: string = "Required";

const newAssignValidationSchema = yup.object().shape({
  locationFullDisplayName: yup.string().required(""),
  formPrefixItems: yup.array().of(
    yup.object().shape({
      formPrefix: yup.string().required(""),
      quantity: yup.number()
      .transform((value) => Number.isNaN(value) ? undefined : value )
      .required("")
      .test({
        name: "isValidQuantity",
        test: function(value, context) {
          const contextFrom = (context as any).from;
          const root = contextFrom[contextFrom.length - 1].value;
          const maxAssignCount = convertToNumber(root.maxAssignCount);
          const maxAssignCountMessage = String(root.maxAssignCountMessage);
          // console.log(root, maxAssignCount, maxAssignCountMessage);
          return Number(value) > maxAssignCount ? context.createError({message: maxAssignCountMessage}) : true
        }
      })
      .test(
        "isQuantityGreaterThanZero",
        "Must be greater than 0",
        (value, context) =>
          Number(value) < 1 ? false : true
      ),
    })
  ),
  notes: yup.string()
  .nullable()
  .test({
    name: "isValidNotes",
    test: function(value, context) {
      let isValid = true;
      if (value) {
        isValid = isValidNotes(value);
      }
      return isValid ? true:context.createError({message: getInvalidNotesMessage()}) 
    }
  })  
  .when("isActive", {
    is: 0,
    then: yup.string()
      .nullable()
      .test("required", requiredMessage, (value, context) => {
        const trimmedValue = value ? value.trim() : '';
        return trimmedValue.length > 0;
      }),
  }),  
});

export default newAssignValidationSchema;

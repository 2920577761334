import {
  Box,
  LinearProgress,
  LinearProgressProps,
} from "@mui/material";
import React from "react";
import { fontSize } from "theme/defaultStyle";

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number }
) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <div style={{ fontSize: fontSize.base }}>{`${Math.round(props.value)}%`}</div>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;

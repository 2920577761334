import { DialogActions } from "@mui/material";
import LinearProgressWithLabel from "controls/global/linear-progress-with-label";
import React from "react";
import { useAutomaticProgressDialogState } from "utils/context/AutomaticProgressDialogContext";
import {
  StewartDialog,
  StewartDialogTitle,
  StewartDialogContent,
} from "../stewart-dialog/StewartDialog";

const AutomaticProgressDialog = () => {
  const [pctComplete, setPctComplete] = React.useState(0);
  const [show, setShow] = React.useState<boolean>(false);
  const [{ open, title }] = useAutomaticProgressDialogState();


  React.useEffect(() => {
    if (!show) {
      return;
    }

    const interval = setInterval(() => {
      if (pctComplete === 100) return;
      if (pctComplete < 80) {
        const newPctComplete = pctComplete + 20;
        setPctComplete(newPctComplete);
      }
    }, 250);

    return () => {
      clearInterval(interval);
    };
  }, [show, pctComplete, setPctComplete]);

  React.useEffect(() => {
    if (open) {
      setPctComplete(0);
      setShow(true);
      return;
    }

    if (!open) {
      setPctComplete(100);
      setTimeout(() => {
        setShow(false);
      }, 100)
    }
  }, [open])

  return (
    <StewartDialog open={show} maxWidth="md" width={580}>
      <StewartDialogTitle
        type={"INFO"}
      >
        {title}
      </StewartDialogTitle>
      <StewartDialogContent type={"INFO"}>
        <LinearProgressWithLabel value={pctComplete} />
      </StewartDialogContent>
      <DialogActions />
    </StewartDialog>
  );
};

export default AutomaticProgressDialog;

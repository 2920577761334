import React from "react";
import {
  Controller,
  useFormContext,
} from "react-hook-form";
// import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import ErrorMessage from "../error-message";
import DatePicker from "./DatePicker";
import { isValidDate } from "utils/shared";

interface iProps {
  name: any;
  label?: string;
  defaultValue?: any;
  minDate?: Date;
  maxDate?: Date;
  defaultToToday?: boolean;
  disabled?: boolean;
  showError?: boolean;
  validateOnBlur?: boolean;
  isShrinkable?: boolean;
  onChangeCallback?: (date: Date | null) => void;
  onFocusCallback?: () => void;
  onBlurCallback?: (date: Date | null) => void;
  size?: "small" | "medium";
  noBorder?: boolean;
}

const DatePickerField = (props: iProps) => {
  const {
    name,
    defaultValue,
    showError = true,
    validateOnBlur = true,
    minDate,
    maxDate,
    onChangeCallback,
    onBlurCallback,
    disabled,
    size = "medium",
    ...rest
  } = props;

  const { control, setValue, setError, clearErrors } = useFormContext();
  // const shouldFieldBeDisabled = useFieldDisabler(name);

  const handleOnChange = (date: Date | null, shouldDirty: boolean = true) => {
    if (isValidDate(date) || date === null) {
      setValue(name, date, {
        shouldValidate: isValidDate(date),
        shouldDirty,
      });
    }
    onChangeCallback && onChangeCallback(date);
  };

  const handleOnBlur = (date: Date | null) => {
    if (isValidDate(date)) {
      clearErrors(name);
    }
    else {
      setValue(name, null, {
        shouldDirty: true,
      });

      if (validateOnBlur) {
        setError(
          name,
          {
            type: "focus",
            message: date === null ? "Required" : "Invalid date",
          },
          { shouldFocus: true }
        );
      }
    }

    onBlurCallback && onBlurCallback(date);
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { name, value, ref }, fieldState: { error } }) => (
          <DatePicker
            name={name}
            value={value}
            hasError={Boolean(error)}
            onChangeCallback={handleOnChange}
            onBlurCallback={handleOnBlur}
            disabled={disabled} // || shouldFieldBeDisabled}
            fieldRef={ref}
            minDate={minDate}
            maxDate={maxDate}
            size={size}
            noBorder={props.noBorder}
            {...rest}
          />
        )}
      />
      {showError ? (
        <ErrorMessage
          name={name}
          className={"errorMessage dateValidation"}
        />
      ) : null}
    </>
  );
};
export default DatePickerField;

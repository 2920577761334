import React from "react";
import { 
  styled, 
  Box, 
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Table
} from "@mui/material";
import { 
  borderRadius, 
  colors, 
  fontFamily, 
  fontSize, 
  fontWeight, 
  padding 
} from "theme/defaultStyle";
import { UploadStatus } from "entities/UIModel";
import DeleteIcon from "@mui/icons-material/Delete";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import CustomTooltip from "controls/global/custom-tooltip/CustomTooltip";

const StyledTableContainer = styled(Box)({
  width: "100%",
  maxHeight: "330px",
  overflowX: "auto",
  "& table": {
    maxWidth: "100%",
    minWidth: "100%",
    backgroundColor: colors.white,
    tableLayout: "fixed",
    "& td": {
      border: `1px solid ${colors.grey09}`,
      borderStyle: "solid solid solid solid",
      padding: "8px",
      textWrap: "noWrap",
    },      
    "& td.file-name": {
      width: "auto",
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal1,
    },
    "& td.error-message": {
      width: "auto",
      color: colors.red01,
      fontFamily: fontFamily.secondary,
      fontSize: fontSize.large,
      fontWeight: fontWeight.normal1,
    },
    "& td.delete-file": {
      width: "60px",
      textAlign: "right",
    },
  },
  "& tbody": {
    "& tr:hover td": {
      background: colors.grey10,
    }
  },  
  "& .MuiSelect-select.MuiSelect-outlined, input.MuiInputBase-input.MuiOutlinedInput-input": {
    paddingTop: padding.xsmall,
    paddingBottom: padding.xsmall,
  },
  "& tr:first-child": {
    "& td:first-child": {
      borderTopLeftRadius: borderRadius.medium,
    },
    "& td:last-child": {
      borderTopRightRadius: borderRadius.medium,
    }
  },  

  "& td div": {
    lineHeight: "150%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& thead th": {
    fontWeight: fontWeight.bold2,
  },
  
});

const StyledTableRow = styled(TableRow)({
});

const StyledTableCell = styled(TableCell)({
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  padding: padding.zero,
  "&:first-child": {
    paddingLeft: padding.small2,
  },
  "& .MuiTextField-root": {
    maxWidth: "180px",
    minWidth: "120px",
    textOverflow: "ellipsis",
  },
  "& .MuiInputBase-root": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .delete-icon": {
    color: colors.blue15,
  },
  "& .delete-icon-disabled": {
    color: colors.blue11,
  },
});

type Props = {
  uploadRequestId?: string;
  fileUploadStatuses?: UploadStatus[];
  showDelete?: boolean;
  handleOnDelete?: (documentId: number) => void;
  className?: string;
  hideMessage?: boolean;
};

const UploadStatusGrid = ({
  uploadRequestId,
  fileUploadStatuses,
  showDelete = false,
  handleOnDelete,
  className,
  hideMessage = false,
}: Props) => {
  return (
    <StyledTableContainer className={className}>
      <Table className="file-upload-status-table">
        <TableBody>
          {fileUploadStatuses?.map(
            (fileUploadStatus: UploadStatus, index: number) => {
              let documentId = fileUploadStatus.documentId || 0;
              return (
                <React.Fragment key={`{uploadRequestId}`}>
                  <StyledTableRow key={`{fileUploadStatus.documentIndex}`}>
                    <StyledTableCell className="file-name">
                      <TooltipCell
                        title={`${fileUploadStatus.documentOriginalFileName}`}
                      />
                    </StyledTableCell>
                    {!hideMessage && (
                      <StyledTableCell className="error-message">
                        <TooltipCell
                          title={`${fileUploadStatus.errorMessage}`}
                        />
                      </StyledTableCell>
                    )}
                    {showDelete && (
                      <StyledTableCell className="delete-file">
                        <CustomTooltip
                          title="Delete"
                          placement="top"
                          aria-label="Delete"
                          arrow
                        >
                          <IconButton
                            aria-label="Delete"
                            disabled={!fileUploadStatus.isUploadSuccess}
                            onClick={() =>
                              handleOnDelete && handleOnDelete(documentId)
                            }
                          >
                            <DeleteIcon
                              className={
                                fileUploadStatus.isUploadSuccess
                                  ? "delete-icon"
                                  : "delete-icon-disabled"
                              }
                            />
                          </IconButton>
                        </CustomTooltip>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                </React.Fragment>
              );
            }
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default UploadStatusGrid;
import {
  Box,
  BoxProps,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import theme from "theme/default";
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  padding,
  titleIconOverflowDistance,
  zIndex,
} from "theme/defaultStyle";

type NavItem = {
  name: string;
  childRef: React.MutableRefObject<HTMLElement | undefined> | undefined;
};

interface Props {
  scrollToTop: boolean;
  includeIconOverflowPadding?: boolean;
  navItems: NavItem[];
}

interface NacContainerProps extends BoxProps {
  theme?: Theme;
  top: string;
}

const NavContainer = styled(Box)<NacContainerProps>(({ top }) => ({
  alignItems: "flex-start",
  background: gradients.blueGradient010,
  borderRadius: borderRadius.xlarge,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  outline: "none",
  padding: padding.large1,
  position: "sticky",
  top:top,
  width: "185px",
  zIndex: zIndex.high1,
  [theme.breakpoints.down("lg")]: {
    borderRadius: borderRadius.small,
    flexDirection: "row",
    gap: gaps.small1,
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    left: padding.xsmall1,
  },
  "& .activeItem": {
    background: `${gradients.blueGradient010} !important`,
    color: colors.white,
  },
}));

const Item = styled("div")((props) => ({
  alignItems: "center",
  background: "transparent",
  borderRadius: borderRadius.small,
  color: colors.white,
  cursor: "pointer",
  display: "flex",
  flexGrow: 1,
  fontSize: fontSize.base,
  fontStyle: "normal",
  fontWeight: fontWeight.normal1,
  height: "48px",
  justifyContent: "flex-start",
  padding: `${padding.large2} ${padding.large1}`,
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
    padding: `${padding.small2} ${padding.small2}`,
  },
}));

const SideNav = ({ scrollToTop, navItems, includeIconOverflowPadding = false }: Props) => {
  const [activeID, setActiveId] = useState(-1);
  const [headerHeight, setHeaderHeight] = useState(0);
  const navRef = useRef<HTMLDivElement>(null);
  const isNavbarHorizontal = useMediaQuery(theme.breakpoints.down("lg"));

  const getHeaderHeight = () => {
    let height = 0;
    const headerElement = document.getElementsByTagName("header")?.[0];
    if (headerElement) {
      height = headerElement.clientHeight;
    }

    setHeaderHeight(height);
  };

  const getStickyPosition = () => {
    let offset = headerHeight;
    if (!isNavbarHorizontal) {
      offset += parseInt(gaps.large1);
      if (includeIconOverflowPadding) {
        offset += parseInt(titleIconOverflowDistance);
      }
    }
    return `${offset-20}px`;
  };

  const onItemClick = (id: number) => {
    if (activeID !== id) {
      setActiveId(id);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    let direction = 0;
    if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
      direction = -1;
    }
    else if (event.key === "ArrowRight" || event.key === "ArrowDown") {
      direction = 1;
    }
    if (direction != 0) {
      navigateByKeypress(direction);
      event.preventDefault();
    }
  };

  const navigateByKeypress = (direction: number) => {
    let newID = activeID + direction;
    if (newID < 0) {
      newID = navItems.length - 1;
    }
    else if (newID >= navItems.length) {
      newID = 0;
    }
    setActiveId(newID);
  };

  const navigateToItem = () => {
    if (activeID >= 0 && activeID < navItems.length) {
      const childRef = navItems[activeID].childRef;

      if (childRef?.current) {
        let scrollY = childRef?.current.offsetTop;

        if (isNavbarHorizontal && navRef?.current) {
          const navbarHeightAdder =
            (navRef?.current?.clientHeight ?? 0) - parseInt(gaps.large1, 10);
          scrollY = scrollY - navbarHeightAdder - parseInt(gaps.large1, 10);
        }

        document
          .documentElement
          ?.scrollTo({ top: scrollY, behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", getHeaderHeight);
    getHeaderHeight();
    return () => window.removeEventListener("resize", getHeaderHeight);
  }, []);

  useEffect(() => {
    const headerElement = document.getElementsByTagName("header")?.[0];
    if (headerElement) {
      const observer = new MutationObserver(getHeaderHeight);
      observer.observe(headerElement, { attributes: true, childList: true, subtree: true });
      return () => observer.disconnect();
    }
  }, []);
  
  useEffect(() => {
    navigateToItem();
  }, [activeID]);

  useEffect(() => {
    setActiveId(0);
  }, [scrollToTop]);

  return (
    <NavContainer
      ref={navRef}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      top={getStickyPosition()}
    >
      {navItems.map((item, index) => (
        <Item
          key={item.name}
          className={`${index === activeID ? "activeItem" : ""}`}
          onClick={() => onItemClick(index)}
        >
          {item.name}
        </Item>
      ))}
    </NavContainer>
  );
};

export default SideNav;

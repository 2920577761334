import React from "react";
import { AutocompleteInputChangeReason } from "@mui/material";
// import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import AutocompleteField from "controls/global/custom-autocomplete";
import { CompanyLocation } from "entities/UIModel/CompanyLocation";
import { 
  useLookupActions, 
  useLookupIsSearchinggCompanyLocation 
} from "utils/context/LookupContext";

interface Props {
  label: string;
  name: string;
  onChange: (newLocation: CompanyLocation ) => void;
  onBlur?: (event: React.ChangeEvent<any>) => void;
  onClear?: () => void;
  locationUniqueID?: string;
}

const AgencyLocationField = ({ label, name, onChange, onBlur, onClear, locationUniqueID }: Props) => {
  // const { setValue } = useFormWrapper();
  const [, { searchCompanyLocations }] = useLookupActions();
  const [{ isSearchingCompanyLocation }] = useLookupIsSearchinggCompanyLocation();

  // Please don't remove this
  // Notes: will be used if needed for styling dropdown content
  // const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: CompanyLocation) => (
  //   <Typography component="li" {...props}>{option.locationFullDisplayName}</Typography>
  // );

  const onSearchCompanyLocations = (
    searchText: string,
    locationUniqueID?: string,
  ): Promise<CompanyLocation[]> => {
    if (!searchText) return new Promise<CompanyLocation[]>(() => []);
    return searchCompanyLocations(searchText, locationUniqueID);
  };

  const onValueChanged = (newValue: CompanyLocation) => {
    onChange && onChange(newValue);
  };

  const handleOnBlur = (event: React.ChangeEvent<any>) => {
    onBlur && onBlur(event);
  };

  const getOptionLabel = (option: CompanyLocation | string) => {
    const label =  typeof option === "string" ? option : option.locationFullDisplayName || "";
    return label;
  };

  const getOptionSelected = (option: CompanyLocation, value: CompanyLocation) =>
    option.locationUniqueID === value.locationUniqueID;

  return (
    <AutocompleteField
      label={label}
      loading={isSearchingCompanyLocation}
      minLengthBeforeSearch={3}
      name={name}
      onTypeInput={(
        inputText: string,
        reason: AutocompleteInputChangeReason
      ) => {
        // if (reason !== "input") return;
        // setValue(name, inputText);
        if (reason.toString() === "clear") {
          onClear && onClear();
        }
      }}
      onGetOptions={(value:string) => onSearchCompanyLocations(value, locationUniqueID)}
      onValueChanged={onValueChanged}
      // renderOption={renderOption}    // please don't remove this
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onBlur={handleOnBlur}
      disableClearable={false}
      popperPlacement="bottom"
      />
  );
};

export default AgencyLocationField;

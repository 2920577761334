import {
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  styled,
} from "@mui/material";
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  iconSize,
  margin,
  padding,
} from "theme/defaultStyle";
import {
  DialogType,
  getBackgroundColor,
} from "utils/custom-hooks/useDialogStyles";

interface StyledDialogProps extends DialogProps {
  width?: number,
  type?: DialogType,
  height?: number,
  titleMarginTop?: string,
}

interface StyledDialogContentProps extends DialogContentProps {
  type?: DialogType,
}

interface StyledDialogTitleProps extends DialogTitleProps {
  type: string;
  titleMarginTop?: string;
}

const StewartDialog = styled(Dialog)<StyledDialogProps>(({ height, width }: StyledDialogProps) => {
  return {
    "& div.MuiPaper-rounded": {
      borderRadius: borderRadius.xlarge,
    },
    "& div.MuiDialog-paper": {
      maxHeight: height ? height : "600px",
      overflowY: "visible",
      width: width ? width : "auto",
    },
    "& & div.MuiDialog-paperFullWidth": {
      maxHeight: height ? height : "600px",
      overflowY: "visible",
      width: width ? width : "auto",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: width ? width : "400px",
    },
  };
});

const StewartDialogTitle = styled(DialogTitle)<StyledDialogTitleProps>((props: StyledDialogTitleProps) => {
  const { type, titleMarginTop } = (props) as any;
  return {
    alignItems: "center",
    backgroundImage: getBackgroundColor(type),
    borderRadius: borderRadius.large,
    color: colors.white,
    fontFamily: 'Roboto, sans-serif',
    fontSize: fontSize.xlarge2,
    fontStyle: 'normal',
    fontWeight: fontWeight.bold2,
    justifyItems: "center",
    margin: 'auto',
    marginTop: titleMarginTop ? `-${titleMarginTop}` : `-${margin.xlarge2}`,
    padding: padding.xlarge,
    position: 'relative',
    textAlign: "center",
    width: "95%",
    "& .action-title": {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      "& .MuiSvgIcon-root": {
        color: colors.white,
        cursor: "pointer",
        fontSize: iconSize.medium,
      },
      "& span": {
        flexGrow: 1,
      },
      "& :not(span)": {
        flexBasis: iconSize.medium,
        "&:hover": {
          color: colors.grey03,
        },
      },
    },
  };
});

const StewartDialogContent = styled(DialogContent)<StyledDialogContentProps>(({ type }: StyledDialogContentProps) => ({
  color: colors.grey08,
  fontSize: fontSize.xlarge0,
  padding: padding.xlarge1,
  paddingBottom: padding.zero,
  paddingTop: `${padding.xlarge1} !important`,
  textAlign: type === "ERROR" || type === "WARNING" ? "center" : "left",
}));

const StewartDialogActions = styled(DialogActions)((props: DialogActionsProps) => ({
  justifyContent: "center",
  paddingBottom: padding.xlarge1,
  paddingTop: padding.xlarge1,
}));


export {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle
};


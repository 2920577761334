export enum InventoryModifyType {
  Transfer = "TRANSFER",
  Edit = "EDIT",
}

export enum ErrorCode {
  Valid = "0",
  InvalidBeginning = "1",
  InvalidEnding = "2",
  InvalidBoth = "3",
  InvalidSerialNumberStatus = "4",
  ValidWithWarning = "5",
}

export enum ErrorMessage {
  Required = "Required",
  InvalidEndingRange = "Cannot be less than Beginning Serial Number",
  InvalidOverlapped = "Cannot be overlapped",
  InvalidAgency = "Numbers invalid for Current Agency below",
  InvalidSerialNumberOnFirstRow = "Invalid Serial Number",
  InvalidSerialNumberStatus = 'Invalid Serial Number Status', // contains "Verified", "Issued", or "Voided"',
  ValidWithWarning = "The provided serial numbers have mixed statuses",
}

export enum SerialNumberRangeType {
  Both = "BOTH",
  Begin = "BEGIN",
  // End = "END",

}

// export enum ValidationTriggerType {
//   OnBlur = "ONBLUR",
//   OnSubmit = "ONSUBMIT",
// }
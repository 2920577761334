import {
  styled,
  TextField,
} from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { default as React } from "react";
import { colors } from "theme/defaultStyle";
import theme from "theme/default";
import LightTooltip from "../light-tooltip";

interface Props {
  name?: string;
  label?: string;
  placeholder?: string;
  roundedCorner?: boolean;
  noBorder?: boolean;
  rest?: any;
  error?: boolean;
  value?: string;
  toolTipTitle?: string;
  showTooltip?: boolean;
  disabled?: boolean;
}

type StyledTextFieldProps = {
  noBorder?: boolean;
} & TextFieldProps;

const StyledTextField = styled(TextField)((props: StyledTextFieldProps) => {
  let noBorderProps = {};
  if (props.noBorder) {
    noBorderProps = {
      borderColor: "transparent",
    };
  }
  return {
    "& div.MuiOutlinedInput-root": theme.custom.textField.inputOutlined,
    "& div.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": noBorderProps,
    "& div.MuiOutlinedInput-root path": { fill: colors.blue15 },
    "& div.MuiOutlinedInput-root.Mui-disabled": theme.custom.textField.inputOutlinedDisabled,
    "& div.MuiOutlinedInput-root.Mui-disabled path": { fill: colors.blue09 },
    "& div.Mui-error path": { fill: colors.red01 },
  };
});

const ComboBoxTextField = (props: Props) => {
  const { label, name, placeholder, rest, error, noBorder, value, showTooltip, toolTipTitle, disabled } = props;
  return showTooltip && disabled ? (
    <LightTooltip title={toolTipTitle ? toolTipTitle : value}>
      <StyledTextField
        variant="outlined"
        noBorder={noBorder}
        {...{ label, name, placeholder, error }}
        {...rest}
      />
    </LightTooltip>
  ) : (
    <StyledTextField
      variant="outlined"
      noBorder={noBorder}
      {...{ label, name, placeholder, error }}
      {...rest}
    />
  );
};

export default ComboBoxTextField;

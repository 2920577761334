import { useMsal } from "@azure/msal-react";
import { NavigateToInfo } from "entities/UIModel";
import {
  useLocation,
  useNavigate,
} from "react-router";
// import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useNavigation } from "utils/context/NavigationContext";
// import { usePendingPay } from "utils/context/PendingPayContext";
// import { useReportPay } from "utils/context/ReportPayContext";
import { NavigateToType } from "utils/data/enum";
import {
  isProfileSettingsPage,
  isInventoryAssignNew,
  isInventoryModifyNew,
} from "utils/routes/pathValidator";

const useNavRedirect = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, { setNextUrl, navigateToNextUrl }] = useNavigation();
  // const [, { clearSelectedItems: clearReportPaySelectedItems }] = useReportPay();  
  // const [, { clearSelectedItems: clearPendingPaySelectedItems }] = usePendingPay();
  const { instance } = useMsal();
  // const [, { clearUserSelectedAgency }] = useGlobalAccess();

  const redirect = (url: string | null, saveFileChangeRef: any, checkSaveFileChange: boolean | undefined = true) => {
    // clear selected items for Report Pay or Pending Pay page
    // if (isReportPayPage(url || "")) {
    //   clearReportPaySelectedItems();
    // }
    // else if (isPendingPayPage(url || "")) {
    //   clearPendingPaySelectedItems();
    // }

    setNextUrl(url);
    if (
      !checkSaveFileChange ||
      ( !isProfileSettingsPage(pathname) &&
        !isInventoryAssignNew(pathname, saveFileChangeRef) &&
        !isInventoryModifyNew(pathname, saveFileChangeRef)
      )
    ) {
      navigateToNextUrl(navigate);
    } else {
      setTimeout(() => {
        //timeout is needed to give the React engine trime to update the navUrlHook
        if (saveFileChangeRef?.current) {
          saveFileChangeRef?.current.click();
        }
      }, 100);
    }
};

  function navigateAfterSaveChangesDialog(navigateToInfo:NavigateToInfo) {
    if (navigateToInfo.navigateToType) {
      switch (navigateToInfo.navigateToType) {
        case NavigateToType.Url:
          navigateToInfo.url && navigate(navigateToInfo.url);
          break;
        case NavigateToType.Tab:
          navigateToInfo.tabName && document?.getElementById(navigateToInfo.tabName)?.click();
          break;
        case NavigateToType.Button:
          (document.querySelector(`#${navigateToInfo.buttonId}`) as HTMLElement)?.click();
          break;
        case NavigateToType.Adb2c:
          instance.logout();
          // clearUserSelectedAgency();
          break;
      }
    }
  }

  return { redirect, navigateAfterSaveChangesDialog };
};

export default useNavRedirect;

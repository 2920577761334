import axios from "axios";
// import { GlobalProfileStore } from "utils/context/ProfileSettingsContext";
import {
  DOMEventType,
  SessionStorageKeys,
} from "utils/data/enum";
import {
  loginRequest,
  msalInstance,
} from "utils/msal/authConfig";
import { v4 as uuidv4 } from "uuid";

const axiosAnonymousInstance = axios.create({
  baseURL: "/api",
  responseType: "json",
});

axiosAnonymousInstance.interceptors.request.use(
  async (config) => {
    // config.headers = {
    //   ...config.headers,
    //   "X-Correlation-ID": uuidv4()
    // };
    config.headers.set("X-Correlation-ID", uuidv4());
    return config;
  }
);

const axiosSecuredInstance = axios.create({
  baseURL: "/api",
  responseType: "json",
  withCredentials: true
});

axiosSecuredInstance.interceptors.request.use(
  async (config) => {
    const instance = await msalInstance();
    const account = instance.getAllAccounts()[0];
    if (!account) throw Error("No account. Verify a user has been signed in.");
    const loginRequestObj = await loginRequest;

    const token = await instance.acquireTokenSilent({
      ...loginRequestObj,
      account,
    });

    // config.headers = {
    //   ...config.headers,
    //   authorization: `Bearer ${token.accessToken}`,
    //   'X-Correlation-ID': uuidv4(),
    // };
    config.headers.set("authorization", `Bearer ${token.accessToken}`);
    config.headers.set("X-Correlation-ID", uuidv4());
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosSecuredInstance.interceptors.response.use(
  (response) => {
    checkAppVersion(response.headers);
    return response;
  },
  (error) => {
    // ;
    if (error?.response?.status === 403) {
      window.location.href = "/permissionDenied";
    }
    return Promise.reject(error);
  },
);

const checkAppVersion = (responseHeaders?: any) => {
  if (responseHeaders) {
    const appVersion = responseHeaders["x-app-version"];
    if (appVersion) {
      const priorVersion = sessionStorage.getItem(SessionStorageKeys.APP_VERSION);
      if (!priorVersion) {
        sessionStorage.setItem(SessionStorageKeys.APP_VERSION, appVersion);
      }
      else if (priorVersion !== appVersion) {
        const pendingVersion = sessionStorage.getItem(SessionStorageKeys.APP_VERSION_PENDING);
        if (pendingVersion !== appVersion) {
          sessionStorage.setItem(SessionStorageKeys.APP_VERSION_PENDING, appVersion);
          sessionStorage.setItem(SessionStorageKeys.APP_RELOAD_REQUIRED, "true");
          window.dispatchEvent(new Event(DOMEventType.APP_RELOAD_REQUIRED));
        }
      }
    }
  }
};

const cancelToken = axios.CancelToken;

export { axiosAnonymousInstance, axiosSecuredInstance, cancelToken };

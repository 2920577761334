import React from 'react';

export const CancelIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M4.58329 16.1242L5.87579 17.4167L11 12.2926L16.1241 17.4167L17.4166 16.1242L12.2925 11.0001L17.4166 5.87592L16.1241 4.58342L11 9.70758L5.87579 4.58341L4.58329 5.87591L9.70746 11.0001L4.58329 16.1242Z"
        fill="white"
      />
    </svg>
  );
};

import {
  Box,
  IconButton,
  styled
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
} from '../dialogs/stewart-dialog/StewartDialog';
import FileSelectionOptions, {
  Choice
} from './FileSelectionOptions';
import {
  gaps,
  margin,
  colors,
  fontFamily,
  borderRadius,
} from "theme/defaultStyle";
import CloseIcon from '@mui/icons-material/Close';
import DialogTitleCloseButton from '../dialog-close-btn/DialogTitleCloseButton';
import ActionButton from '../action-button/ActionButton';

export type SELECTION = 'SELECTED' | 'ALL';
export type ITEMIZATION = 'PRODUCT' | 'FILE';
export type FORMATS = {
  EXCEL: boolean;
  PDF: boolean;
};

const DEFAULT_SELECTION = 'SELECTED';

type Props = {
  open: boolean;
  onCancel?: () => void;
  onExport?: (allFiles: boolean) => void;
  showSelectionPrompt?: boolean;
  selectionPrompt: string;
  icon: any;
};

const StyledDialogContent = styled(StewartDialogContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: gaps.medium1,
  justifyContent: 'center',
  paddingTop: '0px !important',
  paddingLeft: '16%',
  '& hr': {
    borderColor: colors.grey09,
    height: '1px',
    marginBottom: margin.zero,
    marginTop: margin.zero,
    width: '415px',
  },
  '& label': {
    display: 'flex',
  },
});

const CustomDialogTitle = styled(StewartDialogContent)({
  maxHeight: '70vh',
  overflowY: 'auto',
  overflowWrap: 'break-word',
  padding: '5px 36px 0px !important',
  '& .icon-box': {
    backgroundImage: 'linear-gradient(#487FAA 0%, #023450 100%)',
    color: colors.grey08,
    display: 'flex',
    fontFamily: fontFamily.primary,
    fontSize: '20px',
    fontWeight: '400',
    justifyContent: 'center',
    float: 'left',
    border: '1px solid transparent',
    borderRadius: borderRadius.xlarge,
    '& .rotate-90': {
      transform: 'rotate(90deg)',
    },
    '& svg': {
      fontSize: '30px',
      color: 'rgb(255, 255, 255)',
      margin: '15px',
    },
  },
  '.message-box': {
    position: 'relative',
    left: '24px',
    textAlign: 'left',
    marginRight: '64px',
    marginTop: '19px',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    fontFamily: fontFamily.primary,
  },
});

const CustomDialogActions = styled(StewartDialogActions)({
  padding: '0px 40px 40px',
  justifyContent: 'end',
  '& button:not(:first-of-type)': {
    marginLeft: '16px',
  },
});

const CloseIconContainer = styled('div')({
  background: colors.grey10,
  borderRadius: '16px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginLeft: 'auto',
  marginBottom: 'auto',
  width: '28px',
  height: '28px',
  padding: '2px',
  zIndex: 1,
  position: 'relative',
  top: '35px',
  '& .MuiSvgIcon-root': {
    color: colors.blue01,
    width: '24px',
    height: '24px',
  },
  '& .iconButton': {
    padding: '2px',
    backgroundColor: colors.grey10,
  },
});

function FileExportDialog({
  open,
  onExport,
  onCancel,
  showSelectionPrompt,
  selectionPrompt,
  icon: Icon,
}: Props) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selection, setSelection] = useState<SELECTION>(DEFAULT_SELECTION);
  const fileSelectionChoices: Choice<SELECTION>[] = [
    { label: 'Selected policies only', value: 'SELECTED' },
    { label: 'All policies', value: 'ALL' },
  ];

  const resetDialog = () => {
    setSelection(DEFAULT_SELECTION);
    setDisabled(false);
  };

  const handleCancel = () => {
    resetDialog();
    onCancel?.();
  };

  const handleExport = () => {
    setDisabled(true);
    const exportAllFiles = !showSelectionPrompt || selection === 'ALL';
    onExport?.(exportAllFiles);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      handleCancel();
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (open) {
      resetDialog();
    }
  }, [open]);

  return (
    <StewartDialog open={open} onKeyDown={handleKeyDown} width={720}>
      <CustomDialogTitle>
        <CloseIconContainer>
          <IconButton className="iconButton" onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </CloseIconContainer>
        <Box className="icon-box">
          <Icon className="rotate-90" />
        </Box>
        <Box className="message-box">{selectionPrompt}</Box>
        <DialogTitleCloseButton onClick={handleCancel} />
      </CustomDialogTitle>
      <StyledDialogContent>
        {showSelectionPrompt && (
          <>
            <FileSelectionOptions
              groupName="file-selection"
              choices={fileSelectionChoices}
              selected={selection}
              setSelected={setSelection}
            />
          </>
        )}
      </StyledDialogContent>
      <CustomDialogActions>
        <>
          <ActionButton
            disabled={disabled}
            color="secondary"
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </ActionButton>
          <ActionButton
            disabled={disabled}
            color="primary"
            variant="outlined"
            onClick={handleExport}
          >
            Export
          </ActionButton>
        </>
      </CustomDialogActions>
    </StewartDialog>
  );
}

export default FileExportDialog;

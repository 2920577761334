export const startNotifying =
    (
        progressId: string,
        onUpdateProgress: (percent: number | undefined) => void
    ) => {
        setStartTime(progressId);
        const maxPercent = 99;
        const searchTime = getEstimateLoadTime(progressId);
        let interations = 30;
        const timeout = Math.max(100, Math.ceil(searchTime / interations));
        interations = Math.floor(searchTime / timeout) - 1;
        const adder = 100 / (interations || 1);
        let percent = 0;

        const intervalId = window.setInterval(() => {
            const activeIntervalId = getIntervalId();
            if (activeIntervalId !== intervalId) {
                window.clearInterval(intervalId);
            } else {
                if (percent < maxPercent) {
                    percent = Math.floor(Math.min(percent + adder, maxPercent));
                    onUpdateProgress(percent);
                }
                else {
                    window.clearInterval(intervalId);
                }
            }
        }, timeout);

        setIntervalId(intervalId);
    };

export const stopNotifying =
    (
        progressId: string,
        onUpdateProgress: (percent: number | undefined) => void
    ) => {

        const activeIntervalId = getIntervalId();
        if (activeIntervalId > 0) {
            if (progressId) {
                const startTime = getStartTime(progressId);
                const endTime = new Date().getTime();
                if (endTime > startTime) {
                    let loadTime = (endTime - startTime);
                    updateEstimatedLoadTimes(progressId, loadTime);
                }
            }

            window.clearInterval(activeIntervalId);
            setIntervalId(-1);
            onUpdateProgress(undefined);
        }
    };

const getEstimateLoadTime = (progressId: string) => {
    const times = getEstimateLoadTimes(progressId);
    const sum = times.reduce((accumulator, a) => accumulator + a, 0);

    return sum / (times.length || 1);
};

const getEstimateLoadTimes = (progressId: string) => {
    const key = `time-${progressId}`;
    let times: number[] = [];
    let cache = localStorage.getItem(key);
    if (cache === null) {
        times = [1500];
    }
    else {
        times = JSON.parse(cache);
        if (!Array.isArray(times) || times.length === 0) {
            times = [1500];
        }
    }

    return times;
};

const updateEstimatedLoadTimes = (progressId: string, lastLoadTime: number) => {
    const window = 6;
    const key = `time-${progressId}`;
    let times = getEstimateLoadTimes(progressId);
    times.push(lastLoadTime);
    times = times.slice(-window);
    localStorage.setItem(key, JSON.stringify(times));
};

const getStartTime = (progressId: string) => {
    return Number(sessionStorage.getItem(`${progressId}-startTime`));
};

const setStartTime = (progressId: string) => {
    sessionStorage.setItem(`${progressId}-startTime`, String(new Date().getTime()));
};

const getIntervalId = () => {
    return Number(sessionStorage.getItem("progress-intervalId"));
};
const setIntervalId = (intervalId: number) => {
    sessionStorage.setItem("progress-intervalId", String(intervalId));
};

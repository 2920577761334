import IosShareIcon from "@mui/icons-material/IosShare";
import {
  Box,
  Button,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AdvancedSearch from "controls/global/advanced-search/AdvancedSearch";

import ColumnsConfig, {
  MenuColumnItem,
} from "controls/global/columns-config/ColumnsConfig";
import React, {
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  colors,
  gaps,
  iconSize,
  padding,
} from "theme/defaultStyle";
import {
  PERMISSIONS,
  PageType,
} from "utils/data/enum";
import { InventoryModifySearchCriteria } from "entities/UIModel/InventoryModifySearchCriteria";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { InventoryModifyUI } from "entities/UIModel/InventoryModifyUI";
import { orderBy } from "lodash";
import { SelectedHeader } from "pages/components/SelectedHeader";
import useModifyStore from "utils/context/InventoryModifyContext";
import useStewartTableStore from "utils/context/StewartTableContext";

interface Props {
  columns: MenuColumnItem<InventoryModifyUI>[];
  hiddenColumns: (keyof InventoryModifyUI)[];
  updateHiddenColumns?: (columns: (keyof InventoryModifyUI)[]) => void;
  handleAdvancedFilterChange: (name: keyof InventoryModifySearchCriteria, value: any, execSearch: boolean) => void;
  handleAdvanceSearchPanelOpen: (open: boolean) => void;
  storedCriteria?: any;
  onExport?: any;
  setOpenExportDialog: (open: boolean) => void;
}

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  marginTop: "-56px",
});

const ActionButtonGroup = styled(Box)({
  display: "flex",
  gap: gaps.large1,
  justifyContent: "flex-end",
  "& .MuiButton-root": {
    color: colors.blue15,
    display: "flex",
    gap: gaps.small1,
    paddingBottom: padding.xsmall1,
    paddingLeft: padding.xsmall2,
    paddingRight: padding.xsmall2,
    paddingTop: padding.xsmall1,
    textTransform: "none",
    "&.Mui-disabled": {
      background: colors.white,
      color: colors.blue12,
      opacity: 1,
    },
    "& .MuiSvgIcon-root": {
      fontSize: iconSize.medium,
    },
    "& .rotate-90": {
      transform: "rotate(90deg)",
    },
  },
});

const InventoryModifyHeader = ({
  columns,
  hiddenColumns,
  updateHiddenColumns,
  handleAdvancedFilterChange,
  handleAdvanceSearchPanelOpen,
  onExport,
  setOpenExportDialog,
}: Props) => {
  const [{ selectedModifyItems }, { setColumnDefinition }] = useModifyStore();
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState<boolean>(false);
  const advancedButtonRef: RefObject<HTMLButtonElement> = useRef(null);
  const handleSearchPanel = (open: boolean) => {
    setAdvanceSearch(open);
    setAdvancedSearchOpen(open);
    handleAdvanceSearchPanelOpen(open);
  };
  const [, { setAdvanceSearch, setMaxDate, setMinDate }] = useStewartTableStore();
  const [sortedColumns, setSortedColumn] = useState<any>([]);
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  const handleColumnChecked = (id: keyof InventoryModifyUI, checked: boolean) => {
    if (checked) {
      updateHiddenColumns?.([...hiddenColumns, id]);
    } else {
      updateHiddenColumns?.([...hiddenColumns].filter((col) => col !== id));
    }
    setColumnDefinition([id], "hidden", checked);
  };
  const handleHideAllColumns = () => {
    if (hiddenColumns.length === sortedColumns.length) return;
    const fieldIdsExceptFirst = sortedColumns.slice(1).map((col: any) => col.field);
    updateHiddenColumns?.(fieldIdsExceptFirst);
    setColumnDefinition(fieldIdsExceptFirst, "hidden", true);
  };
  const handleShowAllColumns = () => {
    if (hiddenColumns.length === 0) return;
    const fieldIds = columns.map((c) => c.field);
    updateHiddenColumns?.([]);
    setColumnDefinition(fieldIds, "hidden", false);
  };

  const handleExport = () => {
    onExport?.();
  };

  useEffect(() => {
    const sortedColumns = orderBy(columns, "name");
    setSortedColumn(sortedColumns);
  }, [columns]);

  useEffect(() => {
    setMinDate(null);
    setMaxDate(null);
  }, [advancedSearchOpen]);

  return (
    <>
      <Container>
        <ActionButtonGroup>
          <Button
            style={{
              backgroundColor: advancedSearchOpen ? colors.blue13 : colors.white,
              opacity: 1,
            }}
            onClick={() => handleSearchPanel(!advancedSearchOpen)}
            data-test="modifyHeader"
            ref={advancedButtonRef}
          >
            <>
              Advanced
              <SearchIcon />
            </>
          </Button>
          <ColumnsConfig
            options={columns}
            onChecked={handleColumnChecked}
            onHideAll={handleHideAllColumns}
            onShowAll={handleShowAllColumns}
          />
          <Button
            disabled={!permissionCodes?.includes(PERMISSIONS.EXPORT_DATA_POLICY)}
            style={{
              opacity: 1,
            }}
            onClick={() => (selectedModifyItems.length === 0 ? handleExport() : setOpenExportDialog(true))}
          >
            <>
              Export
              <IosShareIcon className="icon rotate-90" />
            </>
          </Button>
        </ActionButtonGroup>
        <AdvancedSearch
          key={`adv_search_${advancedSearchOpen}`}
          open={advancedSearchOpen}
          handleFilterChange={advancedSearchOpen ? handleAdvancedFilterChange : () => {}}
          page={PageType.ModifyPage}
        />
      </Container>
      {selectedModifyItems?.length > 0 && <SelectedHeader page={PageType.ModifyPage} />}
    </>
  );
};

export default InventoryModifyHeader;

import React, {
  useEffect,
  useRef,
  useState
} from "react";
import { StewartDialog } from "controls/global/dialogs/stewart-dialog/StewartDialog";
import {
  Box,
  IconButton,
  Typography,
  styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gradients,
  iconSize,
  margin,
  padding,
  scrollBar,
} from "theme/defaultStyle";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import {
  FileRejection,
  FileWithPath
} from "react-dropzone";
import UploadDropZone from "controls/global/upload-files/UploadDropZone";
import {
  getFormattedErrorMessage,
  mapInventoryVerifyResponse,
  mapRejectedFiles,
} from "./InventoryVerifyHelper";
import ConfirmationDialogWithProgressbar from "controls/global/dialogs/confirmation-dialog-with-progressbar";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import { v4 as uuidv4 } from "uuid";
import {
  FileUploadAdditionalInfo,
  InventoryVerifyErrorDetail,
  InventoryVerifyStatus,
} from "entities/UIModel";
import {
  ActionResultStatus,
  FlashMessageType,
  ProgressImageType,
  UIConstants,
} from "utils/data/enum";
import UploadStatusGrid from "controls/global/upload-files/UploadStatusGrid";
import useLookupStore from "utils/context/InventoryLookupContext";
import { useUploadDropZoneActions  } from "utils/context/UploadDropZoneContext";
import FlashMessage from "controls/global/flash-message/FlashMessage";
import { useFlashMessageActions } from "utils/context/FlashMessageContext";
import InventoryVerifyErrorDialog from "./InventoryVerifyErrorDialog";
import useFlashMessage from "utils/custom-hooks/useFlashMessage";

const StyledDialog = styled(StewartDialog)({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    maxHeight: "calc(95vh)",
    maxWidth: "calc(54vw)",
    minWidth: "calc(54vw)",
    display: "flex",
    justifyContent: "flex-start",
    padding: "36px 40px 40px 40px",
    overflowX: "auto",
  },
});

const StyledDialogTitle = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "24px",
  "& .MuiTypography-root": {
    fontSize: fontSize.xlarge2,
    fontWeight: fontWeight.bold2,
  }

})

const scrollBarstyle = {
  maxHeight: "250px",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    width: "14px",
    height: scrollBar.height,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: scrollBar.borderRadius1,
    backgroundColor: colors.grey20,
    border: `${borderSize.medium} solid transparent`,
    backgroundClip: "content-box",
  },
  "&::-webkit-scrollbar-track": {
    background: colors.white,
    boxShadow: "1px 0px 0px 0px #DEDEDE inset",
    borderRadius: "0px 4px 4px 0px",
    border: "1px solid var(--Input-Default-Stoke, #BDBDBD)",
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
};

const IconContainer = styled("div")({
  background: gradients.blueGradient09,
  borderRadius: borderRadius.large,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "56px",
  height: "56px",
  "& .MuiSvgIcon-root": {
    color: colors.white,
    fontSize: iconSize.medium2,
  },
});

const StyledDialogContent = styled(Box)({
  // marginBottom: margin.xlarge1,
  "& .failed-upload-status": {
    marginTop: margin.xlarge1, 
    marginBottom: margin.large, 
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.large,
  },
  "& .success-upload-status": {
    marginTop: margin.large,
    marginBottom: margin.large,
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.large,
  },
  "& .failed-status-grid": {
    ...scrollBarstyle
  },
  "& .failed-status-only-grid": {
    ...scrollBarstyle
  },
  "& .failed-status-grid table, .failed-status-only-grid table": {
    "& td": {
      paddingTop: "6px",
      paddingBottom: "6px",
      fontWeight: fontWeight.normal1,
      fontSize: fontSize.medium,
      },
  },
  "& .success-status-grid": {
    ...scrollBarstyle
  },
  "& .success-status-only-grid": {
    ...scrollBarstyle
  },
  "& .success-status-grid table, .success-status-only-grid table": {
    "& td": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },

});

const CloseIconContainer = styled("div")({
  background: colors.grey10,
  borderRadius: borderRadius.xlarge1,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  marginLeft: "auto",
  marginBottom: "auto",
  width: "32px",
  height: "32px",
  padding: "4px",
  "& .MuiSvgIcon-root": {
    color: colors.blue01,
    width: "24px",
    height: "24px",
  },
  "& .iconButton": {
    padding: padding.zero,
    backgroundColor: colors.grey10,
  },
});

const VERIFY_SUCCESS_MESSAGE = "Policies successfully verified!";

const VERIFY_ERROR_MESSAGE_HEADER = "Verification Failed!";
const VERIFY_ERROR_MESSAGE_DETAIL = "Please try again. ";

type Props = {
  isOpen: boolean;
  onClose: () => void;
}

const InventoryVerifyDialog = ({ isOpen, onClose }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [, { verifyPolicy, refresh }] = useLookupStore();
  const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);
  const [showUploadError, setShowUploadError] = useState<boolean>(false);
  const [uploadStatusList, setUploadStatusList] = useState<InventoryVerifyStatus[]>([]);
  const [showInventoryVerifyErrorDialog, setShowInventoryVerifyErrorDialog] = useState<boolean>(false);
  const [inventoryVerifyErrorDetailList, setInventoryVerifyErrorDetailList] = useState<InventoryVerifyErrorDetail[]>([]);
  // const [hasSuccessUpload, setHasSuccessUpload] = useState<boolean>(false);
  // const [hasFailedUpload, setHasFailedUpload] = useState<boolean>(false);

  const isProgressCompletedRef = useRef<boolean>(false);
  // const successUploadFilesRef = useRef<InventoryVerifyStatus[]>([]);
  // const failedUploadFilesRef = useRef<InventoryVerifyStatus[]>([]);

  // control show progress 
  const [, { setBypassDefaultLoader, setPercentageComplete }] = useProcessStatusTracking();
  const [openProgressDialog, setOpenProgressDialog] = React.useState<boolean>(false);
  const [runProgress, setRunProgress] = React.useState<boolean>(false);
  const [progressRequestId, setProgressRequestId] = React.useState<string>("");
  // const [disable, setDisable] = React.useState<boolean>(true);

  // control upload drop zone
  const [, { setDisabled, setVisble }] = useUploadDropZoneActions();

  // FlashMessage 
  const [, { showFlashMessage, closeFlashMessage }] = useFlashMessageActions();  
  useFlashMessage();

  // reference variables
  // const internalFileId = useRef<number>(0);

  const handleOnCloseProgressDialog = async () => {
    // console.log("call handleOnCloseProgressDialog  - uploadCompleted:", uploadCompleted);
    isProgressCompletedRef.current = true;
    if (uploadCompleted) {
      setOpenProgressDialog(false);
      setRunProgress(false);
      setProgressRequestId("");
    }
  }

  // const handleClickOnDone = () => {
  //   setDisable(true);
  //   onClose && onClose();
  // }

  // const showVerifError = () => {
  //   console.log("Verify Error ....");
  // }

  const upload = async (
    acceptedFiles: FileWithPath[],
    rejectedFiles: FileRejection[]
  ) => {
    // -- Show progress dialog
    setUploadCompleted(false);
    const progressRequestId = uuidv4();
    setProgressRequestId(progressRequestId);
    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setRunProgress(true);

    isProgressCompletedRef.current = false;

    // -- Prepare for upload
    let fileAdditionalInfos: FileUploadAdditionalInfo[] = [];
    const formData = new FormData();

    let index = 0;
    acceptedFiles?.forEach((file) => {
      formData.append("files", file, file.name);
      fileAdditionalInfos.push({
        index: index,
        filePath: file.path || "",
        fileName: file.name,
        fileSize: file.size,
        errorMessage: "",
        isValidFile: true,
      });
      index++;
    });

    rejectedFiles?.forEach((rejectedFile, i) => {
      fileAdditionalInfos.push({
        index: index,
        filePath: "", // not available
        fileName: rejectedFile.file.name,
        fileSize: rejectedFile.file.size,
        errorMessage: getFormattedErrorMessage(rejectedFile.errors),
        isValidFile: false,
      });
      index++;
    });

    formData.append(
      "fileUploadAdditionalInfo",
      JSON.stringify(fileAdditionalInfos)
    );

    // -- Start upload files
    const result = await verifyPolicy(progressRequestId, formData);

    if (result.status === ActionResultStatus.Failed) {
      showFlashMessage(
        FlashMessageType.Error,
        VERIFY_ERROR_MESSAGE_HEADER,
        VERIFY_ERROR_MESSAGE_DETAIL
      );
    } else {

      const inventoryVerifyStatusList = mapInventoryVerifyResponse(
        result.apiResponse
      );

      const hasUploadError = inventoryVerifyStatusList?.some(
        (vs: InventoryVerifyStatus) => vs.isUploadSuccess === false
      );
      const hasVerifyError = inventoryVerifyStatusList?.some(
        (vs: InventoryVerifyStatus) =>
          vs.inventoryVerifyErrorDetailList &&
          vs.inventoryVerifyErrorDetailList?.length > 0
      );

      if (!hasUploadError && !hasVerifyError) {
        showFlashMessage(FlashMessageType.Success, VERIFY_SUCCESS_MESSAGE, "");
      } else if (hasUploadError) {
        const statusList = inventoryVerifyStatusList?.filter(
          (vs) => !vs.isUploadSuccess
        );
        setUploadStatusList(statusList);
        setShowUploadError(true);
      } else if (hasVerifyError) {
        let verifyDerrorDetailCombinedList: InventoryVerifyErrorDetail[] = [];
        inventoryVerifyStatusList
          ?.filter(
            (vs) =>
              vs.isUploadSuccess &&
              vs.inventoryVerifyErrorDetailList &&
              vs.inventoryVerifyErrorDetailList.length > 0
          )
          .forEach((vs) => {
            verifyDerrorDetailCombinedList.push(
              ...vs.inventoryVerifyErrorDetailList!
            );
          });

        setInventoryVerifyErrorDetailList(verifyDerrorDetailCombinedList);
        handleOnClose();
        setShowInventoryVerifyErrorDialog(true);
      }
    }

    setPercentageComplete(progressRequestId);
    setUploadCompleted(true);
  };



  const handleOnUpload = async (acceptedFiles: FileWithPath[], rejectedFiles: FileRejection[]) => {
    // -- Remove previous error status
    setShowUploadError(false);
    setUploadStatusList([]);

    const hasAcceptedFiles = acceptedFiles?.length > 0;

    if (hasAcceptedFiles) {
      setDisabled(true);
      setVisble(false);
  
      await upload(acceptedFiles, rejectedFiles);

      setDisabled(false);
      setVisble(true);    
    }
    else {
      const inventoyVerifyStatusList = mapRejectedFiles(rejectedFiles);
      setUploadStatusList(inventoyVerifyStatusList);
      setShowUploadError(true);
    }

  }

  function handleOnClose() {
    onClose && onClose();
  }

  useEffect(() => {
    setShowUploadError(false);
    setUploadStatusList([]);
    setOpen(isOpen);
    isProgressCompletedRef.current = false;
  }, [isOpen])

  function handleFlashMessageClose(
    event: React.SyntheticEvent | Event,
    reason?: string
  ) {
    if (reason === "clickaway") {
      return;
    }

    closeFlashMessage();
  }  

  const closeInventoryVerifyErrorDialog  = () => {
    refresh();
    setShowInventoryVerifyErrorDialog(false);
  }

  useEffect(() => {
    if (uploadCompleted && openProgressDialog) {
      handleOnCloseProgressDialog();
    }
  },[uploadCompleted])

  return (
    <>
      <StyledDialog
        open={open}
      >
        <StyledDialogTitle id="styledDialogTitleDebug">
          <IconContainer>
            <NoteAddIcon></NoteAddIcon>
          </IconContainer>
          <Typography id="uploadPolicyImageTitleDebug">Verify Inventory</Typography>
          <CloseIconContainer>
            <IconButton className="iconButton" onClick={handleOnClose}>
              <CloseIcon />
            </IconButton>
          </CloseIconContainer>
        </StyledDialogTitle>
        <StyledDialogContent>
          <UploadDropZone handleOnUpload={handleOnUpload} />
          {showUploadError && (
            <>
              <div className="failed-upload-status">
                Failed to Upload
              </div>
              <UploadStatusGrid
                className={"failed-status-only-grid"}
                fileUploadStatuses={uploadStatusList}
              />
            </>
          )}
        </StyledDialogContent>

      </StyledDialog>
      <InventoryVerifyErrorDialog 
        isOpen={showInventoryVerifyErrorDialog}
        onClose={closeInventoryVerifyErrorDialog}
        inventoryVerifyErrorDetailList={inventoryVerifyErrorDetailList}
      />

      <ConfirmationDialogWithProgressbar
        autoClose={true}
        title="Please wait while uploading your images ..."
        closeButtonText="Done! Click to Continue"
        isOpen={openProgressDialog}
        onClose={handleOnCloseProgressDialog}
        requestId={progressRequestId}
        runProgress={runProgress}
        progressImageType={ProgressImageType.circular}
      />
      <FlashMessage
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={Number(
          UIConstants.FLASH_MESSAGE_AUTO_HIDDEN_DURATION
        )}
        onClose={handleFlashMessageClose}
      />      
    </>
  )
}

export default InventoryVerifyDialog;
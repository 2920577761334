import { 
  styled, 
  TableCell, 
  TableCellProps, 
  TableRow
} from "@mui/material";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { PolicyActivity } from "entities/UIModel";
import React, { 
  useState 
} from "react";
import { 
  colors, 
  fontSize, 
  fontWeight 
} from "theme/defaultStyle";
import AddNoteIcon from "theme/icons/AddNoteIcon";
import { formatDateTime, removeTimezone } from "utils/shared";
import ViewNoteDialog from "../../dialogs/ViewNoteDialog";

declare module "@mui/material/TableCell" {
  interface TableCellProps {
    minWidth?: string;
    maxWidth?: string;
  }
}

interface Props {
  activity?: PolicyActivity;
}

const StyledTableCell = styled(TableCell)((props: TableCellProps) => ({
  border: "none",
  color: colors.grey08,
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  minWidth: props.minWidth,
  maxWidth: props.maxWidth,
  whiteSpace: "pre-line",
}));

const StyledTableRow = styled(TableRow)({
  border: `1px solid ${colors.grey09}`,
  "&. MuiTableRow-root": {
    "td: first-child": {
      borderLeft: "none",
    },
  },
});

const IconButton = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  cursor: "pointer",
  "& .note-available": {
    color: colors.blue15,
  },
  "& .note-not-available": {
    color: colors.blue17,
    cursor: "not-allowed",
  },
});

const PolicyHistoryBodyRow = ({ activity }: Props) => {
  const [isViewNoteDialogOpen, setIsViewNoteDialogOpen] = useState(false);

  const {
    activityCreatedDate,
    serialNumberActivityID,
    activityDescription,
    activityUser,
    notesDescription,
    originationSource,
  } = activity ?? {};


  return (
    <StyledTableRow key={serialNumberActivityID}>
      <StyledTableCell minWidth="400px" maxWidth="calc(40vw)">
        <TooltipCell title={activityDescription!} />
      </StyledTableCell>

      <StyledTableCell>{activityUser}</StyledTableCell>
      <StyledTableCell>
        {activityCreatedDate && formatDateTime(removeTimezone(activityCreatedDate), false)}
      </StyledTableCell>
      <StyledTableCell>{originationSource}</StyledTableCell>
      <StyledTableCell>
        <IconButton>
            <AddNoteIcon
              width={"25px"}
              height={"25px"}
              className={!notesDescription ? "note-not-available" : "note-available"}
              onClick={!notesDescription ? () => {} : () => setIsViewNoteDialogOpen(true)}
            />
        </IconButton>
      </StyledTableCell>

      <ViewNoteDialog
        isOpen={isViewNoteDialogOpen}
        note={notesDescription}
        onCancel={() => setIsViewNoteDialogOpen(false)}
      />
    </StyledTableRow>
  );
};

export default PolicyHistoryBodyRow;

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Box,
  styled,
} from "@mui/material";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";

const ErrorMessageBox = styled(Box)({
  color: colors.red01,
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  paddingTop: padding.large1,
});

function ErrorMessage() {

  return (
    <ErrorMessageBox>
      <ReportProblemIcon
        style={{ fontSize: 20, color: colors.red01, marginRight: margin.small }}
      />
      Please correct the error(s) below to continue.
    </ErrorMessageBox>
  );
}

export default ErrorMessage;

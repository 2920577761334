import Button, {
  ButtonProps as MuiButtonProps,
} from "@mui/material/Button";
import { styled } from "@mui/material";
import React from "react";
import {
  defaultStyle,
  fontWeight,
} from "theme/defaultStyle";

const PREFIX = "ActionButton";

const classes = {
  root: `${PREFIX}-root`,
  outlinedPrimary: `${PREFIX}-outlinedPrimary`,
  outlinedSecondary: `${PREFIX}-outlinedSecondary`
};

const StyledButton = styled(Button)(() => ({
  [`&.${classes.root}`]: {
    borderRadius: button.borderRadius,
    fontSize: button.fontSize,
    fontWeight: fontWeight.normal2,
    height: button.height,
    paddingLeft: button.paddingLeft,
    paddingRight: button.paddingRight,
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  [`&.${classes.outlinedPrimary}`]: {
    color: button.outlinePrimary.color,
    background: button.outlinePrimary.background,
    boxShadow: button.outlinePrimary.boxShadow,
    border: "none",
    "&:hover": {
      background: button.outlinePrimary.hover.background,
      border: "none",
      boxShadow: button.outlinePrimary.hover.boxShadow,
      color: button.outlinePrimary.hover.color,
    },
    "&:focus": {
      color: button.outlinePrimary.focus.color,
      border: "none",
      backgroundColor: button.outlinePrimary.focus.backgroundColor,
    },
    "&.Mui-disabled": {
      opacity: 0.5,
    },
  },
  [`&.ActionButton-outlinedSecondary`]: {
    color: button.outlineSecondary.color,
    backgroundColor: button.outlineSecondary.backgroundColor,
    boxShadow: button.outlineSecondary.boxShadow,
    border: `${control.borderSize} solid ${button.outlineSecondary.borderColor}`,
    "&:hover": {
      color: button.outlineSecondary.hover.color,
      border: `${control.borderSize} solid ${button.outlineSecondary.hover.borderColor}`,
      backgroundColor: button.outlineSecondary.hover.backgroundColor,
      "& svg": {
        color: button.outlineSecondary.hover.color,
      },
    },
    "&:focus": {
      color: button.outlineSecondary.focus.color,
      border: `${control.borderSize} solid ${button.outlineSecondary.focus.borderColor}`,
      backgroundColor: button.outlineSecondary.focus.backgroundColor,
      "& svg": {
        color: button.outlineSecondary.focus.color,
      },
    },
    "&.Mui-disabled": {
      color: button.outlineSecondary.disabled.color,
      backgroundColor: button.outlineSecondary.disabled.backgroundColor,
      border: `${control.borderSize} solid ${button.outlineSecondary.disabled.borderColor}`,
      opacity: 1,
    },
  }
}));

const { control, button } = defaultStyle;

const ActionButton = ({ disableRipple, ...rest }: MuiButtonProps) => {

  return (
    <StyledButton
      {...rest}
      classes={{
        root: classes.root,
        outlinedPrimary: classes.outlinedPrimary,
        outlinedSecondary: classes.outlinedSecondary
      }} />
  );
};

export default ActionButton;

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import { InventoryModifyType } from "pages/modify/new/enum";
import React from "react";
import { Controller } from "react-hook-form";
import {
  colors,
  fontSize,
  fontWeight,
  gaps,
  margin,
  padding,
} from "theme/defaultStyle";

  const RadioButtonFormField = styled("div")({
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: padding.xsmall,
    "& > label": {
      color: colors.grey08,
      fontSize: fontSize.large,
      fontWeight: fontWeight.bold2,
    },
  });  

const StyledRadioGroup = styled(RadioGroup)({
  display: "flex",
  flexDirection: "row",
  gap: gaps.large1,
  "& div": {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "& label": {
      margin: 0,
    },
    "& .MuiRadio-root": {
      marginLeft: `-${padding.small1}`, // The margin/padding pair allows pulse graphic to not be clipped.
      paddingLeft: padding.small1,
      paddingRight: padding.small1,
    }
  },
});

const Container = styled("div")({
  padding: padding.zero,
  margin: margin.zero,
  "& .MuiRadio-root": {
    marginTop: "-6px",
    marginBottom: "-8px",
  },
});

interface Props {
  name: string;
  // defaultValue: any;
  //value: string;
  disabled?: boolean;
  // onChange?: ( event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  onClick?: (event: any) => void;
  hasTransferModifyPermission?: boolean;
  hasEditModifyPermission?: boolean;
}

function InventoryModifyTypeField({
  name, 
  // defaultValue, 
  // value,
  disabled = false, 
  // onChange,
  onClick,
  hasTransferModifyPermission,
  hasEditModifyPermission,
}: Props) {

  // const handleOnChangeEvent = ( event: React.ChangeEvent<HTMLInputElement>, value: string) => {    
  //   onChange && onChange(event, value);
  // };

  const handleOnClickEvent = ( event: any) => {
    onClick && onClick(event);
  }

  return (
    <Container>
      <RadioButtonFormField>
        <label>
          <span>Modification Type</span>
        </label>
        <Controller
          name={name}
          // defaultValue={defaultValue}
          render={({ field: { name, value, onChange } }) => {
            // const handleOnChange = (
            //   event: React.ChangeEvent<HTMLInputElement>,
            //   value: string
            // ) => {
            //   handleOnChangeEvent(event, value);
            // };

            const handleOnClick = (event: any) => {
              handleOnClickEvent(event);
            }
            return (
              <StyledRadioGroup
                {...{ name, value  }}
                // onChange={(e, v) => handleOnChange(e, v)}
              >
                <FormControlLabel
                  value={InventoryModifyType.Transfer}
                  control={<Radio color="primary" />}
                  label="Transfer"
                  disabled={!hasTransferModifyPermission}
                  onClick={
                    hasTransferModifyPermission ? handleOnClick : undefined
                  }
                />
                <FormControlLabel
                  value={InventoryModifyType.Edit}
                  control={<Radio color="primary" />}
                  label="Edit"
                  disabled={!hasEditModifyPermission}
                  onClick={hasEditModifyPermission ? handleOnClick : undefined}
                />
              </StyledRadioGroup>
            );
          }}
        />
      </RadioButtonFormField>
    </Container>
  );
}

export default InventoryModifyTypeField;

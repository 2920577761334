import { Order, getComparator, stableSort } from "controls/global/stewart-table/stewartTableHelper";
import { StoreActionApi } from "react-sweet-state";
import { createHook } from "react-sweet-state";
import { createStore } from "react-sweet-state";

const getOrder = (columns: any) => {
  let result = {};
  columns
    .filter((col: any) => col.field !== "selected" && col.hidden === false)
    .forEach(({ name, field }: any) => {
      result = {
        ...result,
        [field]: name,
      };
    });
  return result;
};

type State = {
  isLoading: boolean;
  minDate: Date | null;
  maxDate: Date | null;
  showToast: boolean;
  isEditMode: boolean;
  isAdvanceSearch: boolean;
  error?: any;
  fileHeader: string[];
  fileData: Record<string, any>[];
  columnsOrder: string[];
};

type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

const actions = {
  setMinDate:
    (minDate: Date | null) =>
    ({ setState }: StoreApi) => {
      setState({ minDate });
    },
  setMaxDate:
    (maxDate: Date | null) =>
    ({ setState }: StoreApi) => {
      setState({ maxDate });
    },
  setShowToast:
    (showToast: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ showToast });
    },
  setEditMode:
    (isEditMode: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isEditMode });
    },
  setAdvanceSearch:
    (isAdvanceSearch: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isAdvanceSearch });
    },
  setFileData:
    (cols: any, rows: Record<string, any>[], orderBy: any, order: Order) =>
    ({ setState }: StoreApi) => {
      const sortedData = orderBy
        ? stableSort<any>(rows, getComparator(order, orderBy))
        : rows;
      setState({
        fileData: sortedData,
        fileHeader: Object.values(getOrder(cols)),
        columnsOrder: Object.keys(getOrder(cols)),
      });
    },

};

const initialState: State = {
  isLoading: false,
  minDate: null,
  maxDate: null,
  showToast: false,
  isEditMode: false,
  isAdvanceSearch: false,
  error: null,
  fileHeader: [],
  fileData: [],
  columnsOrder: [],
};

const stewartTableStore = createStore<State, Actions>({
  initialState,
  actions,
  name: "stewartTableStore",
});

const useStewartTableStore = createHook(stewartTableStore);

export default useStewartTableStore;


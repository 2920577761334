import { axiosSecuredInstance } from "configurations/axiosConfig";
import { 
  InventoryPolicyDetails, 
  PolicyActivity, 
  PolicyNotes 
} from "entities/UIModel";
import { mapInventoryPolicyDetails } from "pages/lookup/policy-details/utils";
import { 
  createStore, 
  StoreActionApi, 
  createHook 
} from "react-sweet-state";

interface State {
  error: any;
  isLoading: boolean;
  isNotesLoading: boolean;
  isNotesSubmitting: boolean;
  isActivitiesLoading: boolean;
  policyDetails: InventoryPolicyDetails;
  policyActivities: PolicyActivity[];
  policyNotes: PolicyNotes[];
}

type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

const initialState: State = {
  isLoading: false,
  isNotesLoading: false,
  isNotesSubmitting: false,
  isActivitiesLoading: false,
  error: null,
  policyDetails: {} as InventoryPolicyDetails,
  policyActivities: [],
  policyNotes: [],
};

//const constructQueryParams = (params: any) => {
//  let queryParams = "?";
//  for (const key in params) {
//    if (params[key]) {
//      queryParams += `${key}=${params[key]}&`;
//    }
//  }
//  return queryParams.slice(0, -1);
//}

const actions = {
  getPolicyDetails:
    (payload: any) =>
    async ({ dispatch, setState }: StoreApi) => {
      try {
        setState({ isLoading: true });

        const { data } = await axiosSecuredInstance.get(
          `/InventoryPolicy/GetSerialNumberDetailsByID?serialNumberDetailID=${
            payload.serialNumberDetailID
          }&date=${new Date().getTime()}`,
          payload
        );

        const policyData: InventoryPolicyDetails =
          mapInventoryPolicyDetails(data);

        setState({
          policyDetails: policyData,
        });

        return policyData;
      } catch (error: any) {
        setState({ isLoading: false, error: error });
        console.log(error);
      }
    },

  getPolicyNotes:
    (payload: any) =>
    async ({ dispatch, setState }: StoreApi) => {
      try {
        setState({ isNotesLoading: true });

        let notesData: PolicyNotes[] = [];

        const { data } = await axiosSecuredInstance.get(
          `/InventoryPolicy/GetNotes?serialNumberNotesID=${payload.serialNumberNotesID}`,
          payload
        );

        if (!data) {
          setState({ isNotesLoading: false, policyNotes: notesData });
          return notesData;
        }

        notesData = data?.map((note: any) => {
          return {
            serialNumberNotesID: note.SerialNumberNotesID,
            serialNumberDetailID: note.SerialNumberDetailID,
            serialNumberActivityID: note.SerialNumberActivityID,
            notesDescription: note.NotesDescription,
            notesUserID: note.NotesUserID,
            notesUser: note.NotesUser,
            notesCreatedDate: note.NotesCreatedDate,
          };
        });

        setState({
          isNotesLoading: false,
          policyNotes: notesData,
        });

        return notesData;
      } catch (error: any) {
        setState({ isNotesLoading: false, error: error });
        console.log(error);
      }
    },

  getPolicyActivities:
    (payload: any) =>
    async ({ dispatch, setState }: StoreApi) => {
      try {
        setState({ isActivitiesLoading: true });

        let activitiesData: PolicyActivity[] = [];

        const { data } = await axiosSecuredInstance.get(
          `/InventoryPolicy/GetActivities?serialNumberDetailID=${payload.serialNumberDetailID}`,
          payload
        );

        if (!data) {
          setState({
            isActivitiesLoading: false,
            policyActivities: activitiesData,
          });
          return activitiesData;
        }

        activitiesData = data?.map((activity: any) => {
          return {
            serialNumberActivityID: activity.SerialNumberActivityID,
            serialNumberDetailID: activity.SerialNumberDetailID,
            serialNumberNotesID: activity.SerialNumberNotesID,
            activityDescription: activity.ActivityDescription,
            activityUserID: activity.ActivityUserID,
            activityUser: activity.ActivityUser,
            activityCreatedDate: activity.ActivityCreatedDate,
            activityDateTime: activity.ActivityDateTime,
            notesDescription: activity.NotesDescription,
          };
        });

        setState({
          isActivitiesLoading: false,
          policyActivities: activitiesData,
        });

        return activitiesData;
      } catch (error: any) {
        setState({ isLoading: false, error: error });
        console.log(error);
      }
    },

  addNote:
    (payload: any) =>
    async ({ dispatch, setState }: StoreApi) => {
      try {
        setState({ isNotesSubmitting: true });
        const response = await axiosSecuredInstance.post(
          `/InventoryPolicy/AddNotes`,
          payload
        );
        setState({ isNotesSubmitting: false });
        return response;
      } catch (error: any) {
        setState({ isNotesSubmitting: false, error: error });
        console.log(error);
      }
    },
};

const getPolicyDetails = (state: State) => state.policyDetails;

const policyStore = createStore<State, Actions>({
  initialState,
  actions,
  name: "policyStore",
});

const usePolicyStore = createHook(policyStore);

export const usePolicyDetails = createHook(policyStore, {
  selector: getPolicyDetails,
});

export default usePolicyStore;

import { ProfileSettings } from "utils/context/ProfileSettingsContext";

const VALID_ZIP_LENGTH = [5, 10];

const maskZipCode = (val?: string) => {
  if (!val) return "";
  if (val.length === 10) return val;
  if (val.length === 9 && val.indexOf("-") === -1)
    return [val.slice(0, 5), "-", val.slice(5)].join("");
  if (val.length === 6 && val.charAt(val.length - 1) === "-")
    return val.slice(0, 5);
  if (
    val.length >= 6 &&
    val.charAt(val.length - 1) !== "-" &&
    val.indexOf("-") === -1
  )
    return [val.slice(0, 5), "-", val.slice(5)].join("");

  return val;
};

const normalizeValue = (maskedValue?: string) =>
  maskedValue ? maskedValue.replace(/[^0-9|^-]/g, "") : "";


const isValidZipCode = (code?: string) =>
  code && code !== "" && VALID_ZIP_LENGTH.includes(code.length);

const getTimeZoneOffset = (profileSettings: ProfileSettings) => {
  return (
    profileSettings.timeZones.find(
      ({ Name }: { Name: string }) => Name === profileSettings.userTimeZone
    )?.Current_UTC_Offset || "+00:00"
  );
};

const isValidNotes = (notes: string) => {
  return notes.length <= 500;
}

const getInvalidNotesMessage = () => {
  return "Cannot exceed 500 characters";
}

export {
  getTimeZoneOffset,
  maskZipCode,
  normalizeValue,
  isValidZipCode,
  isValidNotes,
  getInvalidNotesMessage,
};

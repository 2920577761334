import React, {
  useEffect,
  useState
} from "react";
import { orderBy } from "lodash";
import {
  Grid,
  IconButton,
  styled
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { colors } from "theme/defaultStyle";
import { useFieldArray } from "react-hook-form";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { InventoryAssignFormPrefixItem } from 'entities/UIModel/InventoryAssignFormPrefixItem';
import { InventoryAssignFormPrefixOption } from 'entities/UIModel/InventoryAssignFormPrefixOption';
import AddLink from "controls/global/add-link/AddLink";
import { getNextFormPrefixItem } from "./InventoryAssignNewHelper";
import CustomTooltip from "controls/global/custom-tooltip";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import NumberInputFieldPricing from "controls/global/number-input-field/pricing/NumberInputFieldPricing";
import {
  TextInput
} from "controls/global/text-input-field";
import {
  useLookupActions,
  useLookupFormPrefixes
} from "utils/context/LookupContext";
import PrefixField from "./PrefixField";
import { FlashMessageType } from "utils/data/enum";
const AddPrefixDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
});

const ContainerBox = styled(Grid)({
  "& .gridStyle": {
    paddingBottom: "0 !important" as any,
  },
  "& .flexCenter": {
    display: "flex",
  },
  "& .deleteIcon": {
    color: colors.blue15,
  },
});
interface Props {
  name: string;
  resetFormPrefixItems: boolean;
  showFlashMessage: (
    flashMessageType: FlashMessageType,
    messageHeader: string,
    messageDetail: string
  ) => void;
}

const schema = "formPrefixItems";
const MAXIMUM_ITEMS_REACHED_MESSAGE_HEADER = "Maximum prefixes reached!";
const MAXIMUM_ITEMS_REACHED_MESSAGE_DETAIL = "Only 10 prefixes can be assigned at a time.";

const FormPrefixGrid = ({ name, showFlashMessage, resetFormPrefixItems }: Props) => {
  const { nameString, watch, setValue, setError, clearErrors, formState, getValues } =
    useFormWrapper();
  const [formPrefixes] = useLookupFormPrefixes();
  const [prefixOptionsArray, setPrefixOptionsArray] = useState<
    InventoryAssignFormPrefixOption[]
  >([]);
  const [addItemBtnDisable, setAddItemBtnDisable] = useState(true);
  const [, { getFormPrefixList }] = useLookupActions();
  const { fields, append, remove } = useFieldArray({
    name: nameString(name),
    keyName: "formPrefixItem",
  });
  const formPrefixItems: InventoryAssignFormPrefixItem[] =
    watch(nameString(name), fields) || [];
  const deleteItemDisabled = formPrefixItems.length === 1;

  useEffect(() => {
    formState.errors?.formPrefixItems && setAddItemBtnDisable(true);
  }, [formState]);

  useEffect(() => {
    if (formPrefixes?.length === 0) getFormPrefixList();
    else {
      const mappedFormPrefixes = formPrefixes?.map((item) => {
        const formPrefixOption: InventoryAssignFormPrefixOption = {
          value: String(item.formPrefixCode),
          text: String(item.formPrefixCode),
          desc: item.formPrefixDescription,
          productTypeAbbr: item.productTypeAbbr,
          productType: item.productType,
          formType: item.formType,
        };
        return formPrefixOption;
      });
      setPrefixOptionsArray(orderBy(mappedFormPrefixes, "value", "desc"));
      setValue(
        `${schema}[0].formPrefixOptions`,
        orderBy(mappedFormPrefixes, "value", "desc")
      );
    }
  }, [formPrefixes, resetFormPrefixItems]);

  const disableAddPrefixButton = (formPrefixItems: InventoryAssignFormPrefixItem[]) => {
    formPrefixItems?.forEach((item: InventoryAssignFormPrefixItem, index: number) => {
      if (item.formPrefix === undefined || item.formPrefix === "" || item.quantity === undefined || String(item.quantity) === '') {
        setAddItemBtnDisable(true);
        return true;
      }
    });
  }

  const addItem = () => {
    setAddItemBtnDisable(true);
    if (formPrefixItems?.length === 10) {
      setAddItemBtnDisable(true);
      showFlashMessage(FlashMessageType.Warning, MAXIMUM_ITEMS_REACHED_MESSAGE_HEADER, MAXIMUM_ITEMS_REACHED_MESSAGE_DETAIL);
      return true;
    }
    if (formPrefixItems?.length <= 10)
      append(getNextFormPrefixItem(formPrefixItems));
    checkThePrefixOptionIfExists(formPrefixItems, prefixOptionsArray, false);
  };
  const handleOnDeleteItem = (index: number) => {
    setAddItemBtnDisable(false);
    checkThePrefixOptionIfExists(
      formPrefixItems,
      prefixOptionsArray,
      true,
      index
    );
    remove(index);
    const filteredPrefixes = formPrefixItems.filter(
      (item) => item.rowId !== formPrefixItems[index].rowId
    );
    disableAddPrefixButton(filteredPrefixes);
  };
  const checkThePrefixOptionIfExists = (
    formPrefixItems: InventoryAssignFormPrefixItem[],
    arr: InventoryAssignFormPrefixOption[],
    isHandleChange?: Boolean,
    ind?: number
  ) => {
    let formPrefixCodes = formPrefixItems.map(
      (item: InventoryAssignFormPrefixItem) => item.formPrefix
    );
    formPrefixCodes = formPrefixCodes.filter(Boolean);
    const filteredFormPrefixOptions = arr?.filter(
      (item: InventoryAssignFormPrefixOption) => !formPrefixCodes.includes(item?.value)
    );
    const selectedPrefixOption =
      ind || ind === 0
        ? prefixOptionsArray?.find(
          (item) => item?.value === formPrefixItems[ind]?.formPrefix
        )
        : "";
    if (!isHandleChange) {
      if (ind === undefined)
        setValue(
          `${schema}.${formPrefixItems?.length}.formPrefixOptions`,
          orderBy(filteredFormPrefixOptions, "value", "desc")
        );
      else {
        setValue(
          `${schema}.${formPrefixItems?.length}.formPrefixOptions`,
          orderBy(
            [...filteredFormPrefixOptions, selectedPrefixOption],
            "value",
            "desc"
          )
        );
      }
    }
    if (ind === undefined) {
      formPrefixItems.forEach((element: InventoryAssignFormPrefixItem, index: number) => {
        const selectedPrefixOptionEachIndex = prefixOptionsArray.find(
          (item) => item?.value === element?.formPrefix
        );
        setValue(
          `${schema}.${index}.formPrefixOptions`,
          orderBy(
            [...filteredFormPrefixOptions, selectedPrefixOptionEachIndex],
            "value",
            "desc"
          )
        );
      });
    } else {
      formPrefixItems.forEach((element: InventoryAssignFormPrefixItem, index: number) => {
        const selectedPrefixOptionEachIndex = prefixOptionsArray.find(
          (item) => item?.value === element?.formPrefix
        );
        setValue(
          `${schema}.${index}.formPrefixOptions`,
          orderBy(
            [
              ...filteredFormPrefixOptions,
              selectedPrefixOptionEachIndex,
              selectedPrefixOption,
            ],
            "value",
            "desc"
          )
        );
      });
    }
  };

  const setFormPrefixValues = (
    index: number,
    selectedOption: SelectFieldOption
  ) => {
    setValue(`${schema}.${index}.formPrefixDescription`, selectedOption.desc);
    setValue(`${schema}.${index}.formType`, selectedOption.formType);
    setValue(`${schema}.${index}.productType`, selectedOption.productType);
    setValue(`${schema}.${index}.productTypeAbbr`, selectedOption.productTypeAbbr);
    clearErrors(`${schema}.${index}.formPrefix`);
  };

  const handleQuantityOnBlur = (event: React.ChangeEvent<any>, index: any) => {
   if (getValues(`${schema}.${index}.quantity`) !== '' || getValues(`${schema}.${index}.quantity`) !== undefined || getValues(`${schema}.${index}.formPrefix`) !== undefined && getValues(`${schema}.${index}.formPrefix`) !== "")
      setAddItemBtnDisable(false);
    disableAddPrefixButton(formPrefixItems);
  }

  const handleFormPrefixChange = (
    _: React.ChangeEvent<{}>,
    selectedOption: SelectFieldOption,
    index: number
  ) => {
    if (selectedOption && getValues(`${schema}.${index}.quantity`) !== undefined && getValues(`${schema}.${index}.quantity`) !== "")
      setAddItemBtnDisable(false);
    if (selectedOption) {
      setAddItemBtnDisable(false);
      setFormPrefixValues(index, selectedOption);
      checkThePrefixOptionIfExists(formPrefixItems, prefixOptionsArray, true);
      disableAddPrefixButton(formPrefixItems);
    }
  };
  const resetPrefixAndDesc = (index: number) => {
    setAddItemBtnDisable(true);
    setValue(`${schema}.${index}.formPrefix`, "");
    setValue(`${schema}.${index}.formPrefixDescription`, "");
    setValue(`${schema}.${index}.productTypeAbbr`, "");
    setValue(`${schema}.${index}.productType`, "");
    setValue(`${schema}.${index}.formType`, "");
    setError(
      `${schema}.${index}.formPrefix`,
      {
        type: "required",
        message: "",
      },
      { shouldFocus: true }
    );
  }
  const handleFormPrefixBlur = (event: any, index: number) => {
    const selectedOption = event?.target?.value;
    if (selectedOption === '')
      resetPrefixAndDesc(index);
    if (selectedOption) {
      const currentPrefixOptions = formPrefixItems[index].formPrefixOptions;
      const findInOptions = currentPrefixOptions?.find(
        (p) => p.value.toString() === selectedOption.toString()
      );
      if (findInOptions)
        setFormPrefixValues(index, findInOptions);
      else
        resetPrefixAndDesc(index);
    }
  };

  return (
    <>
      {formPrefixItems?.length > 0 ? (
        <>
          {formPrefixItems?.map(
            (formPrefixItem: InventoryAssignFormPrefixItem, index: number) => {
              return (
                <ContainerBox key={formPrefixItem.rowId} container spacing={3}>
                  <Grid item xs={1.3} className={"gridStyle"}>
                    <PrefixField
                      name={`${schema}.${index}.formPrefix`}
                      formPrefixOptions={
                        formPrefixItems[index].formPrefixOptions
                      }
                      handleFormPrefixChange={handleFormPrefixChange}
                      handleFormPrefixBlur={handleFormPrefixBlur}
                      index={index}
                    />
                  </Grid>
                  <Grid item xs={8} className={"gridStyle"}>
                    <TextInput
                      label="Description"
                      name={`${schema}.${index}.formPrefixDescription`}
                      value={formPrefixItem.formPrefixDescription}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2} className={"gridStyle"}>
                    <NumberInputFieldPricing
                      prefix=""
                      placeholder=""
                      decimalScale={0}
                      name={`${schema}.${index}.quantity`}
                      onBlur={(event: React.ChangeEvent<any>) => handleQuantityOnBlur(event, index)}
                      label="Quantity"
                    />
                  </Grid>
                  <Grid item xs={0.7} className={"gridStyle flexCenter"}>
                    <CustomTooltip
                      title="Delete"
                      placement="bottom"
                      aria-label="Delete"
                      arrow
                    >
                      <IconButton
                        aria-label="Delete"
                        disabled={deleteItemDisabled}
                        onClick={() => handleOnDeleteItem(index)}
                      >
                        <DeleteIcon
                          className={
                            formPrefixItems.length > 1 ? "deleteIcon" : ""
                          }
                        />
                      </IconButton>
                    </CustomTooltip>
                  </Grid>
                </ContainerBox>
              );
            }
          )}
        </>
      ) : null}
      <AddPrefixDiv>
        <AddLink onClick={addItem} disabled={addItemBtnDisable}>
          Add Prefix
        </AddLink>
      </AddPrefixDiv>
    </>
  );
};

export default FormPrefixGrid;
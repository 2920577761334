import ComboBoxField from "controls/global/combo-box-field";
import React from "react";

interface Props {
    name?: any;
    defaultValue?: any;
    formPrefixOptions?: any;
    handleFormPrefixChange?: any;
    handleFormPrefixBlur?: any;
    index?: any;
}
const PrefixField = ({name, defaultValue, formPrefixOptions, handleFormPrefixChange, handleFormPrefixBlur, index}: Props) => {
    return (
        <ComboBoxField
        label="Prefix"
        noBorder={false}
        name={name}
        defaultValue={defaultValue}
        options={formPrefixOptions}
        onChange={(event, value) =>
        handleFormPrefixChange(event, value, index)
        }
        onBlur={(event) => handleFormPrefixBlur(event, index)}
        roundedCorner={true}
        freeSolo={true}
        disableClearable={true}
        controlTooltipOnMouseEvents={true}
        />  
    )    
}

export default PrefixField;

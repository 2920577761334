import { CancelTokenSource } from "axios";
import { Column } from "entities/ApiModel/IColumn";
import { ActionResultStatus, DownloadFileType } from "utils/data/enum";
import { IColumn } from "entities/ApiModel/IColumn";
import { InventoryLookup } from "entities/ApiModel/InventoryLookup";
import { InventoryLookupExportCriteria } from "entities/ApiModel/InventoryLookupExportCriteria";
import { InventoryLookupSearchCriteria } from "entities/UIModel/InventoryLookupSearchCriteria ";
import { InventoryLookupUI } from "entities/UIModel/InventoryLookupUI";
import { LocalStorageKeys } from "utils/data/enum";
import { StoreActionApi } from "react-sweet-state";

import { axiosSecuredInstance } from "configurations/axiosConfig";
import { cancelToken } from "configurations/axiosConfig";
import { createHook } from "react-sweet-state";
import { createStore } from "react-sweet-state";
import { dateTimeAsFilename } from "utils/shared";
import { downloadByBase64 } from "utils/shared";
import { getBase64DataType } from "utils/shared";
import { startNotifying } from "utils/services/ContextProgressNotifierService";
import { stopNotifying } from "utils/services/ContextProgressNotifierService";
import { ActionResult } from "entities/UIModel";
import { InventoryVerifyErrorExportCriteria } from "entities/ApiModel";

type State = {
  progressPercent?: number;
  refreshData: boolean;
  isLoading: boolean;
  requestTimeoutMs: number;
  axiosCancelToken?: CancelTokenSource;
  documents: InventoryLookupUI[];
  minDate: Date | null;
  maxDate: Date | null;
  showToast: boolean;
  toastType: string | undefined;
  toastMessage: string | undefined;
  isEditMode: boolean;
  isAdvanceSearch: boolean;
  fileDocuments: InventoryLookupUI[];
  isFilePolicyUploadEditMode: boolean;
  isFilePolicyUploadShowToast: boolean;
  error?: any;
  dateType: string;
  dateTypeFieldValue: string;
  selectedLookupItems: InventoryLookupUI[];
};

const getSearchType = (criteria?: InventoryLookupSearchCriteria) => {
  if (criteria === undefined)
    return "";

  const getDayBetween = (startDate: Date, endDate: Date): number => {
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(endDate.valueOf() - startDate.valueOf()) / msInDay
    );
  };

  const propertiesToIgnore = [
    "dateRangeStart",
    "dateTypeCode",
    "fileStatusTypeCode",
    "listCount",
    "requestedPage",
    "showInActive",
    "sortColumn",
    "userFilter",
  ];

  let extendedDateSearch = true;
  const filtersApplied = Object.entries(criteria)
    .filter(([name, value]) => value !== null && !propertiesToIgnore.includes(name))
    .length > 0;

  if (!filtersApplied && criteria.dateRangeStart) {
    const daysForQuickerSearch = 90;
    extendedDateSearch = getDayBetween(new Date(), criteria.dateRangeStart) > daysForQuickerSearch;
  }

  return (filtersApplied || extendedDateSearch) ? "Lookup-long" : "Lookup-short";
};

const startProgressNotififer =
  (criteria: InventoryLookupSearchCriteria) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      startNotifying(getSearchType(criteria), handleUpdateProgress);
    };

const stopProgressNotififer =
  (criteria?: InventoryLookupSearchCriteria) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      stopNotifying(getSearchType(criteria), handleUpdateProgress);
    };


type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;
const setFilesRecordList = (filesSearchResponse: InventoryLookup[]) => (): InventoryLookupUI[] => {
  if (!filesSearchResponse || filesSearchResponse.length === 0) {
    return [];
  }

  return filesSearchResponse.map((file: InventoryLookup) => {
    return {
      serialNumberDetailID: file.SerialNumberDetailID || 0, 
      uniqueIdentifier: file.SerialNumberDetailID || 0,
      inventoryLookupDetailID: file.SerialNumberDetailID || 0,
      inventoryLookupID: file.InventoryLookupID || 0,
      formPrefix: file.FormPrefix || "",
      beginningSerialNumber: file.BeginningSerialNumber || "",
      endingSerialNumber: file.EndingSerialNumber || "",
      effectiveDate: file.EffectiveDate || "",
      clientFileId: file.ClientFileID || "",
      locationDisplayName: file.LocationDisplayName || "",
      locationStateAbbr: file.LocationStateAbbr || "",
      locationLegacyID: file.LocationLegacyID || "",
      // assignedUserName: file.LookupedUserName || "",
      assignedDate: file.AssignedDate || "",
      companyName: file.CompanyName || "",
      companyLegacyID: file.CompanyLegacyID || "",
      inventoryModifyTypeName: file.ProductType,
      policyNumber: file.PolicyNumber || "",
      formType: file.PolicyNumber || "",
      active: file.Active,
      isInventory: 0,
      rowsPerPage: file.RowsPerPage || 0,
      currentRow: file.CurrentRow || 0,
      currentPage: file.CurrentPage || 0,
      totalPages: file.TotalPages || 0,
      totalRows: file.TotalRows || 0,
      issueDate: file.IssueDate || "",
      lastModifiedDate: file.LastModifiedDate || "",
      policyTypeDesc: file.PolicyTypeDesc || "",
      serialNumber: file.SerialNumber || "",
      inventoryAssignID: file.InventoryAssignID || "",
      serialNumberStatusTypeName: file.SerialNumberStatusTypeName || "",
      voidedDate: file.VoidedDate || "",
      verifiedDate: file.VerifiedDate || "",
      // serialNumberDetailID: file.SerialNumberDetailID || "",
    };
  });
};

const setColumns =
  (columns: any[]) =>
    () => {
      const gridColumns: IColumn[] = columns.filter((c) => Column.isValid(c));
      const gridHiddenColumns = gridColumns
        .filter((c) => c.hidden)
        .map((m) => m.field as keyof InventoryLookupUI);
      localStorage.setItem(LocalStorageKeys.COLUMNS_INVENTORY_LOOKUP, JSON.stringify(gridColumns));
      localStorage.setItem(LocalStorageKeys.COLUMNS_INVENTORY_LOOKUP_HIDDEN, JSON.stringify(gridHiddenColumns));
      return { gridColumns, gridHiddenColumns };
    };
const actions = {
  setRequestTimeoutMs:
  (requestTimeoutMs: number) =>
    async ({ setState }: StoreApi) => {
      setState({ requestTimeoutMs });
    },
cancelRequests:
  () =>
    async ({ dispatch, getState }: StoreApi) => {
      dispatch(stopProgressNotififer());
      const token = getState().axiosCancelToken;
      if (token) {
        token.cancel(" Lookup was requested to be cancelled");
      }
    },
  getSearchDocuments:
    (
      payload: InventoryLookupSearchCriteria // ImageArchiveUI) =>
    ) =>
    async ({ dispatch, setState ,getState }: StoreApi) => {
      try {
        dispatch(startProgressNotififer(payload));
        setState({ isLoading: true });
        const token = getState().axiosCancelToken;
        if (token) {
          token.cancel("LookupContext was cancelled due to new request");
        }

        const newCancelToken = cancelToken.source();
        setState({ error: undefined, axiosCancelToken: newCancelToken });
        const { data } = await axiosSecuredInstance.post(
          "/InventoryPolicy/Search",
          payload,
          {
            cancelToken: newCancelToken.token,
            timeout: getState().requestTimeoutMs,
            timeoutErrorMessage: "TIMEOUT",
          }
        );
        dispatch(stopProgressNotififer(payload));
         return dispatch(setFilesRecordList(data));
      } catch (error: any) {
        setState({ error: error.message });
        dispatch(stopProgressNotififer());      }
    },
  exportFiles: (criteria: InventoryLookupExportCriteria) => async () => {
    const getExportFilename = (): string => {
      let filename = `Inventory Lookup ${dateTimeAsFilename()}.${
        DownloadFileType.Xlsx
      }`;
      return filename;
    };
    try {
      const { data } = await axiosSecuredInstance.post<string>(
        "/InventoryPolicy/Export",
        criteria
      );
      const filename = getExportFilename();
      downloadByBase64(
        `data:${getBase64DataType(DownloadFileType.Xlsx)};base64,${data}`,
        filename
      );
      return {};
    } catch (error: any) {
      console.error(error);
    }
  },
  exportVerifyErrors: (criteria: InventoryVerifyErrorExportCriteria) => async ():Promise<ActionResult> => {
    const getExportFilename = (): string => {
      let filename = `Inventory Verify Errors ${dateTimeAsFilename()}.${
        DownloadFileType.Xlsx
      }`;
      return filename;
    };
    try {
      const { data } = await axiosSecuredInstance.post<string>(
        "/InventoryPolicy/ExportVerifyErrors",
        criteria
      );
      const filename = getExportFilename();
      downloadByBase64(
        `data:${getBase64DataType(DownloadFileType.Xlsx)};base64,${data}`,
        filename
      );
      const result: ActionResult = {
        status: ActionResultStatus.Success,
      };          
      return result;
    } catch (error: any) {
      const result: ActionResult = {
        status: ActionResultStatus.Failed,
        error: error,
      };
      return result;            
    }
  },  
  updateSelectedLookupItem:
  (item: InventoryLookupUI) =>
  ({ setState, getState }: StoreApi) => {
    const currentLookupItems = getState().selectedLookupItems || [];
    const updatedLookupItems = currentLookupItems?.filter(
      (d) => d.inventoryLookupDetailID !== item.inventoryLookupDetailID
    );

    if (updatedLookupItems.length === currentLookupItems?.length) {
      updatedLookupItems.push(item);
    }

    setState({
      selectedLookupItems: updatedLookupItems,
    });
  },
updateSelectedLookupItems:
  (items: InventoryLookupUI[]) =>
  ({ setState, getState }: StoreApi) => {
    const currentLookupItems = getState().selectedLookupItems || [];
    const newLookupItems = items.filter(
      (item) =>
        !currentLookupItems?.find(
          (currentLookupItem) =>
            currentLookupItem.inventoryLookupDetailID === item.inventoryLookupDetailID
        )
    );

    let updatedLookupItems: InventoryLookupUI[] = [];
    if (newLookupItems.length > 0) {
      updatedLookupItems = currentLookupItems?.concat(newLookupItems);
    }

    setState({
      selectedLookupItems: updatedLookupItems,
    });
  },
resetSelectedLookupItem:
  () =>
  ({ setState }: StoreApi) => {
    setState({
      selectedLookupItems: [],
    });
  },
  setDateType:
  (dateTypeFieldValue: string) =>
  ({ setState }: StoreApi) => {
    setState({ dateTypeFieldValue });
  },
  setMinDate:
    (minDate: Date | null) =>
    ({ setState }: StoreApi) => {
      setState({ minDate });
    },
  setMaxDate:
    (maxDate: Date | null) =>
    ({ setState }: StoreApi) => {
      setState({ maxDate });
    },
  setShowToast:
    (showToast: boolean, toastType?: string,
      toastMessage?: string) =>
    ({ setState }: StoreApi) => {
      setState({ showToast });
      toastType && setState({ toastType });
      toastMessage && setState({ toastMessage });
    },
  setEditMode:
    (isEditMode: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isEditMode });
    },
  setFileShowToast:
    (isFilePolicyUploadShowToast: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isFilePolicyUploadShowToast });
    },
  setFileEditMode:
    (isFilePolicyUploadEditMode: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isFilePolicyUploadEditMode });
    },
  setAdvanceSearch:
    (isAdvanceSearch: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isAdvanceSearch });
    },
  getInitialColumnsDefintion: (hidden: boolean) => () => {
    const key = hidden
      ? LocalStorageKeys.COLUMNS_INVENTORY_LOOKUP_HIDDEN
      : LocalStorageKeys.COLUMNS_INVENTORY_LOOKUP;
    const existingColumns = localStorage.getItem(key) || "[]";
    return JSON.parse(existingColumns);
  },
  getColumnsDefinition:
    () =>
    async ({ dispatch }: StoreApi) => {
      const { data } = await axiosSecuredInstance.get(
        "/UISettings/grids/columnSettings/InventoryLookup"
      );
      return dispatch(setColumns(data));
    },
  setColumnDefinition:
    (
      fields: (keyof InventoryLookupUI)[],
      propName: keyof Omit<IColumn, "field" | "name">,
      value: string | number | boolean | undefined
    ) =>
    async ({ dispatch }: StoreApi) => {
      const data: IColumn[] = fields.map((field) => ({
        field,
        name: "", //NOT REQUIRED,
        [propName]: value,
      }));

      try {
        const response = await axiosSecuredInstance.post(
          "/UISettings/grids/columnSettings/InventoryLookup",
          data
        );
        if (response.data) {
          dispatch(setColumns(response.data));
        }
      } catch (error) {
        console.error(error);
      }
    },
    setDateTypeCode:
    (dateType: string) =>
    ({ setState }: StoreApi) => {
      setState({ dateType });
    },
    refresh:
    () =>
      ({ setState, getState }: StoreApi) =>
        setState({ refreshData: !getState().refreshData }),
    verifyPolicy: 
      (
        requestId: string,
        formData: FormData,        
      ) => async ({ setState }: StoreApi): Promise<ActionResult> => {
        try {
          setState({ isLoading: true });
          const { data, headers } = await axiosSecuredInstance.post(
            `/InventoryPolicy/Verify?requestId=${requestId}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          setState({ isLoading: false });
          const result: ActionResult = {
            status: ActionResultStatus.Success,
            headers: headers,
            apiResponse: data,
          };          
          return result;

        } catch (error) {
          setState({ isLoading: false, error: error});
          const result: ActionResult = {
            status: ActionResultStatus.Failed,
            error: error,
          };
          return result;          
        }
      },
};

const initialState: State = {
  progressPercent: -1,
  isLoading: false,
  documents: [],
  minDate: null,
  maxDate: null,
  showToast: false,
  toastType: '',
  toastMessage: '',
  isEditMode: false,
  isAdvanceSearch: false,
  fileDocuments: [],
  isFilePolicyUploadEditMode: false,
  isFilePolicyUploadShowToast: false,
  error: null,
  dateType: 'ASSIGNED_DATE',
  dateTypeFieldValue: '',
  selectedLookupItems :[],
  requestTimeoutMs: 45000,
  refreshData: false,
};

const documentStore = createStore<State, Actions>({
  initialState,
  actions,
  name: "documentStore",
});

const getFileDocuments = (state: State) => {
  return state.fileDocuments;
}

const getFilePolicyUploadStates = (state: State) => ({
  isFilePolicyUploadEditMode: state.isFilePolicyUploadEditMode,
  isFilePolicyUploadShowToast: state.isFilePolicyUploadShowToast,
})

const useLookupStore = createHook(documentStore);

const useFileDocuments = createHook(documentStore, {
  selector: getFileDocuments,
})

const useFilePolicyUploadStates = createHook(documentStore, {
  selector: getFilePolicyUploadStates,
})

export default useLookupStore;

export {
  useFileDocuments,
  useFilePolicyUploadStates,
}

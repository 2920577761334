import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";

function useGlobal() {
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  const checkIfPermissionIsAssigned = (
    permissions: PERMISSIONS[] | undefined
  ) => {
    if (permissions && permissions.length > 0) {
      for (let permission of permissions) {
        if (permissionCodes?.includes(permission)) {
          return true;
        }
      }
    }
    return false;
  };

  return { checkIfPermissionIsAssigned };
}

export default useGlobal;

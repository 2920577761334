import { TableBody } from "@mui/material";
import { PolicyNotes } from "entities/UIModel";
import React from "react";
import { 
  Order, 
  getComparator, 
  stableSort 
} from "utils/sorting";
import NotesSectionBodyRow from "./NotesSectionBodyRow";

interface Props {
  order: Order;
  orderBy: keyof PolicyNotes;
  notes: PolicyNotes[];
}

const NotesSectionBody = ({ order, orderBy, notes }: Props) => {
  const noNotes = Boolean(notes.length === 0);

  if (noNotes)
    return (
      <TableBody>
        <NotesSectionBodyRow />
      </TableBody>
    );

  return (
    <TableBody>
      {stableSort(notes, getComparator(order, orderBy)).map((rec) => (
        <NotesSectionBodyRow key={rec?.serialNumberNotesID} note={rec} />
      ))}
    </TableBody>
  );
};

export default NotesSectionBody;

import { MenuItem } from "@mui/material";
import OverflowTooltip from "controls/global/overflow-tooltip";
import { isEqual } from "lodash";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { v4 as uuidv4 } from "uuid";
import StewartSelect from "../stewart-select";
import useHandleOnKeyDown from "./useHandleOnKeyDown";

export interface SelectFieldOption {
  value: string;
  text: string;
  sortOrder?: number;
  selected?: boolean;
  desc?: string;
  opaIdentifier?: string;
  altEndorsementID?: string;
  endorsementVersion?: number;
  activeContractID?: string;
  formType?: string;
  productType?: string;
  productTypeAbbr?: string;
  // originalValue: Useful for scenarios where the "value" prop is dirty.
  // "value" might contain extra information (ex: "value - description").
  originalValue?: string;
  legacyID?: string;
}

type Props = {
  label: string;
  name: string;
  options: any[];
  dataValueField?: string;
  dataTextField?: string;
  optionsTextField?: string;
  optionsValueField?: string;
  optionsDescField?: string;
  value?: any;
  error?: boolean;
  disabled?: boolean;
  disableClearButton?: boolean;
  open?: boolean;
  delaySearching: number;
  allowsDelete: boolean;
  fieldRef?: any;
  onChange?: (e: any, item: any, desc?: string, initValue?: string) => void;
  onBlur?: (item: any) => void;
  onKeyDown?: (e: KeyboardEvent) => void;  
  className?: string;
  showTooltipAlways?: boolean;
  tabIndex?: number;
  size?: "small" | "medium";
};

const SelectInput = ({
  label,
  name,
  dataTextField,
  dataValueField,
  optionsTextField = "text",
  optionsValueField = "value",
  optionsDescField = "desc",
  value,
  options,
  disabled,
  error,
  delaySearching,
  allowsDelete,
  fieldRef,
  onChange,
  onBlur,
  onKeyDown,
  className,
  showTooltipAlways,
  tabIndex = 0,
  size = "medium",
  ...rest
}: Props) => {

  const [actualValue, setActualValue] = useState("");
  const [shrinkable, setShrinkable] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const elementRef = useRef<HTMLInputElement>(
    null
  ) as React.MutableRefObject<HTMLInputElement>;
  const { getValues } = useFormWrapper();
  const { handleKeyDown, open, setOpen, setStoreKeys } = useHandleOnKeyDown(
    name,
    optionsValueField,
    delaySearching,
    allowsDelete,
    dataValueField,
    dataTextField
  );

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    const option = options.find((option) => option[optionsValueField] === e.target.value);

    const selectedValue = option[optionsValueField]?.toString();
    const selectedLabel = option[optionsTextField];
    const selectedDescription = option.desc;
    const selectedOriginalValue = option.originalValue?.toString();

    // if click on select option but is not allowed to delete the state
    if (!selectedValue && !allowsDelete) {
      return;
    }
    setFocused(false);
    if (dataValueField === undefined || dataTextField === undefined) {
      onChange &&
        onChange(e, selectedValue, selectedDescription, selectedOriginalValue);
    } else {
      const retval: any = {};
      retval[dataTextField] = selectedLabel;
      retval[dataValueField] = selectedValue;
      onChange &&
        onChange(e, retval, selectedDescription, selectedOriginalValue);
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<any>) => {
    onBlur && onBlur(value);
    setFocused(false);
  };

  const handleOnFocus = () => {
    setFocused(true);
    setShrinkable(true);
  };

  const cleanSetActualValue = (item: any) => {
    if (!isEqual(item, actualValue)) setActualValue(item);
  };

  const handleOnKeyDown = (e: any) => {
    if (name) {
      handleKeyDown(e, options, actualValue, onChange);
    }
    onKeyDown && onKeyDown(e);
  };

  const handleOnClick = (e: any) => {
    if (disabled) {
      return;
    }

    setOpen(!open);
    const value = getValues(name);
    if (value && dataValueField) {
      const newValue = value[dataValueField];
      const currentMatchingIndex = options.findIndex(
        (c) => c.value === newValue
      );
      setStoreKeys({
        key: newValue,
        timesKey: currentMatchingIndex - 1,
      });
    }
  };

  useEffect(() => {
    if (!value) cleanSetActualValue("");
    else if (!dataValueField && !dataTextField) cleanSetActualValue(value);
    else if (dataValueField) cleanSetActualValue(value[dataValueField] || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTextField, dataValueField, value]);

  useEffect(() => {
    setShrinkable(actualValue !== "" || focused || Boolean(error));
  }, [actualValue, error, focused]);

  return (
    <StewartSelect
      id={name}
      select
      label={label}
      fullWidth
      disabled={disabled}
      error={error}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onKeyDown={handleOnKeyDown}
      onClick={handleOnClick}
      ref={elementRef}
      variant="outlined"
      size={size}
      inputRef={(input) => (input ? fieldRef : undefined)}
      className={className}
      inputProps={{
        name,
        "aria-errormessage": error ? "rhfvalidationerror" : "",
        tabIndex: disabled ? -1 : tabIndex,
      }}
      InputLabelProps={{
        shrink: shrinkable || error,
      }}
      SelectProps={{
        open: open,
        value: actualValue,
        disableUnderline: true,
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          onKeyDown: handleOnKeyDown,
        },
      }}
      {...rest}
    >
      {options.map((option) => {
        const optionLabel = option[optionsTextField];
        let optionTooltip = optionLabel;
        if (option[optionsDescField]) optionTooltip = option[optionsDescField];
        const optionValue = option[optionsValueField];
        const isSelected = options.length === 1 || optionValue === actualValue;
        return (
          <MenuItem
            key={uuidv4()}
            value={optionValue}
            selected={isSelected}
            disabled={option.disabled}
            data-label={optionLabel}
            data-test={`${name}_menuItem`}
            data-test-value={`${optionValue}`}
            data-desc={option.desc}
            data-orig-value={option.originalValue}
          >
            {open ? (
              optionLabel
            ) : (
              //Show the tooltip only if it's not open
              <OverflowTooltip
                {...{
                  text: optionLabel,
                  tooltip: optionTooltip,
                  showAlways: showTooltipAlways,
                }}
              />
            )}
          </MenuItem>
        );
      })}
    </StewartSelect>
  );
};

export default SelectInput;

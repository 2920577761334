import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Grid,
  styled
} from '@mui/material';
import {
  FormProvider,
  useForm
} from 'react-hook-form';
import ActionButton from "controls/global/action-button";
import {
  gaps,
  padding
} from 'theme/defaultStyle';
import FormPrefixGrid from './FormPrefixGrid';
import TextInputField from "controls/global/text-input-field";
import {
  checkIfInventoryAssignFormIsDirty,
  getNextFormPrefixItem,
  isCompanyLocationType
} from './InventoryAssignNewHelper';
import newAssignValidationSchema from './newAssignValidationSchema';
import AgencyLocationField from "../../components/AgencyLocationField";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import HeaderRefs from "pages/assign/components/HeaderRefs";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "utils/context/NavigationContext";
import { CompanyLocation } from "entities/UIModel/CompanyLocation";
import FlashMessage from 'controls/global/flash-message/FlashMessage';
import {
  FlashMessageType,
  UIConstants,
  NavigateToType,
  TabTriggerType
} from 'utils/data/enum';
import { useFlashMessageActions } from 'utils/context/FlashMessageContext';
import ConfirmationDialogV2 from "controls/global/dialogs/confirmation-dialog/ConfirmationDialogV2";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { InventoryAssignForm } from "entities/UIModel/InventoryAssignForm";
import { InventoryAssignFormPrefixItem } from 'entities/UIModel/InventoryAssignFormPrefixItem';
import {
  NavigateToInfo,
} from "entities/UIModel";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import TabRefs from "controls/global/tab-navigator/TabRefs";
import useFlashMessage from 'utils/custom-hooks/useFlashMessage';
import { useConfigContext } from 'utils/context/ConfigContextProvider';
import { formatNumber, hasValue } from 'utils/shared';
import { isValidNotes } from 'pages/utils/pageHelper';

const ASSIGN_SUCCESS_MESSAGE_HEADER = "Policy successfully assigned!";
const ASSIGN_ERROR_MESSAGE_HEADER = "Assignment Failed!";
const ASSIGN_ERROR_MESSAGE_DETAIL = "Please try again. ";

const ContainerBox = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& .gridStyle": {
    paddingBottom: "0 !important" as any,
  },
});

const FormPrefixBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const NoteBox = styled(Box)({
});

const SubmitBox = styled("div")({
  paddingTop: padding.xlarge1,
});


const initialValues: InventoryAssignForm = {
  locationFullDisplayName: "",
  selectedLocationFullDisplayName: "",
  notes: "",
  formPrefixItems: [getNextFormPrefixItem([])],
  companyID: "",
  companyName: "",
  companyLegacyID: "",
  locationUniqueID: "",
  locationLegacyID: "",
  locationDisplayName: "",
  locationStateCode: "",
  locationStateAbbr: "",
  maxAssignCount: 0,
  maxAssignCountMessage: "",
  isActive: 1,
};

interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
  tabSaveChangesRef: any;
}

const MAIN_EXIT_MESSAGE = "Exit without submitting?";
const ADDITIONAL_EXIT_MESSAGE = "Leaving will result in data loss.";
const SAVE_ASSIGN_IN_PROGRESS_MESSAGE = "Please wait while saving your changes";

const InventoryAssignNew = ({
  saveFileRef,
  saveFileChangeRef,
  tabSaveChangesRef
}: Props) => {
  // Dialog's states and confirmation dialog
  const { generalConfig } = useConfigContext();
  const [openExitDialog, setOpenExitDialog] = useState<boolean>(false);
  const [openTabExitDialog, setOpenTabExitDialog] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const [{ nextTab }, { setTabTriggerType }] = useNavigation();
  const { navigateAfterSaveChangesDialog } = useNavRedirect();
  const navigate = useNavigate();
  const [, { navigateToNextUrl }] = useNavigation();
  const [resetFormPrefixItems, setResetFormPrefixItems] = useState(false);

  initialValues.maxAssignCount = generalConfig.maxAssignCount;
  initialValues.maxAssignCountMessage = `Cannot exceed ${formatNumber(generalConfig.maxAssignCount)}`;
  const methods = useForm({
    resolver: yupResolver(newAssignValidationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: initialValues,
  });
  const { reset, getValues, clearErrors, trigger, handleSubmit, setValue, watch, formState, } = methods;


  // FlashMessage 
  const [, { showFlashMessage, closeFlashMessage }] = useFlashMessageActions();
  useFlashMessage();

  const [locationFullDisplayName, notes, isActive] = watch([
    "locationFullDisplayName" as const,
    "notes" as const,
    "isActive" as const,
  ]);

  // const validateNotesLength = (notes: string | undefined) => {
  //   return notes !== undefined && notes?.length > 500;
  // };

  const handleSaveChanges = async () => {
    const formValues = getValues();
    const isDirty = checkIfInventoryAssignFormIsDirty(
      initialValues,
      formValues
    );
    if (isDirty) {
      setOpenExitDialog(true);
    }
    else {
      navigateToNextUrl(navigate);
    }
  };



  const clearAgencyLocation = () => {
    setValue("selectedLocationFullDisplayName", "");
    setValue("companyID", "");
    setValue("companyName", "");
    setValue("companyLegacyID", "");
    setValue("locationUniqueID", "");
    setValue("locationLegacyID", "");
    setValue("locationDisplayName", "");
    setValue("locationFullDisplayName", "");
    setValue("locationStateCode", "");
    setValue("locationStateAbbr", "");
    setValue("isActive", 1);
  };

  const handleLocationChange = (location: CompanyLocation) => {
    clearErrors(`notes`);
    if (!location || typeof location === "string") {
      clearAgencyLocation();
      setValue("locationFullDisplayName", location);
      return;
    }

    if (location && isCompanyLocationType(location)) {
      clearErrors(`locationFullDisplayName`);
      setValue("selectedLocationFullDisplayName", location?.locationFullDisplayName);
      setValue("companyID", location?.companyID);
      setValue("companyName", location?.companyName);
      setValue("companyLegacyID", location?.companyLegacyID);
      setValue("locationUniqueID", location?.locationUniqueID);
      setValue("locationLegacyID", location?.locationLegacyID);
      setValue("locationDisplayName", location?.locationDisplayName);
      setValue("locationFullDisplayName", location?.locationFullDisplayName);
      setValue("locationStateCode", location?.locationStateCode);
      setValue("locationStateAbbr", location?.locationStateAbbr);
      setValue("isActive", hasValue(location?.isActive) ? Number(location?.isActive) : 1 );
    }
  };

  const handleLocationBlur = (event: any) => {
    const newInputValue = event?.target?.value;
    const locationUniqueID = getValues("locationUniqueID");
    const selectedLocationFullDisplayName = getValues("selectedLocationFullDisplayName");
    if (
      !locationUniqueID ||
      !newInputValue ||
      !selectedLocationFullDisplayName ||
      newInputValue?.toLowerCase() !== selectedLocationFullDisplayName?.toLowerCase()
    ) {
      clearAgencyLocation();
    }
  };

  const onYesExitDialogHandler = () => {
    setOpenExitDialog(false);
    navigateToNextUrl(navigate);
  };

  const onNoExitDialogHandler = () => {
    setOpenExitDialog(false);
  };

  const onCancelExitDialogHandler = () => {
    setOpenExitDialog(false);
  };

  const onSubmitHandler = async (): Promise<void> => {
    const formValues = getValues();
    clearErrors();
    const isFormValid = await trigger();
    if (!isFormValid)
      return;

    const inventoryAssignDetails = formValues?.formPrefixItems?.map((item: InventoryAssignFormPrefixItem) => {
      return {
        ProductTypeAbbr: item.productTypeAbbr,
        ProductType: item.productType,
        FormType: item.formType,
        FormPrefix: item.formPrefix,
        Quantity: Number(item?.quantity),

      };
    });
    const payload = {
      inventoryAssignDetails,
      companyID: formValues?.companyID,
      companyName: formValues?.companyName,
      companyLegacyID: formValues?.companyLegacyID,
      LocationUniqueID: formValues?.locationUniqueID,
      LocationLegacyID: formValues?.locationLegacyID,
      LocationDisplayName: formValues?.locationDisplayName,
      LocationStateCode: formValues?.locationStateCode,
      LocationStateAbbr: formValues?.locationStateAbbr,
      Notes: formValues?.notes,
    }

    try {
      openAutomaticProgressDialog(SAVE_ASSIGN_IN_PROGRESS_MESSAGE);
      await axiosSecuredInstance.post("/InventoryAssign/save", payload);
      showFlashMessage(FlashMessageType.Success, ASSIGN_SUCCESS_MESSAGE_HEADER, "");
      closeAutomaticProgressDialog();
      reset(initialValues);
      setResetFormPrefixItems(!resetFormPrefixItems);
    } catch (error) {
      showFlashMessage(FlashMessageType.Error, ASSIGN_ERROR_MESSAGE_HEADER, ASSIGN_ERROR_MESSAGE_DETAIL);
      closeAutomaticProgressDialog();
    }
  }

  function handleFlashMessageClose(
    event: React.SyntheticEvent | Event,
    reason?: string
  ) {
    if (reason === "clickaway") {
      return;
    }

    closeFlashMessage();
  }

  const onYesTabExitDialogHandler = () => {
    setOpenTabExitDialog(false);

    const navgiateToInfo: NavigateToInfo = {
      navigateToType: NavigateToType.Tab,
      tabName: nextTab,
    }
    setTabTriggerType(TabTriggerType.System);
    setTimeout(() => {
      navigateAfterSaveChangesDialog(navgiateToInfo);
    }, 100);

  }

  const onNoTabExitDialogHandler = () => {
    setOpenTabExitDialog(false);
  };

  const onCancelTabExitDialogHandler = () => {
    setOpenTabExitDialog(false);
  };

  const handleTabSaveChanges = () => {
    const formValues = getValues();
    const isDirty = checkIfInventoryAssignFormIsDirty(
      initialValues,
      formValues
    );
    if (isDirty) {
      setOpenTabExitDialog(true);
    }
    else {
      const navgiateToInfo: NavigateToInfo = {
        navigateToType: NavigateToType.Tab,
        tabName: nextTab,
      }
      setTabTriggerType(TabTriggerType.System);
      setTimeout(() => {
        navigateAfterSaveChangesDialog(navgiateToInfo);
      }, 100);

    }

  }

  const handleNotesBlur = (event: any) => {
    trigger("notes");
  }

  useEffect(() => {
    const formValues = getValues();
    setSubmitDisabled(false);
    formValues?.formPrefixItems?.forEach(item => {
      Object.values(item).forEach(value => {
        if (value === undefined || value === null || value === "" || locationFullDisplayName === undefined || locationFullDisplayName === null || locationFullDisplayName === "")
          setSubmitDisabled(true);
      });
    });

    // Notes is required for cancelled agency (isActive = 0)
    if (isActive === 0) {
      if (!notes || notes?.length < 0) {
        setSubmitDisabled(true);
      }
    }

    if (notes && !isValidNotes(notes)) {
      setSubmitDisabled(true);
    }

  }, [getValues(), locationFullDisplayName, isActive, notes]);

  useEffect(() => {
    formState.errors?.formPrefixItems && setSubmitDisabled(true);
  }, [formState]);

  useEffect(() => {
    const triggerValidation = async () => {
      await trigger("notes");
    };

    triggerValidation();
  }, [notes]);

  // const handleDebug = () => {
  //   console.log(getValues());
  // }  

  return (
    <>
      <FormProvider {...methods}>
        <ContainerBox>
          <Grid item xs={12} md={6} className={"gridStyle"}>
            <AgencyLocationField
              label="Agency/Legacy ID (Location)"
              name="locationFullDisplayName"
              onChange={handleLocationChange}
              onBlur={handleLocationBlur}
              onClear={clearAgencyLocation}
            />
          </Grid>
          <FormPrefixBox>
            <FormPrefixGrid name="formPrefixItems" showFlashMessage={showFlashMessage} resetFormPrefixItems={resetFormPrefixItems} />
          </FormPrefixBox>
          <NoteBox>
            <Grid item xs={12} className={"gridStyle"}>
              <TextInputField label="Notes" name="notes" onBlur={handleNotesBlur} />
            </Grid>
          </NoteBox>
        </ContainerBox>
      </FormProvider>
      <SubmitBox>
        <ActionButton
          variant="outlined"
          color="primary"
          onClick={onSubmitHandler}
          // disabled={submitDisabled || validateNotesLength(notes)}
          disabled = {submitDisabled}
          >
          Submit
        </ActionButton>
      </SubmitBox>
      {/* <SubmitBox>
        <ActionButton
          variant="outlined"
          color="primary"
          onClick={handleDebug}
          disabled={false}
        >
          Debug
        </ActionButton>
      </SubmitBox> */}
      <HeaderRefs
        saveFileRef={saveFileRef}
        saveFileChangeRef={saveFileChangeRef}
        handleSaveChanges={handleSaveChanges}
        handleSaveFile={handleSubmit(onSubmitHandler)}
      />
      <TabRefs
        tabSaveChangesRef={tabSaveChangesRef}
        handleTabSaveChanges={handleTabSaveChanges}
      />
      <ConfirmationDialogV2
        isOpen={openExitDialog}
        mainMessage={MAIN_EXIT_MESSAGE}
        additionalMessage={ADDITIONAL_EXIT_MESSAGE}
        singleActionButtonText="Exit"
        onYes={onYesExitDialogHandler}
        onNo={onNoExitDialogHandler}
        buttonNoText={"Cancel"}
        onCancel={onCancelExitDialogHandler}
      />
      {/* Dialog for tab */}
      <ConfirmationDialogV2
        isOpen={openTabExitDialog}
        mainMessage={MAIN_EXIT_MESSAGE}
        additionalMessage={ADDITIONAL_EXIT_MESSAGE}
        singleActionButtonText="Exit"
        onYes={onYesTabExitDialogHandler}
        onNo={onNoTabExitDialogHandler}
        buttonNoText={"Cancel"}
        onCancel={onCancelTabExitDialogHandler}
      />
      <FlashMessage
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // autoHideDuration={null}
        autoHideDuration={Number(
          UIConstants.FLASH_MESSAGE_AUTO_HIDDEN_DURATION
        )}
        onClose={handleFlashMessageClose}
      // action={flashMessageAction}
      />
    </>
  );
}

export default InventoryAssignNew
export type Order = "asc" | "desc";

function getFormattedValue<T>(value: T) {
  if (value == null || value === undefined) {
    return "";
  }
  else if (typeof value === "string") {
    return value.toLowerCase();
  }
  else {
    return value;
  }
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aVal = getFormattedValue(a[orderBy]);
  const bVal = getFormattedValue(b[orderBy]);
  if (bVal < aVal) return -1;
  if (bVal > aVal) return 1;
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export { getComparator, stableSort };

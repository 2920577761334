import React, { 
  useState
} from "react";
import { styled } from "@mui/styles";
// import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import useTitle from "utils/custom-hooks/useTitle";
import theme from "theme/default";
import { 
  borderRadius, 
  fontSize, 
  fontWeight, 
  gradients,
  padding,
} from "theme/defaultStyle";
import {
  Box,
  Typography,
} from "@mui/material";
import GradientIcon from "theme/icons/GradientIcon";
import TabNavigator from "controls/global/tab-navigator";

import { TabNavigatorItem } from "entities/UIModel";
import { Assign } from "utils/data/enum";
import { PERMISSIONS } from "utils/data/enum";
import InventoryAssignNew from "./new/InventoryAssignNew";
import InventoryAssign from "./assignGrid/InventoryAssign";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import SerialNumberAssignIcon from "theme/icons/SerialNumberAssignIcon";

const Container = styled("div")({
  backgroundColor: theme.palette.background.default,
  borderBottomLeftRadius: borderRadius.xlarge,
  borderBottomRightRadius: borderRadius.xlarge,
  position: "relative",
  "& .header": {
    display: "flex",
    alignItems: "center",
  },
  "& .headerContainer": {
    display: "flex",
    alignItems: "center",
    height: "28px",
    margin: "40px 0px 20px 0px",
    justifyContent: "space-between",
  },
  "& .titleQuickReferenceCard": {
    marginLeft: "10px",
    background: gradients.redGradient03, 
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: fontWeight.bold2,
    fontSize: fontSize.xlarge2,
    paddingLeft: "10px",
  },
});

interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
  tabSaveChangesRef: any;
}

const AssignHome = ({ saveFileRef, saveFileChangeRef, tabSaveChangesRef }: Props) => {
  useTitle("Stewart Connect - Assign");
  const [tabsWithErrors ] = useState<string[]>([]);  
  const [{ profileSettings },] = useProfileSettingsCache();
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);
  const getTabNames = () => {
    let reorderedTabs: TabNavigatorItem[]= [];
    if(permissionCodes?.includes(PERMISSIONS.INV_ASSIGN_MODIFY))
      reorderedTabs.push({
        title: Assign.New,
        disabled: false,
      });
      if(permissionCodes?.includes(PERMISSIONS.INV_ASSIGN_VIEW))
      reorderedTabs.push({
        title:  Assign.Assigned,
        disabled: false,
      });
      return reorderedTabs;
    
  }
  const navigatePolicyTabs = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !evt.shiftKey) {
      const element = evt.target as HTMLElement;
      if (element.tabIndex < 0) {
        const nextTab = getTabNames().findIndex(tab => tab.title === element.id) + 1;
        if (nextTab < getTabNames().length) {
          evt.preventDefault();
          document?.getElementById(getTabNames()[nextTab].title)?.focus();
        }
        if (nextTab === getTabNames().length) {
          let element = document?.getElementById("New");
          if (!element) {
            element = document?.getElementById("Assigned");
          }

          if (element) {
            evt.preventDefault();
            element?.focus();
          }
        }
      }
    }
  }  
  const renderComponents = () => {
    let reorderedComponents = [];
    if(permissionCodes?.includes(PERMISSIONS.INV_ASSIGN_MODIFY)) {
      reorderedComponents.push(
        <InventoryAssignNew key={"new-data"} 
        saveFileRef={saveFileRef}
        saveFileChangeRef={saveFileChangeRef} 
        tabSaveChangesRef={tabSaveChangesRef} />,
      );
    }
    if(permissionCodes?.includes(PERMISSIONS.INV_ASSIGN_VIEW)) {
      reorderedComponents.push(
        <InventoryAssign key={"assigned-data"} />,
      );
    }
    return reorderedComponents;
  };
  
  return (
    <Container>
      <Box className="headerContainer">
        <Box className="header">    
          <GradientIcon Icon={SerialNumberAssignIcon}/>
          <Typography className="titleQuickReferenceCard" variant="h6">
            Assign Inventory
          </Typography>
        </Box>
        {/* <ScrollToTopArrow /> */}
      </Box>
      <TabNavigator
          tabsWithErrors={tabsWithErrors}
          tabs={getTabNames()}
          padding={padding.medium2}
          onKeyDown={(evt: KeyboardEvent) => {
            navigatePolicyTabs(evt);
          }} 
          checkSaveChanges={true}
          tabSaveChangesRef={tabSaveChangesRef} >
          {renderComponents()}
        </TabNavigator>      
    </Container>
  );
};

export default AssignHome;

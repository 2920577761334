import HomePage from "pages/home/HomePage";
import ProfileSettings from "pages/profile-settings/ProfileSettings";
import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { PERMISSIONS, SiteErrorType } from "utils/data/enum";
import PrivateLayout from "./PrivateLayout";
import { SiteErrorMessage } from "./components/SiteErrorMessage";
import InventoryLookupHome from "pages/lookup/InventoryLookupHome";
import InventoryAssignHome from "pages/assign/InventoryAssignHome";
import InventoryModifyHome from "pages/modify/InventoryModifyHome";
import PolicyDetails from "pages/lookup/policy-details/PolicyDetails";

const createElement = (element: JSX.Element, permissions?: PERMISSIONS[]) => (
  <PrivateLayout permissions={permissions}>{element}</PrivateLayout>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: createElement(<HomePage />),
  },
  {
    path: "/Home",
    element: createElement(<HomePage />),
  },
  {
    path: "/inventoryLookup",
    element: createElement(<InventoryLookupHome />, [
      PERMISSIONS.INV_LOOKUP_ACCESS,
    ]),
  },
  {
    path: "/inventoryAssign",
    element: createElement(
      <InventoryAssignHome
        saveFileRef={undefined}
        saveFileChangeRef={undefined}
        tabSaveChangesRef={undefined}
      />,
      [PERMISSIONS.INV_ASSIGN_VIEW, PERMISSIONS.INV_ASSIGN_MODIFY]
    ),
  },
  {
    path: "/inventoryModify",
    element: createElement(
      <InventoryModifyHome
        saveFileRef={undefined}
        saveFileChangeRef={undefined}
        tabSaveChangesRef={undefined}
      />,
      [PERMISSIONS.INV_MODIFY_VIEW]
    ),
  },
  {
    path: "/profile-settings",
    element: createElement(
      <ProfileSettings saveFileRef={undefined} saveFileChangeRef={undefined} />
    ),
  },
  {
    path: "/bad-account-message",
    element: createElement(
      <SiteErrorMessage errorType={SiteErrorType.BadAccount} />
    ),
  },
  {
    path: "/policy-details",
    element: createElement(<PolicyDetails />, [PERMISSIONS.INV_LOOKUP_VIEW]),
  },
  {
    path: "/nomoduleaccess",
    element: createElement(
      <SiteErrorMessage errorType={SiteErrorType.NoModuleAccess} />
    ),
  },
  {
    path: "403",
    element: createElement(
      <SiteErrorMessage errorType={SiteErrorType.NoAccess} />
    ),
  },
  {
    path: "404",
    element: createElement(
      <SiteErrorMessage errorType={SiteErrorType.NotFound} />
    ),
  },
  {
    path: "*",
    element: createElement(
      <SiteErrorMessage errorType={SiteErrorType.NotFound} />
    ),
  },
  {
    path: "/permissionDenied",
    element: createElement(<SiteErrorMessage errorType={SiteErrorType.PermissionDenied} />),
  },
]);

export default router;

import { useState } from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

// list of only events supported
const events = {
  backspace: "Backspace",
  delete: "Delete",
  tab: "Tab",
  enter: "Enter",
  home: "Home",
  end: "End",
  down: "ArrowDown",
  up: "ArrowUp",
  escape: "Escape",
};

const useHandleOnKeyDown = (
  name: any,
  optionsValueField: string,
  delaySearching: number,
  allowsDelete: boolean,
  dataValueField?: string,
  dataTextField?: string
) => {
  const { setValue } = useFormWrapper();
  const [buffer, setBuffer] = useState("");
  const [bufferTimeout, setBufferTimeout] = useState<any>(-1);
  const [storeKeys, setStoreKeys] = useState<{ key: string; timesKey: number }>(
    { key: "", timesKey: -1 }
  );
  const [open, setOpen] = useState<boolean>(false);

  const handleKeyDown = (
    e: any,
    options: any,
    actualValue: any,
    onChange: any
  ) => {
    const saveFieldState = (item: any) => {
      if (dataValueField === undefined || dataTextField === undefined) {
        const itemValue = item[optionsValueField];
        onChange
          ? onChange(e, itemValue, item["desc"], item["originalValue"])
          : setValue(name, itemValue);
      } else {
        const itemValue = {
          [dataValueField]: item[optionsValueField],
          [dataTextField]: item.text,
        };
        onChange
          ? onChange(e, itemValue, item["desc"], item["originalValue"])
          : setValue(name, itemValue);
      }
    };

    const saveStoreKeys = (timesKey: number) =>
      setStoreKeys({
        key: "",
        timesKey,
      });

    const setNewValue = (index: number, nextElement: boolean) => {
      const newIndex = nextElement ? index + 1 : index - 1;
      const newMatchingItem = options[newIndex];
      if (newMatchingItem?.text) {
        saveFieldState(newMatchingItem);
        saveStoreKeys(
          nextElement ? storeKeys.timesKey + 1 : storeKeys.timesKey - 1
        );
      }
    };

    if (e.key === events.backspace || e.key === events.delete) {
      if (allowsDelete) {
        const selectOption = options[0];
        saveFieldState(selectOption);
        saveStoreKeys(-1);
        setOpen(false);
      }
    }

    if (e.key === events.tab || e.key === events.escape) {
      setOpen(false);
    }

    if (e.key === events.enter) {
      setOpen(!open);
    }

    if (e.key === events.home) {
      setNewValue(0, true);
    }
    if (e.key === events.end) {
      const optionsLength = options.length - 2;
      setNewValue(optionsLength, true);
    }

    if (e.key === events.down || e.key === events.up) {
      const index = options.findIndex((c: any) => {
        return c[optionsValueField] === actualValue;
      });
      if (e.key === events.down) {
        if (index === -1) {
          setNewValue(-1, true);
        } else {
          setNewValue(index, true);
        }
      }
      if (e.key === events.up) {
        setNewValue(index, false);
      }
    }

    // if (e.key === " ") return;
    if (e.key.length > 1) return;

    let newValue = (buffer + e.key).toLowerCase();
    let newMatchingItem = [];

    clearTimeout(bufferTimeout);
    setBuffer(newValue);

    const bTimeout = setTimeout(() => {
      setBuffer("");
      newValue = "";
    }, delaySearching);
    setBufferTimeout(bTimeout);

    const matchingOptions = options.filter((c: any) => {
      return c.text.toLowerCase().startsWith(newValue) && c.text !== "Select";
    });

    if (matchingOptions.length === 0) {
      return;
    }

    const currentMatchingIndex = matchingOptions.findIndex(
      (c: any) => c.text.toLowerCase() === newValue
    );

    newMatchingItem = matchingOptions[currentMatchingIndex + 1];

    if (newMatchingItem?.text && storeKeys.key !== newValue) {
      setStoreKeys({
        key: newValue,
        timesKey: 0,
      });

      saveFieldState(newMatchingItem);
    } else {
      let newIndex = 0;
      if (matchingOptions[0].text.toLowerCase() === newValue) {
        newMatchingItem = matchingOptions[0];
      } else {
        newIndex = storeKeys.timesKey + 1;
        newMatchingItem = matchingOptions[newIndex];
      }
      const updateIndex =
        newIndex === matchingOptions.length - 1 ? -1 : newIndex;
      if (newMatchingItem?.text) {
        setStoreKeys({
          key: newValue,
          timesKey: updateIndex,
        });
        saveFieldState(newMatchingItem);
      }
    }
  };
  return { handleKeyDown, open, setOpen, setStoreKeys };
};

export default useHandleOnKeyDown;

import React from "react";
import { Button } from "@mui/material";

interface Props {
  tabSaveChangesRef?: React.MutableRefObject<HTMLButtonElement>;
  handleTabSaveChanges: () => void;
}

const TabRefs = ({ tabSaveChangesRef, handleTabSaveChanges }: Props) => {
  return (
    <>
      <Button
        className="tabSaveButton"
        ref={tabSaveChangesRef}
        hidden
        onClick={handleTabSaveChanges}
      />    
    </>
  )
};

export default TabRefs;
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import { PageType } from "utils/data/enum";
import useModifyStore from "utils/context/InventoryModifyContext";
import useAssignStore from "utils/context/InventoryAssignContext";
import { CancelIcon } from "theme/icons/CancelIcon";
import useLookupStore from "utils/context/InventoryLookupContext";


const SelectionInterfaceContainer = styled.div`
  display: flex;
  height: 35px;
  padding: 6px 8px;
  align-items: center;
  align-self: stretch;
  background: #487FAA;
  color: #fff;
`;

const SelectionText = styled.span`
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  font-feature-settings: 'clig' off, 'liga' off;
`;

const IconContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

const CancelButton = styled.span`
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  font-feature-settings: 'clig' off, 'liga' off;
`;

type ImageArchiveGridProps = {
  page?: PageType;
  onDownload?: () => void;
};

export const SelectedHeader: React.FC<ImageArchiveGridProps> = ({ page }) => {
  const [{ selectedModifyItems }, { resetSelectedModifyItem }] = useModifyStore();
  const [{ selectedAssignItems }, { resetSelectedAssignItem }] = useAssignStore();
  const [{ selectedLookupItems }, { resetSelectedLookupItem }] = useLookupStore(); // Assuming this context exists

  const getSelectedItemsCount = () => {
    switch (page) {
      case PageType.ModifyPage:
        return selectedModifyItems?.length;
      case PageType.AssignPage:
        return selectedAssignItems?.length;
      case PageType.LookupPage:
        return selectedLookupItems?.length;
      default:
        return 0;
    }
  };

  const resetSelection = () => {
    switch (page) {
      case PageType.ModifyPage:
        resetSelectedModifyItem();
        break;
      case PageType.AssignPage:
        resetSelectedAssignItem();
        break;
      case PageType.LookupPage:
        resetSelectedLookupItem();
        break;
      default:
        break;
    }
  };

  return (
    <SelectionInterfaceContainer>
      <SelectionText>
        {getSelectedItemsCount()} item(s) selected
      </SelectionText>
      <IconContainer>
        <Tooltip title="Cancel">
          <IconButton aria-label="cancel" style={{ color: '#FFF' }} onClick={resetSelection}>
            <CancelIcon />
            <CancelButton>Cancel</CancelButton>
          </IconButton>
        </Tooltip>
      </IconContainer>
    </SelectionInterfaceContainer>
  );
};

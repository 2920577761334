import { Button } from "@mui/material";
import React from "react";

interface Props {
  saveFileRef?: React.MutableRefObject<HTMLButtonElement>;
  saveFileChangeRef?: React.MutableRefObject<HTMLButtonElement>;
  handleSaveFile: () => void;
  handleSaveChanges: () => void;
}

const HeaderRefs = ({
  saveFileRef,
  saveFileChangeRef,
  handleSaveFile,
  handleSaveChanges,
}: Props) => {
  return (
    <>
      <Button
        ref={saveFileRef}
        hidden
        onClick={handleSaveFile}
      />
      <Button
        ref={saveFileChangeRef}
        hidden
        onClick={handleSaveChanges}
      />
    </>
  );
};

export default HeaderRefs;

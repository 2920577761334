import React, {
  useEffect,
  useState
} from "react";
import { orderBy } from "lodash";
import {
  Grid,
  IconButton,
  styled
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFieldArray } from "react-hook-form";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { InventoryModifyFormPrefixItem } from "entities/UIModel/InventoryModifyFormPrefixItem";
import AddLink from "controls/global/add-link/AddLink";
import {
  getNextFormPrefixItem,
  hasAnyFormPrefixItemData,
  isValidLocation,
  validateSerialNumberOverlapping
} from "./InventoryModifyNewHelper";
import CustomTooltip from "controls/global/custom-tooltip";
import ComboBoxField from "controls/global/combo-box-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import TextInputField, {
  TextInput
} from "controls/global/text-input-field";
import {
  useLookupActions,
  useLookupFormPrefixes
} from "utils/context/LookupContext";
import useModifyStore from "utils/context/InventoryModifyContext";
import { hasValue } from "utils/shared";
import { AgencyLocationCriteria } from "entities/ApiModel/AgencyLocationCriteria";
import { CompanyLocation } from "entities/UIModel/CompanyLocation";
import {
  ErrorCode,
  ErrorMessage,
  InventoryModifyType,
  SerialNumberRangeType,
  // ValidationTriggerType 
} from "pages/modify/new/enum";
import useInventoryModify from "./useInventoryModify";
import { margin } from "theme/defaultStyle";
import { FlashMessageType } from "utils/data/enum";
import { colors } from "theme/defaultStyle";
const FromPrefixItemDiv = styled("div")({
  display: "flex",
  gap: "24px",
  "& .button-grid": {
    display: "flex",
  },
  "& .deleteIcon": {
    color: colors.blue15,
  },
});

const AddPrefixDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  marginBottom: margin.medium2,
});

interface Props {
  name: any;
  inventoryModifyTypeCode: string;
  setAddItemDisabled: (disabled: boolean) => void;
  addItemDisabled: boolean;
  setSelectedOrigLocationFullDisplayName: (value?: string) => void;
  showFlashMessage: (
    flashMessageType: FlashMessageType,
    messageHeader: string,
    messageDetail: string
  ) => void;
}

const schema = "formPrefixItems";
const MAXIMUM_ITEMS_REACHED_MESSAGE_HEADER = "Maximum prefixes reached!";
const MAXIMUM_ITEMS_REACHED_MESSAGE_DETAIL = "Only 10 prefixes can be assigned at a time.";

const FormPrefixGrid = ({
  name,
  inventoryModifyTypeCode,
  setAddItemDisabled,
  addItemDisabled,
  setSelectedOrigLocationFullDisplayName,
  showFlashMessage,
}: Props) => {
  const { nameString, watch, setValue, getValues, clearErrors, trigger } =
    useFormWrapper();
  const [formPrefixes] = useLookupFormPrefixes();
  const [formPrefixOptions, setFormPrefixOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [, { getFormPrefixList }] = useLookupActions();
  const [, { getCompanyLocation }] = useModifyStore();
  const { setFPIErrorMessagesByErrorCode } = useInventoryModify();

  // const [addItemDisabled, setAddItemDisabled] = useState<boolean>(true);

  const { fields, append, remove } = useFieldArray({
    name: nameString(name),
    keyName: "formPrefixItem",
  });

  const formPrefixItems: InventoryModifyFormPrefixItem[] =
    watch(nameString(name), fields) || [];

  const lastIndex = formPrefixItems.length - 1;

  const deleteItemDisabled = formPrefixItems.length === 1;
  function setOrigCompanyLocationAndStatus(
    companyLocation: CompanyLocation,
    isValidOrigLocation: boolean
  ) {
    setSelectedOrigLocationFullDisplayName(
      companyLocation.locationFullDisplayName
    );
    setValue("isValidOrigLocation", isValidOrigLocation);
    setValue("origCompanyID", companyLocation.companyID);
    setValue("origCompanyName", companyLocation.companyName);
    setValue("origCompanyLegacyID", companyLocation.companyLegacyID);
    setValue("origLocationUniqueID", companyLocation.locationUniqueID);
    setValue("origLocationLegacyID", companyLocation.locationLegacyID);
    setValue("origLocationDisplayName", companyLocation.locationDisplayName);
    setValue(
      "origLocationFullDisplayName",
      companyLocation.locationFullDisplayName
    );
    setValue("origLocationStateCode", companyLocation.locationStateCode);
    setValue("origLocationStateAbbr", companyLocation.locationStateAbbr);

    setValue("oldSerialNumberStatusTypeCode", companyLocation.serialNumberStatusTypeCode);

    // if (
    //   isValidOrigLocation &&
    //   formPrefixItems?.length > 0 &&
    //   formPrefixItems?.length <= 10 &&
    //   hasValue(formPrefixItems[0].formPrefix) &&
    //   hasValue(formPrefixItems[0].serialNumberBegin) &&
    //   hasValue(formPrefixItems[0].serialNumberEnd)
    // ) {
    //   setAddItemDisabled(false);
    // }
  }

  function validateSerialNumberRange(index: number) {
    let isValid = false;

    // For 1st form prefix item, it must have both beginning and ending serial number
    const serialNumberBegin: string = getValues(
      `${schema}.${index}.serialNumberBegin`
    );
    const serialNumberEnd: string = getValues(
      `${schema}.${index}.serialNumberEnd`
    );
    if (hasValue(serialNumberBegin) && hasValue(serialNumberEnd)) {
      if (Number(serialNumberBegin) <= Number(serialNumberEnd)) {
        isValid = true;
      }
    }

    return isValid;
  }

  async function validateAndSetOrigCompanyLocation(
    index: number,
    formPrefix: string,
    serialNumberBegin: string,
    serialNumberEnd?: string
  ) {
    let errorCode: string;
    let locationUniqueID: string | undefined =
      index === 0 ? undefined : getValues("origLocationUniqueID");

    const criteria: AgencyLocationCriteria = {
      FormPrefix: formPrefix,
      BeginSerialNumber: serialNumberBegin,
      EndSerialNumber: serialNumberEnd || undefined,
      LocationUniqueID: locationUniqueID || undefined,
    };

    const companyLocation = await getCompanyLocation(criteria);
    if (companyLocation) {
      if (index === 0 && companyLocation.errorCode && isValidLocation(companyLocation.errorCode)) {  //    companyLocation.errorCode === ErrorCode.Valid) {
        setOrigCompanyLocationAndStatus(companyLocation, true);
      }
      errorCode = companyLocation.errorCode || ErrorCode.InvalidBoth.toString();
    } else {
      errorCode = ErrorCode.InvalidBoth;
    }

    if (errorCode === ErrorCode.ValidWithWarning) {
      showFlashMessage(FlashMessageType.Warning, "Warning", ErrorMessage.ValidWithWarning)
    }
    return errorCode;
  }

  const addItem = () => {
    setAddItemDisabled(true);
    // Only allow 10 items
    if (formPrefixItems?.length >= 10) {
      setAddItemDisabled(true);
      showFlashMessage(FlashMessageType.Warning, MAXIMUM_ITEMS_REACHED_MESSAGE_HEADER, MAXIMUM_ITEMS_REACHED_MESSAGE_DETAIL);
    }
    else {
      append(getNextFormPrefixItem(formPrefixItems));
    }
  };

  const handleOnDeleteItem = (index: number) => {
    remove(index);
    setAddItemDisabled(false);
    const filteredPrefixes = formPrefixItems.filter(
      (item) => item.rowId !== formPrefixItems[index].rowId
    );
    filteredPrefixes.forEach((item, index: number) => {
      if (item.formPrefix === undefined || item.formPrefix === "") {
        setAddItemDisabled(true);
        return true;
      }
    });
  };

  const setFormPrefixValues = (
    index: number,
    selectedOption: SelectFieldOption
  ) => {
    setValue(`${schema}.${index}.formPrefixDescription`, selectedOption.desc);
    setValue(`${schema}.${index}.formType`, selectedOption.formType);
    setValue(`${schema}.${index}.productType`, selectedOption.productType);
    setValue(`${schema}.${index}.productTypeAbbr`, selectedOption.productTypeAbbr);
  };

  const resetFormPrefixValues = (
    index: number,
  ) => {
    setValue(`${schema}.${index}.formPrefix`, "");
    setValue(`${schema}.${index}.formPrefixDescription`, "");
    setValue(`${schema}.${index}.formType`, "");
    setValue(`${schema}.${index}.productType`, "");
    setValue(`${schema}.${index}.productTypeAbbr`, "");
  };

  const handleFormPrefixChange = (
    _: React.ChangeEvent<{}>,
    selectedOption: SelectFieldOption,
    index: number
  ) => {
    if (selectedOption) {
      // setAddItemDisabled(false);
      // setValue(`${schema}.${index}.formPrefixDescription`, selectedOption.desc);
      setFormPrefixValues(index, selectedOption);
    }
  };

  const handleFormPrefixBlur = async (event: any, index: number) => {
    clearErrors(`${schema}.${index}.formPrefix`);

    const selectedOption = event?.target?.value;
    const formPrefix = getValues(`${schema}.${index}.formPrefix`);
    const serialNumberBegin = getValues(`${schema}.${index}.serialNumberBegin`);
    const serialNumberEnd = getValues(`${schema}.${index}.serialNumberEnd`);

    if (selectedOption) {
      const foundOption = formPrefixOptions?.find(
        (p) => p.value.toString() === selectedOption.toString()
      );

      if (foundOption) {
        setFormPrefixValues(index, foundOption);

        if (
          index === 0 ||
          hasAnyFormPrefixItemData(
            formPrefix,
            serialNumberBegin,
            serialNumberEnd
          )
        ) {
          if (hasValue(serialNumberBegin) && hasValue(serialNumberEnd)) {
            const errorCode = await validateAndSetOrigCompanyLocation(
              index,
              formPrefix,
              serialNumberBegin,
              serialNumberEnd
            );

            if (errorCode) {
              if (isValidLocation(errorCode)) {
                setValue(
                  `${schema}.${index}.serialNumberBeginErrorMessage`,
                  ""
                );
                setValue(
                  `${schema}.${index}.serialNumberEndErrorMessage`,
                  ""
                );
              } else {
                setFPIErrorMessagesByErrorCode(
                  index,
                  errorCode,
                  SerialNumberRangeType.Both,
                  setValue,
                );
              }
            }

            // Validate both serial numbers since form prefix may be updated
            clearErrors(`${schema}.${index}.serialNumberBegin`);
            clearErrors(`${schema}.${index}.serialNumberEnd`);
            trigger(`${schema}.${index}.serialNumberBegin`);
            trigger(`${schema}.${index}.serialNumberEnd`);

          }
          else if (index > 0 && hasValue(serialNumberBegin)) {
            const errorCode = await validateAndSetOrigCompanyLocation(
              index,
              formPrefix,
              serialNumberBegin,
              serialNumberEnd
            );

            if (errorCode) {
              if (isValidLocation(errorCode)) {
                setValue(
                  `${schema}.${index}.serialNumberBeginErrorMessage`,
                  ""
                );
              } else {
                setFPIErrorMessagesByErrorCode(
                  index,
                  errorCode!,
                  SerialNumberRangeType.Begin,
                  setValue,
                );
              }
            }

            clearErrors(`${schema}.${index}.serialNumberBegin`);
            trigger(`${schema}.${index}.serialNumberBegin`);
          }
        }
      }
      else {
        resetFormPrefixValues(index);
      }
    }
    else {
      resetFormPrefixValues(index);
    }

    // Trigger validation for 1st item or if any other of items with entered data
    if (
      index === 0 ||
      hasAnyFormPrefixItemData(formPrefix, serialNumberBegin, serialNumberEnd)
    ) {
      setValue(`${schema}.${index}.isValidationRequired`, true);
    }
    else {
      setValue(`${schema}.${index}.isValidationRequired`, false);
    }
    trigger(`${schema}.${index}.formPrefix`);
  };

  const handleSerialNumberBeginBlur = async (index: number) => {
    clearErrors(`${schema}.${index}.serialNumberBegin`);

    const formPrefix = getValues(`${schema}.${index}.formPrefix`);
    const serialNumberBegin = getValues(`${schema}.${index}.serialNumberBegin`);
    const serialNumberEnd = getValues(`${schema}.${index}.serialNumberEnd`);

    // 1st row requires all item data
    if (
      index === 0 ||
      hasAnyFormPrefixItemData(formPrefix, serialNumberBegin, serialNumberEnd)
    ) {
      setValue(`${schema}.${index}.isValidationRequired`, true);

      // validate overlapping, and show error message and exit when it's invalid
      if (index > 0 && hasValue(serialNumberBegin)) {
        const formPrefixItems: InventoryModifyFormPrefixItem[] =
          getValues("formPrefixItems");
        if (validateSerialNumberOverlapping(formPrefixItems, index, serialNumberBegin)) {
          setValue(
            `${schema}.${index}.serialNumberBeginErrorMessage`,
            ""
          );
        } else {
          setValue(
            `${schema}.${index}.serialNumberBeginErrorMessage`,
            ErrorMessage.InvalidOverlapped
          );

          // Show error and exit 
          trigger(`${schema}.${index}.serialNumberBegin`);
          return; // exit when it's invalid
        }
      }

      // validate original location when all fields are entered for the item
      if (
        hasValue(formPrefix) &&
        hasValue(serialNumberBegin) &&
        (hasValue(serialNumberEnd) || index > 0)
      ) {
        const errorCode = await validateAndSetOrigCompanyLocation(
          index,
          formPrefix,
          serialNumberBegin,
          serialNumberEnd
        );

        if (errorCode) {
          if (isValidLocation(errorCode)) {
            setValue(`${schema}.${index}.serialNumberBeginErrorMessage`, "");
            if (hasValue(serialNumberEnd)) {
              setValue(`${schema}.${index}.serialNumberEndErrorMessage`, "");
            }
          } else {
            setFPIErrorMessagesByErrorCode(
              index,
              errorCode,
              SerialNumberRangeType.Begin,
              setValue,
            );

            // Show error and exit
            trigger(`${schema}.${index}.serialNumberBegin`);
            if (hasValue(serialNumberEnd)) {
              trigger(`${schema}.${index}.serialNumberEnd`);
            }
            return; // exit when it's invalid
          }
        }
      }
      else {
        setValue(
          `${schema}.${index}.serialNumberBeginErrorMessage`,
          ""
        );
      }
    }
    else {
      setValue(`${schema}.${index}.isValidationRequired`, false);
      setValue(`${schema}.${index}.serialNumberBeginErrorMessage`, "");
    }

    trigger(`${schema}.${index}.serialNumberBegin`);
  };

  const handleSerialNumberEndBlur = async (index: number) => {
    clearErrors(`${schema}.${index}.serialNumberEnd`);

    let isValid = true;
    const formPrefix: string = getValues(`${schema}.${index}.formPrefix`);
    const serialNumberBegin: string = getValues(`${schema}.${index}.serialNumberBegin`);
    const serialNumberEnd: string = getValues(`${schema}.${index}.serialNumberEnd`);

    if (
      index === 0 ||
      hasAnyFormPrefixItemData(formPrefix, serialNumberBegin, serialNumberEnd)
    ) {
      setValue(`${schema}.${index}.isValidationRequired`, true);

      // Validate serial number range,  and show error message and exit when it's invalid
      if (hasValue(serialNumberBegin) && hasValue(serialNumberEnd)) {
        isValid = validateSerialNumberRange(index);
        if (isValid) {
          setValue(`${schema}.${index}.serialNumberEndErrorMessage`, "");
        } else {
          setValue(
            `${schema}.${index}.serialNumberEndErrorMessage`,
            ErrorMessage.InvalidEndingRange
          );

          // Show error and exit 
          trigger(`${schema}.${index}.serialNumberEnd`);
          return; // exit when it's invalid          
        }
      }

      // 3. Must have a valid original agency location
      if (
        hasValue(formPrefix) &&
        hasValue(serialNumberBegin) &&
        hasValue(serialNumberEnd)
      ) {
        const errorCode = await validateAndSetOrigCompanyLocation(
          index,
          formPrefix,
          serialNumberBegin,
          serialNumberEnd
        );

        if (errorCode) {
          if (isValidLocation(errorCode)) {
            setValue(`${schema}.${index}.serialNumberBeginErrorMessage`, "");
            setValue(`${schema}.${index}.serialNumberEndErrorMessage`, "");
          } else {
            setFPIErrorMessagesByErrorCode(
              index,
              errorCode,
              SerialNumberRangeType.Both,
              setValue,
            );

            // Show error and exit
            trigger(`${schema}.${index}.serialNumberBegin`);
            trigger(`${schema}.${index}.serialNumberEnd`);
            return; // exit when it's invalid
          }
        }
      }

    }
    else {
      setValue(`formPrefixItems.${index}.isValidationRequired`, false);
      setValue(`formPrefixItems.${index}.serialNumberEndErrorMessage`, "");
    }

    clearErrors(`${schema}.${index}.formPrefix`);
    clearErrors(`${schema}.${index}.serialNumberBegin`);
    trigger(`${schema}.${index}.formPrefix`);
    trigger(`${schema}.${index}.serialNumberBegin`);
    trigger(`${schema}.${index}.serialNumberEnd`);
  };

  useEffect(() => {
    if (formPrefixes?.length === 0) {
      getFormPrefixList();
    } else {
      const mappedFormPrefixes = formPrefixes?.map((fp) => {
        const formPrefixOption: SelectFieldOption = {
          value: String(fp.formPrefixCode),
          text: String(fp.formPrefixCode),
          desc: fp.formPrefixDescription,
          formType: fp.formType,
          productType: fp.productType,
          productTypeAbbr: fp.productTypeAbbr,
        };

        return formPrefixOption;
      });

      setFormPrefixOptions(orderBy(mappedFormPrefixes, "value", "desc"));
    }

  }, [formPrefixes]);


  useEffect(() => {
    if (
      formPrefixItems?.length > 0 &&
      formPrefixItems?.length <= 10 &&
      hasValue(formPrefixItems[lastIndex].formPrefix) &&
      hasValue(formPrefixItems[lastIndex].serialNumberBegin) &&
      hasValue(formPrefixItems[lastIndex].serialNumberEnd)
    ) {
      setAddItemDisabled(false);
    }
    else {
      setAddItemDisabled(true);
    }
  }, [formPrefixItems[lastIndex].formPrefix, formPrefixItems[lastIndex].serialNumberBegin, formPrefixItems[lastIndex].serialNumberEnd])

  return (
    <>
      {formPrefixItems?.length > 0 ? (
        <>
          {formPrefixItems?.map(
            (formPrefixItem: InventoryModifyFormPrefixItem, index: number) => {
              return (
                <FromPrefixItemDiv key={formPrefixItem.rowId}>
                  <Grid key={formPrefixItem.rowId} container spacing={3}>
                    <Grid item xs={1.3}>
                      <ComboBoxField
                        label="Prefix"
                        noBorder={false}
                        name={`${schema}.${index}.formPrefix`}
                        defaultValue={formPrefixItem.formPrefix}
                        options={formPrefixOptions}
                        onChange={(event, value) =>
                          handleFormPrefixChange(event, value, index)
                        }
                        onBlur={(event) => handleFormPrefixBlur(event, index)}
                        //disabled={index > 0} // formPrefixItem.formPrefixDisabled}
                        roundedCorner={true}
                        freeSolo={true}
                        disableClearable={true}
                        controlTooltipOnMouseEvents={true}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextInput
                        label="Description"
                        name={`${schema}.${index}.formPrefixDescription`}
                        value={formPrefixItem.formPrefixDescription}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={inventoryModifyTypeCode === InventoryModifyType.Transfer ? 3 : 3.35}>
                      <TextInputField
                        label="Beginning Serial Number"
                        name={`${schema}.${index}.serialNumberBegin`}
                        defaultValue={formPrefixItem.serialNumberBegin}
                        onBlur={() => handleSerialNumberBeginBlur(index)}
                        autoFocus={false}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={inventoryModifyTypeCode === InventoryModifyType.Transfer ? 3 : 3.35}>
                      <TextInputField
                        label="Ending Serial Number"
                        name={`${schema}.${index}.serialNumberEnd`}
                        defaultValue={formPrefixItem.serialNumberEnd}
                        onBlur={() => handleSerialNumberEndBlur(index)}
                        autoFocus={false}
                        type="number"
                      />
                    </Grid>
                    {inventoryModifyTypeCode === InventoryModifyType.Transfer && (
                      <Grid item xs={0.7} className="button-grid">
                        <CustomTooltip
                          title="Delete"
                          placement="top"
                          aria-label="Delete"
                          arrow
                        >
                          <IconButton
                            aria-label="Delete"
                            disabled={deleteItemDisabled}
                            onClick={() => handleOnDeleteItem(index)}
                          >
                            <DeleteIcon className={
                              formPrefixItems.length > 1 ? "deleteIcon" : ""
                            } />
                          </IconButton>
                        </CustomTooltip>
                      </Grid>
                    )}
                  </Grid>
                </FromPrefixItemDiv>
              );
            }
          )}
        </>
      ) : null}
      {inventoryModifyTypeCode === InventoryModifyType.Transfer && (
        <AddPrefixDiv>
          <AddLink onClick={addItem} disabled={addItemDisabled} >
            Add Prefix
          </AddLink>
        </AddPrefixDiv>
      )}
    </>
  );
};

export default FormPrefixGrid;
import { getInvalidNotesMessage, isValidNotes } from "pages/utils/pageHelper";
import * as yup from "yup";

const transferValidationSchema = yup.object().shape({
  newLocationFullDisplayName: yup.string().required(""),
  notes: yup.string().test({
    name: "isValidNotes",
    test: function (value, context) {
      let isValid = true;
      if (value) {
        isValid = isValidNotes(value);
      }
      return isValid
        ? true
        : context.createError({ message: getInvalidNotesMessage() });
    },
  }),
});

export default transferValidationSchema;

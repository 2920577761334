import React from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  borderRadius,
  colors,
  filters,
  fontSize,
  fontWeight,
  height,
  iconSize,
  lineHeight,
  margin,
  padding,
} from "theme/defaultStyle";
import useTitle from "utils/custom-hooks/useTitle";
import { MenuDefinition, menuDefinitions, menuType } from "./menu";
import useGlobal from "utils/custom-hooks/useGlobal";

const HomeMenu = styled("div")({
  borderRadius: borderRadius.xlarge,
  display: "flex",
  flexDirection: "column",
  margin: `${padding.large2} auto 0 auto`,
  maxWidth: "1272px",
  padding: padding.xlarge3,
  gap :"24px",
});

const MenuItemContainer = styled("div")((props) => ({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  gap :"24px",
  padding: padding.zero,
  // [theme.breakpoints.down(901)]: {
  //   display: "grid",
  //   gridTemplateColumns: "repeat(2, 1fr)",
  // },
  // [theme.breakpoints.down(581)]: {
  //   display: "grid",
  //   gridTemplateColumns: "repeat(1, 1fr)",
  // },
}));

// const FlexContainer = styled("div")({
//   display: "flex",
//   justifyContent: "center",
//   minWidth :"275px",
//   gap :"24px",
// });


const MenuItem = styled("div")({
  backgroundColor: colors.white,
  height: height.xsmall,
  margin: margin.zero,
  padding: padding.zero,
  textAlign: "center",
  borderRadius :borderRadius.xlarge,
  minWidth :"300px",
  maxWidth :"300px",
  // height :"250px",
  "&.disabled-menu-item *": {
    color: colors.blue15,
  },
  "& a": {
    alignItems: "center",
    color: colors.blue15,
    display: "flex",
    flexDirection: "column",
    fontSize: fontSize.xlarge2,
    fontWeight: fontWeight.bold2,
    height: "100%",
    justifyContent: "center",
    padding: padding.large1,
    textDecoration: "none",
    ":hover": {
      color: colors.blue15,
      textDecoration: "none",
    },
    "&.disabledLink": {
      cursor: "default",
      "& .hover-content": {
        display: "none",
      },
    },
    "&.disabledLink:hover .normal-content": {
      display: "none",
    },
    "&.disabledLink:hover .hover-content": {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
  },
  "& a .icon": {
    color: colors.blue15,
    fontSize: iconSize.xlarge,
  },
  "&:hover:not(.disabled-menu-item)": {
    backgroundColor: colors.blue14,
    borderRadius: borderRadius.small,
    filter: filters.filter01,
  },
});

const TitleMenu = styled("p")({
  lineHeight: lineHeight.xlarge,
});

const MenuItems = ({ menuItems }: { menuItems: MenuDefinition[] }) => {
  const { checkIfPermissionIsAssigned } = useGlobal();
  return (
    <>
      {menuItems.filter((data: MenuDefinition) => {
        return !data?.permissionCode || checkIfPermissionIsAssigned(data?.permissionCode);
      }).map(
        ({ menuId, menuName, url, type, Icon: Icon }, index) => {


          return (
            <MenuItem
              key={menuId}
            >
              
                <Link to={url}>
                  <Box>
                    {Icon && Icon}
                    <TitleMenu className="title">{menuName}</TitleMenu>
                  </Box>
                </Link>
            
            </MenuItem>
          );
        }
      )}
    </>
  );
};

const HomePage = () => {
  // const [, { clearSelectedItems: clearReportPaySelectedItems }] = useReportPay();  
  useTitle("Stewart Connect Policy Portal - Home");

  // React.useEffect(() => {
  //   // Need to clear selected Report Pay here, because use can open Report Pay page from Home page
  //   clearReportPaySelectedItems();
  // },[])

  const mainMenus = menuDefinitions?.filter((menu) => menu.type === menuType.mainMenu);
  return (
    (<HomeMenu id="homeMenu">
      <MenuItemContainer>
        <MenuItems menuItems={mainMenus} />
      </MenuItemContainer>
      {/* <FlexContainer>
        <MenuItems menuItems={mainMenus} />
      </FlexContainer> */}
    </HomeMenu>)
  );
};

export default HomePage;

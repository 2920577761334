// import CheckIcon from "@mui/icons-material/Check";
// import ClearIcon from "@mui/icons-material/Clear";
import ActionButton from "controls/global/action-button";
import React, {
  ReactNode,
  useEffect,
  useState,
} from "react";
import { 
  borderRadius, 
  colors, 
  fontSize, 
  fontWeight, 
  gradients, 
  iconSize, 
  margin, 
  padding 
} from "theme/defaultStyle";
import { DialogType } from "utils/custom-hooks/useDialogStyles";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
} from "../stewart-dialog/StewartDialog";
import { 
  Box, 
  IconButton, 
  Typography, 
  styled 
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

const StyledDialog = styled(StewartDialog)({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    maxHeight: "calc(85vh)",
    minHeight: "225px",
    maxWidth: "545px", //"calc(70vw)",
    minWidth: "545px",
    display: "flex",
    justifyContent: "flex-start",
    padding: "40px 40px 20px 40px",
  },
});

const StyledDialogTitle = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "24px",
  "& .MuiTypography-root": {
    fontSize: fontSize.xlarge1,
    fontWeight: fontWeight.bold1,
  }

})

const StyledDialogContent = styled(StewartDialogContent)(({ theme }) => ({
  overflowWrap: "break-word",
  fontSize: fontSize.base,
  fontWeight: fontWeight.normal1,
  padding: "10px 0px 10px 80px !important",
  textAlign: "left",
}));

const StyledDialogAction = styled(StewartDialogActions)({
  justifyContent: "right",
  paddingBottom: padding.large2,
  paddingTop: padding.small2,
  paddingRight: padding.zero,
  paddingLeft: padding.zero,
  marginTop: margin.small2
});

const IconContainer = styled("div")({
  background: gradients.yellowGradient02,
  borderRadius: borderRadius.large,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "56px",
  height: "56px",
  "& .MuiSvgIcon-root": {
    color: colors.white,
    fontSize: iconSize.medium2,
  },
});  

const CloseIconContainer = styled("div")({
  background: colors.grey23,
  borderRadius: borderRadius.xlarge1,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  marginLeft: "auto",
  marginBottom: "auto",
  width: "32px",
  height: "32px",
  padding: "4px",
  "& .MuiSvgIcon-root": {
    color: colors.grey24, 
    width: "24px",
    height: "24px",
  },
  "& .iconButton": {
    padding: padding.zero,
  },
});

type Props = {
  singleActionButton?: boolean;
  singleActionButtonText?: string;
  mainMessage: string | ReactNode;
  additionalMessage?: string;
  isOpen: boolean;
  onYes: any;
  disableYes?: boolean;
  onNo?: any;
  disableNo?: boolean;
  buttonNoText?: string;
  cancelActionButton?: boolean;
  noActionButton?: boolean;
  onCancel?: any;
  type?: DialogType;
  width?: number;
};

export default function ConfirmationDialogV2({
  singleActionButton,
  singleActionButtonText,
  mainMessage,
  additionalMessage,
  isOpen,
  onYes,
  disableYes = false,
  onNo,
  disableNo = false,
  buttonNoText,
  cancelActionButton,
  noActionButton = !singleActionButton,
  onCancel,
  type = "WARNING",
  width = 500,
}: Props) {
  const [open, setOpen] = useState(false);

  function handleOnClose() {
    onCancel && onCancel();
  }

  
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <StyledDialog open={open}>
      <StyledDialogTitle id="title">
        <IconContainer>
          <InfoIcon></InfoIcon>
        </IconContainer>
        <Typography id="mainMessage">{mainMessage}</Typography>
        <CloseIconContainer>
          <IconButton className="iconButton" onClick={handleOnClose}>
            <CloseIcon />
          </IconButton>
        </CloseIconContainer>
      </StyledDialogTitle>
      <StyledDialogContent id="content" type={type}>
        {additionalMessage}
      </StyledDialogContent>
      <StyledDialogAction className="DialogAction">
        {!singleActionButton && (
          <>
            {noActionButton && (
              <ActionButton
                variant="outlined"
                color="secondary"
                onClick={onNo}
                disabled={disableNo}
                // startIcon={<ClearIcon />}
              >
                {buttonNoText || "No"}
              </ActionButton>
            )}
          </>
        )}
        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          onClick={onYes}
          disabled={disableYes}
          // startIcon={<CheckIcon />}
        >
          {singleActionButtonText || "Yes"}
        </ActionButton>
      </StyledDialogAction>
    </StyledDialog>
  );
}


import { getInvalidNotesMessage, isValidNotes } from "pages/utils/pageHelper";
import { 
  object, 
  array, 
  string, 
  boolean, 
  TestContext 
} from "yup";

const requiredMessage: string = "Required";

const getValueFromContext = (context: TestContext<Record<string, any>>) => {
  const contextFrom = (context as any).from;
  const value = contextFrom[contextFrom.length - 1].value;
  return value;
};

const getIndexFromPath = (path: string): number =>
  parseInt(path.split("[")[1].split("]")[0], 10);

const modifyValidationSchema = object().shape({
  inventoryModifyTypeCode: string().required(requiredMessage),
  // Notes: 1st form prefix item always required
  formPrefixItems: array().of(
    object({
      isValidationRequired: boolean(),
      formPrefix: string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: string()
            .nullable()
            .test("", requiredMessage, (value, context) => {
              if (value && value.length > 0) return true;
              return false;
            }),
        }),
      serialNumberBegin: string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: string()
            .nullable()
            .test("required", requiredMessage, (value, context) => {
              if (value && value.length > 0) return true;
              return false;
            })
            .test({
              name: "checking serial number begin error message",
              test: function (value, context) {
                const contextValue = getValueFromContext(context);
                const index = getIndexFromPath(this.path);
                const errorMessage =
                  contextValue &&
                  contextValue.formPrefixItems &&
                  contextValue.formPrefixItems.length > index
                    ? contextValue.formPrefixItems[index]
                        .serialNumberBeginErrorMessage
                    : "";
                // console.log(
                //   "serialNumberBegin - contextValue:",
                //   contextValue,
                //   "index:",
                //   index,
                //   "errorMessage:",
                //   errorMessage
                // );
                return errorMessage?.length > 0
                  ? context.createError({ message: errorMessage })
                  : true;
              },
            }),
        }),
      serialNumberEnd: string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: string()
            .nullable()
            .test("required", requiredMessage, (value, context) => {
              if (value && value.length > 0) return true;
              return false;
            })
            .test({
              name: "checking serial number end error message",
              test: function (value, context) {
                const contextValue = getValueFromContext(context);
                const index = getIndexFromPath(this.path);
                const errorMessage =
                  contextValue &&
                  contextValue.formPrefixItems &&
                  contextValue.formPrefixItems.length > index
                    ? contextValue.formPrefixItems[index]
                        .serialNumberEndErrorMessage
                    : "";
                // console.log(
                //   "serialNumberEnd - contextValue:",
                //   contextValue,
                //   "errorMessage:",
                //   errorMessage
                // );
                return errorMessage?.length > 0
                  ? context.createError({ message: errorMessage })
                  : true;
              },
            }),
        }),
    })
  ),
  // origLocationFullDisplayName: string().required(requiredMessage),
  newLocationFullDisplayName: string()
  .nullable()
  .when("isNewLocationValidationRequired", {
    is: true,
    then: string()
      .nullable()
      .test("required", requiredMessage, (value, context) => {
        if (value && value.length > 0) return true;
        return false;
      })
      .test({
        name: "checking new location error message",
        test: function (value, context) {
          const contextValue = getValueFromContext(context);
          const errorMessage = contextValue.newLocationErrorMessage;
          return errorMessage?.length > 0
            ? context.createError({ message: errorMessage })
            : true;
        },
      }), 
  }),
  newSerialNumberStatusTypeCode: string()
    .nullable()
    .when("isNewSNStatusTypeCodeValidationRequired", {
      is: true,
      then: string()
        .nullable()
        .test("required", requiredMessage, (value, context) => {
          if (value && value.length > 0) return true;
          return false;
        }),
    }),
    notes: string()
    .nullable()
    .test({
      name: "isValidNotes",
      test: function(value, context) {
        let isValid = true;
        if (value) {
          isValid = isValidNotes(value);
        }
        return isValid ? true:context.createError({message: getInvalidNotesMessage()}) 
      }
    })      
    .when("isNotesValidationRequired", {
      is: true,
      then: string()
        .nullable()
        .test("required", requiredMessage, (value, context) => {
          const trimmedValue = value ? value.trim() : '';
          return trimmedValue.length > 0;
        }),
    }),  
});

export default modifyValidationSchema;

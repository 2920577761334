import { PERMISSIONS } from "utils/data/enum";
import React, { ReactElement } from "react";
import { ensure } from "utils/shared";
import SerialNumberLookupIcon from "theme/icons/SerialNumberLookupIcon";
import SerialNumberModifyIcon from "theme/icons/SerialNumberModifyIcon";
import SerialNumberAssignIcon from "theme/icons/SerialNumberAssignIcon";

enum menuIds {
  menuHome = "HOME",
  menuSerialNumberLookup = "SERIAL_NUMBER_LOOKUP",
  menuSerialNumberAssign = "SERIAL_NUMBER_ASSIGN",
  menuSerialNumberModify = "SERIAL_NUMBER_MODIFY",
  menuProfileSettings = "PROFILE_SETTINGS",
  menuLogout = "LOGOUT",
  menuRedirectAdmin ="MENU_REDIRECT_ADMIN",
  menuRedirectPSP ="MENU_REDIRECT_ADMIN",
  menuRedirectMain ="MENU_REDIRECT_ADMIN",  
}

enum menuNames {
  lblMenuHome = "Home",
  lblMenuSerialNumberLookup = " Policy Lookup",
  lblMenuSerialNumberAssign = "Assign",
  lblMenuSerialNumberModify = "Modify",
  lblMenuProfileSettings = "Profile Settings",
  lblMenuLogout = "Logout",
  lblMenuComingSoon = "Coming soon",
}

enum menuType {
  homeMenu = "HOME_MENU",
  mainMenu = "MAIN_MENU",
  subMenu = "SUB_MENU",
}

export type MenuDefinition = {
  menuId: string;
  menuName: string;
  url: string;
  disabled: boolean;
  rolloverIconUrl?: string;
  isVisibleInHeader: boolean;
  type: menuType;
  Icon?: ReactElement;
  HeaderIcon?: ReactElement;
  permissionCode?: PERMISSIONS[];
};

const menuDefinitions: Array<MenuDefinition> = [
  {
    type: menuType.homeMenu,
    menuId: menuIds.menuHome,
    menuName: menuNames.lblMenuHome,
    url: "/Home",
    disabled: false,
    rolloverIconUrl: "",
    isVisibleInHeader: true,
    Icon: undefined,
    HeaderIcon: undefined,
    permissionCode: undefined,
  },
  {
    type: menuType.mainMenu,
    menuId: menuIds.menuSerialNumberLookup,
    menuName: menuNames.lblMenuSerialNumberLookup,
    url: "/inventoryLookup",
    disabled: false,
    rolloverIconUrl: "",
    isVisibleInHeader: true,
    Icon: <SerialNumberLookupIcon color = "action" />, 
    HeaderIcon: <SerialNumberLookupIcon width="25" height="25" color="primary" />,
    permissionCode: [PERMISSIONS.INV_LOOKUP_ACCESS],
  },
  {
    type: menuType.mainMenu,
    menuId: menuIds.menuSerialNumberAssign,
    menuName: menuNames.lblMenuSerialNumberAssign,
    url: "/inventoryAssign",
    disabled: false,
    rolloverIconUrl: "",
    isVisibleInHeader: true,
    Icon: <SerialNumberAssignIcon color = "action"/>,
    HeaderIcon: <SerialNumberAssignIcon width="25" height="25" color="primary" />,
    permissionCode: [PERMISSIONS.INV_ASSIGN_VIEW, PERMISSIONS.INV_ASSIGN_MODIFY],
  },
  {
    type: menuType.mainMenu,
    menuId: menuIds.menuSerialNumberModify,
    menuName: menuNames.lblMenuSerialNumberModify,
    url: "/inventoryModify",
    disabled: false,
    rolloverIconUrl: "",
    isVisibleInHeader: true,
    Icon: <SerialNumberModifyIcon   color = "action"/>,
    HeaderIcon: <SerialNumberModifyIcon width="25" height="25" color="primary"/>,
    permissionCode: [PERMISSIONS.INV_MODIFY_VIEW],
  },
  {
    type: menuType.subMenu,
    menuId: menuIds.menuProfileSettings,
    menuName: menuNames.lblMenuProfileSettings,
    url: "/profile-settings",
    disabled: false,
    rolloverIconUrl: "",
    isVisibleInHeader: true,
  },
];

const getUrlByMenuId = (menuId: string) =>
  ensure(
    menuDefinitions.find((menuDefinition) => menuDefinition.menuId === menuId)
  ).url;

const getUrlByMenuName = (menuName: string) =>
  ensure(
    menuDefinitions.find(
      (menuDefinition) => menuDefinition.menuName === menuName
    )
  ).url;

const getMenuIdByMenuName = (menuName: string) =>
  ensure(
    menuDefinitions.find(
      (menuDefinition) => menuDefinition.menuName === menuName
    )
  ).menuId;

export {
  getMenuIdByMenuName,
  getUrlByMenuName,
  getUrlByMenuId,
  menuDefinitions,
  menuNames,
  menuIds,
  menuType,
};

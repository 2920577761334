import React from "react";

const UpArrow = () => {
  return (
    <svg version="1.1" width="17px" height="20px">
      <g transform="matrix(1 0 0 1 -893 -3163 )">
        <path d="M 2.4917808219178084 9.77216035634744  C 2.1308219178082193 10.143207126948775  1.5292237442922376 10.150779510022273  1.160502283105023 9.787305122494432  L 0.2988584474885844 8.946770601336304  C -0.0659817351598174 8.590868596881961  -0.0659817351598174 8.015367483296215  0.2988584474885844 7.663251670378619  L 7.840182648401826 0.3028953229398664  C 8.20502283105023 -0.053006681514476565  8.79497716894977 -0.053006681514476565  9.15593607305936 0.3028953229398664  L 16.697260273972606 7.659465478841871  C 17.062100456621007 8.015367483296215  17.062100456621007 8.590868596881961  16.697260273972606 8.942984409799555  L 15.835616438356164 9.783518930957683  C 15.46689497716895 10.143207126948774  14.865296803652967 10.13563474387528  14.504337899543378 9.768374164810691  L 10 5.209799554565701  L 10 16.0913140311804  C 10.052511415525114 16.59487750556793  9.637214611872146 17  9.121004566210045 17  L 7.878995433789955 17  C 7.362785388127853 17  6.947488584474886 16.59487750556793  7 16.0913140311804  L 7 5.209799554565701  L 2.4917808219178084 9.77216035634744  Z " fillRule="nonzero" fill="#215D80" stroke="none" transform="matrix(1 0 0 1 893 3163 )" />
      </g>
    </svg>
  );
};

export default UpArrow;

import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Paper,
  Popper,
  PopperPlacementType
} from "@mui/material";
import { debounce } from "lodash";
import React, {
  useState,
} from "react";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import { useFormContext } from "react-hook-form";
import AutocompleteTextField from "./AutocompleteTextField";
import { AutocompleteResponse } from "entities/UIModel";
import LightTooltip from "controls/global/light-tooltip";
import { hasValue, REGEX_NONE_ASCII } from "utils/shared";
import {
    colors,
    boxShadows,
} from "theme/defaultStyle";

interface Props<T> {
  label: string;
  loading?: boolean;
  name: string;
  value?: any;
  freeSolo?: boolean;
  minLengthBeforeSearch?: number;
  disabled?: boolean;
  maxLength?: number;
  stateName?: string;
  hasError?: boolean;
  milisecondsToDelay?: number;
  onFocus?: (event: React.ChangeEvent<any>) => void;
  onBlur?: (event: React.ChangeEvent<any>) => void;
  onBlurForm?: () => void;
  onValueChanged: (value: any) => void;
  onGetOptions?: (inputText: string) => Promise<T[]>;
  onTypeInput?: (
    inputText: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  getOptionSelected: (option: any, value: any) => boolean;
  getOptionLabel?: (option: any) => string;
  options?: AutocompleteResponse[];
  open?: boolean;
  debounceTime?: number;
  fieldRef?: any;
  popperPlacement?: PopperPlacementType | undefined;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: any,
    ownerState: any,
  ) => React.ReactNode;
  resetDefaultValue?: string;
}

const AutocompleteInput = <T,>({
  label,
  loading = false,
  name,
  value,
  minLengthBeforeSearch = 1,
  freeSolo = true,
  disabled = false,
  maxLength,
  stateName,
  hasError = false,
  milisecondsToDelay = 500,
  onBlur,
  onBlurForm,
  onFocus,
  onValueChanged,
  onGetOptions,
  onTypeInput,
  getOptionLabel,
  renderOption,
  options,
  debounceTime = 500,
  fieldRef,
  popperPlacement,
  resetDefaultValue,
  ...rest
}: Props<T>) => {
  const [shrinkable, setShrinkable] = useState<boolean>(false);
  const [_options, setOptions] = React.useState<any[]>([]);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [, { setBypassDefaultLoader }] = useProcessStatusTracking();
  const { getValues } = useFormContext();
  const relatedStateField = stateName
    ? getValues(`${stateName}` as const)
    : undefined;

  const handleOnChange = (_: React.ChangeEvent<any>, newValue: any) => {
    const opts = _options ? _options : [];
    setOptions(
      newValue
        ? [newValue, ...opts?.filter((option) => option !== newValue)]
        : opts
    );
    setShrinkable(true);
    onValueChanged && onValueChanged(newValue);
  };

  const handleOnBlur = (e: React.ChangeEvent<any>) => {
    const currentValue = e.target.value;
    setShrinkable(hasValue(currentValue) || hasError);
    if (currentValue !== value) {
      if (!freeSolo) {
        return;
      }

      onValueChanged(currentValue);
    }

    onBlur && onBlur(e);
    onBlurForm && onBlurForm();
  };

  const handleOnInputChange = async (
    _: React.ChangeEvent<any>,
    newInputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (!newInputValue) setOptions([]);
    const newInputValue1 = newInputValue?.replace(REGEX_NONE_ASCII,"")?.trim();
    if (newInputValue1.length >= minLengthBeforeSearch) {
      if (onGetOptions) {
        setBypassDefaultLoader(true);
        const _options = await onGetOptions(newInputValue1);
        setTimeout(() => setBypassDefaultLoader(false), 500);
        setOptions(_options);
      }
    }
    onTypeInput?.(newInputValue, reason);
  };

  // React.useEffect(() => {
  //   setShrinkable(hasError || hasValue(value));
  // }, [value, hasError]);

  React.useEffect(() => {
    setOptions([]);
  }, [relatedStateField]);

  const handleFocus = () => {
    setShrinkable(true);
  };

  const handleOpenTooltip = (event: any) => {
    const inputElem = event.target;
    const compare =
      inputElem && inputElem?.scrollWidth > inputElem?.clientWidth;
    setIsTooltipOpen(compare);
  };

  // console.log("---Rerender -- Shrinkable:",
  //   JSON.stringify({
  //     shrinkable: shrinkable, 
  //     value: value, 
  //     hasError: hasError
  //   }));

  return (
    <>
      <Autocomplete
        getOptionLabel={getOptionLabel}
        options={options || _options || []}
        filterOptions={(x) => x}
        autoComplete
        disabled={disabled}
        onMouseEnter={handleOpenTooltip}
        onMouseLeave={() => setIsTooltipOpen(false)}
        includeInputInList
        filterSelectedOptions
        disableClearable
        noOptionsText="NO DATA FOUND"
        loading={loading}
        value={value || ""}
        // value={typeof value === "string" ? "" : value}
        onChange={handleOnChange}
        onFocus={onFocus}
        onBlur={handleOnBlur}
        freeSolo={freeSolo}
        handleHomeEndKeys={false}
        onInputChange={debounce(
          (
            event: React.SyntheticEvent,
            value: string,
            reason: AutocompleteInputChangeReason
          ) => handleOnInputChange(event, value, reason),
          debounceTime
        )}
        {...rest}
        renderInput={(params: AutocompleteRenderInputParams) => {
          let defaultValue = params.inputProps.value;
          if (resetDefaultValue === name && disabled) {
            defaultValue = params.inputProps.value || value;
          }

          return (
            <LightTooltip title={value || ""} open={isTooltipOpen} arrow>
              <AutocompleteTextField
                {...params}
                name={name}
                label={label}
                error={hasError}
                onFocus={handleFocus}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                inputProps={{
                  ...params.inputProps,
                  value: defaultValue,
                  maxLength: maxLength,
                  "aria-errormessage": hasError ? "rhfvalidationerror" : "",
                }}
                InputLabelProps={{
                  shrink: Boolean(shrinkable),
                }}
                variant="outlined"
                inputRef={fieldRef}
              />
            </LightTooltip>
          );
        }}
        renderOption={renderOption}
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              padding: 0,
              margin: 0,
              border: `0px solid ${colors.blue15}`,
              boxShadow: `${boxShadows.boxShadow03}`,
            }}
          />
        )}
        PopperComponent={({ open, ...props }) => (
          <Popper
            {...props}
            open={options?.length || _options?.length || loading ? open : false}
            placement={popperPlacement}
          />
        )}
      />
    </>
  );
};

export default AutocompleteInput;

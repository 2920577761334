import { MenuItem } from "@mui/material";
import StewartSelect from "controls/global/stewart-select";
import { DateType } from "entities/UIModel";
import React, {
  useEffect,
  useState,
} from "react";
import { colors } from "theme/defaultStyle";
import {
  DateTypeCode,
  PageType,
} from "utils/data/enum";

interface Props<T> {
  page: PageType;
  handleDateTypeChange: (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => void;
  dateType: string;
  variant?: any;
  disabled?: boolean;
}

const DateTypeField = <T extends object>({ page, handleDateTypeChange, dateType,disabled, ...rest }: Props<T>) => {
  const [dateTypes, setDateTypes] = useState<DateType[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const getDateTypes = (page: PageType): DateType[] => {
    const dateTypeMappings: { [key in DateTypeCode]: string } = {
      [DateTypeCode.Created]: "Opened",
      [DateTypeCode.Modified]: "Modified Date",
      [DateTypeCode.EffectiveDate]: "Effective Date",
      [DateTypeCode.IssueDate]: "Issue Date",
      [DateTypeCode.ReportedDate]: "Reported Date",
      [DateTypeCode.UploadDate]: "Upload Date",
      [DateTypeCode.Assigned]: " Assigned Date",
      [DateTypeCode.VerifiedDate]: " Verified Date",
      [DateTypeCode.VoidedDate]: " Voided Date",

    };

    let dateTypes: DateTypeCode[] = [];
    switch (page) {
      case PageType.MyFilesPage:
        dateTypes = [DateTypeCode.Created, DateTypeCode.Modified];
        break;
      case PageType.LookupPage:
        dateTypes = [DateTypeCode.Assigned, DateTypeCode.Modified, DateTypeCode.EffectiveDate, DateTypeCode.IssueDate,DateTypeCode.VerifiedDate ,DateTypeCode.VoidedDate];
        break;
      case PageType.ModifyPage:
        dateTypes = [DateTypeCode.Modified];
        break;
      case PageType.AssignPage:
        dateTypes = [DateTypeCode.Assigned];
        break;
      default:
        dateTypes = Object.values(DateTypeCode);
        break;
    }
    const formattedDateTypes: DateType[] = dateTypes.map((code: DateTypeCode) => ({
      code,
      name: dateTypeMappings[code],
      selected: false,
      subType: "",
    }));

    return formattedDateTypes;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleDateTypeChange("dateTypeCode" as keyof T, value, false);
  };

  const handleOnClick = () => {
    if (disabled) {
      return;
    }

    setOpen(!open);
  };

  useEffect(() => {
    const getAsync = () => {
      const data: DateType[] = getDateTypes(page);

      if (!data || data.length === 0) return;
      setDateTypes(
        data.sort((dateTypeFirst: DateType, dateTypeSecond: DateType) =>
          dateTypeFirst.name.trim().localeCompare(dateTypeSecond.name.trim())
        )
      );
      
      const modifiedDateType = data.find((t) => t.selected);
      if (!modifiedDateType) return;
      handleDateTypeChange("dateTypeCode" as keyof T, modifiedDateType.code, false);
    };

    getAsync();
  }, []);

  return (
    <StewartSelect
      select
      fullWidth
      label="Date Type"
      InputProps={{
        disableUnderline: true,
        style: { color: colors.black, backgroundColor: disabled ? colors.grey10 : "" },
      }}
      SelectProps={{
        open: open,
      }}
      onChange={handleChange}
      onClick={handleOnClick}
      value={dateType}
      disabled={!dateTypes || dateTypes.length === 0 || disabled}
      {...rest}
    >
      {dateTypes.map((type) => (
        <MenuItem
          key={type.code}
          value={type.code}
        >
          {type.name}
        </MenuItem>
      ))}
    </StewartSelect>
  );
};

export default DateTypeField;

import React from "react";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { defaultStyle } from "theme/defaultStyle";

type IconProps = {
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
  color1?: string;
  color2?: string;
  height?: string;
  width?: string;
};

const GradientIcon = ({
  Icon,
  color1 = defaultStyle.icon.formSection.gradient1,
  color2 = defaultStyle.icon.formSection.gradient2,
  height = defaultStyle.icon.formSection.height,
  width = defaultStyle.icon.formSection.width,
}: IconProps) => {
  return (
    <svg width={width} height={height}>
      <linearGradient
        id="linearColors"
        x1={1}
        y1={0}
        x2={1}
        y2={1}
      >
        <stop
          offset={0}
          stopColor={color1}
        />
        <stop
          offset={1}
          stopColor={color2}
        />
      </linearGradient>
      <Icon style={{ fill: "url(#linearColors)" }} />
    </svg>
  );
};

export default GradientIcon;

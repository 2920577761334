import {
  styled,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import StewartSortLabel from "controls/global/stewart-table/StewartSortLabel";
import {
  IRowIdentifier,
  SelectionConfig,
  StewartTableColumn,
} from "controls/global/stewart-table/StewartTable";
import React, {
  RefObject,
} from "react";
import {
  colors,
  fontSize,
  fontWeight,
} from "theme/defaultStyle";
import ArrowSplitVertical from "theme/icons/ArrowSplitVertical";
import LightTooltip from "../light-tooltip";
import StewartCheckbox from "../stewart-checkbox";
import { Order } from "utils/services/utilsService";

type Props<T extends IRowIdentifier> = {
  cols: StewartTableColumn<T>[];
  paginatedRows?: T[];
  order: Order;
  orderBy?: keyof T;
  onRequestSort: (property: keyof T) => void;
  onColumnResize: (property: keyof T, newWidth: number) => void;
  onColumnsModified?: (columns: StewartTableColumn<T>[]) => void;
  showActionRow: boolean;
  useDetailsView?: boolean;
  detailsViewButtonState?: "expand" | "collapse";
  selectionState?: "Select" | "Unselect";
  selectionConfig?: SelectionConfig<T>;
  onDetailsViewToggle?: () => void;
  onSelectAllToggle?: () => void;
  tableRef: RefObject<HTMLTableElement>;
};

const StyledTitle = styled("div")({
  color: colors.black,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const ResizeLine = styled("div")({
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  position: "absolute",
  right: -2,
  top: 0,
  width: 15,
  "& .arrow-split-vertical": {
    display: "none",
  },
  "&:hover": {
    backgroundColor: colors.white,
    opacity: 0.8,
    "& .arrow-split-vertical": {
      display: "flex",
    },
  },
  "&:active": {
    backgroundColor: colors.white,
    opacity: 0.6,
    "& .arrow-split-vertical": {
      display: "flex",
    },
  },
});

const StyledTableCell = styled(TableCell)({
  fontSize: `${fontSize.base} !important`,
  fontWeight: fontWeight.normal2,
  position: "relative",
  textTransform: "uppercase",
});
const FilterTableCell = styled(TableCell)({
  "& div.MuiInputBase-root": {
    border: "none",
  },
  "& div.MuiInputBase-root:hover": {
    border: "none",
  },
});

const DEFAULT_MIN_WIDTH_CELL = 50;
const DEFAULT_MAX_WIDTH_CELL = 1000;

export default function StewartTableHeader<T extends IRowIdentifier>({
  paginatedRows,
  cols,
  order,
  orderBy,
  onRequestSort,
  onColumnResize,
  onColumnsModified,
  showActionRow,
  useDetailsView,
  detailsViewButtonState,
  selectionConfig,
  selectionState,
  onDetailsViewToggle,
  onSelectAllToggle,
  tableRef,
}: Props<T>) {
  const colResizeGripRefs = cols.map(() => React.createRef<HTMLDivElement>());
  const resizeColumn = React.useRef<{
    colIndex: number;
    col: StewartTableColumn<T> | undefined;
    colResizeGripRef: React.RefObject<HTMLDivElement> | undefined;
    newWidth: number;
  }>({ colIndex: 0, newWidth: 0, col: undefined, colResizeGripRef: undefined });

  React.useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  });

  const onMouseDownResize = (colIndex: number) => {
    const col = cols[colIndex];
    const colResizeGripRef = colResizeGripRefs[colIndex];
    resizeColumn.current = { newWidth: 0, colIndex, col, colResizeGripRef };
    setCursorDocument(true);
  };

  const onMouseUp = () => {
    if (resizeColumn.current.col !== undefined && resizeColumn.current.newWidth > DEFAULT_MIN_WIDTH_CELL) {
      onColumnResize(resizeColumn.current.col.field, resizeColumn.current.newWidth);
    }
    resizeColumn.current.colResizeGripRef = undefined;
    setCursorDocument(false);
  };

  const setCursorDocument = (active: boolean) => {
    document.body.style.cursor = active ? "pointer" : "auto";
  };

  const adjustWidthColumn = (width: number) => {
    const minWidth = DEFAULT_MIN_WIDTH_CELL;
    const maxWidth = DEFAULT_MAX_WIDTH_CELL;
    const newWidth = width > maxWidth ? maxWidth : width < minWidth ? minWidth : width;

    const columnElement = resizeColumn.current?.colResizeGripRef?.current?.parentElement;
    if (columnElement) {
      columnElement.style.width = newWidth + "px";
      resizeColumn.current.newWidth = newWidth;
    }
  };

  const onMouseMove = (ev: MouseEvent) => {
    const columnElement = resizeColumn.current?.colResizeGripRef?.current?.parentElement;
    if (columnElement) {
      const columnLeft = columnElement.offsetLeft;
      const mouseX = ev.clientX;
      const tableScrollLeft = tableRef?.current?.parentElement?.scrollLeft || 0;
      const newWidth = mouseX - columnLeft + tableScrollLeft;
      adjustWidthColumn(newWidth);
    }
  };

  const handleDragStart = (ev: React.DragEvent<HTMLTableCellElement>) => {
    const { id } = ev.target as HTMLTableCellElement;
    const idx = cols.findIndex((c) => String(c.field) === id);
    ev.dataTransfer.setData("colIdx", idx.toString());
  };

  const handleOnDrop = (ev: React.DragEvent<HTMLTableCellElement>) => {
    const target = ev.target as HTMLTableCellElement;
    const id = target.tagName === "TH" ? target.id : target.closest("TH")?.id;
    if (!id) return;

    const droppedColIdx = cols.findIndex((c) => String(c.field) === id);
    const draggedColIdx = Number(ev.dataTransfer.getData("colIdx"));
    const tempCols = [...cols];
    tempCols[draggedColIdx] = cols[droppedColIdx];
    tempCols[droppedColIdx] = cols[draggedColIdx];

    onColumnsModified?.(tempCols);
  };

  const allRowsSelected = () => {
    const selectedPaginatedRowsCount =
      paginatedRows?.filter((r) => selectionConfig?.selectedRows?.includes(r.uniqueIdentifier))?.length || 0;
    const paginatedRowsCount = paginatedRows?.length || 0;
    return paginatedRowsCount > 0 && paginatedRowsCount === selectedPaginatedRowsCount;
  };

  const partialSelect = () => {
    const selectedPaginatedRowsCount =
      paginatedRows?.filter((r) => selectionConfig?.selectedRows?.includes(r.uniqueIdentifier))?.length || 0;
    const paginatedRowsCount = paginatedRows?.length || 0;
    const isPartialSelect =
      paginatedRowsCount > 0 && selectedPaginatedRowsCount > 0 && paginatedRowsCount !== selectedPaginatedRowsCount;
    return isPartialSelect;
  };

  const renderRowHeader = (placeholder?: boolean) => {
    if (!selectionConfig?.enabled && !useDetailsView) {
      return null;
    } else {
      return (
        <TableCell
          className="row-header-cell"
          key="row-header-cell"
        >
          <div className="row-header-items">
            {useDetailsView && !placeholder && (
              <LightTooltip
                title={detailsViewButtonState === "collapse" ? "Collapse All" : "Expand All"}
                arrow
              >
                {detailsViewButtonState === "collapse" ? (
                  <ExpandLess onClick={onDetailsViewToggle} />
                ) : (
                  <ExpandMore onClick={onDetailsViewToggle} />
                )}
              </LightTooltip>
            )}
            {selectionConfig?.enabled && !placeholder && (
              <LightTooltip
                title={allRowsSelected() ? "Deselect All" : "Select All"}
                arrow
              >
                <div>
                  <StewartCheckbox
                    checked={allRowsSelected()}
                    onChange={() => onSelectAllToggle?.()}
                    indeterminate={partialSelect()}
                  />
                </div>
              </LightTooltip>
            )}
          </div>
        </TableCell>
      );
    }
  };
  return (
    <>
      <TableRow className="header-labels">
        {renderRowHeader(true)}
        {cols.map((col, index) => (
          <StyledTableCell
            id={String(col.field)}
            key={String(col.field)}
            draggable={col.draggable}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={col.draggable ? handleDragStart : undefined}
            onDrop={col.draggable ? handleOnDrop : undefined}
            data-pos={col.sticky ? "sticky" : undefined}
            className={col.classes}
            style={{
              width: col.width ?? "auto",
              minWidth: col.minWidth ?? "unset",
              left: col.sticky && col.left ? col.left : undefined,
              right: col.sticky && col.right !== undefined ? col.right : undefined,
            }}
          >
            {col.sortable ? (
              <StewartSortLabel
                active={orderBy === col.field}
                direction={orderBy === col.field ? order : "asc"}
                onRequestSort={() => onRequestSort(col.field)}
              >
                <StyledTitle>{col.name}</StyledTitle>
              </StewartSortLabel>
            ) : (
              <StyledTitle>{col.name}</StyledTitle>
            )}
            {col.resizable ? (
              <ResizeLine
                onMouseDown={(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  ev.preventDefault();
                  onMouseDownResize(index);
                }}
                ref={colResizeGripRefs[index]}
              >
                <div className="arrow-split-vertical">
                  <ArrowSplitVertical />
                </div>
              </ResizeLine>
            ) : null}
          </StyledTableCell>
        ))}
      </TableRow>
      {showActionRow && (
        <TableRow className={"header-actions"}>
          {renderRowHeader()}
          {cols.map((c) => (
            <FilterTableCell
              padding="none"
              key={String(c.field)}
              data-pos={c.sticky ? "sticky" : undefined}
              className={c.classes}
              style={{
                zIndex: c.sticky ? 2 : 0,
                width: c.width ?? "auto",
                minWidth: c.minWidth ?? "unset",
                left: c.sticky && c.left ? c.left : undefined,
                right: c.sticky && c.right !== undefined ? c.right : undefined,
              }}
            >
              {c.actionComponent ? c.actionComponent(paginatedRows) : null}
            </FilterTableCell>
          ))}
        </TableRow>
      )}
    </>
  );
}

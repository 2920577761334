import React, {
  useEffect,
  useState
} from "react";
import { 
  useNavigate 
} from "react-router-dom";
import {
  StewartDialog,
  StewartDialogActions,
} from "controls/global/dialogs/stewart-dialog/StewartDialog";
import {
  Box,
  IconButton,
  Typography,
  styled,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps, 
  gradients,
  height,
  iconSize,
  margin,
  padding,
  scrollBar,
 } from "theme/defaultStyle";
import SwapHoriz from "@mui/icons-material/SwapHoriz";
import ActionButton from "controls/global/action-button";
import { 
  FormProvider, 
  useForm 
} from 'react-hook-form';
import AgencyLocationField from "pages/components/AgencyLocationField";
import TextInputField from "controls/global/text-input-field";
import SelectField from "controls/global/select-field";
import transferValidationSchema from "../transferValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import useModifyStore from "utils/context/InventoryModifyContext";
import {
  ActionResultStatus,
} from "utils/data/enum";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import FlashMessage from 'controls/global/flash-message/FlashMessage';
import { FlashMessageType, UIConstants } from 'utils/data/enum';
import { useFlashMessageActions } from 'utils/context/FlashMessageContext';
import useFlashMessage from "utils/custom-hooks/useFlashMessage";
import { getInvalidNotesMessage, isValidNotes } from "pages/utils/pageHelper";

const TRANSFER_SUCCESS_MESSAGE_HEADER = "Transfer successfully completed!";
const TRANSFER_ERROR_MESSAGE_HEADER = "Transfer Failed!";
const TRANSFER_ERROR_MESSAGE_DETAIL = "Please try again. ";

const StyledDialog = styled(StewartDialog)({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    maxHeight: "calc(95vh)",
    maxWidth: "calc(57.63vw)",
    minWidth: "calc(57.63vw)",
    display: "flex",
    justifyContent: "flex-start",
    padding: "36px 40px 40px 40px",
    overflowX: "auto",
  },
});

const SubmitBox = styled("div")({
  paddingTop: padding.xlarge1,
});

const StyledDialogTitle = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: gaps.large1,
  "& .MuiTypography-root": {
    fontSize: fontSize.xlarge2,
    fontWeight: fontWeight.bold2,
  }
})

const scrollBarstyle = {
  maxHeight: height.small,
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    width: "14px",
    height: scrollBar.height,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: scrollBar.borderRadius1,
    backgroundColor: colors.grey20,
    border: `${borderSize.medium} solid transparent`,
    backgroundClip: "content-box",
  },
  "&::-webkit-scrollbar-track": {
    background: colors.white,
    boxShadow: "1px 0px 0px 0px #DEDEDE inset",
    borderRadius: "0px 4px 4px 0px",
    border: "1px solid var(--Input-Default-Stoke, #BDBDBD)",
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
};

const IconContainer = styled("div")({
  background: gradients.blueGradient09,
  borderRadius: borderRadius.large,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "56px",
  height: "56px",
  "& .MuiSvgIcon-root": {
    color: colors.white,
    fontSize: iconSize.medium2,
  },
});

const StyledDialogContent = styled(Box)({
  padding: "24px 0",
  "& .failed-upload-status": {
    marginTop: margin.large,
    marginBottom: margin.large,
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.large,
  },
  "& .success-upload-status": {
    marginTop: margin.large,
    marginBottom: margin.large,
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.large,
  },
  "& .failed-status-grid": {
    ...scrollBarstyle
  },
  "& .failed-status-only-grid": {
    ...scrollBarstyle
  },
  "& .failed-status-grid table, .failed-status-only-grid table": {
    "& td": {
      paddingTop: padding.zero,
      paddingBottom: padding.zero,
    },
  },
  "& .success-status-grid": {
    ...scrollBarstyle
  },
  "& .success-status-only-grid": {
    ...scrollBarstyle
  },
  "& .success-status-grid table, .success-status-only-grid table": {
    "& td": {
      paddingTop: padding.zero,
      paddingBottom: padding.zero,
    },
  },

});

const StyledDialogAction = styled(StewartDialogActions)({
  justifyContent: "right",
  paddingBottom: padding.zero,
  paddingTop: padding.zero,
  paddingRight: padding.zero,
  paddingLeft: padding.zero,
});

const CloseIconContainer = styled("div")({
  background: colors.grey10,
  borderRadius: borderRadius.xlarge1,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  marginLeft: "auto",
  marginBottom: "auto",
  width: "32px",
  height: "32px",
  padding: padding.xsmall1,
  "& .MuiSvgIcon-root": {
    color: colors.blue01,
    width: "24px",
    height: "24px",
  },
  "& .iconButton": {
    padding: padding.zero,
    backgroundColor: colors.grey10,
  },
});

const ContainerBox = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& .gridStyle": {
    paddingBottom: "0 !important" as any,
  },
});

const buttonStyle = {
  marginRight: margin.large,
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  policyData: any;
}

const initialValues: any = {
  notes: "",
  newLocationFullDisplayName: "",
};

const SAVE_TRANSFER_IN_PROGRESS_MESSAGE = "Please wait while saving your changes";
  
const SerialNumberTransferDialog = ({ isOpen, onClose, policyData }: Props) => {
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
  useAutomaticProgressDialogActions();
  const [open, setOpen] = useState<boolean>(false);
  const [newLocationData, setNewLocationData] = useState<any>();
  const [, { saveModify }] = useModifyStore();
  const [submitDisabled, setSubmitDisabled] = useState(false); 
  const methods = useForm({
      resolver: yupResolver(transferValidationSchema),
      mode: "onBlur",
      reValidateMode: "onBlur",
      defaultValues: {
          ...initialValues,
          policyNumber: policyData?.formPrefix,
          locationFullDisplayName:  policyData?.locationDisplayName,
      },
    });
  
  const navigate = useNavigate();
  const { clearErrors, getValues, trigger, reset, setValue, watch, setError, } = methods;
  const [, { showFlashMessage, closeFlashMessage }] = useFlashMessageActions();  
  useFlashMessage();

  const [newLocationFullDisplayName, notes] = watch([
    "newLocationFullDisplayName" as const,
    "notes" as const,
  ]);
  useEffect(() => {
    setSubmitDisabled(false);
    if (
      notes === undefined ||
      notes === "" ||
      notes === null ||
      newLocationFullDisplayName == undefined ||
      newLocationFullDisplayName === null ||
      newLocationFullDisplayName === "" ||
      // notes?.length > 500
      (notes && !isValidNotes(notes))
    ) {
      setSubmitDisabled(true);
    }
  }, [newLocationFullDisplayName, notes]);

  const handleLocationChange = (location: any) => {
    if (!location || typeof location === "string") return;
    setValue("newLocationFullDisplayName", location?.locationFullDisplayName);
    setNewLocationData(location);
  };

  function handleOnClose() {
    onClose && onClose();
  }

  const handleLocationBlur = async(event: any) => {
    const newInputValue = event?.target?.value;
    if(newLocationData === undefined || newInputValue.toLowerCase() !== newLocationData?.locationFullDisplayName.toLowerCase() || !newInputValue)
     clearAgencyLocation();
  };
  useEffect(() => {
    setOpen(isOpen);
    if(isOpen) {
      clearErrors();
      reset({
        notes: "",
        newLocationFullDisplayName: "",
      });
    }
  }, [isOpen]);
    
  const clearAgencyLocation = () => {
    setValue("newLocationFullDisplayName", "");
  };
  const onTransferHandler = async ():Promise<ActionResultStatus> => {
    clearErrors();
    const isFormValid = await trigger();
    if (!isFormValid) {
      return ActionResultStatus.Failed;
    }
    openAutomaticProgressDialog(SAVE_TRANSFER_IN_PROGRESS_MESSAGE);
    const result =  await save();
    navigate(0);
    closeAutomaticProgressDialog();
    return result;
  }

const save = async(): Promise<ActionResultStatus> => {
  const formValues = getValues();
  const payload = {
    InventoryModifyTypeCode: "TRANSFER",
    InventoryModifyDetails:[
      {
      FormPrefix: policyData?.formPrefix,
      FormType:  policyData?.formType,
      ProductType: policyData?.productType,
      ProductTypeAbbr: policyData?.productTypeAbbr,
      BeginningSerialNumber: policyData?.serialNumber,
      EndingSerialNumber: policyData?.serialNumber,
      OrigCompanyID: policyData?.companyID,
      OrigCompanyLegacyID: policyData?.companyLegacyID,
      OrigCompanyName: policyData?.companyName,
      OrigLocationDisplayName: policyData?.locationDisplayName,
      OrigLocationLegacyID: policyData?.locationLegacyID,
      OrigLocationStateAbbr: policyData?.locationStateAbbr,
      OrigLocationStateCode: policyData?.locationStateCode,
      OrigLocationUniqueID: policyData?.locationUniqueID,
      NewCompanyID: newLocationData?.companyID,
      NewCompanyLegacyID: newLocationData?.companyLegacyID,
      NewCompanyName: newLocationData?.companyName,
      NewLocationDisplayName: newLocationData?.locationDisplayName,
      NewLocationLegacyID: newLocationData?.locationLegacyID,
      NewLocationStateCode: newLocationData?.locationStateCode,
      NewLocationUniqueID: newLocationData?.locationUniqueID,
      },
    ],
    OrigLocationUniqueID: policyData?.locationUniqueID,
    NewLocationUniqueID: newLocationData?.locationUniqueID,
    OldSerialNumberStatusTypeCode: policyData?.serialNumberStatusTypeCode,
    Notes: formValues?.notes,
  }
  const result = await saveModify(payload);
  if (result.status === ActionResultStatus.Success) {
    showFlashMessage(FlashMessageType.Success, TRANSFER_SUCCESS_MESSAGE_HEADER, "");
    reset({
      notes: "",
      newLocationFullDisplayName: "",
      policyNumber: "",
      origLocationFullDisplayName: "",
    });
    onClose && onClose();
    return ActionResultStatus.Success;
  } else {
      showFlashMessage(FlashMessageType.Error, TRANSFER_ERROR_MESSAGE_HEADER,TRANSFER_ERROR_MESSAGE_DETAIL);
      return ActionResultStatus.Failed;
    }
  }

  function handleFlashMessageClose(
    event: React.SyntheticEvent | Event,
    reason?: string
  ) {
    if (reason === "clickaway") {
      return;
    }
    closeFlashMessage();
  }

  useEffect(() => {
    if (notes && !isValidNotes(notes)) {
      setError("notes", {
        type: "manual",
        message: getInvalidNotesMessage(), 
      });
    } else {
      clearErrors("notes");
    }
  }, [notes]);
  
  return (
    <>
      <StyledDialog
        open={open}
      >
          <StyledDialogTitle id="styledDialogTitleDebug">
              <IconContainer>
                  <SwapHoriz></SwapHoriz>
              </IconContainer>
              <Typography id="uploadPolicyImageTitleDebug">Transfer</Typography>
              <CloseIconContainer>
                  <IconButton className="iconButton" onClick={handleOnClose}>
                      <CloseIcon />
                  </IconButton>
              </CloseIconContainer>
          </StyledDialogTitle>
          <StyledDialogContent> 
              <FormProvider {...methods}>
                  <ContainerBox> 
                      <Grid item xs={12} className={"gridStyle"}>
                      <SelectField
                      label="Policy Number"
                      name="policyNumber"
                      options={[{ text: `${policyData?.productTypeAbbr}-${policyData?.formPrefix}-${policyData?.serialNumber}`, value: policyData?.formPrefix }]}
                      defaultValue={policyData?.formPrefix}
                      disabled={true}
                    />
                    </Grid>
                      <Grid item xs={12} md={6} className={"gridStyle"}>
                      <SelectField
                      label="Current Agency/Legacy ID (Location)"
                      name="origLocationFullDisplayName"
                      options={[{text: `${policyData?.locationDisplayName} (${policyData?.locationLegacyID})`, value: policyData?.locationDisplayName}]}
                      defaultValue={policyData?.locationDisplayName}
                      disabled={true}
                      />
                      </Grid> 
                      <Grid item xs={12} md={6} className={"gridStyle"}>
                          <AgencyLocationField
                              label="Receiving Agency/Legacy ID (Location)"
                              name="newLocationFullDisplayName"
                              onChange={handleLocationChange}
                              onBlur={handleLocationBlur}
                              onClear={clearAgencyLocation}
                              locationUniqueID={policyData?.locationUniqueID}
                          />
                      </Grid>
                      <Grid item xs={12} className={"gridStyle"}>
                          <TextInputField label="Notes" name="notes" />
                      </Grid>
                      </ContainerBox>
                  </FormProvider> 
                  <StyledDialogAction className="DialogAction">
                      <SubmitBox>
                          <ActionButton 
                          variant="outlined"
                          color="secondary"
                          disabled={false}
                          style={buttonStyle}
                          onClick={handleOnClose}>
                            Cancel
                          </ActionButton>
                          <ActionButton
                          variant="outlined"
                          color="primary"
                          onClick={onTransferHandler}
                          disabled={submitDisabled}>
                            Transfer
                          </ActionButton>
                      </SubmitBox> 
                  </StyledDialogAction>
              </StyledDialogContent> 
          </StyledDialog>
          <FlashMessage
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={Number(
              UIConstants.FLASH_MESSAGE_AUTO_HIDDEN_DURATION
            )}
            onClose={handleFlashMessageClose}
            />      
          </>
  )
      
};
  
export default SerialNumberTransferDialog;
import {
  ErrorCode,
  ErrorMessage,
  SerialNumberRangeType,
} from "pages/modify/new/enum";
// import { useForm } from "react-hook-form";
// import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { 
  FieldValues, 
  UseFormSetValue 
} from "react-hook-form";

const useInventoryModify = () => {
  // const { setValue } = useForm();
  // const {nameString} = useFormWrapper();

  const setFPIErrorMessagesByErrorCode = (
    index: number,
    errorCode: string,
    serialNumberRangeType: SerialNumberRangeType,
    setValue: UseFormSetValue<FieldValues>,
  ) => {
    // Notes: set validation message based on onblur serial number (begin serial number or both serial numbers)
    switch (serialNumberRangeType) {
      case SerialNumberRangeType.Begin:
        switch (errorCode.toString()) {
          case ErrorCode.InvalidBeginning:
          case ErrorCode.InvalidEnding:
          case ErrorCode.InvalidBoth:
            setValue(
              `formPrefixItems.${index}.serialNumberBeginErrorMessage`,
              index === 0 ? ErrorMessage.InvalidSerialNumberOnFirstRow : ErrorMessage.InvalidAgency
            );
    
            break;
          case ErrorCode.InvalidSerialNumberStatus:
              setValue(
                `formPrefixItems.${index}.serialNumberBeginErrorMessage`,
                ErrorMessage.InvalidSerialNumberStatus
              );
              break;     
          default: 
            setValue(
              `formPrefixItems.${index}.serialNumberBeginErrorMessage`,
              index === 0 ? ErrorMessage.InvalidSerialNumberOnFirstRow : ErrorMessage.InvalidAgency
            );             
        }        
        break;
      case SerialNumberRangeType.Both:
        switch (errorCode.toString()) {
          case ErrorCode.InvalidBeginning:
          case ErrorCode.InvalidEnding:
          case ErrorCode.InvalidBoth:
            setValue(
              `formPrefixItems.${index}.serialNumberEndErrorMessage`,
              index === 0 ? ErrorMessage.InvalidSerialNumberOnFirstRow : ErrorMessage.InvalidAgency
            );
            break;
          case ErrorCode.InvalidSerialNumberStatus:
              setValue(
                `formPrefixItems.${index}.serialNumberEndErrorMessage`,
                ErrorMessage.InvalidSerialNumberStatus
              );              
              break;     
          default: 
            setValue(
              `formPrefixItems.${index}.serialNumberEndErrorMessage`,
              index === 0 ? ErrorMessage.InvalidSerialNumberOnFirstRow : ErrorMessage.InvalidAgency
            );          
        }                
        break;
    }
    // switch (errorCode.toString()) {
    //   case ErrorCode.InvalidBeginning:
    //     setValue(
    //       `formPrefixItems.${index}.serialNumberBeginErrorMessage`,
    //       index === 0 ? ErrorMessage.InvalidSerialNumberOnFirstRow : ErrorMessage.InvalidAgency
    //     );
    //     break;
    //   case ErrorCode.InvalidEnding:
    //     setValue(
    //       `formPrefixItems.${index}.serialNumberEndErrorMessage`,
    //       index === 0 ? ErrorMessage.InvalidSerialNumberOnFirstRow : ErrorMessage.InvalidAgency
    //     );

    //     break;
    //   case ErrorCode.InvalidBoth:
    //     if (serialNumberRangeType === SerialNumberRangeType.Begin) {
    //       setValue(
    //         `formPrefixItems.${index}.serialNumberBeginErrorMessage`,
    //         index === 0 ? ErrorMessage.InvalidSerialNumberOnFirstRow : ErrorMessage.InvalidAgency
    //       );
    //     } else {
    //       setValue(
    //         `formPrefixItems.${index}.serialNumberBeginErrorMessage`,
    //         index === 0 ? ErrorMessage.InvalidSerialNumberOnFirstRow : ErrorMessage.InvalidAgency
    //       );
    //       setValue(
    //         `formPrefixItems.${index}.serialNumberEndErrorMessage`,
    //         index === 0 ? ErrorMessage.InvalidSerialNumberOnFirstRow : ErrorMessage.InvalidAgency
    //       );
    //     }

    //     break;
    //   case ErrorCode.InvalidSerialNumberStatus:
    //       setValue(
    //         `formPrefixItems.${index}.serialNumberBeginErrorMessage`,
    //         ErrorMessage.InvalidSerialNumberStatus
    //       );
    //       setValue(
    //         `formPrefixItems.${index}.serialNumberEndErrorMessage`,
    //         ErrorMessage.InvalidSerialNumberStatus
    //       );

    //       break;        
    // }
  };

  return {
    setFPIErrorMessagesByErrorCode,
  };
};

export default useInventoryModify;

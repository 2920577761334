export enum UIConstants {
  CHECK_PROCESS_IN_PROGRESS_INTERVAL_MILISECONDS = 250, // 0.25 second
  WAITING_PROCESS_IN_PROGRESS_MAX_MILISECONDS = 30000, // 30 seconds
  FLASH_MESSAGE_AUTO_HIDDEN_DURATION = 10000, // 10 seconds
  LOADING_IN_PROGRESS_MESSAGE = "Please wait while your data is being prepared",
  SAVE_IN_PROGRESS_MESSAGE = "Please wait while saving your file",
  SAVE_PROFILE_IN_PROGRESS_MESSAGE = "Please wait while saving your profile",
  VIEW_DOCUMENT_ERROR_MESSAGE = "There was a problem displaying your document. Please try again or contact the Agency Support Center for assistance.",
  SAVE_DOCUMENT_IN_PROGRESS_MESSAGE = "Please wait while saving your policy document",
}

export enum DOMEventType {
  FILE_JUST_LOADED = "fileJustLoaded",
  APP_RELOAD_REQUIRED = "appReloadRequired",
}

export enum LocalStorageKeys {
  COLUMNS_INVENTORY_LOOKUP = "columns-inventory-lookup",
  COLUMNS_INVENTORY_LOOKUP_HIDDEN = "columns-inventory-lookup-hidden",
  COLUMNS_INVENTORY_ASSIGN = "columns-inventory-assign",
  COLUMNS_INVENTORY_ASSIGN_HIDDEN = "columns-inventory-assign-hidden",
  COLUMNS_INVENTORY_MODIFY = "columns-inventory-modify",
  COLUMNS_INVENTORY_MODIFY_HIDDEN = "columns-inventory-modify-hidden",
}

export enum SessionStorageKeys {
  APP_VERSION = "app-version",
  APP_VERSION_PENDING = "app-version-pending",
  APP_RELOAD_REQUIRED = "app-reload-required",
  FEATURE_CONFIG = "feature-config",
  GENERAL_CONFIG = "general-config",
  USE_PRIOR_SEARCH_CRITERIA = "usePriorSearchCriteria",
  USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH = "usePriorSearchCriteriaAdvanceSearch",
  LANDING_PAGE = "landing_page",
}

export enum CreateFileProviderActionType {
  INIT = "INIT",
}

export enum ProductType {
  AllProducts = "",
  Aal = "AAL",
  Cpl = "CPL",
  Jacket = "JACKET",
  Endorsement = "ENDORSEMENT", // not part of table lookup
  StandaloneEndorsement = "SAENDO",
}

export enum ProductTabNameExtension {
  PolicyUpload = "POLICY UPLOAD"
}

export enum ProductTabTitle {
  PolicyUpload = "Policy Upload",
}

export enum PolicyImageTabName {
  UploadImages = "Upload Images",
  ImageArchive = "Image Archive",
}
export enum  Modify {
  New = "New",
  Modified = "Modified",
}
export enum  Assign {
  New = "New",
  Assigned = "Assigned",
}


export enum OrderStatusType {
  Cancelled = "CANCELLED",
  Error = "ERROR",
  Issued = "ISSUED",
  Pending = "PENDING",
  UndoVoidPending = "UNDOVOIDPEND",
  Voided = "VOIDED",
}

export enum EndorsementStatusType {
  Cancelled = "CANCELLED",
  Error = "ERROR",
  Issued = "ISSUED",
  Pending = "PENDING",
  Submitted = "SUBMITTED",
  Voided = "VOIDED",
}

export enum OrderStatusTypeName {
  Pending = "Pending",
  Issued = "Issued",
}

export enum FileStatusType {
  Cancelled = "CANCELLED",
  Inactive = "INACTIVE",
  Open = "OPEN",
  OpenedInError = "OPENEDINERROR",
  ReportPaid = "REPORTEDPAID",
  ReportPending = "REPORTEDPENDING",
}

export enum FileStatusTypeName {
  Cancelled = "Cancelled",
  Inactive = "Inactive",
  Open = "Open",
  OpenedInError = "Opened In Error",
  ReportPaid = "Reported/Paid",
  ReportPending = "Reported/Pending",
}

export enum MapActionType {
  SaveFile = "SaveFile",
  ProductAction = "ProductAction",
  RetrievePricingData = "RetrievePricingData",
  SaveJacketOPN = "SaveJacketOPN",
  CheckFormDirty = "CheckFormDirty",
  SaveFileStatus = "SaveFileStatus",
}

export enum ProductAction {
  Issue = "Issue",
  Revise = "Revise",
  Ppe = "Ppe",
  ReportPricing = "ReportPricing",
  UndoReport = "UndoReport",
  ReportAndPayByCheck = "ReportAndPayByCheck",
  CalculatePricing = "CalculatePricing",
  CalculateItem = "CalculateItem",
  CalculateItems = "CalculateItems",
  Void = "Void",
  None = "None",
}

export enum PartyType {
  Individual = "INDIVIDUAL",
  BusTrustEstate = "BUSTRUSTESTATE",
  Attorneys = "ATTORNEY",
  SettlementCompany = "SETTLEMENTCOMPANY",
  SecondaryAgency = "SECONDARYAGENCY",
}

export enum SearchPartyType {
  Attorney = "ApprovedAttorney",
  SettlementCompany = "SettlementCompany",
  SecondaryAgency = "SecondaryAgency",
}

export enum PartyRoleType {
  BuyerBorrower = "BUYER/BORROWER",
  Seller = "SELLER",
  Lender = "LENDER",
  AdditionalParty = "ADDITIONALPARTY",
}

export enum PartyRoleTypeName {
  BuyerBorrower = "Buyer/Borrower",
  Seller = "Seller",
  Lender = "Lender",
  AdditionalParty = "ADDITIONAL PARTY",
}

export enum ProductTypeName {
  CPL = "CPL",
  AAL = "AAL",
  JACKET = "Jacket",
  STANDALONE_ENDORSEMENT = "Standalone Endorsement",
}

export enum SequenceType {
  First = "FIRST",
  Second = "SECOND",
}

export enum IssueType {
  All = "All",
  Single = "Single",
}


export enum Country {
  USA = "840",
}

export enum DateTypeCode {
  Assigned ="ASSIGNED_DATE",
  Created = "CREATED_DATE",
  Modified = "MODIFIED_DATE",
  EffectiveDate = "EFFECTIVE_DATE",
  IssueDate = "ISSUED_DATE",
  ReportedDate = "BILLED_DATE",
  UploadDate = "UPLOADED_DATE",
  VoidedDate= "VOIDED_DATE",
  VerifiedDate= "VERIFIED_DATE",
}

export enum CacheKey {
  MsalInstance = "STEWART_MSAL_INSTANCE",
}

export enum NotificationType {
  Info = "Info",
  Error = "Error",
}

export enum RuleCondition {
  IsRequired = "IsRequired",
  IsAllowed = "IsAllowed",
  IsNotAllowed = "IsNotAllowed",
}

export enum CompanyContractDateStatus {
  Active = "Active",
  Amended = "Amended",
  Cancelled = "Cancelled",
}

export enum PageType {
  MyFilesPage = "MYFILESPAGE",
  ModifyPage="MODIFYPAGE",
  AssignPage="ASSIGNPAGE",
  LookupPage="LOOKUPPAGE"
}

export enum SiteErrorType {
  BadAccount = "BADACCOUNT",
  NetworkError = "NETWORK",
  NotFound = "NOTFOUND",
  ErrorBoundary = "ERRORBOUNDARY",
  NoAccess = "NOACCESS",
  NoModuleAccess = "NOMODULEACCESS",
  PermissionDenied = "PERMISSIONDENIED",
}

export enum UserRequestAuthorizationType {
  Authorized = "AUTHORIZED",
  ProvidedAuthorization = "PROVIDED_AUTHORIZATION",
  NotAuthorized = "NOT_AUTHORIZED",
}

export enum DownloadFileType {
  Mp4 = "mp4",
  Pdf = "pdf",
  Xlsx = "xlsx",
}

export enum AgencyStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum SignatureLevelType {
  AgencyWide = "AGENCY_WIDE",
  UserOnly = "USER_ONLY"
}

export enum PERMISSIONS {
  INV_LOOKUP_VIEW = 'INV_LOOKUP_VIEW',
  INV_LOOKUP_ACCESS = 'INV_LOOKUP_ACCESS',
  INV_LOOKUP_NOTE_ADD = 'INV_LOOKUP_NOTE_ADD',
  INV_VERIFY_ACCESS = 'INV_VERIFY_ACCESS',
  INV_ASSIGN_VIEW = 'INV_ASSIGN_VIEW',
  INV_ASSIGN_MODIFY = 'INV_ASSIGN_MODIFY',
  INV_MODIFY_VIEW = 'INV_MODIFY_VIEW',
  INV_TRANSFER_MODIFY = 'INV_TRANSFER_MODIFY',
  INV_EDIT_MODIFY = 'INV_EDIT_MODIFY',
  CREATE_FILE_ACCESS= 'CREATE_FILE_ACCESS',
  FILES_SEARCH= 'FILES_SEARCH',
  HELP_ACCESS= 'HELP_ACCESS',
  VIEW_BILLING= 'VIEW_BILLING',
  POLICYIMAGES_ACCESS= 'POLICYIMAGES_ACCESS',
  RESOURCES_ACCESS= 'RESOURCES_ACCESS',
  VIEW_REPORTS= 'VIEW_REPORTS',
  POLICYIMAGES_SEARCH ='POLICYIMAGES_SEARCH',
  POLICYIMAGES_DELETE ='POLICYIMAGES_DELETE',
  POLICYIMAGES_MODIFY ='POLICYIMAGES_MODIFY',
  SIGNATURES_MANAGE= 'SIGNATURES_MANAGE',
  EXPORT_DATA_POLICY = 'EXPORT_DATA_POLICY',
  POLICYIMAGES_VIEWDOCS ='POLICYIMAGES_VIEWDOCS',
  SITE_ACCESS = 'SITE_ACCESS',
  ADMIN_ACCESS = 'ADMIN_ACCESS',
  POLICY_ACCESS = 'POLICY_ACCESS',  
}

export enum ProgressImageType {
  linear = "LinearProgress",
  circular = "CircularProgress",
}

export enum NavigateToType {
  Url = "URL",
  Tab = "TAB",
  Button = "BUTTON",
  Adb2c = "ADB2C",
}

export enum ActionResultStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

export enum TabTriggerType {
  User = "USER",
  System = "SYSTEM",
}

export enum FlashMessageType {
  Success = 'SUCCESS',
  Information = 'INFORMATION',
  Warning = 'WARNING',
  Error = 'ERROR'
}

export enum SerialNumberStatusType {
  Assigned = 'ASSIGNED',
  Destroyed = 'DESTROYED',
  Lost = 'LOST',
  Verified = 'VERIFIED',
  Issued = 'ISSUED',
  Voided = 'VOIDED'
}
import { maxBy } from "lodash";
import { InventoryModifyFormPrefixItem } from "entities/UIModel/InventoryModifyFormPrefixItem";
import { CompanyLocation } from "entities/UIModel/CompanyLocation";
import { 
  hasValue, 
  notEqualWithKeys 
} from "utils/shared";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { 
  ErrorCode, 
  InventoryModifyType 
} from "pages/modify/new/enum";
import * as ApiModel from "entities/ApiModel";
import * as UIModel from "entities/UIModel";

export function getNextFormPrefixItem(currentFormPrefixItems: InventoryModifyFormPrefixItem[]) {
  const lastRowId =  maxBy(currentFormPrefixItems, "rowId")?.rowId ?? 0;
  // const nextFormPrefix = currentFormPrefixItems?.length > 0 ? currentFormPrefixItems[0].formPrefix : undefined;
  // const nextFormPrefixDescription = currentFormPrefixItems?.length > 0 ? currentFormPrefixItems[0].formPrefixDescription : undefined;
  // const formPrefixDisabled = currentFormPrefixItems?.length > 0;
  const nextFormPrefixItem: InventoryModifyFormPrefixItem = {
    rowId: lastRowId + 1,
    formPrefix: "", //undefined,
    formPrefixDescription: "", // undefined,
    formType: "",
    productType: "", 
    productTypeAbbr: "",

    serialNumberBegin: "", //undefined,
    serialNumberBeginErrorMessage: "",
    serialNumberEnd: "", //undefined,
    serialNumberEndErrorMessage: "",
    isValidationRequired: false,
  };

  return nextFormPrefixItem;
}

export const hasAnyFormPrefixItemData = (
  formPrefix?: string,
  serialNumberBegin?: string,
  serialNumberEnd?: string
) => {
  return (
    hasValue(formPrefix) ||
    hasValue(serialNumberBegin) || 
    hasValue(serialNumberEnd)
  );
};

export function validateSerialNumberOverlapping(
  formPrefixItems: InventoryModifyFormPrefixItem[],
  currentIndex: number,
  currentSerialNumber: string
) {
  const currentFormPrefix = formPrefixItems[currentIndex].formPrefix; // getValues(`${schema}.${currentIndex}.formPrefix`);
  // const formPrefixItems: InventoryModifyFormPrefixItem[] =
  //   getValues("formPrefixItems");
  const sameFormPrefixItems = formPrefixItems.filter(
    (fpi, index) =>
      fpi.formPrefix === currentFormPrefix &&
      index !== currentIndex &&
      hasValue(fpi.serialNumberBegin) &&
      hasValue(fpi.serialNumberEnd)
  );
  // console.log("sameFormPrefixItems:", sameFormPrefixItems);
  const hasOverlapped = sameFormPrefixItems.some(
    (fpi) =>
      Number(currentSerialNumber) >= Number(fpi.serialNumberBegin) &&
      Number(currentSerialNumber) <= Number(fpi.serialNumberEnd)
  );
  // console.log("hasOverlapped:", hasOverlapped);
  return !hasOverlapped;
}

export const validateSerialNumberRange = (
  serialNumberBegin?: string,
  serialNumberEnd?: string
) => {
  let isValid = false;

  if (hasValue(serialNumberBegin) && hasValue(serialNumberEnd)) {
    if (Number(serialNumberBegin) <= Number(serialNumberEnd)) {
      isValid = true;
    }
  }

  // console.log(Number(serialNumberBegin), Number(serialNumberEnd), isValid);
  return isValid;
};

export const mapCompanyLocation = (data: ApiModel.CompanyLocation) => {
  const companyLocation: UIModel.CompanyLocation = {
    companyID: data.CompanyID,
    companyName: data.CompanyName,
    companyLegacyID: data.CompanyLegacyID,
    locationUniqueID: data.LocationUniqueID,
    locationLegacyID: data.LocationLegacyID,
    locationDisplayName: data.LocationDisplayName,
    locationFullDisplayName: data.LocationFullDisplayName,
    locationStateCode: data.LocationStateCode,
    locationStateAbbr: data.LocationStateAbbr,
    serialNumberStatusTypeCode: data.SerialNumberStatusTypeCode,

    errorCode: data.ErrorCode,
  };

  return companyLocation;
};

export const validateOrigCompanyLocation = async (
  // index: number,
  formPrefix: string,
  serialNumberBegin: string,
  serialNumberEnd?: string,
  locationUniqueID?: string
) => {
  
  let errorCode: string | undefined;
  
  const criteria: ApiModel.AgencyLocationCriteria = {
    FormPrefix: formPrefix,
    BeginSerialNumber: serialNumberBegin,
    EndSerialNumber: serialNumberEnd,
    LocationUniqueID: locationUniqueID,
  };

  const { data } = await axiosSecuredInstance.post(
    "/InventoryModify/GetCompanyLocation",
    criteria
  );

  if (data) {
    const companyLocation = mapCompanyLocation(data);
    errorCode = companyLocation.errorCode;
  }
  else {
    errorCode = ErrorCode.InvalidBoth;
  }
  
  return errorCode;
};


export function isCompanyLocationType(selectionOption: CompanyLocation | string)  {
  const hasLocationUniqueIDProperty = (selectionOption as CompanyLocation)?.locationUniqueID !== undefined;
  return hasLocationUniqueIDProperty;
}

export function isEmptyObject(objectValue: object) {
  return Object.keys(objectValue).length === 0;
}

export function checkIfInventoryModifyFormIsDirty(
  initialValues: UIModel.InventoryModifyForm,
  newValues: UIModel.InventoryModifyForm
) {
  let isDirty = false;
  let checkFileFields: (keyof UIModel.InventoryModifyForm)[];
  if (newValues.inventoryModifyTypeCode === InventoryModifyType.Transfer) {
    checkFileFields = [
      "origLocationUniqueID", 
      "newLocationUniqueID", 
      "notes"
    ];
  } else {
    checkFileFields = [
      "origLocationUniqueID",
      "newSerialNumberStatusTypeCode",
      "notes",
    ];
  }

  isDirty = notEqualWithKeys(checkFileFields, initialValues, newValues, true);
  
  if (!isDirty) {
    if (
      newValues.formPrefixItems.length > 1 ||
      initialValues.formPrefixItems.length !== newValues.formPrefixItems.length
    ) {
      isDirty = true;
    } else {
      const initialFormPrefixItem = initialValues.formPrefixItems[0];
      const newFormPrefixItem = newValues.formPrefixItems[0];
      isDirty = notEqualWithKeys(
        ["formPrefix", "serialNumberBegin", "serialNumberEnd"],
        initialFormPrefixItem,
        newFormPrefixItem
      );
    }
  }

  return isDirty;
}

export function isValidLocation(errorCode: string) {
  const validGetLocationErrorCode = ["0","5"];  
  if (errorCode && validGetLocationErrorCode.includes(errorCode.toString())) {
    return true;
  }

  return false;
}

import React, { useState } from "react";
import { styled } from "@mui/styles";
// import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import useTitle from "utils/custom-hooks/useTitle";
import theme from "theme/default";
import { borderRadius, fontSize, fontWeight, gradients } from "theme/defaultStyle";
import { Box, Typography } from "@mui/material";
import GradientIcon from "theme/icons/GradientIcon";
import InventoryLookup from "./InventoryLookup";
import ActionButton from "controls/global/action-button/ActionButton";
import SerialNumberLookupIcon from "theme/icons/SerialNumberLookupIcon";
import InventoryVerifyDialog from "./inventory-verify/InventoryVerifyDialog";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";
import useLookupStore from "utils/context/InventoryLookupContext";
import { createGlobalStyle } from "styled-components";

const BodyStyle = createGlobalStyle`
  // body {
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  // }
`;

const Container = styled("div")({
  backgroundColor: theme.palette.background.default,
  borderBottomLeftRadius: borderRadius.xlarge,
  borderBottomRightRadius: borderRadius.xlarge,
  position: "relative",
  "& .header": {
    display: "flex",
    alignItems: "center",
  },
  "& .headerContainer": {
    display: "flex",
    alignItems: "center",
    height: "28px",
    margin: "40px 0px 20px 0px",
    justifyContent: "space-between",
  },
  "& .titleQuickReferenceCard": {
    marginLeft: "10px",
    background: gradients.redGradient03,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: fontWeight.bold2,
    fontSize: fontSize.xlarge2,
    paddingLeft: "10px",
  },
});

const InventoryLookupHome = () => {
  useTitle("Stewart Connect - Lookup");
  const [,{refresh}]  = useLookupStore();
  const [openInventoryVerifyDialog, setOpenInventoryVerifyDialog ] = useState<boolean>(false);
  const [{ profileSettings },] = useProfileSettingsCache();
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);

  const hasInventoryVerifyAccess = permissionCodes.includes(PERMISSIONS.INV_VERIFY_ACCESS);

  const renderComponents = () => {
    const reorderedComponents = [<InventoryLookup key={"assigned-data"} />];

    return reorderedComponents;
  };

  const handleClickOnVerifyInventory = () => {
    setOpenInventoryVerifyDialog(true);
  }

  const closeInventoryVerifyDialog = () => {
    refresh();
    setOpenInventoryVerifyDialog(false);
  }

  return (
    <Container>
      <BodyStyle />
      <Box className="headerContainer">
        <Box className="header">
          <GradientIcon Icon={SerialNumberLookupIcon} />
          <Typography
            className="titleQuickReferenceCard"
            variant="h6"
          >
            Policy Lookup
          </Typography>
        </Box>
        <ActionButton
          variant="outlined"
          color="primary"
          disabled={!hasInventoryVerifyAccess}
          onClick={handleClickOnVerifyInventory}
        >
          Verify Inventory
        </ActionButton>
      </Box>
      <>{renderComponents()}</>
      <InventoryVerifyDialog 
        isOpen={openInventoryVerifyDialog}
        onClose={closeInventoryVerifyDialog}
      />
    </Container>
  );
};

export default InventoryLookupHome;

import {
  ExpandLess,
  ExpandMore,
  Remove,
} from "@mui/icons-material";
import {
  Box,
  Container,
  styled,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiDivider from "@mui/material/Divider";
import React, {
  ReactElement,
  useState,
} from "react";
import {
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  iconSize,
  margin,
  padding,
} from "theme/defaultStyle";
import theme from "theme/default";

type Props = {
  index?: number;
  children: ReactElement;
  title: string;
  label?: string;
  showDivider?: boolean;
  isProductIssuePending?: boolean;
  // onActionClick?: () => void;
  ActionComponent?: ReactElement;
};

const AccordionWrapper = styled("div")({
  ...theme.custom.container.inner.root,
  minWidth: "100%",
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  [theme.breakpoints.up("sm")]: {
    padding: padding.zero,
  },
  "& .MuiCollapse-hidden": {
    display: "none",
  },
});

const RemoveDarkIcon = styled(Remove)({
  color: colors.grey08,
  ".noneditableproduct &": {
    color: colors.blue04,
  },
});

const ExpandLessIcon = styled(ExpandLess)({
  color: colors.blue15,
  ".noneditableproduct &": {
    color: colors.blue04,
  },
});

const ExpandMoreIcon = styled(ExpandMore)({
  color: colors.blue15,
  ".noneditableproduct &": {
    color: colors.blue04,
  },
});

const StyledContainer = styled(Container)({
  minWidth: "100%",
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  [theme.breakpoints.up("sm")]: {
    padding: padding.zero,
  },
  "& .MuiCollapse-hidden": {
    display: "none",
  },
});

const Accordion = styled(MuiAccordion)({
  backgroundColor: "transparent",
  border: "none",
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  width: "100%",
  "&:not(:last-child)": {
    borderBottom: borderSize.zero,
  },
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    margin: margin.zero,
    minHeight: "10px",
  },
});

const AccordionSummary = styled(MuiAccordionSummary)({
  backgroundColor: "transparent",
  borderBottom: "none",
  margin: margin.zero,
  minHeight: 10,
  padding: padding.zero,
  width: "100%",
  "& .MuiAccordionSummary-content": {
    margin: margin.zero,
    padding: padding.zero,
    "&.Mui-expanded": {
      margin: margin.zero,
      minHeight: "10px",
    },
  },
});

const AccordionHeader = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  margin: margin.zero,
  width: "100%",
  "& .MuiSvgIcon-root": {
    height: iconSize.medium,
    width: iconSize.medium,
  },
});

const AccordionDetails = styled(MuiAccordionDetails)({
  marginBottom: margin.zero,
  padding: padding.zero,
});

const Divider = styled(MuiDivider)({
  backgroundColor: colors.grey09,
  margin: margin.zero,
});

const TitleExpandCollapseBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: gaps.small1,
});

const HeaderPrimaryText = styled(Typography)({
  color: colors.blue15,
  display: "flex",
  flexDirection: "row",
  fontSize: fontSize.xlarge0,
  fontWeight: fontWeight.bold1,
  gap: gaps.small1,
  overflow: "visible",
  whiteSpace: "nowrap",
  "& .MuiSvgIcon-root": {
    width: iconSize.medium,
    height: iconSize.medium,
  },
  "& .MuiTypography-root ": {
    padding: padding.zero,
  },
});

const HeaderSecondaryText = styled(Typography)({
  alignItems: "center",
  color: colors.grey07,
  display: "inline-block",
  fontSize: fontSize.large,
  fontWeight: "normal",
  maxWidth: 900,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const CollapsableFormSection = ({
  index,
  children,
  title,
  label,
  showDivider = true,
  isProductIssuePending = true,
  ActionComponent,
  // onActionClick,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleHeaderKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleCollapseToggle();
    }
  };

  const handleCollapseToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // const handleActionClick = (event?: React.MouseEvent<HTMLElement> | undefined) => {
  //   event?.stopPropagation();
  //   onActionClick && onActionClick();
  // };

  return (
    <AccordionWrapper>
      <Accordion
        elevation={0}
        expanded={isExpanded}
        className={isProductIssuePending ? "" : "noneditableproduct"}
      >
        <AccordionSummary
          aria-controls={`panel${index}-content`}
          id={"panelHeader-".concat(title.replace("/", "").replace(" ", ""))}
          onKeyDown={handleHeaderKeyDown}
          tabIndex={0} // Needed for keydown event.
        >
          <AccordionHeader onClick={handleCollapseToggle}>
            <TitleExpandCollapseBox
              id={title.replace("/", "").replace(" ", "")}
            >
              <HeaderPrimaryText title={title}>{title}</HeaderPrimaryText>
              {label?.trim() && !isExpanded && (
                <>
                  <RemoveDarkIcon />
                  <HeaderSecondaryText title={label}>
                    {label}
                  </HeaderSecondaryText>
                </>
              )}
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </TitleExpandCollapseBox>
            {ActionComponent && (
              // <Box onClick={handleActionClick}>{ActionComponent}</Box>
              <Box>{ActionComponent}</Box>
            )}
          </AccordionHeader>
        </AccordionSummary>
        <AccordionDetails>
          <StyledContainer>{children}</StyledContainer>
        </AccordionDetails>
      </Accordion>
      {showDivider && <Divider />}
    </AccordionWrapper>
  );
};

export default CollapsableFormSection;

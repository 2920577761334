import {
  styled,
  TextField,
} from "@mui/material";
import theme from "theme/default";

const StewartInput = styled(TextField)({
  "& div.MuiInputBase-root": theme.custom.textField.inputOutlined,
  "& div.MuiInputBase-root.Mui-disabled": theme.custom.textField.inputOutlinedDisabled,
  "& input.MuiInputBase-input": theme.custom.textField.inputOutlined,
  "& input.MuiInputBase-input.Mui-disabled": theme.custom.textField.inputOutlinedDisabled,
  "& div.Mui-error>input": theme.custom.textField.selectError,
  "& label.MuiInputLabel-shrink.Mui-error": theme.custom.textField.colorError,
  "& .Mui-error .arrow": theme.custom.textField.arrowError,
});

export default StewartInput;

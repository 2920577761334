import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { LayoutBodyContext } from "layout/LayoutBodyContext";
import {
  colors,
  margin,
padding,
} from "theme/defaultStyle";
import PageSection from "controls/global/page-section";
import { Order } from "utils/sorting";
import { useUser } from "utils/context/UserContext";
import {
  orderBy as _orderBy,
  debounce
} from "lodash";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { DateTypeCode } from "utils/data/enum";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import { IColumn } from "entities/ApiModel/IColumn";
import { InventoryLookupExportCriteria } from "entities/ApiModel/InventoryLookupExportCriteria";
import { getColumnSearchField } from "utils/columnsGrid";
import InventoryLookupGrid from "./InventoryLookupGrid";
import InventoryLookupHeader from "./InventoryLookupHeader";

import { InventoryLookupUI } from "entities/UIModel/InventoryLookupUI";
import { SelectionConfig } from "controls/global/stewart-table/StewartTable";
import useLookupStore from "utils/context/InventoryLookupContext";
import FileExportDialog from "controls/global/file-export-dialog/FileExportDialog";
import IosShareIcon from '@mui/icons-material/IosShare';
import { InventoryLookupSearchCriteria } from "entities/UIModel/InventoryLookupSearchCriteria ";

const StyledBoxContainer = styled(Box)({
  position: "relative",
  "& .MuiOutlinedInput-input": {
    marginBottom: margin.xxsmall2,
    marginLeft: margin.small2,
    marginTop: margin.small,
    padding: `${padding.small} ${padding.small2}`,
  },
  "&.image-archive > div > div:nth-child(2):not(.MuiInputAdornment-root)": {
    boxShadow: "none",
    paddingTop: "50px",
  },
  "& div.MuiTableContainer-root": {
    marginTop: `-${margin.medium}`,
  },
  "& div.grid-edit-mode": {
    "& > div.MuiBox-root": {
      color: colors.grey11,
      "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
        color: colors.grey11,
      },
      "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis).Mui-selected": {
        backgroundColor: colors.grey10,
      }
    }
  }
});


const InventoryLookup = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const InitialMessage = "Loading results...";
  const NoRecordsMessage = "No results Found";
  const TimeoutMessage = "Sorry, this search took too long. Please modify your search criteria and try again.";
  const [page, setPage] = useState(1);
  const [data, setData] = useState<InventoryLookupUI[]>();
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [
    { selectedLookupItems, isAdvanceSearch, error: requestError, progressPercent: loadingProgressPercent, refreshData },
    {
      getSearchDocuments,
      getInitialColumnsDefintion,
      getColumnsDefinition,
      setColumnDefinition,
      updateSelectedLookupItems,
      exportFiles,
      updateSelectedLookupItem,
      resetSelectedLookupItem,
      setAdvanceSearch,
      cancelRequests,
    },
  ] = useLookupStore();
  const [readyToSearch, setReadyToSearch] = useState<boolean>(false);
  const [isAdvSearchOpen, setIsAdvSearchOpen] = useState<boolean>(false);
  const { generalConfig } = useConfigContext();

  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof InventoryLookupUI | undefined>("lastModifiedDate");
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [filters, setFilters] = useState<InventoryLookupSearchCriteria | undefined>(undefined);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [partialFilterUpdate, setPartialFilterUpdate] = useState<boolean>(false);
  const [defaultOpenedDate, setDefaultOpenedDate] = useState<Date>(new Date());
  const [userInputDebounceTimeMs, setUserInputDebounceTimeMs] = useState<number>(750);
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);

  const [, { getRowsPerPageSetting, setRowsPerPageSetting }] = useUser();
  const [noRecordsMessage, setNoRecordsMessage] = useState<string[]>([InitialMessage]);
  const [columns, setColumns] = useState<IColumn[]>(getInitialColumnsDefintion(false));
  const [hiddenColumns, setHiddenColumns] = useState<(keyof InventoryLookupUI)[]>(getInitialColumnsDefintion(true));
  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
  const [exportedColumns, setExportedColumns] = useState<string[]>([]);
  const bodyRef = useContext(LayoutBodyContext);
  const getDatabaseSortColumnName = (name: string) => {
    const mappedColumnNames: { [key: string]: string } = {
      documentDisplayFileName: "DocumentDisplayFileName",
      companyName: "CompanyName",
      locationDisplayName: "LocationDisplayName",
      companyId: "CompanyId",
      documentUploadedByUserName: "DocumentUploadedByUserName",
      documentUploadedDate: "DocumentUploadedDate",
      rowsPerPage: "RowsPerPage",
      currentRow: "CurrentRow",
      currentPage: "CurrentPage",
      totalPages: "TotalPages",
      totalRows: "TotalRows",
      locationStateAbbr: "LocationStateAbbr",
      locationLegacyID: "LocationLegacyID",
      companyLegacyID: "CompanyLegacyID",
      documentSize: "DocumentSize",
      documentFileTypeCode: "DocumentFileTypeCode",
      clientFileID: "ClientFileID",
      lastModifiedDate: "lastModifiedDate",
    };
    return mappedColumnNames[name] ?? name;
  };

  const getFileSearchCriteria = () => {
    let criteria: InventoryLookupSearchCriteria;

    criteria = {
      ...filters,
      listCount: rowsPerPage,
      requestedPage: page,
      dateTypeCode: isAdvSearchOpen ? filters?.dateTypeCode : undefined,
      isInitialLoad: initialLoad,
    };
    if (sortColumn) {
      criteria = {
        ...criteria,
        sortColumn: sortColumn,
      };
    }

    return criteria;
  };

  const loadSettings = async () => {
    const debounceTime = generalConfig.userInputDebounceTimeMs;
    const filterDays = generalConfig.filesSearchDefaultLookupDays;
    // const timeoutMs = generalConfig.filesSearchTimeoutMs;

    if (debounceTime) {
      setUserInputDebounceTimeMs(debounceTime);
    }
    if (filterDays) {
      setDefaultOpenedDate(new Date(new Date().getTime() - filterDays * 24 * 60 * 60 * 1000));
    }
  };

  const getDefaultFilters = (advanced: boolean): InventoryLookupSearchCriteria => {
    const sortColumn = "-lastModifiedDate"; // advanced ? undefined : "-lastModifiedDate";
    const dateType = advanced ? DateTypeCode.Assigned : undefined;
    const isInventory = advanced ? 0 : undefined;
    const defaultFilters: InventoryLookupSearchCriteria = {
      listCount: rowsPerPage,
      dateTypeCode: dateType,
      requestedPage: 1,
      sortColumn: sortColumn,
      isInventory: isInventory,
    };
    return defaultFilters;
  };

  const performSearch = async () => {
    let criteria = getFileSearchCriteria();
    criteria.isInitialLoad = false;
    if (initialLoad) {
      criteria.isInitialLoad = true;
      setInitialLoad(false);
    }
    let files: InventoryLookupUI[] | undefined = [];
    try {
      files = await getSearchDocuments({ ...criteria });
      if (files !== undefined) {
        setData(files);
        if (!files?.length) {
          setNoRecordsMessage(getNoRecordsMessage());
          setPage(1);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRowsPerPageChange = async (count: number) => {
    await cancelRequests();
    setRowsPerPageSetting(count);
    setRowsPerPage(count);
    setPartialFilterUpdate(false);
    setPage(1);
  };

  const handleToggleAllRows = async () => {
    if (data) {
      updateSelectedLookupItems(data);
    }
  };

  const handleToggleRow = (document: InventoryLookupUI) => {
    updateSelectedLookupItem(document);
  };

  const selectionConfig: SelectionConfig<InventoryLookupUI> = {
    enabled: true,
    selectedRows: selectedLookupItems?.map((d) => d.uniqueIdentifier) ?? [],
    onToggleAllRows: handleToggleAllRows,
    onToggleRow: handleToggleRow,
    isRowSelected: (document: InventoryLookupUI) =>
      selectedLookupItems?.some((d) => d.uniqueIdentifier === document.uniqueIdentifier),
  };

  const getNoRecordsMessage = () => {
    if (!filters) {
      return [NoRecordsMessage];
    }
    let firstMessage = "No Matches Found";
    const secondMessage = "Please adjust your search criteria and search again.";
    const keys: (keyof InventoryLookupSearchCriteria)[] = [
      "clientFileId",
      "formPrefix",
      "serialNumber",
      "locationStateAbbr",
      "serialNumberStatusTypeName",
      "locationLegacyID",
      "locationDisplayName",
      "companyName",
      "policyNumber",
      "policyTypeDesc",
      "companyLegacyID",
      "inventoryAssignID",
    ];
    let providedFields = keys.map((key) => filters[key]).filter((value) => !!value);

    if (providedFields.length) {
      firstMessage = `No matches found for "${providedFields.join(", ")}"`;
    }
    return [firstMessage, secondMessage];
  };

  const handleAdvancedFilterChange = (name: keyof InventoryLookupSearchCriteria, value: any, shouldSearch: boolean) => {
    if (isAdvSearchOpen) {
      setPartialFilterUpdate(!shouldSearch);
      handleFilterChange(name, value);
    } else {
      handleFilterChange(name, null);
    }
  };

  const handleAdvanceSearchPanelOpen = async (open: boolean) => {
    await cancelRequests();
    setIsAdvSearchOpen(open);
    setReadyToSearch(true);
    setPage(1);
    setAdvanceSearch(open);
    const resetFilters = () => {
      return getDefaultFilters(open);
    };
    setFilters(resetFilters);
  };

  const handlePageChange = async (page: number) => {
    await cancelRequests();
    setPage(page);
  };

  useEffect(() => {
    if (readyToSearch) {
      const search = async () => {
        await performSearch();
        setReadyToSearch(false);
      };

      search();
    }
  }, [readyToSearch]);

  const canSearch = (): boolean => {
    return !partialFilterUpdate && filters !== undefined && Object.values(filters).length > 0;
  };

  const debounceSetReadyToSearch = useCallback(
    debounce(() => setReadyToSearch(true), userInputDebounceTimeMs),
    [userInputDebounceTimeMs],
  );

  useEffect(() => {
    if (canSearch()) {
      if (partialFilterUpdate) {
        setPartialFilterUpdate(false);
      }

      debounceSetReadyToSearch();
    }
  }, [
    filters,
    page,
    sortColumn,
    filtersApplied,
    settingsLoaded,
    columns,
    rowsPerPage,
    partialFilterUpdate,
    isAdvanceSearch,
    refreshData,
  ]);

  useEffect(() => {
    const defaultFilters = getDefaultFilters(false);
    setFilters(defaultFilters);
  }, [defaultOpenedDate]);

  const getSearchField = (field: keyof InventoryLookupUI) =>
    getColumnSearchField<InventoryLookupUI, InventoryLookupSearchCriteria>(columns, field);

  const handleRequestSort = async (columnName: any, disableToggling: boolean = false) => {
    await cancelRequests();
    let sortBy = getDatabaseSortColumnName(columnName);
    if (!disableToggling && sortColumn === sortBy) {
      sortBy = `-${sortBy}`;
    }

    setOrder(sortBy.startsWith("-") ? "desc" : "asc");
    setOrderBy(columnName.replace("-", "") as keyof InventoryLookupUI);
    setSortColumn(sortBy);
  };

  const handleFilterChange = async (name: keyof InventoryLookupSearchCriteria, value: any) => {
    await cancelRequests();
    const formattedValue = typeof value === "string" ? value.replaceAll(",", "|") : value;
    const updatedFilters = (filters: InventoryLookupSearchCriteria | undefined) => {
      let dateType;
      if (name === "dateTypeCode") {
        dateType = value;
      } else if (name === "assignedDate") {
        handleRequestSort("-assignedDate", true);
      } else {
        dateType = filters?.dateTypeCode;
      }
      let adjustedFilters: InventoryLookupSearchCriteria = {
        ...filters,
        [name]: formattedValue,
        dateTypeCode: dateType,
      };
      adjustedFilters = removeInvalidProperties(adjustedFilters).object;
      return adjustedFilters;
    };

    setFiltersApplied(true);
    setFilters(updatedFilters);
  };
  useEffect(() => {
    bodyRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [rowsPerPage]);
  useEffect(() => {
    const getCount = async () => {
      const count = await getRowsPerPageSetting();
      setRowsPerPage(count);
    };

    getCount();
  }, [getRowsPerPageSetting]);

  useEffect(() => {
    const init = async () => {
      await loadSettings();
      resetSelectedLookupItem();
      setSettingsLoaded(true);
    };

    init();
    setAdvanceSearch(false);
  }, []);

  useEffect(() => {
    const getColumns = async () => {
      const { gridColumns, gridHiddenColumns } = await getColumnsDefinition();
      setColumns(gridColumns);
      setHiddenColumns(gridHiddenColumns);
    };
    getColumns();
  }, [getColumnsDefinition]);

  useEffect(() => {
    let sortColumnChanged = false;
    if (sortColumn) {
      const sortColumnName = sortColumn.replace("-", "");
      hiddenColumns.forEach((c) => {
        const mappedName = getDatabaseSortColumnName(c);
        if (mappedName === sortColumnName) {
          setSortColumn(undefined);
          sortColumnChanged = true;
          return;
        }
      });
    }

    const updatedFilters = removeInvalidProperties({ ...filters });
    if (sortColumnChanged || updatedFilters.deleteCount || data === undefined) {
      setFilters((filters: InventoryLookupSearchCriteria | undefined) => updatedFilters.object);
    }
  }, [hiddenColumns]);

  useEffect(() => {
    handleColumnsModified(columns);
  }, [hiddenColumns]);

  useEffect(() => {
    const exportedCols = _orderBy(
      columns.filter((c) => !hiddenColumns.includes(c.field as keyof InventoryLookupUI)),
      "position",
    ).map((c) => c.field);

    setExportedColumns(exportedCols);
  }, [columns ,hiddenColumns]);

  useEffect(() => {
    if (!filters) {
      setInitialLoad(false);
      return;
    }

    const baseRequiredFields: Array<keyof InventoryLookupSearchCriteria> = ["listCount", "requestedPage", "sortColumn"];
    const advanceRequiredFields: Array<keyof InventoryLookupSearchCriteria> = [
      ...baseRequiredFields,
      "dateTypeCode",
      "isInventory",
    ];

    const filterKeys = Object.keys(filters) as Array<keyof InventoryLookupSearchCriteria>;
    const extraFields = filterKeys.filter(
      (key) => !baseRequiredFields.includes(key) && !advanceRequiredFields.includes(key),
    );
    const areExtraFieldsEmpty = extraFields.every((key) => filters[key] === "" || filters[key] == null);

    if (isAdvanceSearch) {
      if (
        filters.isInventory === 0 &&
        filterKeys.length >= advanceRequiredFields.length &&
        advanceRequiredFields.every((field) => filterKeys.includes(field)) &&
        areExtraFieldsEmpty
      ) {
        setInitialLoad(true);
      } else if (readyToSearch) {
        setInitialLoad(true);
      } else {
        setInitialLoad(false);
      }
    } else {
      if (
        filterKeys.length >= baseRequiredFields.length &&
        baseRequiredFields.every((field) => filterKeys.includes(field)) &&
        areExtraFieldsEmpty
      ) {
        setInitialLoad(true);
      } else {
        setInitialLoad(false);
      }
    }
  }, [
    filters,
    page,
    sortColumn,
    filtersApplied,
    settingsLoaded,
    columns,
    rowsPerPage,
    partialFilterUpdate,
    isAdvanceSearch,
    readyToSearch,
    refreshData,
  ]);

  const handleColumnResize = (column: keyof InventoryLookupUI, newWidth: number) => {
    if (column && newWidth > 0) {
      setColumnDefinition([column], "width", newWidth);
    }
  };
  const handleUpdateHiddenColumns = (columns: (keyof InventoryLookupUI)[]) => {
    setHiddenColumns(columns);
  };

  const handleExport = async (allFiles: boolean) => {
    let searchCriteria: InventoryLookupSearchCriteria = {
      ...getFileSearchCriteria(),
    };
    searchCriteria.listCount = 0;
    const exportCriteria: InventoryLookupExportCriteria = {
      InventoryLookupDetailIDLists: allFiles
        ? []
        : selectedLookupItems
            .map((items: InventoryLookupUI) => items.inventoryLookupDetailID)
            .filter((id): id is number => id !== undefined),
      ExportColumns: exportedColumns,
      SerialNumberDetailSearchCriteria: selectedLookupItems.length > 0 && !allFiles ? {} : searchCriteria,
    };
    await exportFiles(exportCriteria);
    setOpenExportDialog(false);
  };

  const handleColumnsModified = (columns: IColumn[]) => {
    const exportedCols = columns
      .filter((c) => !hiddenColumns.includes(c.field as keyof InventoryLookupUI))
      .map((c) => c.field);
    setExportedColumns(exportedCols);
  };

  const visibleColumns = columns
    .filter((c) => c.hideable)
    .map((c) => ({
      field: c.field as keyof InventoryLookupUI,
      name: c.name,
      checked: !hiddenColumns.includes(c.field as keyof InventoryLookupUI),
    }));

  const removeInvalidProperties = (object: { [key: string]: any }) => {
    let deleteCount = 0;
    Object.keys(object).forEach((key) => {
      // Remove null properties and fields that are hidden,
      // with the exception of dateRangeStart which can always be searched even if hidden.
      if (
        object[key] === null ||
        object[key] === undefined ||
        (!isAdvSearchOpen &&
          key !== "dateRangeStart" &&
          hiddenColumns.find((c) => c === key || getSearchField(c) === key))
      ) {
        delete object[key];
        deleteCount++;
      }
    });

    return { object, deleteCount };
  };

  useEffect(() => {
    delete filters?.dateRangeStart;
    delete filters?.dateRangeEnd;
  }, [isAdvanceSearch]);

  useEffect(() => {
    if (requestError === "TIMEOUT") {
      setNoRecordsMessage([TimeoutMessage]);
      setData([]);
    }
  }, [requestError]);

  return (
    <>
      <StyledBoxContainer className="image-archive">
        <PageSection title="">
          <>
            <Typography className="subheading">
              Locate a policy by searching in the columns below, or click the Advanced button to filter the results.
            </Typography>
            <InventoryLookupHeader
              columns={visibleColumns}
              hiddenColumns={hiddenColumns}
              updateHiddenColumns={handleUpdateHiddenColumns}
              handleAdvancedFilterChange={handleAdvancedFilterChange}
              handleAdvanceSearchPanelOpen={handleAdvanceSearchPanelOpen}
              onExport={handleExport}
              setOpenExportDialog={setOpenExportDialog}
            />
            <InventoryLookupGrid
              performSearch={performSearch}
              loadingProgressPercent={loadingProgressPercent}
              colsConfig={columns}
              rows={data ?? []}
              rowsPerPage={rowsPerPage}
              noRowsMessage={noRecordsMessage}
              page={page}
              hiddenColumns={hiddenColumns}
              onFiltersChange={handleFilterChange}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onColumnResize={handleColumnResize}
              selectionConfig={selectionConfig}
            />
            <FileExportDialog
              open={openExportDialog}
              onCancel={() => setOpenExportDialog(false)}
              onExport={handleExport}
              showSelectionPrompt={selectedLookupItems?.some((s) => s)}
              selectionPrompt={"Include only the selected policies or all policies?"}
              icon={IosShareIcon}
            />
            <ScrollToTopArrow refreshSize={data} />
          </>
        </PageSection>
      </StyledBoxContainer>
    </>
  );
};

export default InventoryLookup;

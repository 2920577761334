import { InventoryPolicyDetails } from "entities/UIModel";
import { SerialNumberStatusType } from "utils/data/enum";
import { hasValue } from "utils/shared";

export const mapInventoryPolicyDetails = (
  policyDetails: any
): InventoryPolicyDetails => {
  return {
    serialNumberDetailID: policyDetails.SerialNumberDetailID,
    inventoryAssignID: policyDetails.InventoryAssignID,
    inventoryAssignDetailID: policyDetails.InventoryAssignDetailID,
    inventoryModifyID: policyDetails.InventoryModifyID,
    inventoryModifyDetailID: policyDetails.InventoryModifyDetailID,
    productTypeAbbr: policyDetails.ProductTypeAbbr,
    productType: policyDetails.ProductType,
    product: policyDetails.Product,
    formType: policyDetails.FormType,
    formPrefix: policyDetails.FormPrefix,
    serialNumber: policyDetails.SerialNumber,
    serialNumberStatusTypeCode: policyDetails.SerialNumberStatusTypeCode,
    serialNumberStatusTypeName: policyDetails.SerialNumberStatusTypeName,
    isOnDemand: policyDetails.IsOnDemand,
    isVerified: policyDetails.IsVerified,
    verifiedDate: policyDetails.VerifiedDate,
    assignedDate: policyDetails.AssignedDate,
    modifyDate: policyDetails.ModificationDate,
    companyID: policyDetails.CompanyID,
    companyName: policyDetails.CompanyName,
    companyLegacyID: policyDetails.CompanyLegacyID,
    locationUniqueID: policyDetails.LocationUniqueID,
    locationLegacyID: policyDetails.LocationLegacyID,
    locationDisplayName: policyDetails.LocationDisplayName,
    locationStateCode: policyDetails.LocationStateCode,
    locationStateAbbr: policyDetails.LocationStateAbbr,
    clientFileID: policyDetails.ClientFileID,
    filePricingDetailID: policyDetails.FilePricingDetailID,
    fileID: policyDetails.FileID,
    orderID: policyDetails.OrderID,
    serialNumberNotes: mapInventoryPolicyNotes(policyDetails.SerialNumberNotes),
    serialNumberActivities: mapInventoryPolicyActivities(
      policyDetails.SerialNumberActivities
    ),
    effectiveDate: policyDetails.EffectiveDate,
    ticketNumber: policyDetails.TicketNumber,
    issueDate: policyDetails.IssueDate,
    voidedDate: policyDetails.VoidedDate,
    callingUser: policyDetails.CallingUser,
    userID: policyDetails.UserID,
    identityKey: policyDetails.IdentityKey,
    integrationKey: policyDetails.IntegrationKey,
    hasGUIDChanged: policyDetails.HasGUIDChanged,
    isNew: policyDetails.IsNew,
    isDeleted: policyDetails.IsDeleted,
    isDirty: policyDetails.IsDirty,
    isReadOnly: policyDetails.IsReadOnly,
    partitionYear: policyDetails.PartitionYear,
    active: policyDetails.Active,
    createdDate: policyDetails.CreatedDate,
    modifiedDate: policyDetails.ModifiedDate,
    createdBy: policyDetails.CreatedBy,
    modifiedBy: policyDetails.ModifiedBy,
    modifiedByID: policyDetails.ModifiedByID,
    lockedBy: policyDetails.LockedBy,
  };
};

export const mapInventoryPolicyNotes = (notes: any[]) => {
  return (
    notes &&
    notes.map((note) => {
      return {
        serialNumberNotesID: note.SerialNumberNotesID,
        serialNumberDetailID: note.SerialNumberDetailID,
        serialNumberActivityID: note.SerialNumberActivityID,
        notesDescription: note.NotesDescription,
        notesUserID: note.NotesUserID,
        notesUser: note.NotesUser,
        notesCreatedDate: note.NotesCreatedDate,
      };
    })
  );
};

export const mapInventoryPolicyActivities = (activities: any[]) => {
  return (
    activities &&
    activities.map((activity) => {
      return {
        serialNumberActivityID: activity.SerialNumberActivityID,
        serialNumberDetailID: activity.SerialNumberDetailID,
        serialNumberNotesID: activity.SerialNumberNotesID,
        activityDescription: activity.ActivityDescription,
        activityUserID: activity.ActivityUserID,
        activityUser: activity.ActivityUser,
        activityCreatedDate: activity.ActivityCreatedDate,
        activityDateTime: activity.ActivityDateTime,
        notesDescription: activity.NotesDescription,
        originationSource: activity.OriginationSource,
      };
    })
  );
};

export const checkIfTransferEditAllowed = (
  serialNumberStatusTypeCode?: string
) => {
  return (
    hasValue(serialNumberStatusTypeCode) &&
    serialNumberStatusTypeCode !== SerialNumberStatusType.Verified &&
    serialNumberStatusTypeCode !== SerialNumberStatusType.Issued &&
    serialNumberStatusTypeCode !== SerialNumberStatusType.Voided
  );
};

export const getDate = (dateString: string): string => {
  const [year, month, day] = dateString.split(/[-T]/);
  return `${month}/${day}/${year}`;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from "@mui/styles";
import {
  colors,
  borderRadius,
  fontSize,
  fontWeight,
  gradients,
  padding,
  margin,
  iconSize,
} from "theme/defaultStyle";

export type DialogType = 'INFO' | 'ERROR' | 'SUCCESS' | 'WARNING' | undefined;

type DialogTypeProps = {
  type?: DialogType,
  width?: number,
  height?: number,
  titleMarginTop?: string,
}

export const getBackgroundColor = (type: DialogType) => {
  switch (type) {
    case 'ERROR':
      return gradients.redGradient02;
    case 'SUCCESS':
      return gradients.greenGradient01;
    case 'WARNING':
      return gradients.yellowGradient01;
    default:
      return gradients.blueGradient03;
  }
}

export const useDialogStyles = makeStyles({
  dialogRoot: {
    "& div.MuiPaper-rounded": {
      borderRadius: borderRadius.xlarge,
    },
    "& div.MuiDialog-paper": {
      maxHeight: ({ height }: DialogTypeProps) => height ? height : "600px",
      width: ({ width }: DialogTypeProps) => width ? width : "auto",
      overflowY: "visible",
    },
    "& & div.MuiDialog-paperFullWidth": {
      maxHeight: ({ height }: DialogTypeProps) => height ? height : "600px",
      width: ({ width }: DialogTypeProps) => width ? width : "auto",
      overflowY: "visible",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: ({ width }: DialogTypeProps) => width ? width : "400px",
    },
  },
  dialogTitleRoot: {
    alignItems: "center",
    backgroundImage: ({ type }: DialogTypeProps) => getBackgroundColor(type),
    borderRadius: borderRadius.large,
    color: colors.white,
    fontFamily: 'Roboto, sans-serif',
    fontSize: fontSize.xlarge2,
    fontStyle: 'normal',
    fontWeight: fontWeight.bold2,
    justifyItems: "center",
    margin: 'auto',
    marginTop: ({ titleMarginTop }: DialogTypeProps) => titleMarginTop ? `-${titleMarginTop}` : `-${margin.xlarge2}`,
    padding: padding.xlarge,
    position: 'relative',
    textAlign: "center",
    width: "95%",
    "& .action-title": {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      "& .MuiSvgIcon-root": {
        color: colors.white,
        cursor: "pointer",
        fontSize: iconSize.medium,
      },
      "& span": {
        flexGrow: 1,
      },
      "& :not(span)": {
        flexBasis: iconSize.medium,
        "&:hover": {
          color: colors.grey03,
        },
      },
    },
  },
  dialogContentRoot: {
    color: colors.grey08,
    fontSize: fontSize.xlarge0,
    padding: padding.xlarge1,
    paddingBottom: padding.zero,
    textAlign: ({ type }: DialogTypeProps) => type === "ERROR" || type === "WARNING" ? "center" : "left",
  },
  dialogActionRoot: {
    justifyContent: "center",
    paddingBottom: padding.xlarge1,
    paddingTop: padding.xlarge1,
  },
  supportId: {
    fontSize: "0.6em",
    paddingLeft: "24px",
    textAlign: "left",
    whiteSpace: "nowrap",

  },
  emailToIcon: {
    color: colors.blue15,
    fontSize: "10pt",
  },
  copyToClipboardIcon: {
    color: colors.blue15,
    fontSize: "10pt",
  },
});

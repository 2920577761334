import { 
  styled, 
  TableCell, 
  TableCellProps, 
  TableRow
} from "@mui/material";
import { PolicyNotes } from "entities/UIModel";
import React, { 
  useState 
} from "react";
import { 
  colors,
  fontSize, 
  fontWeight 
} from "theme/defaultStyle";
import { formatDateTime, removeTimezone } from "utils/shared";
import ViewNoteTooltipCell from "./ViewNoteTooltipCell";
import ViewNoteDialog from "../../dialogs/ViewNoteDialog";

declare module "@mui/material/TableCell" {
  interface TableCellProps {
    minWidth?: string;
    maxWidth?: string;
  }
}

interface Props {
  note?: PolicyNotes;
}

const StyledTableCell = styled(TableCell)((props: TableCellProps) => ({
  border: "none",
  color: colors.grey08,
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  minWidth: props.minWidth,
  maxWidth: props.maxWidth,
  whiteSpace: "pre-line",
}));

const StyledTableRow = styled(TableRow)({
  border: `1px solid ${colors.grey09}`,
  "&. MuiTableRow-root": {
    "td: first-child": {
      borderLeft: "none",
    },
  },
});

const NotesSectionBodyRow = ({ note }: Props) => {
  const { notesCreatedDate, serialNumberNotesID, notesDescription, notesUser } =
    note ?? {};
  const [isViewNoteDialogOpen, setIsViewNoteDialogOpen] = useState(false);

  const handleViewNoteOpen = () => {
    setIsViewNoteDialogOpen(true);
  };

  return (
    <StyledTableRow key={serialNumberNotesID}>
      <StyledTableCell minWidth="400px" maxWidth="calc(40vw)">
        <ViewNoteTooltipCell
          title={notesDescription!}
          handleViewNoteOpen={handleViewNoteOpen}
        />
      </StyledTableCell>

      <StyledTableCell>{notesUser}</StyledTableCell>
      <StyledTableCell>
        {notesCreatedDate && formatDateTime(removeTimezone(notesCreatedDate), false)}
      </StyledTableCell>

      <ViewNoteDialog
        isOpen={isViewNoteDialogOpen}
        note={notesDescription}
        onCancel={() => setIsViewNoteDialogOpen(false)}
      />
    </StyledTableRow>
  );
};

export default NotesSectionBodyRow;

import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  getComparator,
  Order,
  stableSort,
} from "./stewartTableHelper";
import React, {
  createRef,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  borderSize,
  colors,
  defaultStyle,
  fontSize,
  gaps,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";
import CircularProgressWithLabel from "../circular-progress-with-label";
import LightTooltip from "../light-tooltip";
import StewartCheckbox from "../stewart-checkbox";
import StewartTableBodyEmpty from "./StewartTableBodyEmpty";
import StewartTableHeader from "./StewartTableHeader";
import useStewartTableStore from "utils/context/StewartTableContext";

export interface IRowIdentifier {
  disabled?: boolean;
  isEdit?: boolean;
  uniqueIdentifier: any;
  checked ?: boolean;
  actions?: string;
}

export interface SelectionConfig<T> {
  enabled?: boolean;
  renderRowDetails?: (row: T) => ReactNode;
  selectedRows?: string[];
  isRowSelected?: (row: T) => boolean;
  onToggleRow?: (row: T) => void;
  onToggleAllRows?: () => void;
}

export interface DetailsViewConfig<T> {
  enabled?: boolean;
  renderRowDetails?: (row: T) => ReactNode;
}

export interface StewartTableColumn<T extends IRowIdentifier> {
  field: keyof T;
  name: string;
  width?: number;
  minWidth?: number;
  sticky?: boolean;
  left?: number;
  right?: number;
  sortable?: boolean;
  resizable?: boolean;
  draggable?: boolean;
  position?: number;
  actionComponent?: (param?: T[]) => React.ReactNode;
  valueGetter?: (param: T) => React.ReactNode;
  classes?: string;
  classname?: string
}

type Props<T extends IRowIdentifier> = {
  cols: StewartTableColumn<T>[];
  rows: T[];
  page: number;
  noRowsMessage: string[];
  hiddenColumns?: (keyof T)[];
  rowsPerPage: number;
  loadingProgressPercent?: number;
  padding?: string | number;
  footerComponent?: ReactElement;
  showActionRow?: boolean;
  useDbPagingSorting?: boolean;
  detailsViewConfig?: DetailsViewConfig<T>;
  selectionConfig?: SelectionConfig<T>;
  order: Order;
  orderBy?: keyof T;
  onRequestSort?: (property: keyof T) => void;
  onColumnResize: (property: keyof T, newWidth: number) => void;
  onColumnsModified?: (columns: StewartTableColumn<T>[]) => void;
};

type TableProps = {
  showActionRow?: boolean;
};

const CircularProgressContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  left: "50%",
  position: "fixed",
  top: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  zIndex: zIndex.positiveMax,
  "& .MuiCircularProgress-circleDeterminate": {
    transition: "stroke-dashoffset 0ms",
  }
});

const StyledPaper = styled(Box)({
  padding: padding.zero,
  //maxHeight: "600px",
  minHeight: "600px",   // smaller resolution will use this 
  maxHeight: "calc(100vh - 600px)",  // 100 vh = (Full HD=1080/1024/1200/1280, 2K=1440, 4K=2160)
  "&.MuiTableContainer-root": {
    width: "calc(100% + 2px)",
    "& table:not(.details-table)": {
      width: "calc(100% - 2px)", 
    },
  },
});

const StyledTable = styled(Table)(({ showActionRow }: TableProps) => ({
  borderCollapse: "separate",
  borderSpacing: 0,
  tableLayout: "fixed",
  position: "sticky",
  top: "0px",
  "& .MuiTableCell-root": {
    fontSize: fontSize.large,
    "&.row-header-cell": {
      backgroundColor: colors.white,
      left: 0,
      paddingBottom: padding.zero,
      paddingLeft: padding.large1,
      paddingRight: padding.zero,
      paddingTop: padding.zero,
      width: "55px",
      position: "sticky",
      zIndex: zIndex.positiveMin,
      "& .row-header-items": {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: gaps.small1,
        "& .MuiSvgIcon-root": {
          color: colors.blue15,
          cursor: "pointer",
        },
        " & .MuiButtonBase-root": {
          padding: padding.small1_5,
        },
      },
    },
  },
  "& .MuiOutlinedInput-root": {
    height: "40px",
  },
  "& thead .MuiTableRow-root.header-actions": {
    height: "56px",
    "& th": {
      borderTop: defaultStyle.table.border,
      paddingRight: padding.small1_5,
      "&:first-child": {
        borderLeft: defaultStyle.table.border,
        borderTopLeftRadius: borderRadius.small,
        zIndex: zIndex.positiveMin,  
      },
      "&:last-child": {
        borderRight: defaultStyle.table.border,
        borderTopRightRadius: borderRadius.small,
      },
      "& div.disabled-input": {
        background: colors.grey10,
        borderTopLeftRadius: borderRadius.small,
        borderTopRightRadius: borderRadius.small,
      },
      "& button.Mui-disabled":{
        "& svg path": {
            stroke: colors.grey09,
        },
      }
    },
  },
  "& tbody .MuiTableRow-root": {
    "&:last-of-type": {
      "& td": {
        borderBottom: defaultStyle.table.border,
      },
      "& td:first-of-type": {
        borderBottomLeftRadius: borderRadius.small,
      },
      "& td:last-of-type": {
        borderBottomRightRadius: borderRadius.small,
      },
    },
    "&.checked-row": {
      "& td": {
        backgroundColor: colors.blue13,
        borderTop: `1px solid ${colors.blue15}`,
        borderBottom: `1px solid ${colors.blue15}`,
        ":first-child": {
          borderLeft: `1px solid ${colors.blue15}`,
        },
        ":last-child": {
          borderRight: `1px solid ${colors.blue15}`,
          "& div.MuiBox-root": {
            // "marginLeft": "48px",
          }
        },
      },
    },
  },
  "& tbody.edit-mode > tr:not(.editing-row) td": {
    opacity: "unset",
    "& svg": {
      color: colors.blue11,
      "& path": {
        fill: colors.blue11,
      }
    },
    "& div": {
      color: `${colors.grey11} !important`,
    }
  },
  "& [data-pos='sticky']": {
    backgroundColor: colors.white,
    left: 0,
    position: "sticky",
    zIndex:  zIndex.positiveMin,
    "&.vertical-scroll": {
      zIndex: zIndex.zero,
      "&::-webkit-scrollbar": {
        zIndex: zIndex.zero,
      },
    },
  },
  ...(!showActionRow && {
    "& tbody .MuiTableRow-root:first-of-type": {
      "& td": {
        borderTop: defaultStyle.table.border,
      },
      "& td:first-of-type": {
        borderTopLeftRadius: borderRadius.small,
      },
      "& td:last-of-type": {
        borderTopRightRadius: borderRadius.small,
      },
    }
  }),
  "& tr.detailsViewContainer-row": {
    "& td.detailsViewContainer-cell": {
      borderBottom: defaultStyle.table.border,
      borderLeft: defaultStyle.table.border,
      borderRight: defaultStyle.table.border,
      borderTop: "none",
      margin: margin.zero,
      padding: padding.zero,
      width: "100%",
    },
  },
}));

const TableContainerWrapper = styled("div")({
  position: "relative",
});

const StyledTableHead = styled(TableHead)({
  userSelect: "none",
  position: "sticky",
  top: 0, 
  backgroundColor: colors.white,
  zIndex: zIndex.positiveMax,
  "& .header-labels": {
    height: "36px",
    "& th": {
      border: "none",
      paddingBottom: padding.zero,
      paddingLeft: padding.small1,
      paddingRight: padding.zero,
      paddingTop: padding.zero,   
    },
    "&.horizontal-scroll": {
      "&::-webkit-scrollbar": {
        zIndex: zIndex.positiveMin,
      },
    },
  },
});

const StyledTableRow = styled(TableRow)({
  backgroundColor: colors.white,
  borderWidth: `${borderSize.xsmall} ${borderSize.xsmall} 0 ${borderSize.xsmall}`,
  "& .MuiTableCell-root": {
    paddingBottom: padding.small1,
    paddingLeft: padding.medium1,
    paddingRight: padding.medium1,
    paddingTop: padding.small1,
    "& > *": {
      lineHeight: "150%",
    },
    "& .MuiButtonBase-root": {
      padding: padding.zero,
    },
  },
  "&:hover": {
    backgroundColor: colors.grey10,
  },
  "& td:first-of-type": {
    borderLeft: defaultStyle.table.border,
    backgroundColor: colors.white,
  },
  "& td:last-of-type": {
    borderRight: defaultStyle.table.border,
  }, "&.expanded > td": {
    borderBottom: "none",
  },
});

  const FooterContainer = styled(TableFooter)({
  borderWidth: `${borderSize.xsmall} ${borderSize.xsmall} 0 ${borderSize.xsmall}`,
  width: "calc(100% + 2px)", 
  lineHeight: "150%",
  fontSize: "16px",
  paddingLeft: "25px",
  border: defaultStyle.table.border,
  borderTop: "none",
  borderBottomLeftRadius: borderRadius.small,
  borderBottomRightRadius: borderRadius.small,
  display: "block",
  "& .MuiTableCell-root": {
    border: "none",
    fontSize: "16px", 
  },
  
});

export default function StewartTable<T extends IRowIdentifier>({
  cols,
  rows,
  rowsPerPage,
  page,
  noRowsMessage,
  hiddenColumns,
  loadingProgressPercent = undefined,
  order,
  orderBy,
  footerComponent,
  showActionRow = true,
  useDbPagingSorting = false,
  detailsViewConfig,
  selectionConfig,
  onRequestSort,
  onColumnResize,
  onColumnsModified,
}: Props<T>) {
  const [columns, setColumns] = useState<StewartTableColumn<T>[]>([]);
  const [editModeGrid, setEditModeGrid] = useState<boolean>(false);

  const [, { setFileData }] = useStewartTableStore();
  const messages = ["Loading results..."];
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const tableRef = createRef<HTMLTableElement>();

  const handleRequestSort = (property: keyof T) => {
    if (onRequestSort) {
      onRequestSort(property);
    }
  };

  const determineSelectionState = (config :any) => {
    return config?.selectedRows?.length > 0 ? "Select" : "Unselect";
  };

  const handleColumnsModified = (sortedColumns: StewartTableColumn<T>[]) => {
    if (sortedColumns.length === cols.length) {
      setColumns(sortedColumns);
      onColumnsModified?.(sortedColumns);
    }
    else {
      const currentCols = new Set(sortedColumns.map((c) => c.field));
      const difference = cols.filter((c) => !currentCols.has(c.field));
      const acc = [...sortedColumns, ...difference];
      setColumns(acc);
      onColumnsModified?.(acc);
    }
  };

  const handleToggleSelectedAll = () => {
    selectionConfig?.onToggleAllRows?.();
  };

  const handleToggleSelected = (row: T) => {
    if (selectionConfig) {
      selectionConfig?.onToggleRow?.(row);
    }
  };

  const handleToggleShowDetailsAll = () => {
    const updatedRows = expandedRows.length
      ? []
      : rows.map(f => f.uniqueIdentifier);

    setExpandedRows(updatedRows);
  };

  const handleToggleShowDetails = (row: T) => {
    const updatedRows = expandedRows.includes(row.uniqueIdentifier)
      ? expandedRows.filter(i => i !== row.uniqueIdentifier)
      : [...expandedRows, row.uniqueIdentifier];

    setExpandedRows(updatedRows);
  };

  const isRowSelected = (row: T) => {
    return selectionConfig?.isRowSelected?.(row) === true;
  };

  const hasCompanyStatus = (obj: any): obj is { companyStatus: string } =>
  "companyStatus" in obj;

  const renderRowHeader = (row: T) => {
    if (!selectionConfig?.enabled && !detailsViewConfig?.enabled) {
      return null;
    } else {
      const isCheckboxDisabled = hasCompanyStatus(row) && row.companyStatus === "Inactive";

      return (
        <TableCell
          className="row-header-cell"
          key="row-header-cell"
        >
          <div className="row-header-items">
            {detailsViewConfig?.enabled &&
              <LightTooltip
                title={expandedRows.includes(row.uniqueIdentifier) ? "Collapse" : "Expand"}
                arrow
              >
                {expandedRows.includes(row.uniqueIdentifier)
                  ? <ExpandLess onClick={() => handleToggleShowDetails(row)} />
                  : <ExpandMore onClick={() => handleToggleShowDetails(row)} />}
              </LightTooltip>
            }
            {selectionConfig?.enabled &&
              <LightTooltip
                title={isRowSelected(row) ? "Deselect" : "Select"}
                arrow
              >

                <StewartCheckbox
                  {...{ checked: isRowSelected(row) }}
                  onChange={() => handleToggleSelected(row)}
                  disabled={isCheckboxDisabled}
                />

              </LightTooltip>
            }
          </div>
        </TableCell >
      );
    }
  };

  const shouldRenderDetails = (row: T) => {
    return detailsViewConfig?.enabled
      && detailsViewConfig?.renderRowDetails
      && expandedRows.includes(row.uniqueIdentifier);
  };

  const sortedRows = orderBy
    ? stableSort<T>(rows, getComparator(order, orderBy))
    : rows;

  const paginatedRows = useDbPagingSorting ? rows : sortedRows.slice(
    (page - 1) * rowsPerPage,
    (page - 1) * rowsPerPage + rowsPerPage
  );

  const visibleCols =
    hiddenColumns && hiddenColumns.length > 0
      ? columns.filter((c) => !hiddenColumns.includes(c.field))
      : columns;

  const totalColSpan = visibleCols.length
    + (selectionConfig?.enabled || detailsViewConfig?.enabled ? 1 : 0);

  useEffect(() => {
    const updatedColumns = cols.map(column => {
      return {
        ...column,
        hidden: !!hiddenColumns?.includes(column.field),
      };
    });
    setFileData(updatedColumns, rows, orderBy, order);
    const editModeGrid = rows.find((row: T) => row.isEdit);
    setEditModeGrid(!!editModeGrid)
  }, [columns, rows, order, orderBy]);

  useEffect(() => {
    setExpandedRows([]);
  }, [rows]);

  useEffect(() => {
    setColumns(cols);
  }, [cols]);

  if (visibleCols.length === 0) return null;

  return (
    <TableContainerWrapper>
      <TableContainer component={StyledPaper}>
        <StyledTable
          id="stewartTable"
          ref={tableRef}
          key={[showActionRow].toString()} // NOTE: This is to work around a material ui 4 bug (fixed in MUI5) with style not always updating when props change.
          showActionRow={showActionRow}
        >
          <StyledTableHead>
            <StewartTableHeader
              cols={visibleCols}
              paginatedRows={paginatedRows}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onColumnResize={onColumnResize}
              onColumnsModified={handleColumnsModified}
              showActionRow={showActionRow}
              useDetailsView={detailsViewConfig?.enabled}
              detailsViewButtonState={expandedRows.length ? "collapse" : "expand"}
              selectionConfig={selectionConfig}
              onDetailsViewToggle={handleToggleShowDetailsAll}
              onSelectAllToggle={handleToggleSelectedAll}
              tableRef={tableRef}
              selectionState={determineSelectionState(selectionConfig)}
            />
          </StyledTableHead>
          {loadingProgressPercent !== undefined && rows.length === 0 ? (
            <StewartTableBodyEmpty
              messages={messages}
              colSpan={totalColSpan}
            />
          ) : rows.length === 0 ? (
            <StewartTableBodyEmpty
              messages={noRowsMessage}
              colSpan={totalColSpan}
            />
          ) : (
            <>
              <TableBody className={editModeGrid ? "edit-mode" : ""}>
                {loadingProgressPercent &&
                  <CircularProgressContainer>
                    <CircularProgressWithLabel value={loadingProgressPercent} />
                  </CircularProgressContainer>
                }
                {paginatedRows.map((row) => (
                  <>
                    <StyledTableRow
                      key={row.uniqueIdentifier}
                      className={(expandedRows.includes(row.uniqueIdentifier) ? "expanded" : "") + (isRowSelected(row) ? "checked-row" : "")}
                    >
                      {/* {renderExpandCollapse(row)}
                    {renderSelected(row)} */}
                      {renderRowHeader(row)}
                      {visibleCols.map(
                        ({ valueGetter, field, sticky, left, right, classname }) => (
                          <TableCell
                            key={String(field)}
                            data-pos={sticky && "sticky"}
                            style={{ 
                              left: sticky && left ? left : undefined,
                              right: sticky && right !== undefined  ? right : undefined, }}
                            className={classname}                              
                            >
                            {(valueGetter ? valueGetter(row) : row[field]) as ReactNode}
                          </TableCell>
                        )
                      )}
                    </StyledTableRow>
                    {shouldRenderDetails(row) &&
                      <tr className="detailsViewContainer-row">
                        <td
                          colSpan={totalColSpan}
                          className="detailsViewContainer-cell"
                        >
                          {detailsViewConfig?.renderRowDetails?.(row)}
                        </td>
                      </tr>
                    }
                  </>
                ))}
              </TableBody>

            </>
          )}

        </StyledTable>
      </TableContainer>
      {footerComponent && <FooterContainer>{footerComponent}</FooterContainer>}
    </TableContainerWrapper>

  );

}

import ActionButton from "controls/global/action-button";
import React, { 
  useEffect, 
  useState 
} from "react";
import {
  borderRadius,
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import { DialogType } from "utils/custom-hooks/useDialogStyles";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
} from "controls/global/dialogs/stewart-dialog/StewartDialog";
import { 
  IconButton, 
  styled, 
  Typography 
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import AddNoteIcon from "theme/icons/AddNoteIcon";
import TextInputField from "controls/global/text-input-field/TextInputField";
import { 
  FormProvider, 
  useForm 
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getInvalidNotesMessage, isValidNotes } from "pages/utils/pageHelper";

const StyledStewartDialog = styled(StewartDialog)({
  "& .MuiPaper-root": {
    width: "830px !important",
    maxWidth: "830px !important",
    "& .MuiDialogContent-root": {
      padding: "12px 40px 0 40px !important",
    },

    "& .MuiDialogActions-root": {
      justifyContent: "end",
      padding: "0 40px 40px 40px",
      "& button.ActionButton-outlinedPrimary": {
        marginLeft: "16px",
      },
    },
  },
});

const CustomDialogContent = styled(StewartDialogContent)({
  maxHeight: "70vh",
  overflowY: "auto",
  overflowWrap: "break-word",
  marginBottom: margin.xlarge1,

  "& .icon-box": {
    backgroundImage: "linear-gradient(#487FAA, #023450)",
    color: colors.grey08,
    display: "flex",
    fontFamily: fontFamily.primary,
    fontSize: fontSize.xlarge0,
    fontWeight: fontWeight.normal1,
    justifyContent: "center",
    float: "left",
    border: "1px solid transparent",
    borderRadius: "12px",
    "& svg": {
      fontSize: "30px",
      color: "rgb(255, 255, 255)",
      margin: "15px",
    },
  },
  ".message-box": {
    position: "relative",
    left: "24px",
    textAlign: "left",
    fontStyle: "normal",
    lineHeight: "normal",
  },
});

const CloseIconContainer = styled("div")({
  background: colors.grey10,
  borderRadius: borderRadius.xlarge1,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  marginLeft: "auto",
  marginBottom: "auto",
  width: "32px",
  padding: "4px",
  zIndex: 1,
  position: "relative",
  top: "35px",
  "& .MuiSvgIcon-root": {
    color: colors.blue15,
    width: "24px",
    height: "24px",
  },
  "& .iconButton": {
    padding: padding.zero,
    backgroundColor: colors.grey10,
  },
});

const notesValidationSchema = yup.object().shape({
  note: yup.string().test({
    name: "isValidNotes",
    test: function (value, context) {
      let isValid = true;
      if (value) {
        isValid = isValidNotes(value);
      }
      return isValid
        ? true
        : context.createError({ message: getInvalidNotesMessage() });
    },
  }),  
});

const AddNote = styled(Typography)({
  fontSize: fontSize.xlarge2,
  fontWeight: "600",
});

type Props = {
  singleActionButton?: boolean;
  singleActionButtonText?: string;
  isOpen: boolean;
  onYes: any;
  onNo?: any;
  disableNo?: boolean;
  buttonNoText?: string;
  cancelActionButton?: boolean;
  noActionButton?: boolean;
  isNotesSubmitting?: boolean;
  onCancel?: any;
  type?: DialogType;
  width?: number;
};

const AddNoteDialog = ({
  singleActionButton,
  singleActionButtonText,
  isOpen,
  onYes,
  onNo,
  disableNo = false,
  buttonNoText,
  noActionButton = !singleActionButton,
  isNotesSubmitting = false,
  onCancel,
  type = "INFO",
  width = 830,
}: Props) => {
  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: yupResolver(notesValidationSchema),
  });
  const { watch, setError, clearErrors } = methods;

  const [note] = watch([
    "note" as const,
  ]);  

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (note && !isValidNotes(note)) {
      setError("note", {
        type: "manual",
        message: getInvalidNotesMessage(), 
      });
    } else {
      clearErrors("note");
    }
  }, [note]);

  return (
    <StyledStewartDialog open={open} width={width}>
      <CustomDialogContent type={type}>
        <CloseIconContainer>
          <IconButton className="iconButton" onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </CloseIconContainer>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: margin.xlarge1,
          }}
        >
          <Box className="icon-box">
            <AddNoteIcon width={"25px"} height={"25px"} />
          </Box>
          <Box className="message-box">
            <AddNote>Add Note</AddNote>
          </Box>
        </div>

        <FormProvider {...methods}>
          <TextInputField name="note" label="Notes" multiline maxRows={15} />
        </FormProvider>
      </CustomDialogContent>
      <StewartDialogActions>
        <ActionButton
          variant="outlined"
          color="secondary"
          onClick={onNo}
          disabled={disableNo}
        >
          {buttonNoText || "No"}
        </ActionButton>

        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          onClick={() =>
            onYes(note, () => methods.setValue("note", ""))
          }
          disabled={
            !note || (note && !isValidNotes(note)) || isNotesSubmitting
          }
        >
          {singleActionButtonText || "Yes"}
        </ActionButton>
      </StewartDialogActions>
    </StyledStewartDialog>
  );
};

export default AddNoteDialog;

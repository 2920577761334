import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { styled } from "@mui/material";
import React from "react";
import {
  colors,
  fontSize,
} from "theme/defaultStyle";

type Props = {
  onClick?: React.MouseEventHandler;
};

const StyledCloseRoundedIcon = styled(CloseRoundedIcon)({
  color: colors.white,
  cursor: "pointer",
  fontSize: fontSize.xlarge2,
  position: "absolute",
  right: "10px",
  top: "50%",
  transform: "translateY(-50%)"
});

export default function DialogTitleCloseButton({ onClick }: Props) {
  return (
    <StyledCloseRoundedIcon
      aria-label="Close"
      onClick={onClick}
    />
  );
}

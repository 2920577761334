import { 
  // FileUploadStatus, 
  InventoryVerifyErrorDetail, 
  InventoryVerifyStatus, 
  PdfDocument 
} from "entities/UIModel";
import { 
  ErrorCode, 
  FileError, 
  FileRejection
} from "react-dropzone";

const mapInventoryVerifyResponse = (data: any): InventoryVerifyStatus[] => {
  let inventoryVerifyStatusList: InventoryVerifyStatus[] = [];

  const mapVerifyErrorDetails = (verifyErrorDetails: any) => {
      let inventoryVerifyErrorDetailList: InventoryVerifyErrorDetail[] = [];

      inventoryVerifyErrorDetailList = verifyErrorDetails?.map((e: any) => {
        const inventoryVerifyErrorDetail: InventoryVerifyErrorDetail = {
          clientFileID: e.ClientFileID,
          companyID: e.CompanyID, 
          companyName: e.CompanyName,
          formPrefix: e.FormPrefix,
          formType: e.FormType,
          inventoryVerifyDetailID: e.InventoryVerifyDetailID,
          inventoryVerifyErrorDetailTypeCode: e.InventoryVerifyErrorDetailTypeCode,
          inventoryVerifyErrorDetailTypeName: e.InventoryVerifyErrorDetailTypeName,
          inventoryVerifyErrorMessage: e.InventoryVerifyErrorMessage,
          inventoryVerifyID: e.InventoryVerifyID,
          locationLegacyID: e.LocationLegacyID,
          policyNumber: e.PolicyNumber, 
          productType: e.ProductType,
          productTypeAbbr: e.ProductTypeAbbr,
          reportingAgencyID: e.ReportingAgencyID,
          serialNumber: e.SerialNumber,
        }
        return inventoryVerifyErrorDetail;
      });

      return inventoryVerifyErrorDetailList;
  };

  inventoryVerifyStatusList = data?.map((d: any) => {
    const inventoryVerifyStatus: InventoryVerifyStatus = {
      inventoryVerifyID: d.DocumentId,
      documentId: d.DocumentId,
      documentIndex: d.DocumentIndex,
      documentOriginalFileName: d.DocumentOriginalFileName,
      errorMessage: d.ErrorMessage,
      detailedErrorMessage: d.DetailedErrorMessage,
      isUploadSuccess: d.IsUploadSuccess,
      inventoryVerifyErrorDetailList: mapVerifyErrorDetails(d.VerificationErrorDetails),
    }

    return inventoryVerifyStatus;
  });

  return inventoryVerifyStatusList;
}

const mapRejectedFiles = (rejectedFiles: FileRejection[]): InventoryVerifyStatus[] => {
  let inventoryVerifyStatusList: InventoryVerifyStatus[] = [];
  rejectedFiles?.forEach((rejectedFile, i) => {
    inventoryVerifyStatusList.push({
      documentIndex: i,
      documentOriginalFileName: rejectedFile.file.name, 
      errorMessage: getFormattedErrorMessage(rejectedFile.errors),
      isUploadSuccess: false,
    })
  })     

  return inventoryVerifyStatusList;
}

// const mapFileUploaResponse = (data: any) => {
//   let fileUploadStatuses: FileUploadStatus[] = [];
//   fileUploadStatuses = data?.map((d: any) => {
//     const fileUploadStatus: FileUploadStatus = {
//       documentId: d.DocumentId,
//       documentIndex: d.DocumentIndex,
//       documentOriginalFileName: d.DocumentOriginalFileName,
//       errorMessage: d.ErrorMessage,
//       isUploadSuccess: d.IsUploadSuccess,
//     };
//     return fileUploadStatus;
//   });

//   return fileUploadStatuses;
// };

const getFormattedErrorMessage = (errors: FileError[]) => {
  let errorMessage = "Unexpected error";
  errors?.forEach((error) => {
    switch(error.code) {
      case ErrorCode.FileInvalidType:
        errorMessage = "This format is unsupported";
        break;
      default: 
        errorMessage = error.message
        break;
    }
  })

  return errorMessage;
}

const getPdfDocument = (pdfUrl: string, latestDocumentId: number, documentDisplayFileName: string) => {
  const pdfFilename = `${documentDisplayFileName}`;

  let pdfDocument: PdfDocument = {
    fileId: 0,
    productType: "",
    orderId:  0,
    documentId: latestDocumentId,
    pdfUrl: pdfUrl,
    fileName: pdfFilename,
    showHidden: 0,
  };

  return pdfDocument;
};

export {
  // mapFileUploaResponse,
  mapInventoryVerifyResponse,
  mapRejectedFiles,
  getFormattedErrorMessage,
  getPdfDocument
}
import { DownloadFileType } from "./data/enum";
import { SessionStorageKeys } from "./data/enum";

import { parse } from "date-fns";

import isEqual from "lodash/isEqual";

export const REGEX_NONE_ASCII = /[^\x20-\x7E]/g; // Printable ASCII characters reange from \x20 to \x7E
export const REGEX_ALPHA_NUMERIC = /[0-9a-z]/i;
export const REGEX_NONE_NUMERIC = /[^\x30-\x39]/g; //characters which don't fall in [0-9] range

export const INT_MAX = 2147483647; // .NET int.MaxValue value to be used for sentinel value;
export const INT_MIN = -2147483648; // .NET int.MinValue to be used for sentinel value;

export function hasValue(value: any) {
  var result = false;
  if (typeof value != "undefined") {
    if (typeof value == "number") {
      result = true;
    } else if (typeof value == "boolean") {
      result = true;
    } else {
      if (value) {
        result = true;
      }
    }
  }
  return result;
}

export function sleep(miliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, miliseconds));
}

export const formatToLocaleString = (date?: Date) => {
  if (!date) return null;
  const formatDate = new Date(date).toLocaleDateString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return formatDate.split(",").join("");
};

export const formatDate = (date: Date | null) => {
  if (!date) return "";

  if (date) {
    const minDate = new Date('0001-01-01');
    if (date.valueOf() === minDate.valueOf()) {
      return "";
    }

    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).toString();

    const year = date.getFullYear().toString().padStart(4, "0");

    return formattedDate.replace(/\d+$/, year);
  } else {
    return date;
  }
};

export const formatDateTime = (
  date: Date,
  separateLineForTime: boolean = true,
  numericHourFormatOption: boolean = false
) => {
  return date
    ? date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .toString() +
    `${separateLineForTime ? "\n" : " "} ` +
    date.toLocaleTimeString("en-US", {
      hour: numericHourFormatOption ? "numeric" : "2-digit",
      minute: "2-digit",
    })
    : ""; //date;
};

export const formatStringDateTime = (isoString: string): string => {
  const date = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC'
  };
  return date.toLocaleString('en-US', options).replace(',', '');
};

export const formatDateRegEx = (isoDate: string) => {
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/;
  const match = isoDate.match(regex);
  if (match) {
    const [, year, month, day, hours, minutes] = match; // Destructure match array
    const ampm = +hours >= 12 ? 'PM' : 'AM';
    const hrs = +hours % 12 || 12;
    return `${month}/${day}/${year} ${hrs}:${minutes} ${ampm}`;
  }
};

export const formatCurrency = (value: number | string | undefined | null,
  includeSpaceAfterCurrencySymbol = false,
  returnZeroIfNullOrUndefined = true) => {
  let parsedValue = typeof value === "string"
    ? parseFloat(value.replaceAll(",", ""))
    : value as number;

  if (parsedValue === null || parsedValue === undefined) {
    if (returnZeroIfNullOrUndefined) {
      parsedValue = 0;
    }
    else {
      return "";
    }
  }
  else if (isNaN(parsedValue)) {
    return "";
  }

  let formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parsedValue);
  if (includeSpaceAfterCurrencySymbol) {
    formattedValue = formattedValue.replace("$", "$ ");
  }
  return formattedValue;
};

export const formatNumber = (value: number, decimalPoint: number | undefined = 0)  => {
  return (
    new Intl.NumberFormat("en-US", {minimumFractionDigits: decimalPoint}).format(value)
  )
}

export const adjustForTimezone = (date?: Date): Date => {
  if (date && typeof date === "object") {
    let adjustedDate = new Date(date.valueOf());
    var timeOffsetInMS: number = adjustedDate.getTimezoneOffset() * 60000;
    adjustedDate.setTime(adjustedDate.getTime() - timeOffsetInMS);
    return adjustedDate;
  } else {
    return new Date();
  }
};

export const formatDateToJSONString = (
  date: Date | undefined | null,
  removeTimezone: boolean = false
) => {
  return date && typeof date === "object"
    ? `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}T00:00:00.000${removeTimezone ? "" : "Z"}`
    : date;
};

export const dateWithoutTime = (date?: Date) => {
  const newDate = date ? new Date(date.getTime()) : new Date();
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const dateTimeAsFilename = (date?: Date) => {
  const newDate = date ? new Date(date.valueOf()) : new Date();

  const dd = String(newDate.getDate()).padStart(2, "0");
  const MM = String(newDate.getMonth() + 1).padStart(2, "0");
  const yyyy = newDate.getFullYear();

  let hours = newDate.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hh = hours % 12 || 12;
  var mm = String(newDate.getMinutes()).padStart(2, "0");

  return `${MM}-${dd}-${yyyy} ${hh}_${mm}${ampm}`;
};

// given string has Mui-DatePicker format
// i.e: '21/22/021_' => '02/12/2021'
// i.e: '23/02/021_' => undefined (since '02/30/2021' is out of range)
export const addLeadingZerosToDate = (date: string) => {
  const underscoreRegex = /_/g;
  const slashRegex = /\//g;

  if (!underscoreRegex.test(date)) return;

  const d = date
    .replace(underscoreRegex, "")
    .replace(slashRegex, "")
    .padStart(8, "0");

  const fullDate =
    d.substring(0, 2) + "/" + d.substring(2, 4) + "/" + d.substring(4, 8);
  const newDate = parse(fullDate, "MM/dd/yyyy", new Date());

  return !isNaN(newDate.getTime()) ? newDate : undefined;
};

export const addDays = (date: Date, days: number): Date => {
  const dateClone = new Date(date.valueOf());
  return new Date(dateClone.setDate(dateClone.getDate() + days));
};

export const capitalize = (text?: string) => {
  if (!text) return "";
  const toLower = text.toLowerCase();
  return toLower.charAt(0).toUpperCase() + toLower.slice(1);
};

// Return 0 if it's not convertable
export const convertToNumber = (value?: string | number | null | undefined) => {
  if (!value) return 0;

  if (typeof value == "number") {
    return value;
  } else if (typeof value == "string") {
    const parseValue = parseFloat(value.replace(/[$,]/g, ""));
    if (!isNaN(parseValue)) return parseValue;
  }

  return 0;
};

// Return -1 if it's not convertable
export const convertToNumberWithNegative = (value?: string | number | null | undefined) => {
  if (!value) return -1;

  if (typeof value == "number") {
    return value;
  } else if (typeof value == "string") {
    const parseValue = parseFloat(value.replace(/[$,]/g, ""));
    if (!isNaN(parseValue)) return parseValue;
  }

  return -1;
};

export const roundToPrecision = (value: number, precision: number) => {
  return Number(
    Math.round(parseFloat(value + "e" + precision)) + "e-" + precision
  );
};

export const getURIEncodedString = (strToCheck: string) => {
  if (!strToCheck) return strToCheck;
  try {
    return decodeURIComponent(strToCheck) === strToCheck
      ? encodeURIComponent(strToCheck)
      : strToCheck;
  } catch {
    return encodeURIComponent(strToCheck);
  }
};

export const cloneObject = <T>(object: T): T => {
  return JSON.parse(JSON.stringify(object));
};

export const showToolTip = (value: any) => {
  if (Number.isInteger(value)) {
    if (value.toString().length > 9) {
      return true;
    }
  } else {
    if (value.toString().length > 12) {
      return true;
    }
  }
  return false;
};

export const addEllipsisNumber = (value: any) => {
  if (Number.isInteger(value)) {
    if (value.toString().length > 9) {
      return formatCurrency(value).toString().substring(0, 13) + "...";
    } else {
      return formatCurrency(value);
    }
  } else {
    if (value.toString().length > 12) {
      return formatCurrency(value).toString().substring(0, 13) + "...";
    } else {
      return formatCurrency(value);
    }
  }
};

export const isEllipsisShown = (element: HTMLInputElement | null): boolean => {
  let shown = false;
  if (element) {
    // NOTE: There is an underlying HTML issue where we can not always detect
    // when an ellipsis is shown due to clientWidth and scrollWidth values being rounded
    // before being returned to us.
    // https://github.com/w3c/csswg-drafts/issues/4123
    shown = element.scrollWidth > element.clientWidth;
  }
  return shown;
};

// Any session storage that should be cleared when the user clicks reload
// for a server update should be included here.
export const resetSessionStorage = () => {
  sessionStorage.removeItem(SessionStorageKeys.APP_RELOAD_REQUIRED);
  sessionStorage.removeItem(SessionStorageKeys.APP_VERSION);
  sessionStorage.removeItem(SessionStorageKeys.APP_VERSION_PENDING);
};

export const convertSQLDBNullToUndefined = (value: number | undefined | null, nullNumber = -9999999): number | undefined | null => {
  if (value === nullNumber) {
    return undefined;
  }
  return value;
};

export const getBase64DataType = (fileType: string): string => {
  switch (fileType.toLowerCase()) {
    case DownloadFileType.Mp4:
      return "video/mp4";
    case DownloadFileType.Pdf:
      return "application/pdf";
    case DownloadFileType.Xlsx:
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    default:
      return "";
  }
};

export const downloadByBase64 = async (base64: string, fileName?: string): Promise<void> => {
  const anchor = document.createElement("a");
  anchor.href = base64;
  if (fileName) {
    anchor.download = fileName;
  }

  document.body.appendChild(anchor);
  anchor.click();
};

export const isValidDate = (date?: Date | null) =>
  Boolean(date && !isNaN(date.getTime()));

// Operates decimal values
export function round(value: number, precision: number = 1) {
  var multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}

export function ensure<T>(
  argument: T | undefined | null,
  message: string = "This value was promised to be there."
) {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

export function notEqualWithKeys<T>(
  keys: (keyof T)[],
  first: T | undefined,
  second: T,
  logKeyValues: boolean = false
)  {
  let isNotEqual = false;
  keys.forEach((key: keyof T) => {
    isNotEqual =
      isNotEqual || notEqualWithKey(key, first, second, logKeyValues);
  });
  return isNotEqual;
}

export function notEqualWithKey<T>(
  key: keyof T,
  first: T | undefined,
  second: T,
  logKeyValues: boolean
) {
  if (!first) return true;

  const val1 = first[key];
  const val2 = second[key];
  let isNotEqual: boolean = false;
  if (val1 && typeof val1 === "object") {
    if (typeof val2 === "object") isNotEqual = !isEqual(val1, val2);
  } else if (typeof val1 === "string" && typeof val2 === "string")
    isNotEqual = val1.trimEnd().toLowerCase() !== val2.trimEnd().toLowerCase();
  else if (!val1 && !val2) isNotEqual = false;
  else if (typeof val1 === "number" || typeof val2 === "number")
    isNotEqual = !isEqual(
      convertToNumber(val1 as any),
      convertToNumber(val2 as any)
    );
  else isNotEqual = val1 !== val2;

  if (logKeyValues || isNotEqual) {
    // console.log("first:", first, "second:", second);
    logKeyEquality(key, val1, val2, isNotEqual);
  }
  return isNotEqual;
}

export function getCorrelationId(httpResponseHeaders: any) {
  if (httpResponseHeaders && httpResponseHeaders["x-correlation-id"]) {
    return httpResponseHeaders["x-correlation-id"];
  }
  return "";
}

function logKeyEquality<T>(
  key: keyof T,
  initialValue: T[keyof T],
  newValue: T[keyof T],
  notEqual: boolean
) {
  console.log({ key, initialValue, newValue, notEqual });
  console.groupCollapsed(
    `%c${key.toString().toUpperCase()}`,
    `color:${notEqual ? "red" : "green"}`
  );
  console.log(
    `%cINITIAL_VALUE=${initialValue}, VALUE TYPE=${typeof initialValue}`,
    "color:lightblue"
  );
  console.log(
    `%cCURRENT_VALUE=${newValue}, VALUE TYPE=${typeof newValue}`,
    "color:magenta"
  );
  console.groupEnd();
}

export const formatDateString = (dateString: string | null | undefined): string => {
  if (!dateString) {
    return "";
  }
  const datePart = dateString.split('T')[0];
  const [year, month, day] = datePart.split('-');
  return `${month}/${day}/${year}`;
};

export const removeTimezone = (date: string) => {
  const [datePart, timePart] = date.split("T");

  const cleanDateTimeString = `${datePart} ${timePart.split("-")[0]}`;

  return new Date(cleanDateTimeString);
};

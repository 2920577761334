import {
  styled,
  TextField,
} from "@mui/material";
import theme from "theme/default";

const AutocompleteTextField = styled(TextField)({
  "& div.MuiAutocomplete-inputRoot":
  theme.custom.textField.inputOutlined,
  "& div.MuiAutocomplete-inputRoot.Mui-disabled":
  theme.custom.textField.inputOutlinedDisabled,
});

export default AutocompleteTextField;

import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

const SerialNumberLookupIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string } = (
  props: SvgIconProps,
) => {
  const { width = "72", height = "72", color = "#9E233A", ...otherProps } = props;

  return (
    <SvgIcon
      {...otherProps}
      viewBox="0 0 72 72"
      style={{ width, height }}
    >
      <g id="SearchFilled">
        <path
          id="Vector"
          d="M48.8816 43.7358H46.1715L45.211 42.8096C48.5729 38.8988 50.5969 33.8216 50.5969 28.2985C50.5969 15.9828 40.6141 6 28.2985 6C15.9828 6 6 15.9828 6 28.2985C6 40.6141 15.9828 50.5969 28.2985 50.5969C33.8216 50.5969 38.8988 48.5729 42.8096 45.211L43.7358 46.1715V48.8816L60.8885 66L66 60.8885L48.8816 43.7358ZM28.2985 43.7358C19.7564 43.7358 12.8611 36.8405 12.8611 28.2985C12.8611 19.7564 19.7564 12.8611 28.2985 12.8611C36.8405 12.8611 43.7358 19.7564 43.7358 28.2985C43.7358 36.8405 36.8405 43.7358 28.2985 43.7358Z"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
};

SerialNumberLookupIcon.muiName = "SvgIcon";

export default SerialNumberLookupIcon;

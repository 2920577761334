// import SelectField from "controls/global/select-field";
import SelectFieldWithClearIcon from "controls/global/select-field/SelectFieldWithClearIcon";
import React from "react";
import {
  useLookupActions,
  useLookupSerialNumberStatusTypes,
} from "utils/context/LookupContext";

interface Props {
  label: string;
  name: string;
  onChange?: () => void;
}

const SerialNumberStatusField = ({ label, name, onChange }: Props) => {
  const [, { getSerialNumberStatusTypes }] = useLookupActions();
  const [serialNumberStatusTypes] = useLookupSerialNumberStatusTypes();

  React.useEffect(() => {
    if (serialNumberStatusTypes.length === 0) {
      getSerialNumberStatusTypes();
    }
  }, [serialNumberStatusTypes, getSerialNumberStatusTypes]);

  return (
    <SelectFieldWithClearIcon
      label={label}
      name={name}
      options={serialNumberStatusTypes}
      // onChange={onChange}
      
    />
  );
};

export default SerialNumberStatusField;

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  ErrorBoundary,
  FallbackProps,
} from "react-error-boundary";
import { useLocation } from "react-router-dom";
import { SiteErrorType } from "utils/data/enum";
import { SiteErrorMessage } from "./SiteErrorMessage";

type Props = {
  children?: ReactElement<string> | undefined;
};

// In order for the ErrorBoundary to handle errors, they must happen within the render cycle.
// To support catching of errors thrown in event handlers or async code, we need to 
//  first catch all errors 
//  and then rethrow the errors inside the render cycle via a state update.
const ErrorBoundaryContext = ({ children }: Props) => {
  const [, setError] = useState();

  const trapError = useCallback((e: any) => {
    setError(() => { throw e; });
  }, [setError]);

  useEffect(() => {
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      trapError(error);
      return true;
    };
  }, []);

  return <>
    {children}
  </>;
};

const ErrorBoundaryFallback = ({ error }: FallbackProps) => {
  const showActualErrorMessage = false;

  return (
    <SiteErrorMessage
      errorType={SiteErrorType.ErrorBoundary}
      errorMessage={showActualErrorMessage ? error.message : undefined}
    />
  );
};

const GlobalErrorBoundary = ({ children }: Props) => {
  const { pathname } = useLocation();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      resetKeys={[pathname]}
    >
      <ErrorBoundaryContext>
        {children}
      </ErrorBoundaryContext>
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
import React from "react";
import { SvgIcon } from "@mui/material";

const DocumentEditIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M6 2C4.89 2 4 2.89 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H10V20.09L12.09 18H6V16H14.09L16.09 14H6V12H18.09L20 10.09V8L14 2H6ZM13 3.5L18.5 9H13V3.5ZM20.15 13C20.076 12.9984 20.0024 13.0117 19.9337 13.0392C19.8649 13.0667 19.8025 13.1078 19.75 13.16L18.73 14.18L20.82 16.26L21.84 15.25C22.05 15.03 22.05 14.67 21.84 14.46L20.54 13.16C20.4889 13.1087 20.4281 13.0682 20.3611 13.0407C20.2942 13.0132 20.2224 12.9994 20.15 13ZM18.14 14.77L12 20.92V23H14.08L20.23 16.85L18.14 14.77Z"
        fill="#9E233A"
      />
    </SvgIcon>
  );
};

export default DocumentEditIcon;

import { TextField } from "@mui/material";
import { TextFieldProps as MuiTextFieldPropsType } from "@mui/material/TextField/TextField";
import StewartDatePicker from "controls/global/stewart-datepicker";
import { utcToZonedTime } from "date-fns-tz";
import { getTimeZoneOffset } from "pages/utils/pageHelper";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import DatePickerIcon from "theme/icons/DatePickerIcon";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { DateFnsTzUtils } from "./DateFnsTzUtils";
import { MuiPickersTzUtilsProvider } from "./MuiPickersTzUtilsProvider";

interface props {
  name: any;
  label?: string;
  value?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  defaultToToday?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  isShrinkable?: boolean;
  fieldRef?: any;
  setError?: (val: boolean) => void;
  onChangeCallback?: (date: Date | null, shouldDirty?: boolean) => void;
  onFocusCallback?: () => void;
  onBlurCallback?: (date: Date | null) => void;
  size?: "small" | "medium";
  noBorder?: boolean;
}

const DatePicker = ({
  name,
  label,
  value,
  minDate,
  maxDate,
  hasError = false,
  disabled = false,
  defaultToToday = false,
  isShrinkable = true,
  fieldRef,
  onChangeCallback,
  onFocusCallback,
  onBlurCallback,
  size = "medium",
  noBorder,
}: props) => {
  const [shrinkable, setShrinkable] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(null);
  const [timeZoneOffset, setTimeZoneOffset] = useState("+00:00");
  const previousValueRef = useRef<any>();

  const [{ profileSettings }] = useProfileSettingsCache();

  const handleOnChange = (value: any, keyboardInputValue?: string) => {
    setDate(value);
    onChangeCallback && onChangeCallback(value);
  };

  const handleOnAccept = (date: any) => {
    onBlurCallback && onBlurCallback(date);
  };

  const handleOnBlur = () => {
    if (previousValueRef.current !== date) {
      previousValueRef.current = date;
      onBlurCallback && onBlurCallback(date);
    }
    setFocused(false);
  };

  const handleOnFocus = () => {
    setFocused(true);
    setShrinkable(true);
    onFocusCallback && onFocusCallback();
  };

  useEffect(() => {
    setDate(value ?? null);
  }, [hasError, value]);

  useEffect(() => {
    setShrinkable(!!value || focused || Boolean(hasError));
  }, [value, hasError, focused]);

  useEffect(() => {
    if (profileSettings.timeZones.length > 0 && defaultToToday && !value) {
      const offset = getTimeZoneOffset(profileSettings);
      setTimeZoneOffset(offset);

      const zonedTime = utcToZonedTime(new Date(), offset);
      setDate(zonedTime);

      onChangeCallback && onChangeCallback(zonedTime, false);
    }
  }, [value, defaultToToday, profileSettings, onChangeCallback]);

  return (
    <MuiPickersTzUtilsProvider utils={DateFnsTzUtils} timeZone={timeZoneOffset}>
      <StewartDatePicker
        label={label}
        inputFormat="MM/dd/yyyy"
        components={{
          OpenPickerIcon: DatePickerIcon
        }}
        InputProps={{
          disableUnderline: true,
        }}
        inputRef={fieldRef}
        onChange={handleOnChange}
        onAccept={handleOnAccept}
        value={date}
        disabled={disabled}
        noBorder={noBorder}
        minDate={minDate}
        maxDate={maxDate}
        renderInput={(params: MuiTextFieldPropsType) => {
          if (params.inputProps) {
            params.inputProps = {
              ...params.inputProps,
              id: name,
              name: name,
              type: "text",
              className: "date-picker-inline-custom",
              "aria-errormessage": hasError ? "rhfvalidationerror" : "",
            };
          }
          return <TextField
            {...params}
            error={hasError}
            fullWidth
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            helperText={null}
            size={size}
            InputLabelProps={{
              shrink: shrinkable || hasError,
            }}
            placeholder={!value ? "mm/dd/yyyy" : ""}
          />
        }}></StewartDatePicker>
    </MuiPickersTzUtilsProvider>
  );
};

export default DatePicker;

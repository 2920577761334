import { 
  styled, 
  TextField 
} from "@mui/material";
import LightTooltip from "controls/global/light-tooltip";
import React, { 
  useEffect, 
  useRef, 
  useState 
} from "react";
import theme from "theme/default";

interface IProps {
  label?: string | undefined;
  name: any;
  type?: string;
  value?: any;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<any>) => void;
  onBlur?: any;
  className?: any;
  inputProps?: any;
  hasError?: boolean;
  fieldRef?: any;
  multiline?: boolean;
  placeholder?: string;
  rows?: number;
  maxRows?: number;
  size?: "small" | "medium";
}

const CssTextField = styled(TextField)({
  "& input.MuiInputBase-input": theme.custom.textField.inputOutlined,
  "& input.MuiInputBase-input.Mui-disabled":
    theme.custom.textField.inputOutlinedDisabled,
  "& div.MuiOutlinedInput-root.Mui-disabled":
    theme.custom.textField.inputOutlinedDisabled,
});

const TextInput = ({
  label,
  name,
  type,
  value,
  autoFocus = false,
  disabled = false,
  onChange,
  onBlur,
  className,
  inputProps,
  hasError = false,
  fieldRef,
  multiline = false,
  rows,
  maxRows,
  placeholder,
  size = "medium",
}: IProps) => {
  const [shrinkable, setShrinkable] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onMouseEnter = () => {
    handleOpenTooltip(true);
  };
  const onMouseLeave = () => {
    handleOpenTooltip(false);
  };

  const handleOpenTooltip = (isMouseEnter: boolean) => {
    const isInRangeAndOverflowed =
      isMouseEnter &&
      inputRef &&
      inputRef.current &&
      inputRef.current.children &&
      inputRef.current.children.length > 1 &&
      inputRef.current.children[1].children &&
      inputRef.current.children[1].children.length > 0;

    if (isInRangeAndOverflowed) {
      const scrollWidth =
        inputRef?.current?.children[1].children[0].scrollWidth || 0;
      const clientWidth =
        inputRef.current?.children[1].children[0].clientWidth || 0;
      const isScrollPresent = scrollWidth > clientWidth + 2;
      setOpenTooltip(isScrollPresent);
    } else {
      setOpenTooltip(false);
    }
  };

  const handleOnBlur = (event: React.ChangeEvent<any>) => {
    if (!event.target.value && !hasError) setShrinkable(false);
    onBlur && onBlur(event);
  };

  const handleOnChange = (e: any) => {
    if (!e.target.value) setShrinkable(false);
    else setShrinkable(true);
    onChange && onChange(e);
  };

  const handleOnFocus = () => setShrinkable(true);

  useEffect(() => {
    (hasError || value) && setShrinkable(true);
    !hasError && !value && setShrinkable(false);
  }, [hasError, value]);

  return (
    <LightTooltip
      title={value || ""}
      open={openTooltip}
      arrow
      //TODO REVIEW MUI5
      //interactive={false}
      disableFocusListener={true}
    >
      <CssTextField
        {...{ type }}
        id={name}
        name={name}
        label={label}
        autoFocus={autoFocus}
        onChange={handleOnChange}
        disabled={disabled}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        fullWidth
        value={value || ""}
        inputProps={{
          name,
          "aria-errormessage": hasError ? "rhfvalidationerror" : "",
          ...inputProps,
        }}
        InputProps={{ ...inputProps, disableUnderline: true }}
        InputLabelProps={{
          shrink: shrinkable,
        }}
        className={className}
        ref={inputRef}
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
        error={hasError}
        inputRef={fieldRef}
        multiline={multiline}
        placeholder={placeholder}
        rows={rows}
        maxRows={maxRows}
        variant="outlined"
        size={size}
      />
    </LightTooltip>
  );
};

export default TextInput;

import { 
  Box,
  styled,
  Typography
} from "@mui/material";
import React, {
  useEffect,
  useState
} from "react";
import {
  colors,
  fontSize,
  margin
} from "theme/defaultStyle";
import TextInputField from "controls/global/text-input-field/TextInputField";
import { 
  FormProvider, 
  useForm 
} from "react-hook-form";
import DatePickerField from "controls/global/datepicker/DatePickerField";
import AddLink from "controls/global/add-link/AddLink";
import usePolicyStore from "utils/context/InventoryPolicyContext";
import AddNoteDialog from "../dialogs/AddNoteDialog";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import NotesSection from "./notes-section/NotesSection";
import { useLocation } from "react-router-dom";
import PolicyHistory from "./policy-history/PolicyHistory";
import Toast from "controls/global/datepicker-search/Toast";
import EditDetailsDialog from "../dialogs/EditDetailsDialog";
import useModifyStore from "utils/context/InventoryModifyContext";
import { 
  ActionResultStatus, 
  FlashMessageType, 
  PERMISSIONS, 
  UIConstants
} from "utils/data/enum";
import { useFlashMessageActions } from "utils/context/FlashMessageContext";
import FlashMessage from "controls/global/flash-message/FlashMessage";
import CollapsableFormSection from "controls/global/collapsable-form-section/CollapsableFormSection";
import ActionButton from "controls/global/action-button/ActionButton";
import { checkIfTransferEditAllowed, getDate } from "../utils";
import useFlashMessage from "utils/custom-hooks/useFlashMessage";
import { hasValue } from "utils/shared";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";

const ContainerBox = styled(Box)({});

const EDIT_SUCCESS_MESSAGE = "Details successfully updated!";
const EDIT_FAILED_MESSAGE = "Failed to update details.";

const Spacer = styled("div")({
  marginBottom: margin.xlarge1,
});

const NotesDetails = styled(Typography)({
  fontSize: fontSize.large,
  color: colors.grey22,
  marginBottom: margin.xlarge1,
});

const HistoryDetails = styled(Typography)({
  fontSize: fontSize.large,
  color: colors.grey22,
});

const FormSection = styled("div")({
  display: "flex",
  gap: "24px",
  marginBottom: margin.xlarge1,
});

const LargeFlexInputField = styled(TextInputField)({
  flex: 3.2,
});

const FlexInputField = styled(TextInputField)({
  flex: 1,
});

const ToastContainer = styled("div")({
  bottom: 0,
  display: "flex",
  justifyContent: "flex-end",
  position: "fixed",
  right: "8px",
  zIndex: "999",
});

function DetailsSection() {
  const methods = useForm();
  const location = useLocation();
  const { redirect } = useNavRedirect();
  const id:string = location.state?.id || "";
  const [policyDetails, setPolicyDetails] = useState<any>({});
  const [isAddNoteDialogOpen, setIsAddNoteDialogOpen] = useState(false);
  const [isEditDetailsDialogOpen, setIsEditDetailsDialogOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [{ isSaving }, { saveModify }] = useModifyStore();

  const [{ profileSettings }] = useProfileSettingsCache();
  const [ { isNotesSubmitting }, { getPolicyDetails, addNote, }] =
    usePolicyStore();
  const [, { showFlashMessage }] = useFlashMessageActions();  
  useFlashMessage();
  const [editAllowed, setEditAllowed] = useState(false);

  const { reset } = methods;

  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  const hasEditModifyPermission = permissionCodes.includes(PERMISSIONS.INV_EDIT_MODIFY);
  const hasAddNotePermission = permissionCodes.includes(PERMISSIONS.INV_LOOKUP_NOTE_ADD);

  const handleAddNoteDialogOpen = () => {
    setIsAddNoteDialogOpen(true);
  };

  const handleAddNoteDialogClose = () => {
    setIsAddNoteDialogOpen(false);
  };

  const handleEditDetailsDialogOpen = (event?: React.MouseEvent<HTMLElement> | undefined) => {
    event?.stopPropagation()
    setIsEditDetailsDialogOpen(true);
  };

  const handleCloseEditDetailsDialog = () => {
    setIsEditDetailsDialogOpen(false);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const addNewNote = async (note: string, setNoteEmpty: any) => {
    const response = await addNote({
      NotesDescription: note,
      SerialNumberDetailID: id,
    });

    if (response?.status === 200) {
      const data = await getPolicyDetails({
        serialNumberDetailID: id,
      });

      setPolicyDetails(data);
    }
    setIsAddNoteDialogOpen(false);
    setNoteEmpty();
  };

  const editPolicyDetails = async (newPolicyStatus: any, notes = "") => {
    const payload = {
      InventoryModifyTypeCode: "EDIT",
      InventoryModifyDetails: [
        {
          FormPrefix: policyDetails.formPrefix,
          FormType: policyDetails.formType,
          ProductType: policyDetails.productType,
          ProductTypeAbbr: policyDetails.productTypeAbbr,
          BeginningSerialNumber: policyDetails.serialNumber,
          EndingSerialNumber: policyDetails.serialNumber,
          OrigCompanyID: policyDetails.companyID,
          OrigCompanyLegacyID: policyDetails.companyLegacyID,
          OrigCompanyName: policyDetails.companyName,
          OrigLocationDisplayName: policyDetails.locationDisplayName,
          OrigLocationLegacyID: policyDetails.locationLegacyID,
          OrigLocationStateAbbr: policyDetails.locationStateAbbr,
          OrigLocationStateCode: policyDetails.locationStateCode,
          OrigLocationUniqueID: policyDetails.locationUniqueID,
        },
      ],
      OrigLocationUniqueID: policyDetails.locationUniqueID,
      NewLocationUniqueID: policyDetails.locationUniqueID,
      OldSerialNumberStatusTypeCode: policyDetails.serialNumberStatusTypeCode,
      NewSerialNumberStatusTypeCode: newPolicyStatus,
      Notes: notes,
    };

    const result = await saveModify(payload);
    if(result.status === ActionResultStatus.Success){
      const data = await getPolicyDetails({
        serialNumberDetailID: id,
      });

      setPolicyDetails(data);

      reset({
        policyType: `${data?.productTypeAbbr}-${data?.formType}`,
        prefix: data?.formPrefix,
        serialNumber: data?.serialNumber,
        fileNumber: data?.clientFileID,
        agencyLocation: data?.locationDisplayName,
        state: data?.locationStateAbbr,
        legacyIdLocation: data?.locationLegacyID,
        legacyIdCompany: data?.companyLegacyID,
        status: data?.serialNumberStatusTypeName,
        ticketNumber: data?.ticketNumber,
        assignedDate: data?.assignedDate && getDate(data?.assignedDate),
        verifiedDate: data?.verifiedDate && getDate(data?.verifiedDate),
        modifiedDate: data?.modifyDate && getDate(data?.modifyDate),
        effectiveDate: data?.effectiveDate && getDate(data?.effectiveDate),
        issueDate: data?.issueDate && getDate(data?.issueDate),
        voidedDate: data?.voidedDate && getDate(data?.voidedDate),
      });
      handleCloseEditDetailsDialog();

      showFlashMessage(FlashMessageType.Success, EDIT_SUCCESS_MESSAGE, "");
    } else {
      showFlashMessage(FlashMessageType.Error, EDIT_FAILED_MESSAGE, "");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPolicyDetails({
        serialNumberDetailID: id,
      });
      setPolicyDetails(data);
      setEditAllowed(checkIfTransferEditAllowed(data?.serialNumberStatusTypeCode));

      reset({
        policyType: `${data?.productTypeAbbr}-${data?.formType}`,
        prefix: data?.formPrefix,
        serialNumber: data?.serialNumber,
        fileNumber: data?.clientFileID,
        agencyLocation: data?.locationDisplayName,
        state: data?.locationStateAbbr,
        legacyIdLocation: data?.locationLegacyID,
        legacyIdCompany: data?.companyLegacyID,
        status: data?.serialNumberStatusTypeName,
        ticketNumber: data?.ticketNumber,
        assignedDate: data?.assignedDate && getDate(data?.assignedDate),
        verifiedDate: data?.verifiedDate && getDate(data?.verifiedDate),
        modifiedDate: data?.modifyDate && getDate(data?.modifyDate),
        effectiveDate: data?.effectiveDate && getDate(data?.effectiveDate),
        issueDate: data?.issueDate && getDate(data?.issueDate),
        voidedDate: data?.voidedDate && getDate(data?.voidedDate),
      });
    };

    if (hasValue(id)) {
      fetchData();
    }
    else {
      const path = location?.pathname?.toLocaleLowerCase().split("/");
      if (path?.includes("policy-details")) {
        redirect("/404", null);
      }
    }
  }, [id]);

  useEffect(() => {
    if (showToast) {
      const toastTimer = setTimeout(() => {
        setShowToast(false);
      }, 1500);
      return () => {
        clearTimeout(toastTimer);
      };
    }
  }, [showToast]);

  const EditButton = (
    <ActionButton
      variant="outlined"
      color="primary"
      disabled={!hasEditModifyPermission || !editAllowed }
      onClick={handleEditDetailsDialogOpen}
    >
      Edit
    </ActionButton>
  );

  return (
    <ContainerBox>
      <CollapsableFormSection
        title="Details"
        ActionComponent={EditButton}
        // onActionClick={handleEditDetailsDialogOpen}
      >
        <FormProvider {...methods}>
          <FormSection>
            <TextInputField name={"policyType"} label="Policy Type" disabled />
            <TextInputField name={"prefix"} label="Prefix" disabled />
            <TextInputField
              name={"serialNumber"}
              label="Serial Number"
              disabled
            />
            <TextInputField
              name={"fileNumber"}
              label="File Name/Number"
              disabled
            />
          </FormSection>
          <FormSection>
            <LargeFlexInputField
              name="agencyLocation"
              label="Agency Location"
              disabled
            />
            <FlexInputField name="state" label="State" disabled />
            <FlexInputField
              name="legacyIdLocation"
              label="Legacy ID (Location)"
              disabled
            />
            <FlexInputField
              name="legacyIdCompany"
              label="Legacy ID (Company)"
              disabled
            />
          </FormSection>
          <FormSection>
            <TextInputField name="status" label="Status" disabled />
            <TextInputField
              name="ticketNumber"
              label="Ticket Number"
              disabled
            />
            <DatePickerField
              name="assignedDate"
              label="Assigned Date"
              disabled
            />
            <DatePickerField
              name="verifiedDate"
              label="Verified Date"
              disabled
            />
          </FormSection>
          <FormSection>
            <DatePickerField
              name="modifiedDate"
              label="Modified Date"
              disabled
            />
            <DatePickerField
              name="effectiveDate"
              label="Effective Date"
              disabled
            />
            <DatePickerField
              name="issueDate"
              label="Issue Date"
              disabled
            />
            <DatePickerField
              name="voidedDate"
              label="Voided Date"
              disabled
            />
          </FormSection>
        </FormProvider>
      </CollapsableFormSection>
      <Spacer />
      <CollapsableFormSection title="Notes">
        <div>
          {policyDetails?.serialNumberNotes &&
          policyDetails?.serialNumberNotes.length > 0 ? (
            <>
              <NotesSection notes={policyDetails?.serialNumberNotes} />
              <Spacer />
            </>
          ) : (
            <NotesDetails variant="body1">
              There are currently no notes on this policy. Click the Add Note
              button below to add notes.
            </NotesDetails>
          )}
          <AddLink onClick={handleAddNoteDialogOpen} disabled={!hasAddNotePermission}>
            Add Note
          </AddLink>
        </div>
      </CollapsableFormSection>
      <Spacer />
      <CollapsableFormSection title="History" showDivider={false}>
        <div>
          {policyDetails?.serialNumberActivities &&
          policyDetails?.serialNumberActivities.length > 0 ? (
            <PolicyHistory activities={policyDetails?.serialNumberActivities} />
          ) : (
            <HistoryDetails variant="body1">
              There are currently no activities on this policy.
            </HistoryDetails>
          )}
        </div>
      </CollapsableFormSection>
      {isAddNoteDialogOpen && (
        <AddNoteDialog
          isOpen={isAddNoteDialogOpen}
          buttonNoText="Cancel"
          onCancel={handleAddNoteDialogClose}
          onNo={handleAddNoteDialogClose}
          onYes={addNewNote}
          singleActionButton={true}
          isNotesSubmitting={isNotesSubmitting}
          singleActionButtonText="Add"
        />
      )}
      {isEditDetailsDialogOpen && (
        <EditDetailsDialog
          isOpen={isEditDetailsDialogOpen}
          onCancel={handleCloseEditDetailsDialog}
          onNo={handleCloseEditDetailsDialog}
          onYes={editPolicyDetails}
          currentStatus={policyDetails?.serialNumberStatusTypeCode}
          isSaving={isSaving}
          singleActionButtonText="Update"
          buttonNoText="Cancel"
        />
      )}
      {showToast && (
        <ToastContainer>
          <Toast
            title="Note successfully added!"
            type="success"
            onClose={handleCloseToast}
          />
        </ToastContainer>
      )}
      <FlashMessage
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={Number(
          UIConstants.FLASH_MESSAGE_AUTO_HIDDEN_DURATION
        )}
      />
    </ContainerBox>
  );
}

export default DetailsSection;

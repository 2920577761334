import {
  Autocomplete,
  Box,
  Paper,
  // Popper,
} from "@mui/material";
import LightTooltip from "controls/global/light-tooltip";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  boxShadows,
  defaultStyle,
} from "theme/defaultStyle";
import ComboBoxTextField from "./ComboBoxTextField";
import OverflowTooltip from "../overflow-tooltip/OverflowTooltip";

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  options: SelectFieldOption[];
  value?: any;
  hasError?: boolean;
  freeSolo?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
  onBlur?: (_: React.ChangeEvent<{}>) => void;
  onChange?: (_: React.ChangeEvent<{}>, value: any) => void;
  roundedCorner?: boolean;
  size?: "small" | "medium";
  noBorder?: boolean;
  controlTooltipOnMouseEvents?: boolean; // This property is to address autocomplete reset behavior on mouse leave with Agency Control on CreateFile
  paperStyle?: any;
  toolTipTitle?: string;
  showTooltip?: boolean;
}

const ComboBoxInput = ({
  label,
  name,
  placeholder,
  options = [],
  value,
  onBlur,
  onChange,
  roundedCorner,
  hasError = false,
  freeSolo = false,
  disableClearable = true,
  disabled = false,
  noBorder = false,
  size = "medium",
  controlTooltipOnMouseEvents = false,
  toolTipTitle,
  paperStyle,
  showTooltip,
}: Props) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const inputElementRef = useRef<HTMLInputElement | null>(null);
  const [shrinkable, setShrinkable] = useState(false);
  const [popperOpen, setPopperOpen] = useState(false);

  const { control } = defaultStyle;

  const handleOnBlur = (e: React.ChangeEvent<any>) => {
    const newValue = e.target.value;
    setShrinkable(newValue || hasError);
    if (!freeSolo) return;

    onBlur?.(e);
  };

  const handleOnChange = (event: React.ChangeEvent<{}>, value: unknown) => {
    if (value instanceof Object) {
      setShrinkable(true);
      onChange?.(event, value);
    } else if (typeof value === "string") {
      setShrinkable(true);
      onChange?.(event, {
        value: value,
        text: value,
      });
    } else {
      setShrinkable(false);
      onChange?.(event, { text: "", value: "" });
    }
  };

  const getOptionSelected = (option: any, value: any) => {
    // if (typeof value === "string" && !value) return false;
    // if (value instanceof Object && 
    //   (!value?.value || !value?.id)) 
    // return false; 
    if (!value) return false;
    setShrinkable(true);
    return option?.value === value?.value;
  };

  const handleOpenTooltip = (open: boolean, ignoreElementDimensions: boolean = false) => {
    const compare =
      inputElementRef &&
      inputElementRef.current &&
      inputElementRef?.current?.scrollWidth >
      inputElementRef?.current?.offsetWidth+2;
    if (ignoreElementDimensions) {
      setOpenTooltip(false);
    }
    else {
      setOpenTooltip((open && compare) || false);
    }
  };

  let selectedOption = {
    text: value,
    value,
  };

  if (options && options?.length > 0) {
    const optionFound = options?.find((option) => option?.value === value);
    if (optionFound) selectedOption = optionFound;
  }

  useEffect(() => {
    if (value instanceof Object) {
      setShrinkable(value?.value || value?.id);
    } else if (typeof value === "string") {
      if (value) {
        setShrinkable(Boolean(value));
      }
      else {
        setTimeout(() => {
          setShrinkable(false);
        }, 10);
      }
    } else {
      setTimeout(() => {
        setShrinkable(false);
      }, 10);
    }
  }, [name, value]);

  useEffect(() => {
    if (disabled && popperOpen) setPopperOpen(false);
  }, [disabled, popperOpen]);

  return (
    <LightTooltip
      title={selectedOption.text || ""}
      open={openTooltip}
      arrow
    >
      <Box overflow="visible" sx={{ width: "calc(100%)+2px" }}>
        <Autocomplete
          disablePortal
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onFocus={() => {
            setShrinkable(true);
            setPopperOpen(true && !disabled);
          }}
          value={{
            text: selectedOption?.text || "",
            value: selectedOption?.value || "",
          }}
          noOptionsText="NO DATA FOUND"
          getOptionLabel={(option: any) => option?.text}
          isOptionEqualToValue={getOptionSelected}
          // TODO REVIEW MUI5
          //getOptionSelected={getOptionSelected}
          onMouseEnter={() => {
            handleOpenTooltip(true, controlTooltipOnMouseEvents);
          }}
          onMouseLeave={() => handleOpenTooltip(false, controlTooltipOnMouseEvents)}
          onInputChange={() => {
            setShrinkable(true);
          }}
          openOnFocus
          forcePopupIcon
          size={size}
          disabled={disabled}
          {...{ options, freeSolo, disableClearable }}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option?.value}
            >
              <OverflowTooltip
                tooltip={option?.text}
                text={option?.text}
              />
            </li>
          )}
          renderInput={(params: any) => (
            <ComboBoxTextField
              error={hasError}
              noBorder={noBorder}
              {...{ label, name, placeholder, roundedCorner, value, toolTipTitle, showTooltip, disabled }}
              rest={{
                ...params,
                inputProps: {
                  ...params.inputProps,
                  "aria-errormessage": hasError ? "rhfvalidationerror" : "",
                },
                InputProps: { ...params.InputProps, disableUnderline: true },
                InputLabelProps: {
                  shrink: Boolean(shrinkable || !!value || hasError),
                },
                inputRef: inputElementRef,
              }}
            />
          )}
          PaperComponent={(props: any) => (
            <Paper
              {...props}
              style={{
                padding: 0,
                margin: 0,
                borderRadius: `0 0 ${control.borderRadius} ${control.borderRadius}`,
                boxShadow: `${boxShadows.boxShadow03}`,
              }}
              sx={paperStyle}
            />
          )}
          // PopperComponent={({ open, ...props }: any) => (
          //  <Popper {...props} open={popperOpen} />
          // )}
        />
      </Box>
    </LightTooltip>
  );
};

export default ComboBoxInput;

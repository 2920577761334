import { maxBy } from "lodash";
import { InventoryAssignFormPrefixItem } from "entities/UIModel/InventoryAssignFormPrefixItem";
import { CompanyLocation } from "entities/UIModel/CompanyLocation";
import * as UIModel from "entities/UIModel";
import { 
  notEqualWithKeys 
} from "utils/shared";

export function getNextFormPrefixItem(currentFormPrefixItems: InventoryAssignFormPrefixItem[]) {
  const lastRowId =  maxBy(currentFormPrefixItems, "rowId")?.rowId ?? 0;
  const nextFormPrefix = undefined;
  const nextFormPrefixDescription = undefined;
  const formPrefixDisabled = currentFormPrefixItems?.length > 0;
  const nextFormPrefixItem: InventoryAssignFormPrefixItem = {
    rowId: lastRowId + 1,
    formPrefix: nextFormPrefix,
    formPrefixDisabled: formPrefixDisabled,
    formPrefixDescription: nextFormPrefixDescription,
    formPrefixOptions: undefined,
    productTypeAbbr: undefined,
    quantity: undefined,
    productType: undefined,
    formType: undefined,
  };
  return nextFormPrefixItem;
}

export function checkIfInventoryAssignFormIsDirty(
  initialValues: UIModel.InventoryAssignForm,
  newValues: UIModel.InventoryAssignForm
) {
    let isDirty = false;
    let checkFileFields: (keyof UIModel.InventoryAssignForm)[];
      checkFileFields = [
        "locationUniqueID", 
        "notes"
      ];
      isDirty = notEqualWithKeys(checkFileFields, initialValues, newValues, true);
      
      if (!isDirty) {
        if (
          newValues?.formPrefixItems!.length > 1 ||
          initialValues?.formPrefixItems!.length !== newValues?.formPrefixItems!.length
        ) {
          isDirty = true;
        } else {
          const initialFormPrefixItem = initialValues?.formPrefixItems[0];
          const newFormPrefixItem = newValues?.formPrefixItems[0];
          isDirty = notEqualWithKeys(
            ["formPrefix", "quantity"],
            initialFormPrefixItem,
            newFormPrefixItem
          );
        }
      }
    return isDirty;
  }


export function isCompanyLocationType(selectionOption: CompanyLocation | string)  {
  const hasLocationUniqueIDProperty = (selectionOption as CompanyLocation)?.locationUniqueID !== undefined;
  return hasLocationUniqueIDProperty;
}

import {
  Icon,
} from "@mui/material";
import { styled } from "@mui/material";
import React, {
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  colors,
  fontSize,
  gaps,
  gradients,
  padding,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import CloseIcon from "@mui/icons-material/Close";
import {
  DOMEventType,
  SessionStorageKeys,
} from "utils/data/enum";

type BannerProps = {
  onNavigate: (url: string) => void;
};

type MessageProps = {
  children: ReactNode;
};

const RELOAD_REQUIRED_MESSAGE =
  "This application has been updated and needs to be refreshed. Please save your work and then ";
const RELOAD_ACTION_MESSAGE = "click here to reload";

const Banner = styled("div")({
  alignItems: "flex-start",
  color: colors.white,
  display: "flex",
  flexDirection: "column",
  fontSize: `${fontSize.large}`,
  justifyContent: "space-between",
  "& .message-container": {
    display: "inline-flex",
    flexDirection: "row",
    gap: gaps.small1,
    "& .icon-container": {
      display: "flex",
      alignItems: "center",
      "& .MuiIcon-root": {
        fontSize: fontSize.xlarge0,
      },
    },
    "& .message": {
      textAlign: "left",
      marginLeft: "30px",
      display: "block",
      "& .action": {
        color: colors.blue05,
        cursor: "pointer",
      },
    },
  },
});

const StyledMessageContainer = styled("div")({
  alignItems: "center",
  backgroundImage: gradients.yellowGradient01,
  borderRadius: `0px 0px ${borderRadius.medium} ${borderRadius.medium}`,
  color: colors.white,
  display: "flex",
  fontSize: `${fontSize.large}`,
  fontWeight: "bold",
  gap: gaps.small1,
  justifyContent: "space-between",
  marginTop: `-${borderRadius.medium}`,
  padding: padding.medium1,
  paddingTop: padding.large2,
});

const CloseIconContainer = styled("div")({
  paddingTop: "0px",
  marginTop: "0px",
  padding: padding.medium1,
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem",
  },
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  cursor: "pointer",
});

const Message = ({ children }: MessageProps) => {
  return (
    <div className="message-container">
      <div className="icon-container">
        <Icon className="fa fa-exclamation-triangle" />
      </div>
      <div className="message">{children}</div>
    </div>
  );
};

const MessageBanner = ({ onNavigate }: BannerProps) => {
  const {
    featureConfig: { inMaintenanceMode, maintenanceMessage },
  } = useConfigContext();
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [reloadRequired, setReloadRequired] = useState(false);

  const handleReload = () => {
    onNavigate("reload");
  };

  useEffect(() => {
    const checkReloadRequired = () => {
      const required = sessionStorage.getItem(SessionStorageKeys.APP_RELOAD_REQUIRED) === "true";
      setReloadRequired(required);
    };

    checkReloadRequired();
    window.addEventListener(DOMEventType.APP_RELOAD_REQUIRED, checkReloadRequired);

    return () => window.removeEventListener(DOMEventType.APP_RELOAD_REQUIRED, checkReloadRequired);
  }, []);

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  useEffect(() => {
    setIsBannerVisible(true);
  }, []);

  return (
    <>
      {isBannerVisible && (inMaintenanceMode || reloadRequired) && (
        <StyledMessageContainer>
          <Banner>
            {inMaintenanceMode && <Message>{maintenanceMessage}</Message>}
            {reloadRequired && (
              <Message>
                <span>{RELOAD_REQUIRED_MESSAGE}</span>
                <span
                  className="action"
                  onClick={handleReload}
                >
                  {RELOAD_ACTION_MESSAGE}
                </span>
              </Message>
            )}
          </Banner>
          <CloseIconContainer onClick={handleCloseBanner}>
            <CloseIcon />
          </CloseIconContainer>
        </StyledMessageContainer>
      )}
    </>
  );
};

export default MessageBanner;

import { styled } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  colors,
  margin,
  padding,
} from "theme/defaultStyle";

declare module "@mui/x-date-pickers/DesktopDatePicker" {
  // @ts-ignore
  interface DesktopDatePickerProps {
    noBorder?: boolean;
  }
}

const StewartDatePicker = styled(DesktopDatePicker)((props) => {
  let noBorderProps = {};
  if (props.noBorder) {
    noBorderProps = {
      borderColor: "transparent",
    };
  }
  return {
    "& .MuiOutlinedInput-root": {
      backgroundColor: colors.white,
      // height: "56px",
      paddingRight: padding.zero,
      ...noBorderProps,
      "&.Mui-disabled": {
        backgroundColor: colors.grey10,
      },
      "&.Mui-error": {
        "& .MuiIconButton-root": {
          color: colors.red01,
        },
      },
      "& .MuiIconButton-root": {
        color: colors.blue15,
        left: "-13px",
        marginRight: margin.zero,
        padding: padding.zero,
        "&.Mui-disabled": {
          color: colors.blue04,
        },
      },
    },
  };
});

export default StewartDatePicker;

import React, { 
  useState 
} from "react";

import { 
  Box, 
  styled, 
  Typography 
} from "@mui/material";
import ActionButton from "controls/global/action-button/ActionButton";
import {
  borderRadius,
  boxShadows,
  colors,
  fontSize,
  fontWeight,
  gaps,
  padding,
} from "theme/defaultStyle";
import DetailsSection from "./details-section/DetailsSection";
import DocumentEditIcon from "theme/icons/DocumentEditIcon";
import { usePolicyDetails } from "utils/context/InventoryPolicyContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import SerialNumberTransferDialog from "./dialogs/SerialNumberTransferDialog";
import { checkIfTransferEditAllowed } from "./utils";

const ContainerBox = styled(Box)({
  position: "relative",
  margin: "24px 0 0 0",
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const SectionsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large3,
  maxWidth: "100%",
  background: "#fff",
  padding: padding.xlarge1,
  borderRadius: borderRadius.xlarge,
  boxShadow: boxShadows.boxShadow07,
});

const BreadcrumbContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const Breadcrumb = styled("div")({
  display: "flex",
  gap: gaps.xsmall2,
});

const BreadcrumbPathText = styled(Typography)({
  color: colors.blue17,
  fontSize: fontSize.large,
});

const BreadcrumbPolicyNumber = styled(Typography)({
  color: colors.blue15,
  fontSize: fontSize.large,
});

const PolicyHeadingContainer = styled("div")({
  display: "flex",
  gap: gaps.small1,
  color: colors.maroon02,
  alignItems: "center",
});

const PolicyHeading = styled(Typography)({
  fontSize: fontSize.xlarge1,
  fontWeight: fontWeight.bold2,
});

function PolicyDetails() {
  const [policyTransferDialogOpen, setPolicyTransferDialogOpen] = useState<boolean>(false);
  const [{ profileSettings }] = useProfileSettingsCache();
  const [policyDetails] = usePolicyDetails();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];
  const hasTransferModifyPermission = permissionCodes.includes(
    "INV_TRANSFER_MODIFY"
  );

  const handleClickOnTransfer = () => {
    setPolicyTransferDialogOpen(true);
  }
  const closeTransferDialog = () => {
    setPolicyTransferDialogOpen(false);
  }

  return (
    <ContainerBox>
      <BreadcrumbContainer>
        <Breadcrumb>
          <BreadcrumbPathText>Policy Lookup / </BreadcrumbPathText>
          <BreadcrumbPolicyNumber>
            Policy Details (
            {`${policyDetails?.productTypeAbbr}-${policyDetails?.formPrefix}-${policyDetails?.serialNumber}`}
            )
          </BreadcrumbPolicyNumber>
        </Breadcrumb>

        <ActionButton
          variant="outlined"
          color="primary"
          onClick={handleClickOnTransfer}
          disabled={(!hasTransferModifyPermission) || !checkIfTransferEditAllowed(policyDetails?.serialNumberStatusTypeCode)  }
        >
          Transfer
        </ActionButton>
      </BreadcrumbContainer>

      <PolicyHeadingContainer>
        <DocumentEditIcon width="24" height="24" color={colors.maroon02} />
        <PolicyHeading>
          Policy Details (
          {`${policyDetails?.productTypeAbbr}-${policyDetails?.formPrefix}-${policyDetails?.serialNumber}`}
          )
        </PolicyHeading>
      </PolicyHeadingContainer>
      <SectionsContainer>
        <DetailsSection />
      </SectionsContainer>
      <SerialNumberTransferDialog 
          isOpen={policyTransferDialogOpen}
          onClose={closeTransferDialog}
          policyData={policyDetails}
        />
    </ContainerBox>
  );
}

export default PolicyDetails;

import {
  SvgIcon,
  SvgIconProps,
} from "@mui/material";
import React from "react";

const SortIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="8 8 8 8.6" {...props}>
      <path d="m11.3 14.3l-2.6-2.6q-.475-.475-.212-1.087Q8.75 10 9.425 10h5.15q.675 0 .937.613q.263.612-.212 1.087l-2.6 2.6q-.15.15-.325.225q-.175.075-.375.075t-.375-.075q-.175-.075-.325-.225Z" />
    </SvgIcon>
  );
};

export default SortIcon;

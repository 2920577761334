import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import { FlashMessageType } from "utils/data/enum";

type State = {
  show: boolean;
  flashMessageType: FlashMessageType;
  messageHeader: string;
  messageDetail: string;
  onClose?: () => void;
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  showFlashMessage:
    (flashMessageType: FlashMessageType, messageHeader: string, messageDetail: string) =>
    ({ setState }: StoreApi) => {
      setState({
        show: true,
        flashMessageType: flashMessageType,
        messageHeader: messageHeader,
        messageDetail: messageDetail,
      });
    },
  closeFlashMessage:
    (onCloseFn?: () => void) =>
    ({ setState }: StoreApi) => {
      setState({ show: false, onClose: onCloseFn });
    },
};

const Store = createStore<State, Actions>({
  initialState: {
    show: false,
    flashMessageType: FlashMessageType.Success,
    messageHeader: "",
    messageDetail: "",
  },
  actions,
  name: "flashMessage"

});

const getFlashMessageStatus = (state: State) => ({
  show: state.show,
  flashMessageType: state.flashMessageType,
  messageHeader: state.messageHeader,
  messageDetail: state.messageDetail,
});

const useFlashMessageState = createHook(Store, {
  selector: getFlashMessageStatus,
});

const useFlashMessageActions = createHook(Store, {
  selector: null,
});

export { useFlashMessageActions, useFlashMessageState };

import { 
  styled, 
  Typography 
} from "@mui/material";
import Box from "@mui/material/Box";
import LightTooltip from "controls/global/light-tooltip";
import React, { 
  ReactNode, 
  useRef, 
  useState 
} from "react";
import { 
  colors, 
  fontSize, 
  margin
} from "theme/defaultStyle";
import { isEllipsisShown } from "utils/shared";

interface Props {
  title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
  className?: string | undefined;
  sticky?: boolean;
  alwaysShowOnHover?: boolean;
  handleViewNoteOpen?: any;
}
interface TextProps {
  children: ReactNode;
}

interface TextWithViewNoteProps {
  title: any;
}

const TooltipText = styled(Typography)({
  fontSize: fontSize.small,
  color: colors.white,
  margin: margin.xsmall,
});

const ViewNoteText = styled(TooltipText)({
  textDecorationLine: "underline",
  cursor: "pointer",
});

const TextContainer = styled("div")({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const Text = React.forwardRef(
  ({ children }: TextProps, ref: React.LegacyRef<HTMLDivElement>) => {
    return (
      <TextContainer
        ref={ref}
      >
        {children}
      </TextContainer>
    );
  }
);
Text.displayName = "Text";

const ViewNoteTooltipCell = ({
  title,
  className,
  alwaysShowOnHover = false,
  handleViewNoteOpen,
}: Props) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const inputElementRef = useRef<HTMLInputElement | null>(null);

  const TextWithViewNote = ({ title }: TextWithViewNoteProps) => {
    return (
      <Box>
        <TooltipText>{title}</TooltipText>
        <ViewNoteText
          onClick={() => {
            setOpenTooltip(false);
            handleViewNoteOpen();
          }}
        >
          View Full Note
        </ViewNoteText>
      </Box>
    );
  };

  const handleOpenTooltip = (open: boolean) => {
    const show =
      open && (alwaysShowOnHover || isEllipsisShown(inputElementRef.current));

    setOpenTooltip(show);
  };

  if (!title) return null;
  return (
    <Box
      onMouseEnter={() => handleOpenTooltip(true)}
      onMouseLeave={() => handleOpenTooltip(false)}
      className={className}
    >
      <LightTooltip
        arrow
        title={<TextWithViewNote title={title} />}
        open={openTooltip}
      >
        <Text ref={inputElementRef}>{title}</Text>
      </LightTooltip>
    </Box>
  );
};

export default ViewNoteTooltipCell;


import * as ApiModel from "entities/ApiModel";
import * as UIModel from "entities/UIModel";

import { ActionResult } from "entities/UIModel";
import { ActionResultStatus } from "utils/data/enum";
import { AgencyLocationCriteria } from "entities/ApiModel/AgencyLocationCriteria";
import { CancelTokenSource } from "axios";
import { Column } from "entities/ApiModel/IColumn";
import { DownloadFileType } from "utils/data/enum";
import { IColumn } from "entities/ApiModel/IColumn";
import { InventoryModify } from "entities/ApiModel/InventoryModify";
import { InventoryModifyExportCriteria } from "entities/ApiModel/InventoryModifyExportCriteria";
import { InventoryModifySearchCriteria } from "entities/UIModel/InventoryModifySearchCriteria";
import { InventoryModifyUI } from "entities/UIModel/InventoryModifyUI";
import { LocalStorageKeys } from "utils/data/enum";
import { StoreActionApi } from "react-sweet-state";

import { axiosSecuredInstance, cancelToken } from "configurations/axiosConfig";
import { createHook } from "react-sweet-state";
import { createStore } from "react-sweet-state";
import { dateTimeAsFilename } from "utils/shared";
import { downloadByBase64 } from "utils/shared";
import { getBase64DataType } from "utils/shared";
import { mapCompanyLocation } from "pages/modify/new/InventoryModifyNewHelper";
import { startNotifying } from "utils/services/ContextProgressNotifierService";
import { stopNotifying } from "utils/services/ContextProgressNotifierService";

type State = {
  progressPercent ?: number,
  isLoading: boolean;
  isSaving: boolean;
  documents: InventoryModifyUI[];
  minDate: Date | null;
  maxDate: Date | null;
  showToast: boolean;
  isEditMode: boolean;
  isAdvanceSearch: boolean;
  fileDocuments: InventoryModifyUI[];
  isFilePolicyUploadEditMode: boolean;
  isFilePolicyUploadShowToast: boolean;
  error?: any;
  dateType: string;
  dateTypeFieldValue: string;
  selectedModifyItems: InventoryModifyUI[];
  axiosCancelToken?: CancelTokenSource;
  requestTimeoutMs: number;
  refreshData: boolean;
};

const getSearchType = (criteria?: InventoryModifySearchCriteria) => {
  if (criteria === undefined)
    return "";

  const getDayBetween = (startDate: Date, endDate: Date): number => {
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(endDate.valueOf() - startDate.valueOf()) / msInDay
    );
  };

  const propertiesToIgnore = [
    "dateRangeStart",
    "dateTypeCode",
    "fileStatusTypeCode",
    "listCount",
    "requestedPage",
    "showInActive",
    "sortColumn",
    "userFilter",
  ];

  let extendedDateSearch = true;
  const filtersApplied = Object.entries(criteria)
    .filter(([name, value]) => value !== null && !propertiesToIgnore.includes(name))
    .length > 0;

  if (!filtersApplied && criteria.dateRangeStart) {
    const daysForQuickerSearch = 90;
    extendedDateSearch = getDayBetween(new Date(), criteria.dateRangeStart) > daysForQuickerSearch;
  }

  return (filtersApplied || extendedDateSearch) ? "Lookup-long" : "Lookup-short";
};

const startProgressNotififer =
  (criteria: InventoryModifySearchCriteria) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      startNotifying(getSearchType(criteria), handleUpdateProgress);
    };

const stopProgressNotififer =
  (criteria?: InventoryModifySearchCriteria) =>
    (storeApi: StoreApi) => {
      const handleUpdateProgress = (percent: number | undefined) =>
        storeApi.setState({ progressPercent: percent });
      stopNotifying(getSearchType(criteria), handleUpdateProgress);
    };

type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

const setFilesRecordList = (filesSearchResponse: InventoryModify[]) => (): InventoryModifyUI[] => {
  if (!filesSearchResponse || filesSearchResponse.length === 0) {
    return [];
  }

  return filesSearchResponse.map((file: InventoryModify) => {
    return {
      uniqueIdentifier: file.IntegrationKey || 0,
      rowId: file.IdentityKey || 0,
      inventoryModifyDetailID :file.InventoryModifyDetailID,
      inventoryModifyID:file.InventoryModifyID  || 0 ,
      formPrefix: file.FormPrefix  || "",
      beginningSerialNumber: file.BeginningSerialNumber  || "",
      endingSerialNumber: file.EndingSerialNumber  || "",
      newLocationDisplayName: file.NewLocationDisplayName  || "",
      newLocationStateAbbr: file.NewLocationStateAbbr || "",
      newLocationLegacyID: file.NewLocationLegacyID ||"",
      inventoryModifiedUserName: file.InventoryModifiedUserName  || "",
      inventoryModifiedDate: file.InventoryModifiedDate,
      policyNumber :file.PolicyNumber || "",
      companyName: file.NewCompanyName,
      newCompanyName :file.NewCompanyName,
      companyLegacyID: file.NewCompanyLegacyID,
      inventoryModifyTypeName: file.InventoryModifyTypeName,
      newCompanyLegacyID: file.NewCompanyLegacyID,
      policyTypeDesc: file.PolicyTypeDesc,
      active: file.Active,
      rowsPerPage: file.RowsPerPage || 0,
      currentRow: file.CurrentRow || 0,
      currentPage: file.CurrentPage || 0,
      totalPages: file.TotalPages || 0,
      totalRows: file.TotalRows || 0,
      modifiedBy: file.ModifiedBy || "",
      serialNumberStatusTypeName: file.SerialNumberStatusTypeName || "",
    };
  });
};

const setColumns =
  (columns: any[]) =>
    () => {
      const gridColumns: IColumn[] = columns.filter((c) => Column.isValid(c));
      const gridHiddenColumns = gridColumns
        .filter((c) => c.hidden)
        .map((m) => m.field as keyof InventoryModifyUI);
      localStorage.setItem(LocalStorageKeys.COLUMNS_INVENTORY_MODIFY, JSON.stringify(gridColumns));
      localStorage.setItem(LocalStorageKeys.COLUMNS_INVENTORY_MODIFY_HIDDEN, JSON.stringify(gridHiddenColumns));
      return { gridColumns, gridHiddenColumns };
    };

const setCompanyLocation =
  (data: ApiModel.CompanyLocation) =>
  ({ setState }: StoreApi): UIModel.CompanyLocation | null => {
    if (!data) {
      return null;
    }

    const companyLocation = mapCompanyLocation(data);

    setState({ isLoading: false });

    return companyLocation;
  };

const actions = {
  setRequestTimeoutMs:
  (requestTimeoutMs: number) =>
    async ({ setState }: StoreApi) => {
      setState({ requestTimeoutMs });
    },
cancelRequests:
  () =>
    async ({ dispatch, getState }: StoreApi) => {
      dispatch(stopProgressNotififer());
      const token = getState().axiosCancelToken;
      if (token) {
        token.cancel(" Modify was requested to be cancelled");
      }
    },
  getSearchDocuments:
  (
    payload: InventoryModifySearchCriteria // ImageArchiveUI) =>
  ) =>
  async ({ dispatch, setState ,getState }: StoreApi) => {
    try {
      dispatch(startProgressNotififer(payload));
      setState({ isLoading: true });
      const token = getState().axiosCancelToken;
      if (token) {
        token.cancel("ModifyContext was cancelled due to new request");
      }
      const newCancelToken = cancelToken.source();
      setState({ error: undefined, axiosCancelToken: newCancelToken });
      const { data } = await axiosSecuredInstance.post(
        "/InventoryModify/search",
        payload,
        {
          cancelToken: newCancelToken.token,
          timeout: getState().requestTimeoutMs,
          timeoutErrorMessage: "TIMEOUT",
        }
      );
      dispatch(stopProgressNotififer(payload));
       return dispatch(setFilesRecordList(data));
    } catch (error: any) {
      setState({ error: error.message });
      dispatch(stopProgressNotififer());      }
  },
  exportFiles: (criteria: InventoryModifyExportCriteria) => async () => {
    const getExportFilename = (): string => {
      let filename = `Inventory Modify ${dateTimeAsFilename()}.${
        DownloadFileType.Xlsx
      }`;
      return filename;
    };
    try {
      const { data } = await axiosSecuredInstance.post<string>(
        "InventoryModify/Export",
        criteria
      );
      const filename = getExportFilename();
      downloadByBase64(
        `data:${getBase64DataType(DownloadFileType.Xlsx)};base64,${data}`,
        filename
      );
      return {};
    } catch (error: any) {
      console.error(error);
    }
  },
  updateSelectedModifyItem:
    (item: InventoryModifyUI) =>
    ({ setState, getState }: StoreApi) => {
      const currentModifyItems = getState().selectedModifyItems || [];
      const updatedModifyItems = currentModifyItems?.filter(
        (d) => d.inventoryModifyDetailID !== item.inventoryModifyDetailID
      );

      if (updatedModifyItems.length === currentModifyItems?.length) {
        updatedModifyItems.push(item);
      }

      setState({
        selectedModifyItems: updatedModifyItems,
      });
    },
  updateSelectedModifyItems:
    (items: InventoryModifyUI[]) =>
    ({ setState, getState }: StoreApi) => {
      const currentModifyItems = getState().selectedModifyItems || [];
      const newModifyItems = items.filter(
        (item) =>
          !currentModifyItems?.find(
            (currentModifyItem) =>
              currentModifyItem.inventoryModifyDetailID ===
              item.inventoryModifyDetailID
          )
      );

      let updatedModifyItems: InventoryModifyUI[] = [];
      if (newModifyItems.length > 0) {
        updatedModifyItems = currentModifyItems?.concat(newModifyItems);
      }

      setState({
        selectedModifyItems: updatedModifyItems,
      });
    },
  resetSelectedModifyItem:
    () =>
    ({ setState }: StoreApi) => {
      setState({
        selectedModifyItems: [],
      });
    },
  setDateType:
    (dateTypeFieldValue: string) =>
    ({ setState }: StoreApi) => {
      setState({ dateTypeFieldValue });
    },
  setMinDate:
    (minDate: Date | null) =>
    ({ setState }: StoreApi) => {
      setState({ minDate });
    },
  setMaxDate:
    (maxDate: Date | null) =>
    ({ setState }: StoreApi) => {
      setState({ maxDate });
    },
  setAdvanceSearch:
    (isAdvanceSearch: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isAdvanceSearch });
    },
  getInitialColumnsDefintion: (hidden: boolean) => () => {
    const key = hidden
      ? LocalStorageKeys.COLUMNS_INVENTORY_MODIFY_HIDDEN
      : LocalStorageKeys.COLUMNS_INVENTORY_MODIFY;
    const existingColumns = localStorage.getItem(key) || "[]";
    return JSON.parse(existingColumns);
  },
  getColumnsDefinition:
    () =>
    async ({ dispatch }: StoreApi) => {
      const { data } = await axiosSecuredInstance.get(
        "/UISettings/grids/columnSettings/InventoryModify"
      );
      return dispatch(setColumns(data));
    },
  setColumnDefinition:
    (
      fields: (keyof InventoryModifyUI)[],
      propName: keyof Omit<IColumn, "field" | "name">,
      value: string | number | boolean | undefined
    ) =>
    async ({ dispatch }: StoreApi) => {
      const data: IColumn[] = fields.map((field) => ({
        field,
        name: "", //NOT REQUIRED,
        [propName]: value,
      }));

      try {
        const response = await axiosSecuredInstance.post(
          "/UISettings/grids/columnSettings/InventoryModify",
          data
        );
        if (response.data) {
          dispatch(setColumns(response.data));
        }
      } catch (error) {
        console.error(error);
      }
    },
  setDateTypeCode:
    (dateType: string) =>
    ({ setState }: StoreApi) => {
      setState({ dateType });
    },
  getCompanyLocation:
    (agencyLocationCriteria: AgencyLocationCriteria) =>
    async ({ dispatch, setState }: StoreApi) => {
      try {
        setState({ isLoading: true });
        const { data } = await axiosSecuredInstance.post(
          "/InventoryModify/GetCompanyLocation",
          agencyLocationCriteria
        );

        return dispatch(setCompanyLocation(data));
      } catch (error: any) {
        setState({ isLoading: false, error: error });
        console.error("ERROR: InventoryModifyContext: GetCompanyLocation.", error);
        return null;
      }
    },
  saveModify: 
  (inventoryModifySaveRequest: ApiModel.InventoryModifySaveRequest) =>
    async ({ setState }: StoreApi): Promise<ActionResult> => {
      try {
        setState({ isSaving: true });
        const { data, headers } = await axiosSecuredInstance.post(
          "/InventoryModify/Save",
          inventoryModifySaveRequest
        );
        setState({ isSaving: false });
        const result: ActionResult = {
          status: ActionResultStatus.Success,
          headers: headers,
          apiResponse: data,
        };
        return result;
      } catch (error: any) {
        setState({ isSaving: false, error: error });
        const result: ActionResult = {
          status: ActionResultStatus.Failed,
          error: error,
        };
        return result;
      }
    }, 
  };

const initialState: State = {
  isLoading: false,
  isSaving: false,
  documents: [],
  minDate: null,
  maxDate: null,
  showToast: false,
  isEditMode: false,
  isAdvanceSearch: false,
  fileDocuments: [],
  isFilePolicyUploadEditMode: false,
  isFilePolicyUploadShowToast: false,
  error: null,
  dateType: 'MODIFIED_DATE',
  dateTypeFieldValue: '',
  selectedModifyItems :[],
  requestTimeoutMs: 45000,
  refreshData: false,
};

const modifyStore = createStore<State, Actions>({
  initialState,
  actions,
  name: "modifyStore",
});

const getFileDocuments = (state: State) => {
  return state.fileDocuments;
}

const getFilePolicyUploadStates = (state: State) => ({
  isFilePolicyUploadEditMode: state.isFilePolicyUploadEditMode,
  isFilePolicyUploadShowToast: state.isFilePolicyUploadShowToast,
})

const useModifyStore = createHook(modifyStore);

const useFileDocuments = createHook(modifyStore, {
  selector: getFileDocuments,
})

const useFilePolicyUploadStates = createHook(modifyStore, {
  selector: getFilePolicyUploadStates,
})

export default useModifyStore;

export {
  useFileDocuments,
  useFilePolicyUploadStates,
}

import { yupResolver } from "@hookform/resolvers/yup";
import {
  // Home,
  Person,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  styled,
} from "@mui/material";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import Loading from "controls/global/loading";
import SelectField from "controls/global/select-field";
import TextInputField from "controls/global/text-input-field";
import HeaderRefs from "../utils/HeaderRefs";
import ProfileValidationSchema from "./profileValidationSchema";
import React, { 
  // useCallback,
  useEffect,
  useState,
} from "react";
import {
  FormProvider,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import theme from "theme/default";
import {
  // colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  padding,
} from "theme/defaultStyle";
import GradientIcon from "theme/icons/GradientIcon";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useConfigContext } from "utils/context/ConfigContextProvider";
// import { useLookup } from "utils/context/LookupContext";
import { useNavigation } from "utils/context/NavigationContext";
import { ProfileSettings, useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { userExternalAccounts } from "utils/context/UserExternalSystemAccountsContext";
import { useUserStates } from "utils/context/UserStatesContext";
import { UIConstants } from "utils/data/enum";
import classes from "./ProfileSettings.module.css";
// import RadioButtonField from "./components/RadioButton";
// import AgencyField from "./AgencyField";
// import AgencyLocationField from "./AgencyLocationField";
// import PropertyStateField from "./PropertyStateField";

const DEFAULT_MESSAGE = "Do you want to save your changes?";
interface Props {
  saveFileRef: any;
  saveFileChangeRef: any;
  forceUpdate: (sausername: string) => void;
  profileSettingsTitleRef: any;
  newFileDefaultsTitleRef: any;
}

// const FormField = styled("div")({
//   flexGrow: 1,
// });

// const RadioButtonFormField = styled("div")({
//   alignItems: "start",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   paddingLeft: padding.xsmall,
//   "& > label": {
//     color: colors.grey08,
//     fontSize: fontSize.large,
//     fontWeight: fontWeight.bold2,
//   },
// });

const Sections = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large3,
});

const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.medium1,
});

const SectionTitle = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: gaps.small2,
});

const SectionTitleText = styled(Box)({
  background: gradients.maroonGradient01,
  fontSize: fontSize.xlarge2,
  fontWeight: fontWeight.bold2,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});

const SectionPanel = styled(Box)({
  ...theme.custom.container.outer.root,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: gaps.large1,
  justifyContent: "space-between",
  padding: padding.xlarge1,
  "& .flex-row ": {
    display: "flex",
    flexDirection: "row",
    gap: gaps.large1,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      flexWrap: "wrap",
    },
  },
  "& .displayname, & .timezone": {
    flexBasis: "50%",
    flexGrow: 1,
    "& > div": {
      width: "100%",
    },    
  },
  "& .defaultagency": {
    minWidth: "30% !important",    
  },
  "& .defaultagencylocation": {
    minWidth: "55%"
  },
  "& .defaultpropertystate": {
    minWidth: "10%"
  },
  "& .defaultpropertytype": {
    maxWidth: "30%"
  },
});

const ProfileSettingsForm = ({
  saveFileRef,
  saveFileChangeRef,
  profileSettingsTitleRef,
  newFileDefaultsTitleRef,
  forceUpdate,
}: Props) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [saUserChanged, setSAUserChanged] = useState(false);
  const [currentSAUser, setCurrentSAUser] = useState("");
  // const [currentDefaultState, SetCurrentDefaultState] = useState("");
  const navigate = useNavigate();
  const [, { navigateToNextUrl }] = useNavigation();
  const [{ isLoading, profileSettings }, { onSubmit, getProfileSettings }] =
    useProfileSettingsCache();
  // const [{ propertyTypes }, { getPropertyTypes }] = useLookup();
  const [{ userStates }, { getUserStates }] = useUserStates();
  const [{ userStewartAccounts }, { getUserStewartAccounts }] = userExternalAccounts();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const { generalConfig: { saDomain } } = useConfigContext();
          
  const methods = useForm({
    resolver: yupResolver(ProfileValidationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: profileSettings,
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
    reset,
  } = methods;

  
  const getAllTimeZones = () => {
    return profileSettings.timeZones.map((timeZone: any, index: number) => {
      return {
        text: timeZone.Display_Name,
        value: timeZone.Name,
        selected: timeZone.Name === profileSettings.userTimeZone,
      };
    });
  };

 
  const SubmitForm = async (data: any) => {
    openAutomaticProgressDialog(UIConstants.SAVE_PROFILE_IN_PROGRESS_MESSAGE);
    let primaryIsFound = false;
    const exAccounts = userStewartAccounts.map(sa => {
      const retval = sa.payload;
      const isPrimary = sa.text.toLowerCase() === currentSAUser.toLowerCase();
      const isChanged = isPrimary !== retval.IsPrimary;
      retval.IsPrimary = isPrimary ? 1 : 0;
      retval.IsDirty = isChanged;

      if (isPrimary) primaryIsFound = true;
      return retval;
    });

    if (!primaryIsFound) {
      const newRecord =
      {
        ExternalSystemTypeCode: "STEWARTACCESS",
        IsPrimary: 1,
        ExternalUserName: `${currentSAUser.split("@", 1)[0]}@${saDomain}`,
        UserID: userStewartAccounts[0].UserID,
        IsNew: true,
        Active: 1
      };
      exAccounts.push(newRecord);
    }

    data.ExternalSystemAccounts = exAccounts.sort((a, b) => {
      return a.IsPrimary === 0 ? -1 : 0;
    });

    await onSubmit(data);
    await getProfileSettings();
    getUserStewartAccounts();
    closeAutomaticProgressDialog();
    forceUpdate(profileSettings.stewartAccessUsername);
  };

  const checkIfIsDirty = () => {
    const formValues: ProfileSettings = getValues();
    if (profileSettings?.displayName !== formValues.displayName ||
      profileSettings?.userTimeZone !== formValues.userTimeZone ||
      profileSettings?.userProfile?.defaultAgencyID !== formValues?.userProfile?.defaultAgencyID ||
      profileSettings?.userProfile?.defaultAgencyLocation?.value !== formValues?.userProfile?.defaultAgencyLocation?.value ||
      profileSettings?.userProfile?.defaultPropertyState !== formValues?.userProfile?.defaultPropertyState ||
      profileSettings?.userProfile?.skipAgencySelection !== formValues?.userProfile?.skipAgencySelection ||
      profileSettings?.userProfile?.useAddressSuggestion !== formValues?.userProfile?.useAddressSuggestion
    ) {
      return true;
    }
    return false;
  };

  const handleSaveChanges = () => {
    if (checkIfIsDirty()) {
      setOpenConfirmationDialog(true);
    }
    else {
      navigateToNextUrl(navigate);
    }
  };

  const handleDialogYes = async () => {
    setOpenConfirmationDialog(false);
    if (Object.keys(errors).length === 0) {
      await SubmitForm(getValues());
      navigateToNextUrl(navigate);
    }
  };

  const handleDialogNo = () => {
    setOpenConfirmationDialog(false);
    navigateToNextUrl(navigate);
  };

  useEffect(() => {
    if (currentSAUser === "") {
      setCurrentSAUser(profileSettings.stewartAccessUsername?.split("@", 1)[0]);
      setSAUserChanged(true);
    }

    if (currentSAUser && (userStates.length === 0 || saUserChanged)) {
      getUserStates(currentSAUser);
      setSAUserChanged(false);
    }
    
  }, [
    profileSettings,
    userStates,
    saUserChanged,
    currentSAUser,
    getUserStates,
  ]);

  useEffect(() => {
    reset(profileSettings);
  }, [profileSettings, reset]);

  if (userStewartAccounts.length === 0) getUserStewartAccounts();
  
  return (
    <>
      {profileSettings.hasError && (
        <Alert className={classes.error} severity="error">
          {
            "Your request couldn't be processed due to a technical issue. Please try again."
          }
        </Alert>
      )}
      {isSubmitting || (isLoading && <Loading></Loading>)}

      <FormProvider {...methods}>
        <form className={classes.form} id="profileSettingsForm">
          <Sections>
            <Section>
              <SectionTitle ref={profileSettingsTitleRef}>
                <GradientIcon Icon={Person} />
                <SectionTitleText>Profile Settings</SectionTitleText>
              </SectionTitle>
              <SectionPanel>
                <div className="flex-row displayname-timezone-row">
                  <div className="displayname">
                    <TextInputField label="Display Name" name="displayName" />
                  </div>
                  <div className="timezone">
                    <SelectField
                      label="User Time Zone"
                      name="userTimeZone"
                      options={getAllTimeZones()}
                    />
                  </div>
                </div>
              </SectionPanel>
            </Section>
          </Sections>
        </form>
      </FormProvider>
      <HeaderRefs
        saveFileRef={saveFileRef}
        saveFileChangeRef={saveFileChangeRef}
        handleSaveChanges={handleSaveChanges}
        handleSaveFile={handleSubmit(SubmitForm)}
      />
      <ConfirmationDialog
        confirmationMessage={DEFAULT_MESSAGE}
        isOpen={openConfirmationDialog}
        onYes={handleDialogYes}
        onNo={handleDialogNo}
      />
    </>
  );
};

export default ProfileSettingsForm;

import React from "react";
import { Controller } from "react-hook-form";
import ErrorMessage from "controls/global/error-message/ErrorMessage";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
// import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import SelectInputClearIcon from "./SelectInputClearIcon";

interface FieldProps {
  name: any;
  defaultValue?: any;
  label: string;
  dataValueField?: string;
  dataTextField?: string;
  optionsTextField?: string;
  optionsValueField?: string;
  optionsDescField?: string;
  disabled?: boolean;
  disableClearable?: boolean;
  autosetValue?: boolean;
  onChange?: (e: any, item: any, desc?: string, initValue?: string) => void;
  onBlur?: (item: any) => void;
  options: any[];
  updateOn?: "change" | "blur";
  delaySearching?: number;
  allowsDelete?: boolean;
  overrideValue?: any;
  errorOverride?: string;
  showTooltipAlways?: boolean;
  highlightError?: boolean;
  validateOnBlur?: boolean;
  className?: string;
  tabIndex?: number;
  size?: "small" | "medium";
  style?: React.CSSProperties;
  errorClassName?: string;
}

const SelectFieldWithClearIcon = ({
  name,
  label,
  defaultValue,
  dataTextField,
  dataValueField,
  optionsTextField,
  optionsValueField,
  optionsDescField,
  autosetValue = true,
  disabled = false,
  disableClearable = false,
  options,
  onChange,
  onBlur,
  delaySearching = 300,
  updateOn,
  allowsDelete = false,
  overrideValue,
  errorOverride,
  showTooltipAlways,
  highlightError = false,
  validateOnBlur = false,
  className,
  tabIndex = 0,
  size = "medium",
  style,
  errorClassName,
  ...rest
}: FieldProps) => {
  const { control, setValue } = useFormWrapper();
  // const shouldFieldBeDisabled = useFieldDisabler(name);

  const handleOnBlur = (item: any, onBlurForm: any, hasError: boolean) => {
    onBlur && onBlur(item);
    if (validateOnBlur || hasError) {
      /**
       * Trigger RHF field validation.
       */
      onBlurForm();
    }
  };

  const handleOnChange = (
    e: any,
    value: any,
    desc?: string,
    initValue?: string
  ) => {
    if (autosetValue) setValue(name, value, { shouldValidate: true });
    onChange && onChange(e, value, desc, initValue);
  };

  const handleClear = (
    e: any,
    value?: any
  ) => {
    const newValue = defaultValue;
    if (autosetValue) setValue(name, newValue, { shouldValidate: true });
    onChange && onChange(e, newValue, undefined, undefined);
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { value, onBlur: onBlurForm, ref }, fieldState }) => {
          const fieldHasError = Boolean(
            fieldState.error || errorOverride || highlightError
          );

          return (
            <>
              <SelectInputClearIcon
                error={fieldHasError}
                name={name}
                label={label}
                value={overrideValue || value || ""}
                options={options}
                dataTextField={dataTextField}
                dataValueField={dataValueField}
                optionsTextField={optionsTextField}
                optionsValueField={optionsValueField}
                optionsDescField={optionsDescField}
                onChange={handleOnChange}
                onClear={handleClear}
                onBlur={(item: any) =>
                  handleOnBlur(item, onBlurForm, fieldHasError)
                }
                disabled={disabled} // || shouldFieldBeDisabled}
                delaySearching={delaySearching}
                allowsDelete={allowsDelete}
                fieldRef={ref}
                showTooltipAlways={showTooltipAlways}
                className={className}
                tabIndex={tabIndex}
                size={size}
                {...rest}
              />
              {fieldHasError ? (
                <div className={errorClassName ? errorClassName : "errorMessage"}>
                  {fieldState?.error?.message || errorOverride}
                </div>
              ) : (
                <ErrorMessage name={name + ".abbr"} />
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default SelectFieldWithClearIcon;

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import * as PropTypes from "prop-types";

interface MuiPickersTzUtilsProviderProps {
  utils: any;
  children: React.ReactNode;
  locale?: any;
  dateLibInstance?: any;
  dateFormats?: any;
  timeZone: string;
}

export function MuiPickersTzUtilsProvider(props: MuiPickersTzUtilsProviderProps) {
  const { children, utils, dateFormats, dateLibInstance, locale, timeZone } = props;
  const utilsContext = React.useMemo(
    () => new utils({ locale, timeZone, formats: dateFormats, instance: dateLibInstance }),
    [utils, locale, timeZone, dateFormats, dateLibInstance],
  );
  return <LocalizationProvider value={utilsContext} dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>;
}

MuiPickersTzUtilsProvider.propTypes = {
  utils: PropTypes.object.isRequired,
  locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  timeZone: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.arrayOf(PropTypes.element.isRequired)])
    .isRequired,
};

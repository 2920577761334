import {
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material";
import StewartCheckbox from "controls/global/stewart-checkbox";
import React from "react";
import {
  colors,
  fontSize,
} from "theme/defaultStyle";
import { PageType } from "utils/data/enum";

interface Props<T> {
  handleCheckboxChange: (
    name: keyof T,
    value: any,
    execSearch: boolean,
  ) => void;
  checked: boolean,
  disabled?: boolean;
  page? :PageType,
}

const StyledBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  height: "100%",
});

const Label = styled(Typography)({
  color: colors.grey08,
  fontSize: fontSize.large,
});

const OpenCheckbox = <T extends object>({ handleCheckboxChange, checked, disabled,page, ...rest }: Props<T>) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    handleCheckboxChange("isInventory" as keyof T, isChecked ? 1 : 0, true);
  };

  return (
    <StyledBox {...rest}>
      <StewartCheckbox
        disabled={disabled}
        {...{ checked} }
        onChange={handleChange}
        style={{ marginLeft: '-5px' }}
      />
      <Label>{"Show Inventory Only"}</Label>
    </StyledBox>
  );
};

export default OpenCheckbox;

import React from "react";
import {
  Box,
  IconButton,
  Portal,
  Snackbar,
  SnackbarProps,
  styled,
  Typography,
} from "@mui/material";
import {
  borderRadius,
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  margin,
  padding,
} from "theme/defaultStyle";
import CloseIconButton from "theme/icons/CloseIconButton";
import { useFlashMessageActions, useFlashMessageState } from "utils/context/FlashMessageContext";
import { FlashMessageType } from "utils/data/enum";

const SnackbarStyled = styled(Snackbar)({
});

const MessageContainer = styled(Box)({
  borderRadius: borderRadius.large,
  boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",  
  display: "flex",
  flexDirection: "row",
  alignItems: "center",  
  gap: gaps.large1,
  backgroundColor: colors.white,  
  "& .messageIconSuccess": {
    background: gradients.greenGradient02,
  },
  "& .messageIconInformation": {
    background: gradients.yeelowGradient03,
  },  
  "& .messageIconWarning": {
    background: gradients.yeelowGradient03,
  },
  "& div.messageIconError": {
    background: gradients.redGradient04,
  },  
});

const MessageBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: margin.xlarge1,
  marginBottom: margin.xlarge1,
  minWidth: "340px",
});

const MessageHeaderBox = styled(Box)({
});

const MessageHeaderTypography = styled(Typography)({
  color: colors.black01, 

  fontFamily: fontFamily.primary,
  fontSize: fontSize.xlarge0,
  fontStyle: "normal",
  fontWeight: fontWeight.bold1,
  lineHeight: "normal",
  "& .messageHeaderSuccess": {
  },
  "& .messageHeaderInformation": {
  },
  "& .messageHeaderWarning": {
  },
  "& .messageHeaderError": {
  },
});

const MessageDetailBox = styled(Box)({
});

const MessageDetailTypography = styled(Typography)({
  color: colors.black01, 
  fontFamily: fontFamily.primary,
  fontSize: fontSize.medium,
  fontStyle: "normal",
  fontWeight: fontWeight.normal1,
  lineHeight: "normal",
  "& .messageDetailSuccess": {
  },
  "& .messageDetailInformation": {
  },
  "& .messageDetailWarning": {
  },
  "& .messageDetailError": {
  },  
});

const IconBox = styled(Box)({
  borderRadius: "8px 0px 0px 8px",  
  padding: padding.medium2,
  display: "flex",
  width: "90px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
});

const IconButtonBox = styled(Box)({
  paddingRight: padding.medium2,
  "& .iconButton": {
    padding: padding.zero,
    width: "24px",
    height: "24px",
    flexShrink: "0",
  },
});


const FlashMessage = (props: SnackbarProps) => {
  const [{ show, flashMessageType, messageHeader, messageDetail }] = useFlashMessageState();
  const [, { closeFlashMessage }] = useFlashMessageActions();

  const getMessageIcon = (): JSX.Element | null => {
    let messageIcon: JSX.Element | null = null;
    switch (flashMessageType) {
      case FlashMessageType.Success:
        messageIcon = 
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path d="M16.9987 2.83337C9.1787 2.83337 2.83203 9.18004 2.83203 17C2.83203 24.82 9.1787 31.1667 16.9987 31.1667C24.8187 31.1667 31.1654 24.82 31.1654 17C31.1654 9.18004 24.8187 2.83337 16.9987 2.83337ZM14.1654 24.0834L7.08203 17L9.07953 15.0025L14.1654 20.0742L24.9179 9.32171L26.9154 11.3334L14.1654 24.0834Z" fill="white"/>
          </svg>      
        break;
      case FlashMessageType.Information:
      case FlashMessageType.Warning:
        messageIcon = 
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
            <path d="M16.9987 3.33337C9.1787 3.33337 2.83203 9.68004 2.83203 17.5C2.83203 25.32 9.1787 31.6667 16.9987 31.6667C24.8187 31.6667 31.1654 25.32 31.1654 17.5C31.1654 9.68004 24.8187 3.33337 16.9987 3.33337ZM18.4154 24.5834H15.582V21.75H18.4154V24.5834ZM18.4154 18.9167H15.582V10.4167H18.4154V18.9167Z" fill="white"/>
          </svg>        
        break;
      case FlashMessageType.Error:
        messageIcon = 
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
            <path d="M17.0026 3.33325C9.16844 3.33325 2.83594 9.66575 2.83594 17.4999C2.83594 25.3341 9.16844 31.6666 17.0026 31.6666C24.8368 31.6666 31.1693 25.3341 31.1693 17.4999C31.1693 9.66575 24.8368 3.33325 17.0026 3.33325ZM24.0859 22.5858L22.0884 24.5833L17.0026 19.4974L11.9168 24.5833L9.91927 22.5858L15.0051 17.4999L9.91927 12.4141L11.9168 10.4166L17.0026 15.5024L22.0884 10.4166L24.0859 12.4141L19.0001 17.4999L24.0859 22.5858Z" fill="white"/>
          </svg>        
        break;
      default:
        messageIcon = null;
    }    

    return messageIcon;
  }

  const getMessageIconClassName = (): string => {
    let className = "";
    switch (flashMessageType) {
      case FlashMessageType.Success:
        className = "messageIconSuccess";
        break;
      case FlashMessageType.Information:
        className = "messageIconInformation";
        break;
      case FlashMessageType.Warning:
        className = "messageIconWarning";
        break;
      case FlashMessageType.Error:
        className = "messageIconError";
        break;
    }
    return className;
  }

  const getMessageHeaderClassName = (): string => {
    let className = "";
    switch (flashMessageType) {
      case FlashMessageType.Success:
        className = "messageHeaderSuccess";
        break;
      case FlashMessageType.Information:
        className = "messageHeaderInformation";
        break;
      case FlashMessageType.Warning:
        className = "messageHeaderWarning";
        break;
      case FlashMessageType.Error:
        className = "messageHeaderError";
        break;
    }
    return className;
  }
  
  const getMessageDetailClassName = (): string => {
    let className = "";
    switch (flashMessageType) {
      case FlashMessageType.Success:
        className = "messageDetailSuccess";
        break;
      case FlashMessageType.Information:
        className = "messageDetailInformation";
        break;
      case FlashMessageType.Warning:
        className = "messageDetailWarning";
        break;
      case FlashMessageType.Error:
        className = "messageDetailError";
        break;
    }
    return className;
  };

  const handleOnClose = () => {
    closeFlashMessage();
  }

  return (
    <Portal>
      <SnackbarStyled {...props} className="flashMessage" open={show}>
        <MessageContainer>
          <IconBox className={getMessageIconClassName()}>
            {getMessageIcon()}
          </IconBox>
          <MessageBox>
            {messageHeader && messageHeader.trim().length > 0 && (
              <MessageHeaderBox>
                <MessageHeaderTypography
                  className={getMessageHeaderClassName()}
                >
                  {messageHeader}
                </MessageHeaderTypography>
              </MessageHeaderBox>
            )}
            {messageDetail && messageDetail.trim().length > 0 && (
              <MessageDetailBox>
                <MessageDetailTypography
                  className={getMessageDetailClassName()}
                >
                  {messageDetail}
                </MessageDetailTypography>
              </MessageDetailBox>
            )}
          </MessageBox>
          <IconButtonBox>
            <IconButton className="iconButton" onClick={handleOnClose}>
              <CloseIconButton />
            </IconButton>
          </IconButtonBox>
        </MessageContainer>
      </SnackbarStyled>
    </Portal>
  );
};

export default FlashMessage;

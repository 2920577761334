import { PublicClientApplication } from "@azure/msal-browser";
import Cache from "../interfaces/Cache";

class PubliClientApplicationCache<T> implements Cache<T> {
  protected cacheMap: Map<string, T>;
  constructor() {
    this.cacheMap = new Map<string, T>();
  }

  get(key: string) {
    return this.cacheMap.get(key);
  }

  set(key: string, value: T) {
    this.cacheMap.set(key, value);
  }

  has(key: string) {
    return this.cacheMap.has(key);
  }

  del(key: string) {
    this.cacheMap.delete(key);
  }

  clear() {
    this.cacheMap.clear();
  }
}

const PubliClientApplicationService = new PubliClientApplicationCache<PublicClientApplication>();
export default PubliClientApplicationService;
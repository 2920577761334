import {
  Configuration,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import { axiosAnonymousInstance } from "configurations/axiosConfig";
import { CacheKey } from "utils/data/enum";
import PubliClientApplicationService from "../services/PubliClientApplicationService";

const loginRequest: Promise<RedirectRequest> = axiosAnonymousInstance
  .get("/configuration/authentication")
  .then((data) => {
    const scope: string = data.data.Scopes;
    const retval: RedirectRequest = {
      scopes: [scope],
    };

    return retval;
  });

const passwordResetRequest: Promise<RedirectRequest> = axiosAnonymousInstance
  .get("/configuration/authentication")
  .then((data) => {
    const scope: string = data.data.Scopes;
    const retval: RedirectRequest = {
      scopes: [scope],
      authority: data.data.ResetPasswordAuthority,
    };

    return retval;
  });

const msalInstance = async () => {
  const instance = PubliClientApplicationService.get(CacheKey.MsalInstance);
  if (instance) return instance;

  const { data } = await axiosAnonymousInstance.get(
    "/configuration/authentication"
  );

  const config: Configuration = {
    auth: {
      clientId: data.ClientId,
      authority: data.Authority,
      knownAuthorities: data.KnownAuthorities,
      redirectUri: data.RedirectUri,
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: data.RedirectUri,
    },
    cache: {},
    system: {},
  };

  
  if (window.location.href.indexOf("/profile") > 0) {
    config.auth.redirectUri = window.location.href;
  } else {
    if (config.auth.redirectUri && config.auth.redirectUri === "/profile")
    config.auth.redirectUri = config.auth.redirectUri.replace("/profile", "/");
  }

  const newInstance = new PublicClientApplication(config);
  PubliClientApplicationService.set(CacheKey.MsalInstance, newInstance);

  return newInstance;
};

export { msalInstance, loginRequest, passwordResetRequest };

import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

const SerialNumberModifyIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string } = (
  props: SvgIconProps,
) => {
  const { width = "72", height = "72", color = "#9E233A", ...otherProps } = props;

  return (
    <SvgIcon
      {...otherProps}
      viewBox="0 0 72 72"
      style={{ width, height }}
    >
      <g id="Modify">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7574 4.75736C11.8826 3.63214 13.4087 3 15 3H38.1716C38.702 3 39.2107 3.21071 39.5858 3.58578L56.4142 20.4142C56.7893 20.7893 57 21.298 57 21.8284V32.126L51.6667 37.4593V24H36V8.33333H15.3333C14.781 8.33333 14.3333 8.78105 14.3333 9.33333V56.6667C14.3333 57.219 14.781 57.6667 15.3333 57.6667H31.4593L30 59.126V63H15C13.4087 63 11.8826 62.3679 10.7574 61.2426C9.63214 60.1174 9 58.5913 9 57V9C9 7.4087 9.63214 5.88258 10.7574 4.75736ZM44.7926 44.3333L39.4593 49.6667H21V44.3333H44.7926ZM21 39V33.6667H45V39H21Z"
          fill={color}
        />
        <path
          id="Vector"
          d="M36 59.7509V66H42.2491L60.6799 47.5692L54.4308 41.3201L36 59.7509ZM65.5126 42.7366C66.1625 42.0867 66.1625 41.0368 65.5126 40.3869L61.6131 36.4874C60.9632 35.8375 59.9133 35.8375 59.2634 36.4874L56.2139 39.537L62.463 45.7861L65.5126 42.7366Z"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
};

SerialNumberModifyIcon.muiName = "SvgIcon";

export default SerialNumberModifyIcon;

import StewartTableFooterPaginator from "controls/global/stewart-table/StewartTableFooterPaginator";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { yupResolver } from "@hookform/resolvers/yup";
import { 
  useForm, 
  FormProvider,
 } from "react-hook-form";
import * as yup from "yup";
import React, { useMemo } from "react";
import { 
  borderRadius, 
  borderSize, 
  colors, 
  fontSize, 
  fontWeight,
  gaps, 
  margin, 
  padding, 
  zIndex,
 } from "theme/defaultStyle";
import StewartTable, { 
  SelectionConfig, 
  StewartTableColumn,
} from "controls/global/stewart-table/StewartTable";
import { 
  Box, 
  styled,
 } from "@mui/material";
import DatePickerSearch from "controls/global/datepicker-search";
import { InventoryAssignUI } from "entities/UIModel/InventoryAssignUI";
import { InventoryAssignSearchCriteria } from "entities/UIModel/InventoryAssignSearchCriteria";
import { Order } from "utils/sorting";
import { PageType } from "utils/data/enum";
import { IColumn } from "entities/ApiModel/IColumn";
import { getColumnConfig } from "utils/columnsGrid";
import InputSearch from "./InputSearch";
import useStewartTableStore from "utils/context/StewartTableContext";
import { formatDateString } from "utils/shared";
import { orderBy as _orderBy } from "lodash";
// import { getNavigationInfo } from "utils/services/utilsService";

type ImageArchiveGridProps = {
  page: number;
  rows: InventoryAssignUI[];
  onFiltersChange: (name: keyof InventoryAssignSearchCriteria, value: any) => void;
  orderBy?: keyof InventoryAssignUI;
  order: Order;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (count: number) => void;
  rowsPerPage: number;
  noRowsMessage: string[];
  onRequestSort?: (property: any, disableToggling?: boolean) => void;
  performSearch?: () => Promise<void>;
  onColumnResize: (column: any, newWidth: number) => void;
  colsConfig: IColumn[];
  hiddenColumns?: (keyof InventoryAssignUI)[];
  selectionConfig?: SelectionConfig<InventoryAssignUI>;
  loadingProgressPercent ?: number;
};

const StyledTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& table.MuiTable-root": {
    "& th.MuiTableCell-root.MuiTableCell-head": {
      color: colors.grey08,
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal2,
      "& div.MuiBox-root": {
        color: colors.grey08,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal2,
      },
      "& div.MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-adornedEnd": {
        border: 0,
        borderBottom: `1px solid ${colors.grey15}`,
        borderRadius: 0,
        "&:focus-within": {
          borderBottom: `2px solid ${colors.blue15}`,
        },
        "& fieldset": {
          border: 0,
        },
      },
    },
    "& thead": {
      "& tr th div.MuiFormControl-root": {
        width: "100%",
        marginLeft: margin.small1_5,
      },
      "& tr th label.MuiFormLabel-root:not(.MuiInputLabel-shrink)": {
        transform: "translate(14px, 11px) scale(1)",
      },
      "& .MuiTableRow-root.header-actions th": {
        paddingRight: padding.large2,
        zIndex :zIndex.positiveMax,
      },
      "& tr th input": {
        padding: "6px 3px",
      },
    },
    "& tbody": {
      "& tr:not(.checked-row):hover td": {
        background: colors.grey10,
      },
      "& tr td.emptyCell": {
        paddingLeft: "20px",
        boxShadow: "none",
      },
      "& td:not(.emptyCell)": {
        maxWidth: "75px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      "& tr:last-child": {
        "& td:not(.emptyCell)": {
          borderBottom: `1px solid ${colors.grey09}`,
        },
        "& td:not(.emptyCell):first-child": {
          borderBottomLeftRadius: borderSize.zero,
        },
        "& td:not(.emptyCell):last-child": {
          borderBottomRightRadius: borderSize.zero,
        },
      },
      // "& td:nth-last-child(1)": {
      //   boxShadow: "5px 0 2px -2px rgba(87, 87, 87, 0.10) inset",
      //   paddingLeft: padding.zero,
      //   "& div": {
      //     marginLeft: margin.small2,
      //     "& button": {
      //       width: "23px",
      //     },
      //   },
      // },
    },
    "& td.MuiTableCell-root.MuiTableCell-body": {
      "& div": {
        color: colors.grey08,
        fontWeight: fontWeight.normal1,
      },
      "& button.MuiButtonBase-root": {
        margin: `${margin.xxsmall1} ${margin.small1}`,
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-footer": {
      borderBottom: `1px solid ${colors.grey09}`,
      borderBottomLeftRadius: borderRadius.small,
      borderBottomRightRadius: borderRadius.small,
      borderLeft: `1px solid ${colors.grey09}`,
      borderRight: `1px solid ${colors.grey09}`,
      padding: padding.xsmall2,
      paddingRight: padding.small2,
    },
  },
  "& > .MuiTableContainer-root": {
    maxHeight: "100px",
    minHeight: "auto",
  },
});
const imageArchiveGridSchema = yup.object().shape({
  documentDisplayFileName: yup.string().trim().nullable().required("Required Field"),
});

const InventoryAssignGrid: React.FC<ImageArchiveGridProps> = ({
  page,
  rows,
  onFiltersChange,
  orderBy,
  order,
  onRowsPerPageChange,
  onPageChange,
  rowsPerPage,
  noRowsMessage,
  onRequestSort,
  performSearch,
  onColumnResize,
  colsConfig,
  hiddenColumns,
  selectionConfig,
  loadingProgressPercent,
}) => {
  const methods = useForm({
    resolver: yupResolver(imageArchiveGridSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [{ maxDate, minDate }] = useStewartTableStore();

  const columns = useMemo(
    (): StewartTableColumn<InventoryAssignUI>[] =>
      _orderBy(
        [
          {
            field: "formPrefix",
            valueGetter: ({ formPrefix }: InventoryAssignUI) => (
              <TooltipCell title={formPrefix || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch name="formPrefix" onChange={onFiltersChange} />
            ),
            ...getColumnConfig<InventoryAssignUI>(colsConfig, "formPrefix"),
          },
          {
            field: "inventoryAssignID",
            actionComponent: () => (
              <InputSearch
                name="inventoryAssignID"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ inventoryAssignID }: InventoryAssignUI) => (
              <TooltipCell title={inventoryAssignID || ""} />
            ),
            ...getColumnConfig<InventoryAssignUI>(
              colsConfig,
              "inventoryAssignID"
            ),
          },
          {
            field: "beginningSerialNumber",
            valueGetter: ({ beginningSerialNumber }: InventoryAssignUI) => (
              <TooltipCell title={beginningSerialNumber || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch
                name="beginningSerialNumber"
                onChange={onFiltersChange}
              />
            ),
            ...getColumnConfig<InventoryAssignUI>(
              colsConfig,
              "beginningSerialNumber"
            ),
          },
          {
            field: "endingSerialNumber",
            valueGetter: ({ endingSerialNumber }: InventoryAssignUI) => (
              <TooltipCell title={endingSerialNumber || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch
                name="endingSerialNumber"
                onChange={onFiltersChange}
              />
            ),
            ...getColumnConfig<InventoryAssignUI>(
              colsConfig,
              "endingSerialNumber"
            ),
          },
          {
            field: "assignedDate",
            valueGetter: ({ assignedDate }: InventoryAssignUI) => (
              <TooltipCell title={formatDateString(assignedDate)}></TooltipCell>
            ),
            actionComponent: () => (
              <DatePickerSearch
                handleDateChange={(date: Date | null) => {
                  onFiltersChange("assignedDate", date);
                }}
                page={PageType.AssignPage}
                minDate={minDate}
                maxDate={maxDate}
              />
            ),
            ...getColumnConfig<InventoryAssignUI>(colsConfig, "assignedDate"),
          },
          {
            field: "locationStateAbbr",
            actionComponent: () => (
              <InputSearch
                name="locationStateAbbr"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ locationStateAbbr }: InventoryAssignUI) => (
              <TooltipCell title={locationStateAbbr || ""} />
            ),
            ...getColumnConfig<InventoryAssignUI>(
              colsConfig,
              "locationStateAbbr"
            ),
          },
          {
            field: "locationLegacyID",
            valueGetter: ({ locationLegacyID }: InventoryAssignUI) => (
              <TooltipCell title={locationLegacyID || ""}></TooltipCell>
            ),
            actionComponent: () => (
              <InputSearch name="locationLegacyID" onChange={onFiltersChange} />
            ),
            ...getColumnConfig<InventoryAssignUI>(
              colsConfig,
              "locationLegacyID"
            ),
          },
          {
            field: "locationDisplayName",
            actionComponent: () => (
              <InputSearch
                name="locationDisplayName"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({ locationDisplayName }: InventoryAssignUI) => (
              <TooltipCell title={locationDisplayName || ""} />
            ),
            ...getColumnConfig<InventoryAssignUI>(
              colsConfig,
              "locationDisplayName"
            ),
          },
          {
            field: "companyName",
            actionComponent: () => (
              <InputSearch name="companyName" onChange={onFiltersChange} />
            ),
            valueGetter: ({ companyName }: InventoryAssignUI) => (
              <TooltipCell title={companyName || ""} />
            ),
            ...getColumnConfig<InventoryAssignUI>(colsConfig, "companyName"),
          },
          {
            field: "policyTypeDesc",
            actionComponent: () => (
              <InputSearch name="policyTypeDesc" onChange={onFiltersChange} />
            ),
            valueGetter: ({ policyTypeDesc }: InventoryAssignUI) => (
              <TooltipCell title={policyTypeDesc || ""} />
            ),
            ...getColumnConfig<InventoryAssignUI>(colsConfig, "policyTypeDesc"),
          },
          {
            field: "assignedUserName",
            actionComponent: () => (
              <InputSearch name="assignedUserName" onChange={onFiltersChange} />
            ),
            valueGetter: ({ assignedUserName }: InventoryAssignUI) => (
              <TooltipCell title={assignedUserName || ""} />
            ),
            ...getColumnConfig<InventoryAssignUI>(
              colsConfig,
              "assignedUserName"
            ),
          },
          {
            field: "companyLegacyID",
            actionComponent: () => (
              <InputSearch name="companyLegacyID" onChange={onFiltersChange} />
            ),
            valueGetter: ({ companyLegacyID }: InventoryAssignUI) => (
              <TooltipCell title={companyLegacyID || ""} />
            ),
            ...getColumnConfig<InventoryAssignUI>(
              colsConfig,
              "companyLegacyID"
            ),
          },
          {
            field: "assignDetailStatusTypeName",
            actionComponent: () => (
              <InputSearch
                name="assignDetailStatusTypeName"
                onChange={onFiltersChange}
              />
            ),
            valueGetter: ({
              assignDetailStatusTypeName,
            }: InventoryAssignUI) => (
              <TooltipCell title={assignDetailStatusTypeName || ""} />
            ),
            ...getColumnConfig<InventoryAssignUI>(
              colsConfig,
              "assignDetailStatusTypeName"
            ),
          },
        ],
        "position"
      ),
    [colsConfig, minDate, maxDate, onFiltersChange]
  );

  return (
    <>
      <FormProvider {...methods}>
        <StyledTableContainer width={1}>
          <StewartTable
            cols={columns}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            showActionRow={true}
            noRowsMessage={noRowsMessage}
            orderBy={orderBy}
            order={order}
            useDbPagingSorting={true}
            onRequestSort={onRequestSort}
            onColumnResize={onColumnResize}
            hiddenColumns={hiddenColumns}
            selectionConfig={selectionConfig}
            loadingProgressPercent={loadingProgressPercent}
          />
          <StewartTableFooterPaginator
            totalRows={rows[0]?.totalRows || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            totalPages={rows[0]?.totalPages}
          />
        </StyledTableContainer>
      </FormProvider>
    </>
  );
};

export default InventoryAssignGrid;

import { TableBody } from "@mui/material";
import { PolicyActivity } from "entities/UIModel";
import React from "react";
import { 
  Order, 
  getComparator, 
  stableSort
} from "utils/sorting";
import PolicyHistoryBodyRow from "./PolicyHistoryBodyRow";

interface Props {
  order: Order;
  orderBy: keyof PolicyActivity;
  activities: PolicyActivity[];
}

const PolicyHistoryBody = ({ order, orderBy, activities }: Props) => {
  const noRecords = Boolean(activities.length === 0);

  if (noRecords)
    return (
      <TableBody>
        <PolicyHistoryBodyRow />
      </TableBody>
    );

  return (
    <TableBody>
      {stableSort(activities, getComparator(order, orderBy)).map((rec) => (
        <PolicyHistoryBodyRow key={rec.serialNumberActivityID} activity={rec} />
      ))}
    </TableBody>
  );
};

export default PolicyHistoryBody;

import { useNavigate } from 'react-router';
import { styled } from "@mui/material";
import { Replay } from "@mui/icons-material";
import ActionButton from "controls/global/action-button";
import CircularProgressWithLabel from "controls/global/circular-progress-with-label";
import React, {
  useEffect,
  useState,
} from "react";
import {
  colors,
  fontSize,
  fontWeight,
  gaps,
  letterSpacing,
  zIndex,
} from "theme/defaultStyle";
import { SiteErrorType } from "utils/data/enum";

const PAGE_NOT_FOUND_MESSAGE = "Page Not Found";
const LOGIN_MESSAGE = "One moment while we access your Stewart Connect user account.";
const BAD_ACCOUNT_MESSAGE = "There is a problem with your account.";
const NETWORK_ISSUE_MESSAGE = "A network error may have occurred.";
const UNHANDLED_EXCEPTION_MESSAGE = "An unexpected error has occurred.";
const CONTACT_MESSAGE = "Please contact the Agency Support Center for assistance.";
const TRY_AGAIN_CONTACT_MESSAGE = "Please try again or contact the Agency Support Center for assistance.";
const NO_MODULE_ACCESS_MESSAGE = "You do not have access to any modules";
const PERMISSIONS_CHANGED_MESSAGE =
  "Your permissions have changed. Please select an icon in the navigation bar to continue.";

interface Props {
  errorType?: SiteErrorType;
  progressPercent?: number;
  errorMessage?: string;
}

const MessageContainer = styled("div")(({ center }: { center: boolean; }) => ({
  alignItems: "center",
  color: colors.grey08,
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  fontFamily: "Roboto",
  fontSize: fontSize.xlarge0,
  fontStyle: "normal",
  fontWeight: fontWeight.normal1,
  gap: gaps.large1,
  height: "100%",
  justifyContent: center ? "center" : "flex-start",
  letterSpacing: letterSpacing.medium1,
  lineHeight: "160%",
  textAlign: "center",
  "& .agency": {
    fontWeight: fontWeight.bold2,
  },
  "& .contact": {
    display: "flex",
    flexDirection: "column",
    "& .email a": {
      color: colors.blue15,
      fontWeight: fontWeight.bold2,
      textDecoration: "underline",
    },
    "& .phone a": {
      color: colors.grey08,
      textDecorationLine: "none",
    },
  },
  "& .message": {
    maxWidth: "1000px",
  },
}));

const CircularProgressContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  left: "50%",
  position: "fixed",
  top: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  zIndex: zIndex.positiveMax,
  "& .MuiCircularProgress-circleDeterminate": {
    transition: "stroke-dashoffset 0ms",
  }
});

export const SiteErrorMessage = ({
  errorType = undefined,
  progressPercent = undefined,
  errorMessage = undefined,
}: Props) => {
  const [message, setMessage] = useState<string>("");
  const [contactMessage, setContactMessage] = useState<string>("");
  const [showReloadLink, setShowReloadLink] = useState<boolean>();
  const navigate = useNavigate();

  useEffect(() => {
    let msg = "";
    let contactMsg = "";
    let showReload = false;

    switch (errorType) {
      case SiteErrorType.BadAccount:
        msg = BAD_ACCOUNT_MESSAGE;
        contactMsg = CONTACT_MESSAGE;
        break;

      case SiteErrorType.NetworkError:
        msg = NETWORK_ISSUE_MESSAGE;
        contactMsg = TRY_AGAIN_CONTACT_MESSAGE;
        showReload = true;
        break;

      case SiteErrorType.NotFound:
        msg = PAGE_NOT_FOUND_MESSAGE;
        contactMsg = TRY_AGAIN_CONTACT_MESSAGE;
        break;

      case SiteErrorType.NoAccess:
        msg = UNHANDLED_EXCEPTION_MESSAGE;
        contactMsg = TRY_AGAIN_CONTACT_MESSAGE;
        break;

      case SiteErrorType.NoModuleAccess:
        msg = NO_MODULE_ACCESS_MESSAGE;
        contactMsg = CONTACT_MESSAGE;
        break;

      case SiteErrorType.ErrorBoundary:
        msg = UNHANDLED_EXCEPTION_MESSAGE;
        contactMsg = TRY_AGAIN_CONTACT_MESSAGE;
        showReload = true;
        break;
      case SiteErrorType.PermissionDenied:
        msg = PERMISSIONS_CHANGED_MESSAGE;
        contactMsg = CONTACT_MESSAGE;
        break;

      default:
        msg = LOGIN_MESSAGE;
        contactMsg = "";
        break;
    }

    setMessage(errorMessage || msg);
    setContactMessage(contactMsg);
    setShowReloadLink(showReload);
  }, [errorType]);

  // useEffect(() => {
  //   setSiteError(true);
  //   return () => {
  //     setSiteError(false);
  //   }
  // }, []);

  return (
    <>
      {progressPercent !== undefined &&
        <CircularProgressContainer>
          <CircularProgressWithLabel value={progressPercent} />
        </CircularProgressContainer>
      }
      <MessageContainer
        id="login-message"
        center={!!contactMessage}
      >
        <div className="message">
          <div>
            {message}
          </div>
          {contactMessage &&
            <div>
              {contactMessage}
            </div>
          }
        </div>
        {showReloadLink &&
          <div className="reload">
            <ActionButton
              variant="outlined"
              color="primary"
              startIcon={<Replay />}
              onClick={() => navigate(0)}
            >
              Reload
            </ActionButton>
          </div>
        }
        {contactMessage &&
          <div className="contact">
            <div className="agency">
              Agency Support Center
            </div>
            <div className="phone">
              <a href="tel:(844) 835-1200">
                (844) 835-1200
              </a>
            </div>
            <div className="email">
              <a href="mailto:CustomerCare@Stewart.com">
                CustomerCare@Stewart.com
              </a>
            </div>
          </div>
        }
      </MessageContainer>
    </>
  );
};

import React, { useEffect, useState } from "react";
// import { uniqBy } from "lodash";
import { StewartDialog } from "controls/global/dialogs/stewart-dialog/StewartDialog";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  iconSize,
  margin,
  padding,
  scrollBar,
} from "theme/defaultStyle";
import { 
  styled, 
  Box, 
  IconButton, 
  Typography 
} from "@mui/material";
import { InventoryVerifyErrorDetail } from "entities/UIModel";
import ErrorIcon from "theme/icons/ErrorIcon";
import InventoryVerifyErrorGrid from "./InventoryVerifyErrorGrid";
import ActionButton from "controls/global/action-button";
import useLookupStore from "utils/context/InventoryLookupContext";
import { InventoryVerifyErrorExportCriteria } from "entities/ApiModel";
// import { hasValue } from "utils/shared";
import {
  ActionResultStatus,
  FlashMessageType,
  PERMISSIONS,
  UIConstants,
} from "utils/data/enum";
import FlashMessage from "controls/global/flash-message/FlashMessage";
import { useFlashMessageActions } from "utils/context/FlashMessageContext";
import useFlashMessage from "utils/custom-hooks/useFlashMessage";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

const closeIcon = 
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <g clipPath="url(#clip0_2499_40422)">
      <circle cx="16" cy="16" r="16" fill="#F5F5F5" />
      <path
        d="M22.9648 10.3741L21.5548 8.96411L15.9648 14.5541L10.3748 8.96411L8.96484 10.3741L14.5548 15.9641L8.96484 21.5541L10.3748 22.9641L15.9648 17.3741L21.5548 22.9641L22.9648 21.5541L17.3748 15.9641L22.9648 10.3741Z"
        fill="#215D80"
      />
    </g>
    <defs>
      <clipPath id="clip0_2499_40422">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>

const StyledDialog = styled(StewartDialog)({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    maxHeight: "calc(90vh)",
    maxWidth: "calc(94vw)",
    minWidth: "calc(94vw)",
    display: "flex",
    justifyContent: "flex-start",
    padding: "36px 40px 40px 40px",
    overflowX: "auto",
  },
});

const StyledDialogTitle = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "24px",
  "& .MuiTypography-root": {
    fontSize: fontSize.xlarge2,
    fontWeight: fontWeight.bold2,
  }
})

const IconContainer = styled("div")({
  background: gradients.redGradient04,
  borderRadius: borderRadius.large,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "56px",
  height: "56px",
  "& .MuiSvgIcon-root": {
    color: colors.white,
    fontSize: iconSize.medium2,
  },
});

const CloseIconContainer = styled("div")({
  background: colors.grey10,
  borderRadius: borderRadius.xlarge1,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  marginLeft: "auto",
  marginBottom: "auto",
  width: "32px",
  height: "32px",
  padding: "0px",
  "& .MuiSvgIcon-root": {
    color: colors.blue01,
    width: "24px",
    height: "24px",
  },
  "& .iconButton": {
    padding: padding.zero,
    backgroundColor: colors.grey10,
  },
});

const scrollBarstyle = {
  maxHeight: "250px",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    width: "14px",
    height: scrollBar.height,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: scrollBar.borderRadius1,
    backgroundColor: colors.grey20,
    border: `${borderSize.medium} solid transparent`,
    backgroundClip: "content-box",
  },
  "&::-webkit-scrollbar-track": {
    background: colors.white,
    boxShadow: "1px 0px 0px 0px #DEDEDE inset",
    borderRadius: "0px 4px 4px 0px",
    border: "1px solid var(--Input-Default-Stoke, #BDBDBD)",
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
};

const StyledDialogContent = styled(Box)({
  maxHeight: "calc(70vh)",
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  // marginBottom: margin.xlarge1,
  "& .failed-upload-status": {
    marginTop: "16px",
    marginBottom: "16px",
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.large,
  },
  "& .success-upload-status": {
    marginTop: margin.large,
    marginBottom: margin.large,
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.large,
  },
  "& .failed-status-grid": {
    ...scrollBarstyle
  },
  "& .failed-status-only-grid": {
    ...scrollBarstyle
  },
  "& .failed-status-grid table, .failed-status-only-grid table": {
    "& td": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  "& .success-status-grid": {
    ...scrollBarstyle
  },
  "& .success-status-only-grid": {
    ...scrollBarstyle
  },
  "& .success-status-grid table, .success-status-only-grid table": {
    "& td": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },

});

const ActionButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: gaps.medium1,
})

const EXPORT_ERROR_MESSAGE_HEADER = "Export Verification Errors Failed!";
const EXPORT_ERROR_MESSAGE_DETAIL = "Please try again. ";

type Props = {
  isOpen: boolean;
  inventoryVerifyErrorDetailList: InventoryVerifyErrorDetail[];
  onClose: () => void;
};

const InventoryVerifyErrorDialog = ({ isOpen, inventoryVerifyErrorDetailList, onClose }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [,{exportVerifyErrors}] = useLookupStore();
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  // FlashMessage 
  const [, { showFlashMessage, closeFlashMessage }] = useFlashMessageActions();  
  useFlashMessage();

  function handleOnClose() {
    onClose && onClose();
  }

  async function handleExportClick() {
    // const uniqueInventoryVerifyIDList = uniqBy(inventoryVerifyErrorDetailList.filter((ive) => hasValue(ive.inventoryVerifyID)), ive => ive.inventoryVerifyID);
    const criteria:InventoryVerifyErrorExportCriteria = {
      // InventoryVerifyIDList: uniqueInventoryVerifyIDList?.map((ive) => ive.inventoryVerifyID!),
      InventoryVerifyID: inventoryVerifyErrorDetailList[0].inventoryVerifyID || 0,
      ExportColumns: [
        "PolicyNumber",
        "ReportingAgencyID",
        "ClientFileID",
        "InventoryVerifyErrorMessage",
      ],

    };
    const result = await exportVerifyErrors(criteria);
    // console.log("result:", result);
    if (result.status === ActionResultStatus.Failed) {
      showFlashMessage(
        FlashMessageType.Error,
        EXPORT_ERROR_MESSAGE_HEADER,
        EXPORT_ERROR_MESSAGE_DETAIL
      );
    }
  }

  function handleFlashMessageClose(
    event: React.SyntheticEvent | Event,
    reason?: string
  ) {
    if (reason === "clickaway") {
      return;
    }

    closeFlashMessage();
  }    

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <StyledDialog open={open}>
        <StyledDialogTitle id="styledDialogTitleDebug">
          <IconContainer>
            <ErrorIcon />
          </IconContainer>
          <Typography id="uploadPolicyImageTitleDebug">
            Verify Errors ({inventoryVerifyErrorDetailList.length})
          </Typography>
          <CloseIconContainer>
            <IconButton className="iconButton" onClick={handleOnClose}>
              {closeIcon}
            </IconButton>
          </CloseIconContainer>
        </StyledDialogTitle>
        <StyledDialogContent>
          <InventoryVerifyErrorGrid
            className="inventory-verify-error-grid"
            inventoryVerifyErrorDetailList={inventoryVerifyErrorDetailList}
          />
          <ActionButtonBox>
            <ActionButton
              color="secondary"
              variant="outlined"
              onClick={handleExportClick}
              disabled={!permissionCodes?.includes(PERMISSIONS.EXPORT_DATA_POLICY)}            >
              Export Errors
            </ActionButton>
            <ActionButton
              color="primary"
              variant="outlined"
              onClick={handleOnClose}
            >
              Done
            </ActionButton>
          </ActionButtonBox>
        </StyledDialogContent>
      </StyledDialog>
      <FlashMessage
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={Number(
          UIConstants.FLASH_MESSAGE_AUTO_HIDDEN_DURATION
        )}
        onClose={handleFlashMessageClose}
      />           
    </>
  );
};

export default InventoryVerifyErrorDialog;
